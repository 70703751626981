import React, { useCallback, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import styled from 'styled-components';

import 'react-quill/dist/quill.snow.css';
import '../assets/css/CustomToolBar.css';

import { useQueryClient } from '@tanstack/react-query';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize';

import { endPointMutation } from '../api/endPoint';
import { queryKeys } from '../react-query/queryKeyConstant';
import { useMutationInstance } from '../react-query/useMutationInstance';
import { usePopup } from './popup/usePopup';

// 드로그앤드롭
Quill.register('modules/imageDrop', ImageDrop);

// 리사이즈등록
Quill.register('modules/ImageResize', ImageResize);

// 이미지크기 제한
const MAX_IMAGE_FILE_SIZE = 3 * 1024 * 1024;

// 사이즈 커스텀
const sizeFixArr = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '30px',
  '36px',
  '48px',
  '60px',
  '72px',
];
const SizeStyle = ReactQuill.Quill.import('attributors/style/size');
SizeStyle.whitelist = sizeFixArr;
ReactQuill.Quill.register(SizeStyle, true);

// 툴바 제작
const CustomToolbar = () => (
  <div id="toolbar">
    {/* 사이즈 드롭다운 */}
    <select className="ql-size" defaultValue="18px">
      {sizeFixArr.map((el) => (
        <option value={el} key={el}>
          {el}
        </option>
      ))}
    </select>

    {/* Font Colors and Backgrounds */}
    <select className="ql-color"></select>
    <select className="ql-background"></select>

    {/* Align options */}
    <select className="ql-align"></select>

    {/* 폰트스타일 (bold 등) */}
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>

    {/* 들여쓰기 옵션 */}
    <button className="ql-indent" value="-1"></button>
    <button className="ql-indent" value="+1"></button>

    {/* 텍스트정렬 */}
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>

    {/* 이미지추가 */}
    <button className="ql-image"></button>
  </div>
);

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'align',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'background',
  'color',
  'link',
  'image',
  'video',
  'width',
];

// 에디터 커스텀
const modules = {
  toolbar: { container: '#toolbar' },
  // 이미지조절모듈추가
  ImageResize: {
    parchment: Quill.import('parchment'),
  },
  imageDrop: true,
  clipboard: {
    matchVisual: false, // toggle to add extra line breaks when pasting HTML:
  },
};

// 메인 컴포넌트 랜더 부분 ----------------------------------------------------------------
// queryKey 입력 필수
const TextEditor_Quill = ({
  onChangeEvent,
  value,
  idx,
  copy,
  queryKey,
  typeCode,
  height = '580px',
  maxWidth = 'auto',
}) => {
  const quillRef = useRef(null);

  const { openPopup } = usePopup();

  // 리액트쿼리 상태 보관
  const queryClient = useQueryClient();

  //mutation 이미지업로드 Post 요청
  const { mutate: uploadIMGMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.quill.uploadFile,
    apiMultipartPost: true,
    onErrorFn: () => {
      return openPopup({
        title: '오류',
        content: '사진을 다시 업로드해주세요.',
      });
    },
    onSuccessFn: (response) => {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(true);

      const url = response.data.img_url;
      const split = { url: url.split('net/')[1] };

      // 쿼리에 상태보관
      const currentData = queryClient.getQueryData([queryKey]) || [];
      const updateQueryData = [...currentData, split];
      queryClient.setQueryData([queryKey], updateQueryData);
      // url이미지로 교체
      editor.insertEmbed(range.index, 'image', url);
      // 이미지 옆으로 편집 커서 이동
      editor.setSelection(range.index + 1);
    },
  });

  //mutation 이미지삭제 Post 요청
  const { mutate: removeIMGMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.quill.removeUploadFile,
    onErrorFn: () => {
      return openPopup({
        title: '오류',
        content: '사진을 다시 업로드해주세요.',
      });
    },
    onSuccessFn: (_, variables) => {
      const { apiBody } = variables;
      // 쿼리 상태보관에서 삭제
      if (apiBody && apiBody?.length > 0) {
        const currentQuery = queryClient.getQueryData([queryKey]);
        if (currentQuery) {
          const updateQuery = currentQuery.filter(
            (el) => !apiBody.some((item) => item.url === el.url),
          );
          if (updateQuery.length > 0) return queryClient.setQueryData([queryKey], updateQuery);
          return queryClient.removeQueries([queryKey]);
        }
        return;
      }
    },
  });

  // 에디터 이미지 delete inspector을 위한 함수 -----
  // 이미지 추출하여 모으기
  const filterImageOp = useCallback((ops) => {
    return ops
      .filter((op) => typeof op.insert === 'object' && op.insert.image)
      .map((op) => {
        const split = op.insert.image.split('net/')[1];
        return { url: split };
      });
  }, []);
  //------------------------------------------

  // delete키 삭제 핸들
  const deleteApi = useCallback(async (inspectorImage) => {
    removeIMGMutation({ apiBody: await inspectorImage });
  }, []);

  // 이미지 업로드 핸들러 등록을 위한 훅
  useEffect(() => {
    // 에디터 DOM이 선택안되면 종료
    if (!quillRef.current) return;

    // 아니라면 아래 실행

    // editor 접근
    const editor = quillRef.current.getEditor();

    // 툴바 이미지 핸들러(file데이터 추출) ---------------
    const handleImage = () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.setAttribute('name', 'img');

      const onInputChange = () => {
        if (input.files[0]) {
          const file = input.files[0];
          // 사이즈 체크
          if (file.size > MAX_IMAGE_FILE_SIZE) {
            openPopup({
              title: '안내',
              content: '파일 크기가 너무 큽니다 3MB 이하의 이미지파일을 입력해주세요.',
            });
          } else {
            const formData = new FormData();
            formData.append('uploadFile', file);
            typeCode ? formData.append('type_code', typeCode) : null;

            // 최종 이미지 등록 요청
            if (formData && formData.has('uploadFile') && formData.has('type_code')) {
              uploadIMGMutation({ apiBody: formData });
            } else {
              return openPopup({
                title: '오류',
                content: '사진을 다시 업로드해주세요.',
              });
            }
          }
        }
        input.removeEventListener('change', onInputChange);
      };

      input.addEventListener('change', onInputChange);
      input.click();
    };
    // --------------------------------------

    // 드롭이미지 핸들러(file데이터 추출용)----------
    const handleDragDrop = (e) => {
      // 드롭시 파일 트랜스퍼 과정을 캐치하여 파일이 들어온지 일단 체크
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
        e.stopPropagation();
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        // 사이즈 체크
        if (file.size > MAX_IMAGE_FILE_SIZE) {
          openPopup({
            title: '안내',
            content: '파일 크기가 너무 큽니다 3MB 이하의 이미지파일을 입력해주세요.',
          });
        } else {
          const formData = new FormData();
          formData.append('uploadFile', file);
          typeCode ? formData.append('type_code', typeCode) : null;

          // 최종 이미지 등록 요청
          if (formData && formData.has('uploadFile') && formData.has('type_code')) {
            uploadIMGMutation({ apiBody: formData });
          } else {
            return openPopup({
              title: '오류',
              content: '사진을 다시 업로드해주세요.',
            });
          }
        }
      }
    };
    // --------------------------------

    // 에디터 접근
    // 드롭시 핸들러 추가(캡쳐단에서 작동하도록, 기본동작방지, true)
    editor.root.addEventListener('drop', handleDragDrop, true);
    // 툴바에 핸들러 추가
    const toolbar = editor.getModule('toolbar');
    toolbar.addHandler('image', handleImage);

    // 이미지 삭제 감지 ------------------
    if (editor) {
      editor.on('text-change', async (delta, oldDelta, source) => {
        if (source === 'user' && delta.ops.some((op) => 'delete' in op)) {
          // 이전 데이터
          const oldHtml = filterImageOp(oldDelta.ops);
          // delete 후 데이터
          const newHtml = filterImageOp(editor.getContents().ops);
          // 이미지 삭제 여부 체크
          const inspectorImage = oldHtml.filter(
            (img) => !newHtml.some((pre) => pre.url === img.url),
          );

          // // API요청
          if (idx && !copy) {
            if (inspectorImage.length > 0 && inspectorImage[0].url) {
              const currentQueryData = queryClient.getQueryData([queryKeys.deleteImageUrlEdit]);
              if (!currentQueryData) {
                queryClient.setQueryData([queryKeys.deleteImageUrlEdit], inspectorImage);
                return;
              } else {
                const newData = [...currentQueryData, inspectorImage[0]];
                queryClient.setQueryData([queryKeys.deleteImageUrlEdit], newData);
                return;
              }
            }
            return;
          } else {
            inspectorImage.length > 0 && inspectorImage[0].url ? deleteApi(inspectorImage) : null;
          }
        }
        return;
      });
    }
    //-------------------

    // 마운트해제시 클린업
    return () => {
      // 툴바는 ReactQuill 언마운트시 자동으로 소멸, 그래서 드롭만 제거
      if (quillRef.current) {
        // 드롭 핸들러 이벤트 삭제
        editor.root.removeEventListener('drop', handleDragDrop, true);
      }
      if (editor) {
        editor.off('text-change');
      }
    };
  }, []);

  // 새로고침시 및 페이지 나갈시 요청
  // *언마운트시 요청은 별도로 해줘야함 -> 이건 부모컴포넌트에서 커스텀
  useEffect(() => {
    // 새로고침용 핸들러
    const reloadHandler = () => {
      queryClient.removeQueries([queryKeys.deleteImageUrlEdit]);
      const removeURLs = queryClient.getQueryData([queryKey]);
      if (removeURLs) {
        removeIMGMutation({ apiBody: removeURLs });
      }
    };
    window.addEventListener('beforeunload', reloadHandler);

    return () => {
      window.removeEventListener('beforeunload', reloadHandler);
    };
  }, []);

  return (
    <QuillContainer height={height} maxWidth={maxWidth}>
      <CustomToolbar />
      <ReactQuill
        value={value}
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        onChange={onChangeEvent}
        style={{ height: '500px' }}
      />
    </QuillContainer>
  );
};

export default React.memo(TextEditor_Quill);

const QuillContainer = styled.div`
  position: relative;
  margin: 0 auto;
  display: block;
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
`;
