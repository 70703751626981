import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';

import { colorList } from '../assets/style/theme';
import BtnNormal from './BtnNormal';
import ImageLayout from './layout/ImageLayout';

const InputCustom = styled.input`
  &::placeholder {
    font-size: ${({ placeHolderFontSize }) => placeHolderFontSize};
  }
`;

const InputBoxFile = ({
  children,
  fileName,
  inputRef,
  onChangeEvent,
  placeHolderText,
  closeOnClickEvent,
  titleFontSize = '14px',
  conMarginBottom = '15px',
  isRequiered = false,
  addFileOnClickEvent,
  previewImgSrc,
  previewImgSize = '60%',
  previewJustifyContent = 'center',
  btnTitle = '이미지 등록',
  btnPadding = '12px 20px',
  btnFontSize = '16px',
  accept = '.jpg, jpeg, .png, .gif',
  placeHolderFontSize,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        marginBottom: conMarginBottom,
      }}
    >
      {children && (
        <p style={{ fontSize: titleFontSize, padding: '10px 0' }}>
          {children} {isRequiered && <span style={{ color: 'rgb(238, 106, 123)' }}>*</span>}
        </p>
      )}

      <div
        style={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* 페이크 인풋 */}
        <InputCustom
          style={{ position: 'relative', display: 'block', width: '100%' }}
          type="text"
          placeHolderFontSize={placeHolderFontSize}
          placeholder={fileName === '' ? placeHolderText : ''}
          disabled
        />
        {fileName !== '' ? (
          <>
            <div
              style={{
                width: '65%',
                wordBreak: 'break-all',
                position: 'absolute',
                color: 'gray',
                fontSize: '16px',
                left: '10px',
              }}
            >
              <span>{fileName}</span>
              <AiOutlineClose
                onClick={closeOnClickEvent}
                style={{
                  position: 'relative',
                  color: '#FF351A',
                  cursor: 'pointer',
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        <BtnNormal
          fontSize={btnFontSize}
          fontWeight="400"
          padding={btnPadding}
          bgColor={colorList.white}
          color={colorList.dark}
          border={'1px solid' + colorList.dark}
          onClickEvent={addFileOnClickEvent}
        >
          {btnTitle}
        </BtnNormal>
      </div>

      {previewImgSrc && (
        <div
          style={{ display: 'flex', justifyContent: previewJustifyContent, alignItems: 'center' }}
        >
          <div style={{ width: previewImgSize }}>
            <ImageLayout imgSrc={previewImgSrc} />
          </div>
        </div>
      )}

      {/* 실제 데이터 들어가는 인풋 */}
      <input
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={onChangeEvent}
      />
    </div>
  );
};

export default React.memo(InputBoxFile);
