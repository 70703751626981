import React, { useEffect } from 'react';

import '../../../assets/css/Story.css';

function Story() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="content_story">
      <div className="buy_con03 custom_pad" id="con01">
        <div className="inner">
          <div className="sub_path">
            <div className="inner">
              <div className="home">
                <p>일빵이야기</p>
              </div>
              <div className="depth01 pret">
                <span>일빵이란?</span>
              </div>
            </div>
          </div>
          <div className="buy_con03_tit">
            <span>일빵이란?</span>
          </div>
          <div className="buy_con03_txt">
            <p>
              "일빵"은 전라도 방언으로 ‘늘, 항상, 언제나’라는 뜻입니다. <br />
              조합의 온라인 사업 이름으로 MRO 사용자뿐만 아니라 제조기업들과 좋을 때나 어려울 때나
              서로 도움이 될 수 있도록 함께 하자는 의미입니다.
            </p>
          </div>
          <div className="buy_con03_list">
            <ul>
              <li>
                <p>
                  <b>Who :</b>
                  <span>MRO 유통산업 종사자</span>
                </p>
              </li>
              <li>
                <p>
                  <b>When : </b>
                  <span>
                    2021년 부천에서 유통산업의 미래에 대한 이야기중 MRO유통 사업의 지속성과 어려운
                    시장 환경 극복을 위해서 단체 결성의 필요성과 온라인 유통산업과 오프라인
                    유통산업의 결합으로 시너지가 필요한 시점이라는 판단에서 시작이 되었습니다.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <b>What : </b>
                  <span>
                    {' '}
                    국가 산업 발전과 MRO 유통산업 환경 개선, MRO 유통산업의 미래를 위한 준비
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <b>Why : </b>
                  <span>어려워진 시장 환경 극복, off-line 유통 한계성 극복, 시너지 극대화</span>
                </p>
              </li>
              <li>
                <p>
                  <b>How : </b>
                  <span>유통산업 종사자들이 함께 ON/OFF 플랫폼을 만들고 다양한 사업 전개</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="buy_con04">
        <div className="inner">
          <div className="buy_con04_tit">
            <span>일빵에서 공동구매 진행 목적</span>
          </div>
          <div className="buy_con04_txt">
            <p>
              공동구매 목적은 저렴한 가격에 좋은 제품을 구매 하는데 목적이 있습니다. 가격 경쟁력이
              생명이 되어가는 시점에서 공동구매는 경쟁력의 핵심입니다. 따라서 개별 유통점이 아무리
              많이 구매를 하여도 <br />
              자본의 한계성으로 인하여 대량 구매에 의한 가격 다운은 어려운 것이 사실 입니다.
              적극적인 공동구매로 경영의 어려움과 안정된 거래처를 유지 관리하여야 합니다.
            </p>
          </div>
          <div className="buy_con04_txt_list_box">
            <ul>
              <li>
                <span>01</span>
                <p>경영의 안정화</p>
              </li>
              <li>
                <span>02</span>
                <p>적정 납품가로 거래처 유지</p>
              </li>
              <li>
                <span>03</span>
                <p>경쟁력 확보</p>
              </li>
              <li>
                <span>04</span>
                <p>이익의 극대화</p>
              </li>
              <li>
                <span>05</span>
                <p>사업의 영속성 확보</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="buy_con03 buy_con05">
        <div className="inner">
          <div className="buy_con03_tit">
            <span>조합 주관 공동구매</span>
          </div>
          <div className="buy_con03_txt">
            <p>조합이 직접 주관하여 MRO 제조기업과 협약을 통하여 공동구매 진행</p>
          </div>
          <div className="buy_con05_list">
            <ul>
              <li>
                <p>
                  <b>01.</b>
                  <span>공동구매 제품 선정</span>
                </p>
              </li>
              <li>
                <p>
                  <b>02.</b>
                  <span>가격 책정</span>
                </p>
              </li>
              <li>
                <p>
                  <b>03.</b>
                  <span>제품공지</span>
                </p>
              </li>
              <li>
                <p>
                  <b>04.</b>
                  <span>신청 및 접수</span>
                </p>
              </li>
              <li>
                <p>
                  <b>05.</b>
                  <span>각 지역회장에게 지역별 회원사 발주 수량 배송</span>
                </p>
              </li>
              <li>
                <p>
                  <b>06.</b>
                  <span>주문자가 해당 지역회장 거점에서 제품 pick-up</span>
                </p>
              </li>
              <li>
                <p>
                  <b>07.</b>
                  <span>수량 확인 및 완료</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="buy_con03 buy_con05 buy_con06">
        <div className="inner">
          <div className="buy_con03_tit">
            <span>회원 주관 공동구매 </span>
          </div>
          <div className="buy_con03_txt">
            <p>
              회원 누구나 자유롭게 공동구매 할 제품을 등록하여 진행하는 공동 구매 진행하는
              서비스입니다.
            </p>
          </div>
          <div className="buy_con05_list">
            <ul>
              <li>
                <p>
                  <b>01.</b>
                  <span>공동 구매 제품등록(제품가격 책정)</span>
                </p>
              </li>
              <li>
                <p>
                  <b>02.</b>
                  <span>전체 공동 구매 수량 등록</span>
                </p>
              </li>
              <li>
                <p>
                  <b>03.</b>
                  <span>제품 공동구매 회원 모집</span>
                </p>
              </li>
              <li>
                <p>
                  <b>04.</b>
                  <span>공동구매 수량확인</span>
                </p>
              </li>
              <li>
                <p>
                  <b>05.</b>
                  <span>진행</span>
                </p>
              </li>
              <li>
                <p>
                  <b>06.</b>
                  <span>완료</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="buy_con06">
        <div className="inner">
          <div className="img_box">
            <ul>
              <li>
                <img src="/src/assets/img/sub/buy_con06_img01.png" alt="" />
              </li>
              <li>
                <img src="/src/assets/img/sub/buy_con06_img02.png" alt="" />
              </li>
              <li>
                <img src="/src/assets/img/sub/buy_con06_img03.png" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="buy_con04 buy_con07" id="con02">
        <div className="inner">
          <div className="buy_con04_tit">
            <span>일빵에서 공동 구매 시스템</span>
          </div>
          <div className="btn_box">
            <a href="">
              서비스 이용 문의
              <i>
                <img src="/src/assets/img/icon/buy_con07_icon01.png" alt="" />
              </i>
            </a>
          </div>
          <div className="buy_con04_txt_list_box">
            <ul>
              <li>
                <span>01</span>
                <p>거래처 납품가 대응 </p>
              </li>
              <li>
                <span>02</span>
                <p>경쟁력 확보 </p>
              </li>
              <li>
                <span>03</span>
                <p>수익의 극대화</p>
              </li>
              <li>
                <span>04</span>
                <p>제품 단가 파악</p>
              </li>
              <li>
                <span>05</span>
                <p>제품 정보 획득</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="buy_con01">
        <div className="inner">
          <div className="buy_con01_bg">
            <div className="buy_con01_txtbox">
              <p>
                조합주관 공동구매 과정 <br />
                <b>조합이 직접 주관하여 MRO 제조기업과 협약을 통하여 공동구매 진행 함</b>
              </p>
            </div>
            <div className="buy_con01_imgbox">
              <ul>
                <li>
                  <div className="circle">
                    <i>
                      <img src="/img/icon/buy_con01_icon01.png" alt="공동구매 제품 신청" />
                    </i>
                  </div>
                  <span>01</span>
                  <p>공동구매 제품 선정</p>
                </li>
                <li>
                  <div className="circle">
                    <i>
                      <img src="/img/icon/buy_con01_icon02.png" alt="가격 책정" />
                    </i>
                  </div>
                  <span>02</span>
                  <p>가격 책정</p>
                </li>
                <li>
                  <div className="circle">
                    <i>
                      <img src="/img/icon/buy_con01_icon03.png" alt="제품공지" />
                    </i>
                  </div>
                  <span>03</span>
                  <p>제품공지</p>
                </li>
                <li>
                  <div className="circle">
                    <i>
                      <img src="/img/icon/buy_con01_icon04.png" alt="신청 및 접수" />
                    </i>
                  </div>
                  <span>04</span>
                  <p>신청 및 접수</p>
                </li>
                <li>
                  <div className="circle">
                    <i>
                      <img
                        src="/img/icon/buy_con01_icon05.png"
                        alt="지역회장에게 지역별 회원사 발주 수량 배송"
                      />
                    </i>
                  </div>
                  <span>05</span>
                  <p>
                    지역회장에게 <br />
                    지역별 회원사 <br />
                    발주 수량 배송
                  </p>
                </li>
                <li>
                  <div className="circle">
                    <i>
                      <img
                        src="/img/icon/buy_con01_icon06.png"
                        alt="주문자가 해당 지역회장 거정ㅁ에서 제품 pick-up"
                      />
                    </i>
                  </div>
                  <span>06</span>
                  <p>
                    주문자가 해당 <br />
                    지역회장 거점에서 <br />
                    제품 pick-up
                  </p>
                </li>
                <li>
                  <div className="circle">
                    <i>
                      <img src="/img/icon/buy_con01_icon07.png" alt="수량 확인 및 완료" />
                    </i>
                  </div>
                  <span>07</span>
                  <p>수량 확인 및 완료</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="buy_con08">
        <div className="inner">
          <div className="buy_con08_con01">
            <div className="tit_box">
              <span>
                회원 주관 공동구매 과정
                <br />
                <b>조합 회원은 누구나 자유롭게 제품을 등록하여 공동 구매를 진행 할 수 있음</b>
              </span>
            </div>
            <div className="list_box">
              <ul>
                <li>
                  <span>01</span>
                  <img src="/img/sub/buy_con07_img01.png" alt="제품 등록 가격" />
                  <p>제품 등록(가격)</p>
                </li>
                <li>
                  <span>02</span>
                  <img src="/img/sub/buy_con07_img02.png" alt="총 수량 등록" />
                  <p>총 수량 등록</p>
                </li>
                <li>
                  <span>03</span>
                  <img src="/img/sub/buy_con07_img03.png" alt="구매회원 모집" />
                  <p>구매회원 모집</p>
                </li>
                <li>
                  <span>04</span>
                  <img src="/img/sub/buy_con07_img04.png" alt="주문확인" />
                  <p>주문확인</p>
                </li>
                <li>
                  <span>05</span>
                  <img src="/img/sub/buy_con07_img05.png" alt="진행" />
                  <p>진행</p>
                </li>
                <li>
                  <span>06</span>
                  <img src="/img/sub/buy_con07_img06.png" alt="완료" />
                  <p>완료</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="buy_con08_btn">
        <div className="inner">
          <div className="btn_box">
            <span>공동 구매 등록</span>
          </div>
        </div>
      </div>
      <div className="buy_con09" id="con03">
        <div className="inner">
          <div className="buy_con09_bg">
            <div className="buy_con09_tit">
              <span>일빵 광역 긴급구매 시스템</span>
              <p>
                기업에 재고가 없거나 패킹 수량과 구매 수량이 맞지 않아서 구매가 어려운 제품을 전국
                유통점을 상대로 제품을 찾아서 구매 할 수 있게 해주는 서비스 입니다.
              </p>
            </div>
            <div className="btn_box">
              <a href="">
                서비스 이용 문의
                <i>
                  <img src="/src/assets/img/icon/buy_con09_icon.png" alt="" />
                </i>
              </a>
            </div>
            <div className="buy_con09_border_box">
              <div className="buy_con09_sub_tit">
                <p>
                  광역 긴급구매 과정
                  <b>
                    전국에 있는 회원들에게 구매 정보를 공지하여 소량의 제품을 찾아서 구매 할 수
                    있도록 지원하는 서비스
                  </b>
                </p>
              </div>

              <div className="buy_con09_desc_tit">
                <span>소량 구매 어려움 지원을 위한 서비스</span>
              </div>
              <div className="buy_con09_list_box">
                <ul>
                  <li>
                    <span>1</span>
                    <p>구매 제품 등록</p>
                  </li>
                  <li>
                    <span>2</span>
                    <p>회원 문자 자동 발송</p>
                  </li>
                  <li>
                    <span>3</span>
                    <p>제품 보유자 연결</p>
                  </li>
                  <li>
                    <span>4</span>
                    <p>제품 구매</p>
                  </li>
                  <li>
                    <span>5</span>
                    <p>구매 완료</p>
                  </li>
                </ul>
              </div>
              <div className="buy_con09_btn_box">
                <a>지역 긴급 구매 등록</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buy_con09 buy_con10">
        <div className="inner">
          <div className="buy_con09_bg">
            <div className="buy_con09_border_box">
              <div className="buy_con09_sub_tit">
                <p>
                  지역 긴급구매 과정
                  <b>
                    근처에 있는 회원들에게 구매 정보를 공지하여 즉시 제품을 구입하여 소비자에게 판매
                    할 수 있는 시스템
                  </b>
                </p>
              </div>

              <div className="buy_con09_desc_tit">
                <span>재고가 없을 때 즉시 구매하여 판매하기 위한 서비스</span>
              </div>
              <div className="buy_con09_list_box">
                <ul>
                  <li>
                    <span>1</span>
                    <p>제품 등록</p>
                  </li>
                  <li>
                    <span>2</span>
                    <p>회원 문자 자동 발송</p>
                  </li>
                  <li>
                    <span>3</span>
                    <p>제품 보유자 연결</p>
                  </li>
                  <li>
                    <span>4</span>
                    <p>제품 구매</p>
                  </li>
                  <li>
                    <span>5</span>
                    <p>구매 완료</p>
                  </li>
                </ul>
              </div>
              <div className="buy_con09_btn_box">
                <a>지역 긴급 구매 등록</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Story;
