import React from 'react';
import { TbReload } from 'react-icons/tb';
import { TiArrowLeftThick } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { colorList } from '../assets/style/theme';
import BtnNormal from './BtnNormal';
import InputSearch from './InputSearch';
import SelectCustom from './SelectCustom';

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: ${({ conFlexDirection }) => conFlexDirection};
  justify-content: ${({ conJustfyContent }) => conJustfyContent};
  align-items: ${({ conAlignItems }) => conAlignItems};
  width: 100%;
  margin: ${({ conMargin }) => conMargin};
  padding: ${({ conPadding }) => conPadding};
  border-bottom: ${({ borderBottom }) => borderBottom};
  gap: ${({ conGap }) => conGap};
`;

const Title = styled.h1`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  padding: ${({ titlePadding }) => titlePadding};
`;

// searchAndFilterMode 스타일 ---
// 좌측모드
const SubSearchText = styled.p`
  font-size: ${({ subSearchTextFontSize }) => subSearchTextFontSize};
  margin: ${({ subSearchTextMargin }) => subSearchTextMargin};
`;

// 우측모드
const RightBoxWrap = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 600px;
  gap: 10px;
`;
const InputSearchWrap = styled.div`
  width: ${({ noneSearchType }) => (noneSearchType ? '50%' : '100%')};
  border-color: ${colorList.lightGray};
  border-style: solid;
  border-width: 0 2px 0 0;
  padding-right: 20px;
  margin-right: 10px;
`;
// ----

const HeaderTitle = ({
  children,
  fontSize = '20px',
  fontWeight = '700',
  titlePadding,
  conPadding = '0 0 20px 0',
  conMargin = '0 0 20px 0',
  conJustfyContent = 'space-between',
  selectorData,
  selectorChangeEvent,
  selectorValue,
  selectorMaxWidth,
  selectorHeight,
  selectorFontSize,
  selectorPadding,
  selectorFontColor,
  btnTitle,
  btnBgColor,
  btnPadding,
  btnBorderRadius,
  btnColor,
  btnFontSize,
  btnClassName,
  btnOnClickEvent,
  btnFontWeight,
  btnDisabled,
  btnBorder,
  btnType,
  borderBottom = 'solid 2px black;',
  conGap,
  conFlexDirection = 'row',
  conAlignItems = 'center',
  backBtn = false,

  // 서치 & 필터모드 설정 ----
  searchAndFilterMode = false,
  // 서치는 쿼리파람스 밸류를 배열로 받는다.(샘플 참조)
  searchParamsValueArr = ['쿼리값 샘플', '쿼리값 샘플'],
  // '0'은 항상 '모두보기'
  statusCode,
  // 셀렉터 구성을 위한 프롭스 '검색용필터'와 '일반필터' 2개로 나누어 받는다.
  // 구성은 아래 샘플 참조
  searchAndFilterLabelArr = {
    filterLabel: [
      { label: '필터샘플1', value: '1-1' },
      { label: '필터샘플2', value: '2-2' },
      { label: '필터샘플3', value: '3-3' },
    ],
    searchTypeLabel: [
      { label: '서치샘플1', value: '1' },
      { label: '서치샘플2', value: '2' },
    ],
  },
  subSearchTextFontSize,
  subSearchTextMargin = '10px 0 0 0',
  onClickEventSearchReset,
  searchAndFilterTypeSelectorMaxWidth = '150px',
  onChangeEventSearchAndFilterTypeSelector,
  searchAndFilterTypeSelectorValue,
  searchAndFilterInputRef,
  onClickEventSearchAndFilterInput,
  searchAndFilterSelectorValue,
  searchAndFilterSelectorMaxWidth = '130px',
  onChangeEventSearchAndFilterSelector,
  searchAndFilterinputPlacehlder,
  searchAndFilterinputPlacehlderFontSize,
  // --------------------
}) => {
  // 현재 검색어 찾기
  const searchValue = searchAndFilterMode && searchParamsValueArr?.find((el) => el);
  // 현재 필터 라벨 찾기
  const filterValue =
    searchAndFilterMode &&
    searchAndFilterLabelArr?.filterLabel?.find((el) => el.value === Number(statusCode));
  const filterLabel = filterValue ? filterValue.label : '';

  const navigate = useNavigate();

  return (
    <>
      {backBtn && searchAndFilterMode && !selectorData && (
        <BtnNormal
          bgColor={colorList.main}
          onClickEvent={() => navigate(-1)}
          padding="10px"
          fontWeight="200"
          margin="30px 0 0 0"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
            }}
          >
            <TiArrowLeftThick style={{ fontSize: '20px' }} color={colorList.white} />
            <span style={{ fontSize: '15px' }}>뒤로가기</span>
          </div>
        </BtnNormal>
      )}

      <Container
        conAlignItems={conAlignItems}
        conFlexDirection={conFlexDirection}
        conMargin={conMargin}
        conPadding={conPadding}
        conJustfyContent={conJustfyContent}
        borderBottom={borderBottom}
        conGap={conGap}
      >
        {!searchAndFilterMode && (
          <Title titlePadding={titlePadding} fontSize={fontSize} fontWeight={fontWeight}>
            {children}
          </Title>
        )}
        {selectorData && !searchAndFilterMode && (
          <SelectCustom
            data={selectorData}
            onChangeEvent={selectorChangeEvent}
            value={selectorValue}
            maxWidth={selectorMaxWidth}
            height={selectorHeight}
            fontSize={selectorFontSize}
            padding={selectorPadding}
            color={selectorFontColor}
          />
        )}
        {btnTitle && !searchAndFilterMode && (
          <BtnNormal
            bgColor={btnBgColor}
            padding={btnPadding}
            borderRadius={btnBorderRadius}
            color={btnColor}
            fontSize={btnFontSize}
            className={btnClassName}
            onClickEvent={btnOnClickEvent}
            fontWeight={btnFontWeight}
            disabled={btnDisabled}
            border={btnBorder}
            buttonType={btnType}
          >
            {btnTitle}
          </BtnNormal>
        )}

        {backBtn && !btnTitle && !searchAndFilterMode && (
          <BtnNormal
            bgColor={colorList.main}
            onClickEvent={() => navigate(-1)}
            padding="10px"
            fontWeight="200"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <TiArrowLeftThick style={{ fontSize: '20px' }} color={colorList.white} />
              <span style={{ fontSize: '15px' }}>뒤로가기</span>
            </div>
          </BtnNormal>
        )}

        {searchAndFilterMode && !selectorData && (
          <>
            {/* 좌측박스(타이틀, 검색되는 서브타이틀) */}
            <div>
              <Title titlePadding={titlePadding} fontSize={fontSize} fontWeight={fontWeight}>
                {children}
              </Title>
              {searchValue && (
                <SubSearchText
                  subSearchTextMargin={subSearchTextMargin}
                  subSearchTextFontSize={subSearchTextFontSize}
                >
                  [{searchValue}] 에 대한 검색 결과
                </SubSearchText>
              )}
              {statusCode && statusCode !== '0' && filterLabel && (
                <SubSearchText
                  subSearchTextMargin={subSearchTextMargin}
                  subSearchTextFontSize={subSearchTextFontSize}
                >
                  [{filterLabel}] 에 대한 필터 결과
                </SubSearchText>
              )}
            </div>

            {/* 우측박스(검색창 및 필터 셀렉터영역) */}
            <RightBoxWrap>
              {searchValue && (
                <TbReload
                  size={!searchAndFilterLabelArr?.searchTypeLabel ? '25' : '60'}
                  color={colorList.main}
                  style={{ cursor: 'pointer' }}
                  onClick={onClickEventSearchReset}
                />
              )}

              {searchAndFilterLabelArr?.searchTypeLabel && (
                <SelectCustom
                  data={searchAndFilterLabelArr?.searchTypeLabel}
                  value={searchAndFilterTypeSelectorValue}
                  maxWidth={searchAndFilterTypeSelectorMaxWidth}
                  onChangeEvent={onChangeEventSearchAndFilterTypeSelector}
                />
              )}

              {searchAndFilterInputRef && (
                <InputSearchWrap noneSearchType={!searchAndFilterLabelArr?.searchTypeLabel}>
                  <InputSearch
                    inputPlacehlder={searchAndFilterinputPlacehlder}
                    placeholderFontSize={searchAndFilterinputPlacehlderFontSize}
                    boxWidth="100%"
                    boxHeight="38px"
                    borderRadius="5px"
                    inputPadding="10px"
                    inputRef={searchAndFilterInputRef}
                    iconOnClickEvent={onClickEventSearchAndFilterInput}
                  />
                </InputSearchWrap>
              )}

              {searchAndFilterLabelArr?.filterLabel && (
                <SelectCustom
                  data={searchAndFilterLabelArr?.filterLabel}
                  value={searchAndFilterSelectorValue}
                  maxWidth={searchAndFilterSelectorMaxWidth}
                  onChangeEvent={onChangeEventSearchAndFilterSelector}
                />
              )}
            </RightBoxWrap>
          </>
        )}
      </Container>
    </>
  );
};

export default React.memo(HeaderTitle);
