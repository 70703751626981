import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../../../../assets/css/Sub.css';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { endPoint, endPointMutation } from '../../../../api/endPoint';
import style from '../../../../assets/css/PhoneAuth.module.scss';
import { colorList } from '../../../../assets/style/theme.jsx';
import BtnNormal from '../../../../components/BtnNormal.jsx';
import ErrorFetching from '../../../../components/error/ErrorFetching';
import InputBox from '../../../../components/InputBox.jsx';
import MyPageButtonComponent from '../../../../components/MyPageButtonComponent.jsx';
import { usePopup } from '../../../../components/popup/usePopup.js';
import { BankStandardCodeList } from '../../../../constant/BankStandardCodeList';
import { TIME } from '../../../../constant/NUMBER_CONSTANT.js';
import { isRegexCheck, REGEX } from '../../../../constant/REGEX.js';
import { queryKeys } from '../../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../../react-query/useQueryInstance';
import { IsLoggedIn } from '../../../../utils/IsLoggedIn.js';
import ValidateInput from '../../../../utils/ValidateInput';

function MemberInfoEdit2() {
  const { openPopup } = usePopup();

  const navigate = useNavigate();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  // 회원정보 불러오는 api
  const {
    data: { data },
    isError,
  } = useQueryInstance({
    apiMethod: 'get',
    apiEndPoint: endPoint.member.getMember,
    queryKey: [queryKeys.getMember],
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: 0,
  });

  const [memberId, setMemberId] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  // 휴대폰 번호 입력 유효성검사 핸들
  const inputPhoneHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyNumCheck,
      fn: (value) => setPhoneNumber(value),
      checkLengthOpt: { length: 11, comparison: '<=' },
    });
  }, []);
  // 인증번호 성공시 성공 값
  const [isPhoneSuccess, setIsPhoneSuccess] = useState(false);

  const [email, setEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postCode, setPostCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [mainProduct, setMainProduct] = useState('');
  // 글자 수 제한
  const mainProductHandler = (e) => {
    const value = e.target.value;
    if (value.length <= 10 || value === '') {
      setMainProduct(value);
    }
  };
  const [officePhoneNumber, setOfficePhoneNumber] = useState('');
  const [bankStandardCode, setBankStandardCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [businessNumber, setBusinessNumber] = useState('');

  // 주소데이터
  const openPostcodePopup = () => {
    const win = window.open('/address/search_address', 'postCode', 'width=500,height=500');
    window.addEventListener('message', (event) => {
      const addressData = event.data;

      setAddress1(addressData.address); // 주소
      setPostCode(addressData.zonecode); // 우편번호
      // 필요한 경우 기타 로직 추가
    });
  };

  // 회원정보 셋
  useEffect(() => {
    if (!data) {
      return;
    }

    // 1차 검증 체크
    const memberEditCheck = JSON.parse(localStorage.getItem('member_edit'));

    if (!memberEditCheck) {
      navigate(-1);
    }

    // 체크 끝나면 셋
    localStorage.removeItem('member_edit');

    setMemberId(data.member_id);
    setName(data.name);
    setPhoneNumber(data.phone_number);
    setEmail(data.email);

    const addressData = data.address;
    if (addressData !== undefined) {
      setAddress1(addressData.address1);
      setAddress2(addressData.address2);
      setPostCode(addressData.post_code);
    }
    setCompanyName(data.company_name);
    setMainProduct(data.main_product);
    setOfficePhoneNumber(data.office_phone_number);
    setBankStandardCode(data.bank_standard_code);
    setAccountNumber(data.account_number);
    setBusinessNumber(data.business_number);
  }, [data]);

  function isValidEmail(email) {
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  const validateJoin = () => {
    var check = false;

    if (!ValidateInput(name)) {
      openPopup({ title: '안내', content: '이름을 입력해주세요.' });
      return;
    }
    if (!ValidateInput(phoneNumber)) {
      openPopup({ title: '안내', content: '휴대폰 번호를 입력해주세요.' });
      return;
    }
    if (!ValidateInput(address1)) {
      openPopup({ title: '안내', content: '사업장 주소를 입력해주세요.' });
      return;
    }
    if (!ValidateInput(companyName)) {
      openPopup({ title: '안내', content: '회사명을 입력해주세요.' });
      return;
    }
    if (!ValidateInput(mainProduct)) {
      openPopup({ title: '안내', content: '주요 취급 품목을 입력해주세요.' });
      return;
    }

    if (ValidateInput(email)) {
      if (!isValidEmail(email)) {
        openPopup({ title: '안내', content: '이메일 형식을 맞춰주세요.' });
        return;
      }
    }
    if (!ValidateInput(bankStandardCode)) {
      openPopup({ title: '안내', content: '정산 은행을 선택해주세요.' });
      return;
    }
    if (!ValidateInput(accountNumber)) {
      openPopup({ title: '안내', content: '정산 계좌번호를 입력해주세요.' });
      return;
    }
    check = true;

    return check;
  };

  const { mutate: onEditMemberInfoMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.editMemberInfo,
    onErrorFn: (error) => {
      const errorData = error.response.data;
      openPopup({
        title: '오류',
        content: errorData.message,
      });
    },
    onSuccessFn: () => {
      openPopup({
        title: '안내',
        content: '회원 정보가 수정되었습니다.',
        onComplete: () => window.location.reload(),
      });
      navigate('/pages/member/mypage/edit1');
    },
  });

  const onCancel = () => {
    window.history.back();
  };

  const onEdit = () => {
    if (!validateJoin()) {
      return;
    }

    if (data?.phone_number !== phoneNumber) {
      if (!isPhoneSuccess)
        return openPopup({ title: '안내', content: '변경하시는 휴대폰 인증을 진행해주세요.' });
    }

    const newData = {
      name: name.trim(),
      phone_number: phoneNumber.trim(),
      email: email,
      address1: address1,
      address2: address2,
      post_code: postCode,
      company_name: companyName.trim(),
      main_product: mainProduct,
      office_phone_number: officePhoneNumber,
      bank_standard_code: bankStandardCode,
      account_number: accountNumber,
    };

    onEditMemberInfoMutation({ apiBody: newData });
  };

  // 인증번호 입력 모드를 위한 훅
  const [onAuthTime, setOnAuthTime] = useState(false);

  // 카운트 다운 값
  const [time, setTime] = useState(moment.duration(TIME.SET_TIME, 'minutes'));

  // 인증번호 받기
  const [authNum, setAuthNum] = useState('');

  // 인증번호 유효성검사 핸들
  const inputAuthHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyNumCheck,
      fn: (value) => setAuthNum(value),
      checkLengthOpt: { length: 6, comparison: '<=' },
    });
  }, []);

  // 카운트다운 컨트롤 훅
  useEffect(() => {
    if (!onAuthTime) return;

    const interval = setInterval(() => {
      setTime((pre) => {
        const newTime = pre.clone().subtract(1, 'second');
        if (newTime.asSeconds() <= 0) {
          clearInterval(interval);
          setOnAuthTime(false);
          setAuthNum('');
          return moment.duration(TIME.SET_TIME, 'minutes');
        }
        return newTime;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [onAuthTime]);

  // 인증코드 요청 api
  const { mutate: onSendMessage } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.send.sendVerifyPhoneNumber,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;

      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: (_, variable) => {
      if (variable.type) {
        setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
        openPopup({
          title: '인증번호 재전송 완료',
          content: '새로운 인증번호로 다시 입력해주세요.',
        });
        setAuthNum('');
        setOnAuthTime(true);
        return;
      }

      setOnAuthTime(true);
    },
  });

  // 인증코드 요청 API 핸들
  const phoneNumberCheck = useCallback(
    (e, type) => {
      e.preventDefault();
      if (!phoneNumber) return openPopup({ title: '안내', content: '휴대폰 번호를 입력해주세요.' });

      onSendMessage({
        apiQueryParams: {
          phone_number: phoneNumber,
        },
        type,
      });
    },
    [phoneNumber],
  );

  // 최종 전화번호 인증 api
  const { mutate: verifyMemberWithCode } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.send.verifyPhoneNumberWithCode,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: (response) => {
      setOnAuthTime(false);
      setAuthNum('');
      setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
      setIsPhoneSuccess(response.data);
    },
  });

  // 최종 전화번호 인증 api 핸들
  const finalAuthApiHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (!authNum) return openPopup({ title: '안내', content: '인증번호를 입력해주세요.' });

      const data = {
        phone_number: phoneNumber,
        verify_code: authNum,
      };

      verifyMemberWithCode({
        apiBody: data,
      });
    },
    [authNum],
  );

  // 변경 취소 핸들러
  const cancel = () => {
    setPhoneNumber(data?.phone_number);
    setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
    setAuthNum('');
    setOnAuthTime(false);
  };

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <div className="content">
      <div className="container section">
        <div className="my_page">
          <MyPageButtonComponent
            dataList={[
              { title: '회원정보수정', current: true, url: '/pages/member/mypage/edit1' },
              { title: '주문내역', current: false, url: '/pages/member/mypage/order_list' },
              { title: '판매내역', current: false, url: '/pages/member/mypage/sales_list' },
              { title: '내상품', current: false, url: 'pages/member/mypage/product_list' },
              { title: '내가 쓴 글', current: false, url: '/pages/member/mypage/my_board_list' },
              // { title: '견적Chat', current: false, url: '/pages/member/mypage/my_estimate_index' },
            ]}
          />

          <div className="con-list" style={{ maxWidth: '1000px', margin: '50px auto' }}>
            <div className="con-item">
              <div className="item-head">
                <p className="title">회원정보 수정</p>
              </div>
            </div>
          </div>
          <div className="input-list" style={{ maxWidth: '1000px', margin: 'auto' }}>
            <div className="input-item">
              <div className="input-head">
                <span className="title">아이디</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box id-input"
                  disabled={true}
                  value={memberId}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">이름</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            {/*<div className="input-item">*/}
            {/*  <div className="input-head">*/}
            {/*    <span className="title">비밀번호</span>*/}
            {/*    <span className="required"> *</span>*/}
            {/*  </div>*/}
            {/*  <div className="input-body">*/}
            {/*    <input*/}
            {/*        type="password"*/}
            {/*        className="input-box"*/}
            {/*        // onChange={(e) => setPassword(e.target.value)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="input-item">*/}
            {/*  <div className="input-head">*/}
            {/*    <span className="title">비밀번호확인</span>*/}
            {/*    <span className="required"> *</span>*/}
            {/*  </div>*/}
            {/*  <div className="input-body">*/}
            {/*    <input*/}
            {/*        type="password"*/}
            {/*        className="input-box"*/}
            {/*        // onChange={(e) => setConfirmPassword(e.target.value)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="input-item">
              <div className="input-head">
                <span className="title">휴대폰</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body" style={{ display: 'flex', gap: '5px' }}>
                <input
                  style={{ flex: '6.5' }}
                  type="number"
                  className="input-box"
                  value={phoneNumber}
                  onWheel={(e) => e.target.blur()}
                  placeholder="- 를 제외해서 입력해주세요."
                  onChange={inputPhoneHandler}
                  disabled={onAuthTime || isPhoneSuccess}
                />
                <BtnNormal
                  flex="4"
                  color={
                    data?.phone_number === phoneNumber ||
                    phoneNumber.length < 10 ||
                    onAuthTime ||
                    isPhoneSuccess
                      ? '1px solid #c2c2c2'
                      : '1px solid #424242'
                  }
                  bgColor={colorList.white}
                  border={
                    data?.phone_number === phoneNumber ||
                    phoneNumber.length < 10 ||
                    onAuthTime ||
                    isPhoneSuccess
                      ? '1px solid #c2c2c2'
                      : '1px solid #424242'
                  }
                  fontSize="15px"
                  fontWeight="500"
                  disabled={
                    data?.phone_number === phoneNumber ||
                    phoneNumber.length < 10 ||
                    onAuthTime ||
                    isPhoneSuccess
                  }
                  onClickEvent={phoneNumberCheck}
                  activeOpacity={
                    data?.phone_number === phoneNumber ||
                    phoneNumber.length < 10 ||
                    onAuthTime ||
                    isPhoneSuccess
                      ? '1'
                      : '0.5'
                  }
                >
                  전화번호 변경
                </BtnNormal>
              </div>
              {onAuthTime && (
                <>
                  <div className={style.authContainer}>
                    <div className={style.authBoxWrap}>
                      {/* 카운트다운 영역 */}
                      <p className={style.countDown}>
                        {moment.utc(time.asMilliseconds()).format('mm:ss')}
                      </p>
                      <InputBox
                        inputValue={authNum}
                        htmlFor="auth"
                        placeholder="인증번호 6자리"
                        onChangeEvent={inputAuthHandler}
                      >
                        인증번호
                      </InputBox>
                    </div>
                    <BtnNormal
                      flex="1.8"
                      fontSize="15px"
                      fontWeight="500"
                      bgColor={colorList.white}
                      color={authNum.length >= 6 && onAuthTime ? '#424242' : '#c2c2c2'}
                      border={
                        authNum.length >= 6 && onAuthTime
                          ? '1px solid #424242'
                          : '1px solid #c2c2c2'
                      }
                      padding="15px 5px"
                      className={style.resend_btn}
                      disabled={authNum.length < 6}
                      onClickEvent={finalAuthApiHandler}
                    >
                      인증하기
                    </BtnNormal>
                    <BtnNormal
                      flex="0.5"
                      fontSize="15px"
                      fontWeight="500"
                      bgColor={colorList.darkBlue}
                      color={colorList.white}
                      padding="15px 10px"
                      className={style.resend_btn}
                      onClickEvent={(e) => phoneNumberCheck(e, true)}
                    >
                      재전송
                    </BtnNormal>
                    <BtnNormal
                      flex="0.5"
                      fontSize="15px"
                      fontWeight="500"
                      className={style.resend_btn}
                      padding="15px 10px"
                      bgColor={colorList.darkBlue}
                      color={colorList.white}
                      onClickEvent={cancel}
                    >
                      변경취소
                    </BtnNormal>
                  </div>
                  <p className={style.auth_subtitle}>※ 인증번호를 입력해주세요.</p>
                </>
              )}
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">이메일</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">사업장 주소</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body address">
                <input type="text" className="input-box first-address" value={address1} disabled />
                <button type="button" className="input-btn" onClick={openPostcodePopup}>
                  우편번호
                </button>
                <input
                  type="text"
                  className="input-box second-address"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">회사명</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">주요 취급 품목</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  value={mainProduct}
                  type="text"
                  className="input-box"
                  onChange={mainProductHandler}
                  placeholder="10자 이하 (띄어쓰기 포함)"
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <p className="title">회사 전화번호</p>
              </div>
              <div className="input-body">
                <input
                  type="number"
                  className="input-box"
                  value={officePhoneNumber}
                  onWheel={(e) => e.target.blur()}
                  placeholder="- 를 제외해서 입력해주세요."
                  onChange={(e) => setOfficePhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">정산 은행</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <select
                  value={bankStandardCode}
                  onChange={(e) => {
                    setBankStandardCode(e.target.value);
                  }}
                >
                  <option value="">- 은행 선택 -</option>
                  {BankStandardCodeList().map((options) => (
                    <option key={options.code} value={options.code}>
                      {options.bankName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">정산 계좌번호</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="number"
                  className="input-box"
                  value={accountNumber}
                  onWheel={(e) => e.target.blur()}
                  placeholder="- 를 제외해서 입력해주세요."
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </div>
            </div>
            {/*<div className="input-item">*/}
            {/*  <div className="input-head">*/}
            {/*    <span className="title">사업자등록증(이미지)</span>*/}
            {/*    <span className="required"> *</span>*/}
            {/*  </div>*/}
            {/*  <div className="input-body type_02">*/}
            {/*    <input*/}
            {/*        type="text"*/}
            {/*        className="input-box business-img-name"*/}
            {/*        disabled*/}
            {/*        // value={selectedImageName}*/}
            {/*    />*/}
            {/*    <input*/}
            {/*        type="file"*/}
            {/*        accept=".jpg, .jpeg, .png, .gif"*/}
            {/*        style={{ display: 'none' }}*/}
            {/*        // ref={fileInputRef}*/}
            {/*        // onChange={onFileChange}*/}
            {/*    />*/}
            {/*    /!*<button type="button" className="input-btn" onClick={openFilePicker}>*!/*/}
            {/*    /!*  이미지 등록*!/*/}
            {/*    /!*</button>*!/*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="input-item">
              <div className="input-head">
                <span className="title">사업자등록번호</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="number"
                  className="input-box business-input"
                  disabled={true}
                  value={businessNumber}
                  onWheel={(e) => e.target.blur()}
                  placeholder="- 를 제외한 10자리수를 입력해주세요."
                  // onChange={(e) => setBusinessNumber(e.target.value)}
                />
                {/*<button type="button" className="input-btn" onClick={onBusinessNumberCheck}>*/}
                {/*  검증*/}
                {/*</button>*/}
              </div>
            </div>
          </div>
          <div className="page-btn" style={{ maxWidth: '1000px', margin: '50px auto' }}>
            <button className="page-btn-item prev" onClick={onCancel}>
              취소
            </button>
            <button className="page-btn-item next" onClick={onEdit}>
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberInfoEdit2;
