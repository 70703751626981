import React from 'react';
import styled from 'styled-components';

import noneIcon from '../assets/img/icon/icon-none.svg';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ padding }) => padding};
`;

const NonIconImg = styled.img`
  width: 48px;
  height: 48px;
`;

const NonPageTitle = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin-top: 24px;
`;

const EmptyDataPage = ({
  padding = '50px',
  title = '등록된 상품이 없습니다.',
  isIcon = true,
  fontSize = '32px',
  fontWeight = '500',
  fontFamily = 'Pretendard-bold',
}) => {
  return (
    <Container padding={padding} className="none-box">
      {isIcon && <NonIconImg src={noneIcon} />}
      <NonPageTitle
        className="title"
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontFamily={fontFamily}
      >
        {title}
      </NonPageTitle>
    </Container>
  );
};

export default React.memo(EmptyDataPage);
