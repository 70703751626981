import { Client } from '@stomp/stompjs';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BsFillSendFill } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import SockJs from 'sockjs-client';

import instance from '../../../../api/axios';
import { endPoint, endPointMutation } from '../../../../api/endPoint';
import { colorList } from '../../../../assets/style/theme';
import BtnBack from '../../../../components/BtnBack';
import BtnNormal from '../../../../components/BtnNormal';
import ErrorFetching from '../../../../components/error/ErrorFetching';
import HeaderTitle from '../../../../components/HeaderTitle';
import SectionLayout from '../../../../components/layout/SectionLayout';
import WriteEstimateCard from '../../../../components/layout/WriteEstimateCard';
import { useMediaQueryCustom } from '../../../../components/mediaQueryCustom/useMediaQueryCustom';
import { usePopup } from '../../../../components/popup/usePopup';
import TextAreaCustom from '../../../../components/TextAreaCustom';
import { useLoginCheck } from '../../../../components/useLoginCheck/useLoginCheck';
import useScrollObserver from '../../../../components/useScrollObserver/useScrollObserver';
import { DELIVERY_CODE } from '../../../../constant/DELIVERY_CODE';
import { ORDER_STATUS, PAYMENT_TYPE, WS_MESSAGE_TYPE } from '../../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../../react-query/useQueryInstance';
import { checkProxy } from '../../../../utils/checkProxy';
import { decodingCrypto } from '../../../../utils/crypto';
import { dateFormatHelper } from '../../../../utils/dateHelper';
import { resultCommaRemove } from '../../../../utils/numberComma';
import VATCalculate from '../../../../utils/VATCalculate';

const MyEstimateChat = () => {
  useLoginCheck();

  const { SERVER_URL } = checkProxy();

  const { MEDIUM_SMALL_SCREEN } = useMediaQueryCustom();
  const { openPopup } = usePopup();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { roomId } = useParams();
  // 현재 사용자 아이디 표시
  const loginMemberIdx = Number(decodingCrypto(localStorage.getItem('idx')));
  const myMemberName = decodingCrypto(localStorage.getItem('member_name'));
  const myCompanyName = decodingCrypto(localStorage.getItem('company_name'));

  // room정보
  const {
    data: { data: chatRoomInfo },
    isError,
  } = useQueryInstance({
    queryKey: [queryKeys.getChatRoom, roomId],
    apiMethod: 'get',
    apiEndPoint: endPoint.chat.getChatRoom,
    apiQueryParams: { room_idx: roomId },
  });

  // extraData 리스트
  // get extra_data List, 문의한 상품목록
  const {
    data: { data_list: extraProductList },
    refetch: extraDataRefetch,
    isError: extraIsError,
  } = useQueryInstance({
    queryKey: [queryKeys.estimateProductList, roomId],
    apiMethod: 'get',
    apiEndPoint: endPoint.chat.getEstimateProductList,
    apiQueryParams: {
      room_idx: roomId,
    },
    queryEnable: roomId !== undefined && roomId !== null,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  // 견적서 팝업 훅
  const [writeEstimate, setWriteEstimate] = useState(false);

  // 내가 보내는 메시지
  const sendMessageRef = useRef('');

  // stomp연결확인
  const client = useRef({});

  const accessToken = decodingCrypto(localStorage.getItem('token'));

  // 실시간 메시지 진행상태
  const [isNewMessage, setIsNewMessage] = useState(false);

  useEffect(() => {
    // 이미 연결된 경우, 함수를 더 이상 실행하지 않음
    const wsConnection = () => {
      if (client.current?.connected) {
        return;
      }

      const socket = new SockJs(SERVER_URL + '/chat', null, {
        transports: ['websocket', 'xhr-streaming', 'xhr-polling'],
      });
      client.current = new Client({
        webSocketFactory: () => socket,
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        connectHeaders: {
          Authorization: 'Bearer ' + accessToken,
          'room-idx': roomId,
        },
        // debug: (str) => {
        //   console.log('STOMP_MSG: ' + str);
        // },
      });

      // stomp인스턴스 생성되면 시작
      if (client.current) {
        // 연결 성공 시 룸 구독으로
        client.current.onConnect = () => {
          client.current.subscribe(`/sub/chat/room/${roomId}`, async (receiveMessage) => {
            const message = JSON.parse(receiveMessage.body);

            if (!message) return;

            if (message) {
              setIsNewMessage(true);
              // 무한 스크롤 쿼리로 바로 넣기
              await queryClient.setQueryData([queryKeys.chatList, roomId], (prevData) => {
                const newPageData = prevData.pages[0]
                  ? [message, ...prevData.pages[0].data.data_list]
                  : null;

                return {
                  ...prevData,
                  pages: [{ data: { data_list: newPageData } }, ...prevData.pages.slice(1)],
                  pageParams: prevData.pageParams,
                };
              });

              message.message_type === WS_MESSAGE_TYPE.INQUIRY ? extraDataRefetch() : null;
            }
          });
        };

        // 연결 에러 시 호출될 콜백
        client.current.onStompError = (frame) => {
          console.error('error: ' + frame.headers['message']);
          console.error('error details: ' + frame.body);
        };

        // 연결 종료 후 자동리커넥트
        client.current.reconnectDelay = 1000;

        client.current.activate();
      }
    };

    // 인터넷 연결 감지시 작동
    window.addEventListener('online', wsConnection);

    wsConnection();

    return () => {
      client.current?.deactivate();
      window.removeEventListener('online', wsConnection);
    };
  }, [roomId]);

  const sendMessageHandler = useCallback(
    async (estimateData) => {
      // estimateData로 견적서 보내는 건지, 단순 메시지인지 구분
      // undefined면 메시지만 보내므로 메시지 데이터가 있는 지 여부 체크
      if (!estimateData) {
        if (sendMessageRef.current?.value.trim().length === 0) {
          return;
        }
      }

      if (estimateData) {
        if (!estimateData.count)
          return openPopup({ title: '오류', content: '수량을 입력해주세요.' });

        if (!estimateData.price)
          return openPopup({ title: '오류', content: '가격을 입력해주세요.' });

        try {
          await instance.get(endPointMutation.chat.validateEstimate, {
            params: {
              product_idx: estimateData.product_idx,
              count: estimateData.count,
              price: estimateData.price,
              product_option_idx: estimateData.product_option_idx,
            },
          });
        } catch (error) {
          const errorMsg = error.response.data.message;
          return openPopup({ title: '오류', content: errorMsg });
        }
      }

      if (!client.current.connected)
        return openPopup({ title: '오류', content: '새로고침해주세요.' });

      try {
        await client.current.publish({
          destination: `/app/chat/send`,
          body: JSON.stringify({
            chat_room_idx: roomId,
            sender_idx: loginMemberIdx,
            sender_name: `${myMemberName}(${myCompanyName})`,
            message_type: !estimateData ? WS_MESSAGE_TYPE.TEXT : WS_MESSAGE_TYPE.ESTIMATE,
            message: !estimateData ? sendMessageRef.current?.value : '견적서',
            extra_data: !estimateData ? undefined : JSON.stringify(estimateData),
            product_idx: !estimateData ? undefined : estimateData.product_idx,
            price: !estimateData ? undefined : estimateData.price,
            count: !estimateData ? undefined : estimateData.count,
            product_option_idx: !estimateData ? undefined : estimateData.product_option_idx,
            product_option_name: !estimateData ? undefined : estimateData.product_option_name,
          }),
        });

        !estimateData ? (sendMessageRef.current.value = '') : setWriteEstimate(false);
      } catch (error) {
        const serverMessage = error.response.data.message;
        openPopup({ title: '오류', content: serverMessage });
      }
    },
    [roomId, loginMemberIdx],
  );

  // 텍스트입력창에서 엔터 누를 시 send
  const textareaKeyHandler = useCallback(
    async (e) => {
      if (e.key === 'Enter') {
        if (!e.shiftKey) {
          e.preventDefault();

          if (
            sendMessageRef.current?.value.trim().length !== 0 &&
            // 한글 입력시 필수
            e.nativeEvent.isComposing === false &&
            !writeEstimate
          ) {
            await sendMessageHandler();
          }
        }
      }
    },
    [writeEstimate],
  );

  if (isError || extraIsError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout padding={MEDIUM_SMALL_SCREEN ? '0' : '20px 50px'}>
      {!MEDIUM_SMALL_SCREEN && (
        <div
          style={{
            marginBottom: MEDIUM_SMALL_SCREEN ? '0' : '50px',
            marginTop: MEDIUM_SMALL_SCREEN ? '10px' : '0',
          }}
        >
          <BtnBack onClickEvent={() => navigate('/pages/member/mypage/my_estimate_index')} />
        </div>
      )}

      {!MEDIUM_SMALL_SCREEN && <HeaderTitle>견적문의 대화방</HeaderTitle>}

      {/* 채팅 칸 ------------------- */}
      <div
        style={{
          width: '100%',
          height: MEDIUM_SMALL_SCREEN ? '100vh' : 'auto',
          boxSizing: 'border-box',
          margin: MEDIUM_SMALL_SCREEN ? '0' : '30px 0 0 0',
          backgroundColor: '#ebebeb',
          borderRadius: MEDIUM_SMALL_SCREEN ? '0' : '10px',
          border: `1px solid ${colorList.gray}`,
          paddingBottom: MEDIUM_SMALL_SCREEN ? '0' : '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            position: 'relative',
            boxSizing: 'border-box',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: MEDIUM_SMALL_SCREEN ? '10px' : '10px 30px',
            borderRadius: MEDIUM_SMALL_SCREEN ? '0' : '10px 10px 0 0',
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            backgroundColor: colorList.lightGray,
            borderBottomColor: colorList.gray,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '15px',
            }}
          >
            {MEDIUM_SMALL_SCREEN && <IoIosArrowBack size={25} onClick={() => navigate(-1)} />}
            <div>
              <p style={{ fontSize: '10px', color: colorList.mild_dark, marginBottom: '5px' }}>
                대화 중인 상대방
              </p>
              <p style={{ fontWeight: '600' }}>{chatRoomInfo?.receiver_name}</p>
            </div>
          </div>
          <p style={{ fontSize: '12px', color: colorList.mild_dark }}>
            {dateFormatHelper(chatRoomInfo?.reg_by_time)}
          </p>
        </div>
        {/* 채팅 본문 시작 ----- */}
        <ChatBox
          breakPoint={MEDIUM_SMALL_SCREEN}
          loginMemberIdx={loginMemberIdx}
          roomId={roomId}
          isNewMessage={isNewMessage}
          setIsNewMessage={setIsNewMessage}
        />
        {/* 채팅 본문 시작 ----- */}

        <div
          style={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: MEDIUM_SMALL_SCREEN ? '10px' : '10px 30px',
            gap: MEDIUM_SMALL_SCREEN ? '5px' : '10px',
          }}
        >
          {/* 견적서보내기 팝업 -------------- */}
          {writeEstimate && (
            <WriteEstimateCard
              breakPoint={MEDIUM_SMALL_SCREEN}
              contentWidth={MEDIUM_SMALL_SCREEN ? '250px' : '300px'}
              extraProductList={extraProductList}
              contentHeight="570px"
              wrapStyle={{ bottom: '45px', right: '30px' }}
              onClickCancelBtn={() => setWriteEstimate(false)}
              onClickSend={sendMessageHandler}
            />
          )}
          {/* 견적서보내기 팝업 -------------- */}

          {/* 채팅입력칸 -------------- */}
          <TextAreaCustom
            style={{
              padding: '10px 15px',
              boxSizing: 'border-box',
            }}
            onKeyDownTextArea={textareaKeyHandler}
            ref={sendMessageRef}
          />

          <BtnNormal
            padding="10px 15px"
            bgColor={!writeEstimate ? colorList.main : colorList.gray}
            activeOpacity={!writeEstimate ? '0.6' : '1'}
            disabled={writeEstimate}
            onClickEvent={() => sendMessageHandler()}
          >
            <BsFillSendFill />
          </BtnNormal>
          {!writeEstimate && extraProductList?.length !== 0 ? (
            <BtnNormal
              fontSize="16px"
              fontWeight="400"
              padding={MEDIUM_SMALL_SCREEN ? '10px' : '12px'}
              activeOpacity="0.6"
              onClickEvent={() => setWriteEstimate(true)}
            >
              {MEDIUM_SMALL_SCREEN ? <IoDocumentTextOutline size={20} /> : '견적서 작성'}
            </BtnNormal>
          ) : writeEstimate && extraProductList?.length !== 0 ? (
            <BtnNormal
              fontSize="16px"
              fontWeight="400"
              padding={MEDIUM_SMALL_SCREEN ? '10px' : '10px 12px 8px 12px'}
              activeOpacity="0.6"
              onClickEvent={() => setWriteEstimate(false)}
            >
              <IoDocumentTextOutline size={20} />
            </BtnNormal>
          ) : null}
        </div>
        {/* 채팅입력칸 -------------- */}
      </div>
      {/* ------------------- */}
    </SectionLayout>
  );
};

export default MyEstimateChat;

const ChatBox = React.memo(
  ({ breakPoint, loginMemberIdx, roomId, isNewMessage, setIsNewMessage }) => {
    const { isInView, elementRef } = useScrollObserver({
      isOnlyTop: false,
      options: { rootMargin: '0px', threshold: 0 },
    });
    const [initial, setInitial] = useState(true);
    const [isFetchingNewPage, setIsFetchingNewPage] = useState(false);

    const {
      data: messages,
      fetchNextPage,
      hasNextPage,
      isFetching,
      isError,
    } = useInfiniteQuery({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: [queryKeys.chatList, roomId],
      queryFn: async ({ pageParam = 1 }) => {
        const response = await instance.get(endPoint.chat.getChatList, {
          params: { room_idx: Number(roomId), page: pageParam },
        });
        return response;
      },
      getNextPageParam: (LastPage, allPage) => {
        if (LastPage.data.data_list?.length < 20) return undefined;
        const nextPageParam = allPage?.length + 1;
        return nextPageParam;
      },
      select: (data) => {
        const newMessageList = data.pages
          ?.map((el) => el.data.data_list)
          .flat()
          .reverse();
        return newMessageList;
      },
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnReconnect: true,
    });
    const scrollBoxRef = useRef(null);

    useEffect(() => {
      if (!messages) return;

      if (messages?.length <= 20) {
        const value = messages?.reduce((acc, el) => (el.chat_message_idx ? acc + 1 : acc), 0);

        if (value <= 1) return;
      }

      const fetchDebounced = _.debounce(() => {
        if (isInView && !isFetching && hasNextPage && !initial) {
          setScrollHeight(scrollBoxRef.current.scrollHeight);
          setIsFetchingNewPage(true);
          fetchNextPage();
        }
      }, 500);

      fetchDebounced();

      return () => {
        fetchDebounced.cancel();
      };
    }, [isInView, hasNextPage, isFetching, fetchNextPage, initial]);

    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
      if (!scrollBoxRef) return;

      if (messages?.length > 20 && isFetchingNewPage) {
        const scrollTop = scrollBoxRef.current.scrollHeight - scrollHeight;
        scrollBoxRef.current.scrollTop = scrollTop;
        setScrollHeight(scrollBoxRef.current.scrollHeight);
        setIsFetchingNewPage(false);
      }
    }, [messages?.length]);

    useLayoutEffect(() => {
      if (scrollBoxRef.current && initial) {
        setScrollHeight(scrollBoxRef.current.scrollHeight);
        scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
      }

      if (scrollBoxRef.current && isNewMessage) {
        scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
        setIsNewMessage(false);
      }
    }, [messages]);

    useEffect(() => {
      const time = setTimeout(() => {
        setInitial(false);
      }, 200);

      return () => {
        clearTimeout(time);
      };
    }, []);

    if (isError) return <ErrorFetching isError={isError} />;

    return (
      <div
        ref={scrollBoxRef}
        style={{
          position: 'relative',
          height: breakPoint ? '82%' : '800px',
          padding: breakPoint ? '10px' : '10px 30px',
          boxSizing: 'border-box',
          overflowY: 'scroll',
          scrollBehavior: 'auto',
        }}
      >
        <div ref={elementRef} style={{ width: '0', height: '0', visibility: 'hidden' }} />

        {messages?.map((el, index) => {
          return (
            <ChatCard
              isMine={el.sender_idx === loginMemberIdx}
              breakPoint={breakPoint}
              receive={el.sender_idx !== loginMemberIdx ? el.message : ''}
              send={el.sender_idx === loginMemberIdx ? el.message : ''}
              extraData={el.extra_data}
              chatMessageIdx={el.chat_message_idx}
              key={index}
              time={el.send_time}
              roomId={roomId}
              messageType={el.message_type}
            />
          );
        })}
        <div />
      </div>
    );
  },
);

ChatBox.displayName = 'ChatBox';

const ChatCard = React.memo(
  ({
    receive,
    send,
    extraData,
    chatMessageIdx,
    read = 1,
    breakPoint,
    time,
    isMine,
    roomId,
    messageType,
  }) => {
    const { REACT_APP_CLIENT_TEST_KEY } = process.env;
    const { REACT_APP_CLIENT_REAL_KEY } = process.env;
    const queryClient = useQueryClient();
    const estimateData = JSON.parse(extraData);

    const { openPopup } = usePopup();

    // 결제완료되면 db저장 API
    const { mutate: apiOnPayFinal } = useMutationInstance({
      apiMethod: 'post',
      apiEndPoint: endPointMutation.order.addOrder,
      onSuccessFn: () => {
        queryClient.refetchQueries([queryKeys.orderListMypage], { force: true });
        // 데이터 전송 되었으므로 세션스토리지 초기화
        sessionStorage.removeItem('delivery');
        sessionStorage.removeItem('estimateOrder');
      },
      onErrorFn: (error) => {
        const serverMessage = error.response.data.message;
        // 데이터 오류이므로 세션스토리지 초기화
        sessionStorage.removeItem('delivery');
        sessionStorage.removeItem('estimateOrder');
        openPopup({
          title: '오류',
          content: serverMessage,
        });
      },
    });
    const apiOn = (paymentResult) => {
      const deliveryData = sessionStorage.getItem('delivery');
      const { deliveryName } = deliveryData && JSON.parse(deliveryData);
      const estimateOrderData = sessionStorage.getItem('estimateOrder');
      const { product_idx, count, chat_message_idx, product_option_idx } =
        estimateOrderData && JSON.parse(estimateOrderData);

      const { PCD_PAY_GOODS, PCD_PAY_TYPE, PCD_PAY_TOTAL } = paymentResult;

      const orderItemList = [
        {
          product_idx,
          name: PCD_PAY_GOODS,
          price: resultCommaRemove(PCD_PAY_TOTAL),
          count,
          product_option_idx,
        },
      ];

      const properties = {
        shipping_company_name: deliveryName,
        payment_result: paymentResult,
      };

      // 최종 데이터
      const addOrderData = {
        order_item_list: orderItemList,
        payment_type: PCD_PAY_TYPE === 'card' ? PAYMENT_TYPE.CARD : PAYMENT_TYPE.TRANSFER,
        order_status: ORDER_STATUS.ORDER,
        properties: JSON.stringify(properties),
        chat_message_idx,
      };

      apiOnPayFinal({
        apiBody: addOrderData,
      });
    };

    // 페이플 결제
    const onPayple = (payType) => {
      // 날라가는 데이터 확보
      sessionStorage.setItem(
        'estimateOrder',
        JSON.stringify({
          product_idx: estimateData.product_idx,
          count: estimateData.count,
          chat_message_idx: chatMessageIdx,
          product_option_idx: estimateData.product_option_idx,
        }),
      );

      let obj = new Object();

      obj.PCD_PAY_TYPE = payType;
      // 계좌결제
      // obj.PCD_PAY_TYPE = "transfer";
      obj.PCD_PAY_WORK = 'PAY';

      /* 01 : 페이플 간편결제 */
      // obj.PCD_CARD_VER = "01"
      /* 02 : 페이플 앱카드결제 */
      obj.PCD_CARD_VER = payType === 'card' ? '02' : '01';

      obj.PCD_PAYER_NAME = decodingCrypto(localStorage.getItem('member_name'));
      obj.PCD_PAYER_HP = decodingCrypto(localStorage.getItem('member_phone_number'));
      // obj.PCD_PAYER_EMAIL = '';
      obj.PCD_PAY_GOODS =
        estimateData?.name +
        (estimateData.product_option_idx && estimateData.product_option_name
          ? ` (${estimateData.product_option_name})`
          : '');
      obj.PCD_PAY_TOTAL = VATCalculate(estimateData?.price);
      obj.PCD_PAY_ISTAX = 'Y';

      /* 결과를 콜백 함수로 받고자 하는 경우 함수 설정 추가 */
      obj.callbackFunction = getPaypleResult; // 콜백 함수

      obj.clientKey =
        window.location.hostname === 'www.ilppang.com'
          ? REACT_APP_CLIENT_REAL_KEY
          : REACT_APP_CLIENT_TEST_KEY;

      obj.PCD_RST_URL = `/pages/member/mypage/my_estimate_index/detail/${roomId}`; // 결제결과 수신 URL

      PaypleCpayAuthCheck(obj);
    };

    const getPaypleResult = (result) => {
      if (result) {
        if (result.PCD_PAY_RST === 'close') {
          openPopup({
            title: '안내',
            content: '결제를 취소하였습니다.',
          });
        } else if (result.PCD_PAY_RST === 'success') {
          // api 호출
          apiOn(result);
        } else {
          openPopup({
            title: '안내',
            content: result.PCD_PAY_MSG,
          });
        }
      }
    };

    // 택배사 입력
    const selectDelivery = (name, payType) => {
      if (name) {
        const deliveryData = {
          deliveryName: name,
        };

        // 세션스토리지에 택배사 데이터 저장
        sessionStorage.setItem('delivery', JSON.stringify(deliveryData));

        openPopup({
          title: '안내',
          content: '택배사 : ' + name + '\n\n정말 결제하시겠습니까?',
          onComplete: () => onPayple(payType),
          twoButton: true,
        });
      } else {
        openPopup({
          title: '안내',
          content: '택배사를 입력해주세요.',
        });
      }
    };

    // 최초 주문 버튼
    const addOrder = async (payType) => {
      try {
        const response = await instance.get(endPointMutation.chat.validateEstimateOrder, {
          params: {
            chat_message_idx: chatMessageIdx,
          },
        });

        if (response.status === 200) {
          const checkOrderResult = response.data.data;

          if (!checkOrderResult)
            return openPopup({
              title: '오류',
              content: '더 이상 결제할 수 없는 견적서입니다.',
            });
        }
      } catch (error) {
        const serverMessage = error.response.data.message;
        openPopup({
          title: '오류',
          content: serverMessage,
        });
        return;
      }

      // 세션스토리지 초기화
      sessionStorage.removeItem('delivery');

      openPopup({
        title: '안내',
        content: '택배사를 입력해주세요. \n * 택배비는 착불입니다.',
        twoButton: true,
        input: false,
        select: DELIVERY_CODE,
        selectEtcInput: true,
        setSelectValue: (name) => selectDelivery(name, payType),
      });
    };

    // 주문삭제
    const { mutate: estimateRemoveAPI } = useMutationInstance({
      apiMethod: 'post',
      apiEndPoint: endPointMutation.chat.removeEstimate,
      onSuccessFn: () => {
        queryClient.refetchQueries([queryKeys.chatList, roomId], { force: true });
      },
      onErrorFn: (error) => {
        const serverMessage = error.response.data.message;
        openPopup({
          title: '오류',
          content: serverMessage,
        });
      },
    });

    return (
      <div
        style={{
          display: 'flex',
          gap: breakPoint ? '0' : '10px',
          justifyContent: receive ? 'left' : 'right',
          alignItems: 'flex-start',
          marginBottom: '20px',
        }}
      >
        {receive && !send && !estimateData && (
          <>
            <div style={{ display: 'flex', gap: '5px' }}>
              <div
                style={{
                  backgroundColor: colorList.white,
                  maxWidth: breakPoint ? '150px' : '250px',
                  borderRadius: '10px',
                  padding: '10px',
                  wordBreak: 'break-all',
                  fontSize: '14px',
                }}
              >
                <p style={{ whiteSpace: 'pre-wrap' }}>{receive}</p>
              </div>
            </div>
          </>
        )}
        {send && !receive && !estimateData && (
          <>
            <div style={{ display: 'flex', gap: '5px' }}>
              {/* <p style={{ fontSize: '12px', color: colorList.main_dark }}>{read}</p> */}
              <div
                style={{
                  backgroundColor: colorList.white,
                  maxWidth: breakPoint ? '150px' : '250px',
                  borderRadius: '10px',
                  padding: '10px',
                  wordBreak: 'break-all',
                  fontSize: '14px',
                }}
              >
                <p style={{ whiteSpace: 'pre-wrap', lineHeight: '1.5' }}>{send}</p>
              </div>
            </div>
          </>
        )}

        {estimateData && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: send ? 'flex-end' : receive ? 'flex-start' : 'flex-end',
              gap: '20px',
            }}
          >
            {(send || receive) && estimateData.is_initial && (
              <div style={{ display: 'flex', gap: '5px' }}>
                {/* {send && <p style={{ fontSize: '12px', color: colorList.main_dark }}>{read}</p>} */}
                <div
                  style={{
                    backgroundColor: colorList.white,
                    maxWidth: breakPoint ? '150px' : '250px',
                    borderRadius: '10px',
                    padding: '10px',
                    wordBreak: 'break-all',
                    fontSize: '14px',
                  }}
                >
                  <p style={{ whiteSpace: 'pre-wrap', lineHeight: '1.5' }}>
                    {send ? send : receive ? receive : ''}
                  </p>
                </div>
              </div>
            )}

            <div style={{ display: 'flex', gap: '5px' }}>
              {/* {send && <p style={{ fontSize: '12px', color: colorList.main_dark }}>{read}</p>} */}
              <div
                style={{
                  backgroundColor: colorList.white,
                  width: breakPoint ? 'fit-content' : '250px',
                  borderRadius: '10px',
                  padding: '10px',
                  wordBreak: 'break-all',
                }}
              >
                <WriteEstimateCard
                  chatMessageIdx={chatMessageIdx}
                  isMine={isMine}
                  breakPoint={breakPoint}
                  sendEstimate={true}
                  initialSendEstimate={estimateData.is_initial}
                  time={time}
                  wrapStyle={{
                    position: 'relative',
                    display: 'block',
                    bottom: '0',
                    right: '0px',
                  }}
                  contentWidth={breakPoint ? '160px' : undefined}
                  estimateData={estimateData}
                  padding={breakPoint ? '5px' : undefined}
                  onClickBuy={addOrder}
                  onClickRemove={() => {
                    estimateRemoveAPI({
                      apiBody: {
                        chat_message_idx: chatMessageIdx,
                      },
                    });
                  }}
                  messageType={messageType}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
ChatCard.displayName = 'ChatCard';
