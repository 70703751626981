import React from 'react';

const SearchMagnifier = ({ color = '#f17823', size = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="10" cy="10" r="9" stroke={color} strokeWidth="2" />
      <rect
        x="16"
        y="17.4142"
        width="2"
        height="8"
        transform="rotate(-45 16 17.4142)"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(SearchMagnifier);
