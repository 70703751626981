// 팝업창 커스텀 훅

import { useRecoilState } from 'recoil';

import { usePopupState } from '../../recoil/usePopupState';

export const usePopup = () => {
  const [popup, setPopup] = useRecoilState(usePopupState);

  const openPopup = ({
    title = '',
    content = '',
    onComplete = null,
    twoButton = false,
    input = false,
    inputMaxLength = null,
    selectEtcInput = false,
    select = [],
    setInputValue = null,
    setSelectValue = null,
    confirmButtonText = '',
    cancelButtonText = '',
    closeButtonText = '',
  } = {}) => {
    setPopup({
      show: true,
      title,
      content,
      onComplete,
      twoButton,
      input,
      inputMaxLength,
      selectEtcInput,
      select,
      setInputValue,
      setSelectValue,
      confirmButtonText,
      cancelButtonText,
      closeButtonText,
    });
  };

  const closePopup = () => {
    setPopup({
      show: false,
      title: '',
      content: '',
      onComplete: null,
      twoButton: false,
      input: false,
      inputMaxLength: null,
      selectEtcInput: false,
      select: [],
      setInputValue: null,
      setSelectValue: null,
      confirmButtonText: '',
      cancelButtonText: '',
      closeButtonText: '',
    });
  };

  return { popup, openPopup, closePopup };
};
