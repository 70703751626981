import React from 'react';
import { TiArrowLeftThick } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

import { colorList } from '../assets/style/theme';
import BtnNormal from './BtnNormal';

const BtnBack = ({
  padding = '10px',
  fontWeight = '200',
  fontSize = '15px',
  title = '뒤로가기',
  onClickEvent,
}) => {
  const navigate = useNavigate();

  return (
    <BtnNormal
      bgColor={colorList.main}
      onClickEvent={onClickEvent ? onClickEvent : () => navigate(-1)}
      padding={padding}
      fontWeight={fontWeight}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: title ? '5px' : '0',
        }}
      >
        <TiArrowLeftThick
          style={{ fontSize: fontSize && `${Number(fontSize?.replace('px', '')) + 5}px` }}
          color={colorList.white}
        />
        <span style={{ fontSize: fontSize }}>{title}</span>
      </div>
    </BtnNormal>
  );
};

export default React.memo(BtnBack);
