import React, { useEffect, useState } from 'react';
import { BiSolidDownArrow } from 'react-icons/bi';
import { FaCircleExclamation, FaCircleQuestion } from 'react-icons/fa6';
import styled from 'styled-components';

import SectionLayout from '../../../components/layout/SectionLayout';
import { FAQ_Data } from '../../../constant/FAQ_Data';

export const FAQ = () => {
  const [status, setStatus] = useState(Array(FAQ_Data.length).fill(false));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SectionLayout>
      <Title>FAQ</Title>
      <ContentContainer>
        {FAQ_Data?.map((el, index) => (
          <ContentBoxDropDown
            map={true}
            key={index}
            question={el.q}
            answer={el.a}
            status={status}
            index={index}
            onClickEvent={() =>
              setStatus((cur) => {
                let copy = [...cur];
                copy[index] = !copy[index];
                return copy;
              })
            }
          />
        ))}
      </ContentContainer>
    </SectionLayout>
  );
};

// style ---------
const Title = styled.p`
  font-size: 30px;
  font-family: 'Pretendard-medium';
  border-bottom: 2px solid #000000;
  padding-bottom: 20px;
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  padding: 20px 0px;
`;

// 컨텐츠 박스 따로 분리  ---------

const ContentBoxDropDown = ({
  question = '',
  answer = '',
  onClickEvent,
  status,
  index,
  map = false,
}) => {
  return (
    <>
      {map ? (
        <ContentBoxWrap>
          <ContentBox onClick={onClickEvent}>
            <FaCircleQuestion
              style={{
                flex: '2',
                color: `${status[index] ? 'black' : '#ff7200'}`,
                fontSize: '24px',
              }}
            />
            <CntentTitle>{question}</CntentTitle>
            <BiSolidDownArrow
              style={{
                flex: '2',
                color: `${status[index] ? 'black' : '#ff7200'}`,
                fontSize: '18px',
                transform: `${status[index] ? 'rotate(0deg)' : 'rotate(180deg)'}`,
              }}
            />
          </ContentBox>
          {!status[index] ? null : (
            <AnswerBox>
              <FaCircleExclamation style={{ flex: '2', color: '#ff7200', fontSize: '24px' }} />
              <AnswerText>{answer}</AnswerText>
            </AnswerBox>
          )}
        </ContentBoxWrap>
      ) : (
        <ContentBoxWrap>
          <ContentBox onClick={onClickEvent}>
            <FaCircleQuestion
              style={{
                flex: '2',
                color: `${status ? 'black' : '#ff7200'}`,
                fontSize: '24px',
              }}
            />
            <CntentTitle>{question}</CntentTitle>
            <BiSolidDownArrow
              style={{
                flex: '2',
                color: `${status ? 'black' : '#ff7200'}`,
                fontSize: '18px',
                transform: `${status ? 'rotate(0deg)' : 'rotate(180deg)'}`,
              }}
            />
          </ContentBox>
          {!status ? null : (
            <AnswerBox>
              <FaCircleExclamation style={{ flex: '2', color: '#ff7200', fontSize: '24px' }} />
              <AnswerText>{answer}</AnswerText>
            </AnswerBox>
          )}
        </ContentBoxWrap>
      )}
    </>
  );
};

// style
const ContentBoxWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  border-style: solid;
  border-color: #e0e0e0e0;
  border-width: 0px 0px 1px 0px;
`;

const ContentBox = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px 0px;
  cursor: pointer;
`;

const CntentTitle = styled.p`
  flex: 8;
  font-size: 18px;
`;

const AnswerBox = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  padding: 40px 0px;
  margin: 0px 40px;
  background-color: rgb(238, 238, 238);
`;

const AnswerText = styled.p`
  flex: 10;
  padding-right: 100px;
  line-height: 1.5;
  word-break: break-all;
  text-align: justify;
  font-size: 18px;
`;
