import React from 'react';

import useScrollObserver from './useScrollObserver';

const ScrollObserverFadeIn = ({
  children,
  style,
  time = '1s',
  easeOpt = 'ease',
  delay = '0s',
  root,
  rootMargin,
  threshold,
  isOnlyTop,
}) => {
  const { isInView, elementRef } = useScrollObserver({
    options: {
      root: root ? root : null,
      rootMargin: rootMargin ? rootMargin : '0px',
      threshold: threshold ? threshold : 0,
    },
    isOnlyTop: isOnlyTop,
  });

  return (
    <div
      ref={elementRef}
      style={{
        position: 'relative',
        opacity: isInView ? '1' : '0',
        transition: `${time} ${easeOpt}`,
        transitionDelay: delay,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
export default React.memo(ScrollObserverFadeIn);
