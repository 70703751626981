import React, { useCallback, useEffect, useState } from 'react';
import { MdDoubleArrow } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { endPoint } from '../../../../api/endPoint';
import { hover } from '../../../../assets/style/pointer';
import { colorList } from '../../../../assets/style/theme';
import EmptyDataPage from '../../../../components/EmptyDataPage';
import ErrorFetching from '../../../../components/error/ErrorFetching';
import HeaderTitle from '../../../../components/HeaderTitle';
import SectionLayout from '../../../../components/layout/SectionLayout';
import MyPageButtonComponent from '../../../../components/MyPageButtonComponent';
import { useLoginCheck } from '../../../../components/useLoginCheck/useLoginCheck';
import { queryKeys } from '../../../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../../../react-query/useQueryInstance';
import { dateFormatHelper } from '../../../../utils/dateHelper';

const MyEstimateIndex = () => {
  useLoginCheck();

  const params = new URLSearchParams(location.search);
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');

  const { data: listData, isError } = useQueryInstance({
    queryKey: [queryKeys.chatRoomList, page, count],
    apiMethod: 'get',
    apiEndPoint: endPoint.chat.getChatRoomList,
    apiQueryParams: {
      page,
      count,
    },
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout maxWidth="1400px">
      <MyPageButtonComponent
        dataList={[
          { title: '회원정보수정', current: false, url: '/pages/member/mypage/edit1' },
          { title: '주문내역', current: false, url: '/pages/member/mypage/order_list' },
          { title: '판매내역', current: false, url: '/pages/member/mypage/sales_list' },
          { title: '내상품', current: false, url: '/pages/member/mypage/product_list' },
          { title: '내가 쓴 글', current: false, url: '/pages/member/mypage/my_board_list' },
          { title: '견적문의', current: true, url: '/pages/member/mypage/my_estimate_index' },
        ]}
      />
      <HeaderTitle conMargin="70px 0 0 0">대화 중인 방</HeaderTitle>

      <div
        style={{ width: '100%', minHeight: '300px', boxSizing: 'border-box', padding: '10px 50px' }}
      >
        {listData.data_list?.length === 0 ? (
          <EmptyDataPage title="문의내역이 없습니다." />
        ) : (
          listData.data_list?.map((el) => <EstimateRoomCard key={el.chat_room_idx} data={el} />)
        )}
      </div>
    </SectionLayout>
  );
};

export default MyEstimateIndex;

const EstimateRoomCardBox = styled.div`
  ${hover}
`;

const EstimateRoomCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <EstimateRoomCardBox
      style={{
        width: '100%',
        boxSizing: 'border-box',
        border: `1px solid ${colorList.gray}`,
        borderRadius: '10px',
        padding: '20px 30px',
        marginTop: '20px',
        cursor: 'pointer',
      }}
      // 나중에 쳇방 idx 같은 값을 넣어줘야함
      onClick={() =>
        navigate(`/pages/member/mypage/my_estimate_index/detail/${data.chat_room_idx}`)
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '30px',
        }}
      >
        <div style={{ flex: '0.5', justifySelf: 'center' }}>
          <MdDoubleArrow size={30} color={colorList.main_dark} />
        </div>

        <div
          style={{
            flex: '9.5',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <div>
            <p style={{ fontSize: '14px', marginBottom: '3px', color: colorList.darkGray }}>
              {dateFormatHelper(data?.send_time)}
            </p>
            <p
              style={{
                fontSize: '18px',
                marginBottom: '10px',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '5px',
              }}
            >
              {data?.receiver_company}{' '}
              <span style={{ fontSize: '16px', fontWeight: '400' }}>({data?.receiver_name})</span>
            </p>
            <p style={{ fontSize: '18px' }}>{data?.message}</p>
          </div>
        </div>
      </div>
    </EstimateRoomCardBox>
  );
};
