/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  line-height: 1.5;
`;

const POLICY_ELECTRONIC = () => {
  return (
    <P>
      **제 1조 (목적)**
      <br />
      이 약관은 스판 (이하 ‘회사’)이 운영하는 ‘일빵’(http://www.ilppang.kr)을 통해 제공되는 모든
      기능을 이용함에 있어, 전자지급결제대행서비스, 결제대금예치서비스(에스크로) 및
      선불전자지급수단의 발행 및 관리 서비스(이하 통칭하여 ‘전자금융거래서비스’)를 ‘이용자’가 이용할
      때 ‘회사’와 ‘이용자’ 간 전자금융거래의 법률관계를 정하는 데 목적이 있습니다.
      <br />
      <br />
      - PC통신, 무선 등을 이용하는 모든 서비스 이용에 있어 본질에 반하지 않는 한, 본 약관을
      준용합니다.
      <br />
      <br />
      <br />
      **제 2조 (정의)**
      <br />
      1. ‘일빵’이란 ‘회사’가 재화 또는 용역(이하 ‘재화 등’이라 함)을 ‘이용자’에게 제공하기 위하여
      컴퓨터 등 정보통신 설비를 이용, MRO관련 제품을 거래할 수 있도록 설정한 가상의 영업장 및
      거래공간을 운영하는 사업자를 의미합니다.
      <br />
      2. ‘전자금융거래’는 ‘회사’가 전자적 수단 또는 장치를 통하여 전자금융업무를 제공하고,
      ‘이용자’가 ‘회사’의 종사자 또는 ‘일빵’내 명시된 ‘판매회원’과 직접 대면 또는 의사 소통하지 않고
      자동화된 방식으로 이를 이용하는 거래를 의미합니다.
      <br />
      3. ‘전자지급거래’는 자금을 지급하는 개인 또는 사업체(이하 ‘지급인’)가 ‘회사’로 하여금
      전자지급수단을 이용하여 자금을 수령하게 되는 ‘회사’ 또는 ‘판매회원’(이하 ‘수취인’)에게 자금을
      이동하게 하는 전자금융거래를 의미합니다.
      <br />
      4. ‘전자적 장치’는 전자금융거래정보를 전자적 방법으로 전송하거나 처리하는 데 이용되는 장치로서
      현금자동지급기, 자동입출금기, 지급용 단말기, 컴퓨터, 전화기 및 기타 전자적 방법으로 정보를
      전송하거나 처리하는 장치를 의미합니다.
      <br />
      5. ‘접근매체’는 전자금융거래에서 거래지시를 하거나 ‘이용자’와 거래내용의 진실성과 정확성을
      확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적
      정보(신용카드번호를 포함한다), ‘전자서명법’상의 인증서, 금융기관 또는 전자금융업자에 등록된
      이용자번호(아이디), ‘이용자’의 생체정보, 이상의 수단이나 정보를 사용하는 데 필요한 비밀번호 등
      전자금융거래법 제2조 제10호에서 정하고 있는 것을 의미합니다.
      <br />
      6. 이용자: 본 약관에 따라 ‘회사’가 제공하는 서비스를 받는 회원을 의미합니다.
      <br /> 가) 회원: ‘회사’가 정한 가입절차에 따라 회원등록을 한 사업자로서, ‘회사’의 정보를
      지속적으로 제공받으며 ‘회사’가 제공한 서비스를 이용할 수 있는 자를 의미합니다.
      <br />
      7. ‘아이디’란 ‘이용자’의 동일성 식별과 서비스 이용을 위해 ‘일빵’ 내에서 정의한 설정 규칙을,
      ‘이용자’가 이행하여 ‘회사’로부터 승인된 숫자와 문자의 조합을 의미합니다.
      <br />
      8. ‘비밀번호’란 ‘이용자’의 동일성 식별과 회원정보의 보호를 위하여 ‘이용자’가 설정하고 ‘회사’가
      승인한 숫자와 문자(특수문자 포함)의 조합을 의미합니다.
      <br />
      9. ‘거래지시’란 ‘이용자’가 본 약관에 따라 ‘회사’에 전자금융거래의 처리를 지시하는 것을
      의미합니다.
      <br />
      10. ‘오류’란 ‘이용자’의 고의 또는 과실 없이 전자금융거래가 본 약관 또는 ‘이용자’의 거래지시에
      따라 이행되지 않은 경우를 의미합니다.
      <br />
      <br />
      **제 3조 (약관 등의 명시와 설명 및 개정)**
      <br />
      1. 본 서비스에서는 이용자가 전자금융거래 진행 전, ‘회사’가 지정한 영역 내 본 약관을 게시하여
      본 약관의 중요한 내용을 확인할 수 있도록 하며, 약관의 내용을 연결화면을 통해 인지하도록 할 수
      있습니다.
      <br />
      2. ‘회사’가 본 약관을 변경하는 때에는 그 시행일 1개월 전에 변경되는 약관을 금융거래정보
      입력화면과 ‘일빵’내 결제 또는 거래 진행 페이지와 공지사항 및 전자우편상에 게시하여
      ‘이용자’에게 공지합니다. 다만, 법령의 개정으로 인하여 긴급하게 약관을 변경한 때에는 변경된
      약관을 홈페이지에 1개월 이상 게시하고 ‘이용자’에게 전자우편 등을 통하여 사후 통지합니다.
      <br />
      3. ‘회사’는 제2항의 고지나 통지를 할 경우 이용자가 변경에 동의하지 아니한 경우 고지나 통지를
      받은 날로부터 30일 이내에 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는
      변경에 동의한 것으로 본다.”라는 취지의 내용을 고지하거나 통지합니다.
      <br />
      <br />
      **제 4조 (거래내용의 확인)**
      <br />
      1. ‘회사’는 ‘일빵’의 마이페이지를 통해 거래내용(‘이용자’간의 모든 거래내용 및 ‘서비스 이용의
      전반적인 처리에 관련 사항’ 포함)을 확인할 수 있도록 하며, ‘이용자’의 요청 시, 요청을 받은
      날로부터 2주 이내에 모사전송, 우편 또는 직접 교부의 방법으로 거래내용에 관한 서면을
      교부합니다. 다만, 전자적 장치의 운영장애, 그 밖의 사유로 거래내용을 제공할 수 없는 때에는 즉시
      ‘이용자’에게 전자문서 전송(전자우편을 이용한 전송을 포함합니다)의 방법으로 그러한 사유를
      공지해야 하며, 거래내용을 제공할 수 없는 기간은 서면교부 기간에 산입하지 아니합니다.
      <br />
      2. 제1항의 대상이 되는 거래내용 중 대상기간이 5년인 것은 다음 각 호와 같습니다.
      <br /> ① 전자금융거래가 계좌를 통하여 이루어지는 경우 거래계좌의 명칭 또는 번호
      <br /> ② 거래의 종류 및 금액
      <br />
      {''}③ 거래상대방을 나타내는 정보
      <br />
      {''}④ 거래일자
      <br />
      {''}⑤ 전자적 장치의 종류와 전자적 장치를 식별할 수 있는 정보
      <br /> ⑥ 해당 전자금융거래와 관련한 전자적 장치의 접속기록
      <br /> ⑦ 금융회사 또는 전자금융업자가 전자금융거래의 대가로 받은 수수료
      <br /> ⑧ 전자금융거래법 제15조 제1항에 따른 지급인의 출금 동의에 관한 사항
      <br /> ⑨ 전자금융거래 신청 및 조건의 변경에 관한 사항
      <br /> ⑩ 건당 거래금액이 1만원을 초과하는 전자금융거래에 관한 기록
      <br />
      3. 제1항의 대상이 되는 거래내용 중 대상기간이 1년인 것은 다음 각 호와 같습니다.
      <br /> ① 건당 거래금액이 1만 원 이하인 소액 전자금융거래에 관한 기록
      <br /> ② 전자지급수단을 이용할 때의 거래승인에 관한 기록
      <br /> ③ ‘이용자’의 오류정정 요구사실 및 처리결과에 관한 사항
      <br />
      4. ‘일빵’의 ‘이용자’는 제1항에서 정한 서면교부를 다음의 주소와 전화번호로 요청할 수 있습니다.
      <br /> ① 주소: 경기도 구리시 갈매순환로 166번길 45 아너시티 S718호
      <br /> ② 이메일(E-mail) 주소: nobusy.info@gmail.com
      <br /> ③ 전화번호: 010-2475-3532
      <br />
      1. 본 서비스 내 적용된 전자금융거래서비스의 오류를 인지한 ‘이용자’는 ‘회사’에 그 정정을 요구할
      수 있습니다.
      <br />
      2. ‘회사’는 제1항의 규정에 따른 오류의 정정요구를 받았거나 스스로 오류 발생을 인지했을
      경우에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날 또는 오류가 있음을 안 날부터 2주
      이내에 그 결과를 문서, 전화 또는 전자우편으로 ‘이용자’에게 알려 드리며, ‘이용자’가 문서로
      알려줄 것을 요청하는 경우에는 문서로 공지합니다.
      <br />
      <br />
      **제6조 (전자금융거래 기록의 생성 및 보존)**
      <br />
      1. ‘회사’는 ‘이용자’가 이용한 전자금융거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생했을
      때 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.
      <br />
      2. 제1항의 규정에 따라 ‘회사’가 보존해야 하는 기록의 종류와 보존방법은 제4조 제2항, 제3항에서
      정한 바를 따릅니다.
      <br />
      **제7조 (거래지시의 철회 등)**
      <br />
      <br />
      1. 전자지급거래 절차를 수행한 ‘이용자’는 지급의 효력이 발생하기 전까지 본 약관에서 정한 바에
      따라 제4조 제4항 기재 담당자에게 전자문서를 전송(전자우편을 이용한 전송을 포함합니다)하는
      방법으로 거래지시를 철회할 수 있습니다.
      <br />
      2. 서비스 별 거래지시 철회의 효력 발생시기는 본 약관 제15조와 제20조, 제26조 에서 정하는
      항목에 의거합니다.
      <br />
      3. ‘이용자’는 전자지급의 효력이 발생하면 전자상거래 등에서의 소비자보호에 관한 법률 등 관련
      법령상 청약 철회의 방법으로 결제대금을 반환 받을 수 있습니다.
      <br />
      <br />
      **제8조 (전자금융거래정보의 제공금지)**
      <br />
      ‘회사’와 ‘이용자’는 전자금융거래서비스를 제공하기 위해 취득한 ‘이용자’의 인적 사항, ‘이용자’의
      계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를 법령에 따르거나
      ‘이용자’의 동의를 얻지 않고 제3자에게 제공•누설하거나 업무상 목적 외에 사용하지 않습니다.
      <br />
      <br />
      **제9조 (회사의 책임)**
      <br />
      1. ‘회사’는 접근매체의 위조나 변조로 발생한 사고, 계약체결 또는 거래지시의 전자적 전송이나
      처리 과정에서 발생한 사고, 전자금융거래를 위한 전자적 장치 또는 '정보통신망 이용촉진 및
      정보보호 등에 관한 법률' 제2조제1항제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한
      방법으로 획득한 접근매체의 이용으로 발생한 사고로 ‘이용자’에게 손해가 발생했을 때 그 손해를
      배상할 책임을 집니다.
      <br />
      2. ‘회사’는 제1항에도 불구하고 다음 각 호의 경우에는 그 책임의 전부 또는 일부를 ‘이용자’가
      부담하게 할 수 있습니다.
      <br /> ① ‘이용자’가 접근매체를 제3자에게 대여하거나 그 사용을 위임하거나 양도 또는 담보
      목적으로 제공하거나, 제3자가 권한 없이 ‘이용자’의 접근매체를 이용하여 전자금융거래를 할 수
      있음을 알았거나 알 수 있었음에도 ‘이용자’가 자신의 접근매체를 누설 또는 노출하거나 방치한 경우
      <br /> ② 법인(‘중소기업기본법’ 제2조 제2항에 따른 소기업은 제외)인 ‘이용자’에게 손해가 발생한
      경우로서 ‘회사’가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 지키는 등 합리적으로
      요구되는 충분한 주의 의무를 다한 경우
      <br /> ③ ‘이용자’로부터 거래지시가 있음에도 천재지변, ‘회사’의 귀책사유가 없는 정전, 화재,
      통신장애, 기타의 불가항력적인 사유로 처리할 수 없거나 지연된 경우로서 ‘이용자’에게 처리할 수
      없거나 지연할 수밖에 없는 사유를 통지 (금융기관 또는 결제수단 발행업체나 통신판매업자가 통지한
      경우를 포함)했을 때에는 ‘이용자’에 대하여 그 책임을 지지 않습니다.
      <br /> ④ ‘회사’는 컴퓨터 등 정보통신설비의 보수점검, 교체의 사유가 발생하여 전자금융서비스의
      제공을 일시적으로 중단할 때에는 회사의 홈페이지 또는 ‘스판’내 공지사항, 고객센터를 통해
      ‘스판’을 이용하는 ‘구매고객’ 및 ‘판매회원’을 비롯한 모든 ‘이용자’에게 중단 일정과 중단 사유를
      미리 공지합니다.
      <br />
      <br />
      **제10조 (분쟁처리 및 분쟁조정)**
      <br />
      1. ‘이용자’는 ‘일빵’의 홈페이지 메인 화면 하단에 게시된 분쟁처리 책임자와 담당자에게
      전자금융거래와 관련한 의견 및 불만 제기, 손해배상 청구 등의 분쟁처리를 요구할 수 있습니다.
      <br />
      2. ‘이용자’가 ‘회사’에 분쟁처리를 신청했을 때 ‘회사’는 15일 이내에 이에 대한 조사 또는 처리
      결과를 ‘이용자’에게 안내합니다.
      <br />
      3. ‘이용자’는 ‘회사’의 분쟁처리결과에 이의가 있을 경우, ‘금융위원회의 설치 등에 관한 법률’
      제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 ‘소비자기본법’ 제31조 제1항의 규정에
      따른 한국소비자원의 소비자분쟁조정위원회에 ‘회사’의 전자금융거래서비스 이용과 관련한
      분쟁조정을 신청할 수 있습니다.
      <br />
      <br />
      **제11조 (회사의 안정성 확보 의무)**
      <br />
      1. ‘회사’는 전자금융거래가 안전하게 처리될 수 있도록 선량한 관리자로서의 주의를 다하며
      전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나
      처리를 위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가
      정하는 기준을 지킵니다.
      <br />
      <br />
      **제12조 (약관 외 준칙)**
      <br />
      1. ‘회사’와 ‘이용자’ 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과 다를 때에는 그 합의
      사항을 이 약관에 우선하여 적용합니다.
      <br />
      2. 본 약관에서 정하지 않은 사항(용어의 정의 포함)은 전자금융거래법, 전자상거래 등에서의 소비자
      보호에 관한 법률, 통신판매에 관한 법률, 여신전문금융업법 등 소비자보호 관련 법령 및
      개별약관에서 정한 바를 따릅니다.
      <br />
      <br />
      **13조 (관할)**
      <br />
      ‘회사’와 ‘이용자’ 간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바를 따릅니다.
      <br />
      <br />
      <br />
      **[제 2 장 전자지급결제대행 서비스]**
      <br />
      <br />
      **제14조 (정의)**
      <br />
      전자지급결제대행 서비스’란 전자적 방법으로 재화 또는 용역(이하 ‘재화 등’)을 구매하는 것과
      관련하여 지급결제정보를 송신하거나 수신하는 것 또는 그 대가의 정산을 대행하거나 매개하는
      서비스를 의미합니다.
      <br />
      <br />
      **제15조 (구매 및 거래지시의 철회)**
      <br />
      1. 전자지급결제대행 서비스를 이용하는 ‘이용자’는 수취인의 계좌가 개설된 금융기관 또는 ‘회사’
      계좌의 원장에 입금이 기록되거나 전자적 장치에 입력이 끝날 때까지 거래 지시된 금액의 정보에
      대하여 거래지시를 철회할 수 있습니다.
      <br />
      2. ‘회사’는 ‘이용자’가 거래지시를 철회하여 지급거래가 이루어지지 않으면 수령한 자금을
      ‘이용자’에게 반환해야 합니다.
      <br />
      <br />
      **제16조 (접근매체의 관리)**
      <br />
      1. 전자지급결제대행 서비스를 제공할 때 ‘회사’는 접근매체를 선정하여 ‘이용자’의 신원, 권한 및
      거래지시의 내용 등을 확인할 수 있습니다.
      <br />
      2. ‘이용자’는 접근매체를 제3자에게 대여하거나 그 사용을 위임하거나 양도 또는 담보 목적으로
      제공할 수 없습니다.
      <br />
      3. ‘이용자’는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치해서는 안 되며, 접근매체의
      도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
      <br />
      4. ‘회사’는 ‘이용자’로부터 접근매체의 분실이나 도난 등의 통지를 받았을 때에는 그때부터 제3자가
      그 접근매체를 사용하여 ‘이용자’에게 끼친 손해를 배상할 책임이 있습니다.
      <br />
      <br />
      <br />
      **[제 3 장 결제대금예치서비스]**
      <br />
      <br />
      **17조 (정의)**
      <br />
      본 장에서 정하는 용어의 정의는 다음과 같습니다.
      <br />
      1. 결제대금예치서비스는 ‘구매고객과 ‘판매회원’을 대상으로 ‘스판’과의 업무 제휴를 통한 전자결제
      대행사(이하 ‘결제 대행사’)를 통해 제공됩니다.
      <br />
      2. 결제대금예치서비스는 ‘일빵’내에서 이루어지는 구매 및 거래에서 ‘구매고객’이 지급하는
      결제대금을 ‘결제 대행사’에 예치하고 제품에 대한 배송 및 인도 절차가 완료된 후 재화 등의 대금을
      ‘판매회원’에게 지급하는 제도를 의미합니다.
      <br />
      <br />
      **제18조 (적용범위)**
      <br />
      본 장은 ‘일빵’을 이용하는 구매고객과 판매회원을 통한 구매, 거래를 이용하는 경우에 적용됩니다.
      <br />
      <br />
      **제19조 (예치된 결제대금의 지급방법 및 절차)**
      <br />
      1. ‘구매고객’이 ‘일빵’에서 결제대금예치서비스를 통해 결제를 진행할 경우, 주문 금액과 결제
      금액이 일치하게 되면 ‘결제 대행사’에서 ‘회사’와 ‘판매회원’에게 이를 통보합니다.
      <br />
      2. ‘결제대행사’는 ‘구매고객’으로부터 지급받은 결제 대금을 지정한 날짜 내에 ‘판매회원’ 에게
      지급합니다.
      <br />
      3. ‘구매고객’이 ‘판매회원’으로부터 제품을 공급받은 후, ‘일빵’내 당 구매 및 거래건에 대한
      ‘구매완료’ 조치를 취할 경우에 한해 ‘전자결제’ 대행사는 ‘판매회원’에게 결제대금을 지급할 수
      있습니다.
      <br />
      4. ‘구매고객’이 ‘판매회원’으로부터 제품을 공급받은 이후에도 3일 이내 ‘구매완료’ 조치를 취하지
      않을 경우, ‘회사’는 ‘구매고객’에게 구매완료에 대한 조치를 요구할 수 있으며 이에 불구하고
      ‘구매회원’의 구매완료 조치가 이루어 지지 않은 경우, 제품 공급일 기준 4일차에 결제 대금을
      ‘판매회원’에게 지급합니다. (휴일 또는 공휴일인 경우, 이후의 업무 시작일 기준)
      <br />
      5. 결제대금예치서비스의 결제 절차 및 결제 대금 지급기한 등에 대한 사항은 ‘결제 대행사’의
      규정에 의거합니다.
      <br />
      <br />
      **제20조 (구매 및 거래지시의 철회)**
      <br />
      1. 구매 및 거래 철회 요청이 접수될 경우에는, ‘이용자’간의 귀책사유 및 수수료 부담 주체 등의
      협의를 거쳐, ‘판매회원’의 구매 및 거래 철회에 대한 승인이 완료된 후에 한해 ‘회사’는 결제
      대금을 ‘구매고객’에게 반환할 수 있도록 ‘결제 대행사’에 요청합니다.
      <br />
      2. ‘판매회원’은 본 철회 요청건에 대한 사항을 ‘구매고객’에게 공지해야 하며, 이에 대한
      ‘구매고객’ 별도의 요청 또는 이의제기가 없을 경우에는 ‘판매회원’과 ‘회사’는 이에 동의한 것으로
      간주합니다.
      <br />
      3. 결제대금예치서비스를 이용할 경우, 거래 지시된 금액의 정보가 수취인의 계좌가 개설되어 있는
      금융회사 또는 전자금융업자의 전자적 장치에 입력이 끝난 때까지 거래지시를 철회할 수 있습니다.
      <br />
      4. 해당 구매 및 거래에 대한 철회는 ‘일빵’을 통해 ‘구매고객’ 또는 ‘판매회원’이 직접 요청한
      것으로 간주하며, 철회과정에서 발생되는 모든 사항에 대해 ‘회사’ 또는 ‘일빵’은 관여하지
      않습니다.
      <br />
      본 약관은 2023년 08월 30일부터 적용됩니다.
      <br />
      <br />
      <br />
      **[제 1 장 총 칙]**
      <br />
      <br />
      **제 1조 (목적)**
      <br />
      본 약관은 스판(이하 ‘회사’)가 운영하는 ‘일빵’(http://www.ilppang.kr)를 통해 제공되는
      전자상거래 관련 서비스를 이용함에 있어 상품 또는 용역을 거래하는 자 간의 권리, 의무 등 기타
      필요사항, 회원과 회사간의 권리, 의무, 책임사항 및 회원의 서비스 이용절차 등에 관한 사항을
      규정함을 목적으로 합니다.
      <br />
      - PC통신, 무선 등을 이용하는 모든 서비스 이용에 있어 본질에 반하지 않는 한, 본 약관을
      준용합니다.
      <br />
      <br />
      **제 2조 (정의)**
      <br />
      1. ‘일빵’이란 ‘회사’가 재화 또는 용역(이하 ‘재화 등’이라 함)을 이용자에게 제공하기 위하여 MRO
      산업군 제품을 거래할 수 있도록 설정한 가상의 영업장 및 거래공간을 운영하는 사업자를
      의미합니다.
      <br />
      2. 이용자: 본 약관에 따라 ‘회사’가 제공하는 서비스를 받는 회원을 의미합니다.
      <br /> 가) 회원: ‘회사’가 정한 가입절차에 따라 회원등록을 한 개인 또는 사업자로서, ‘회사’의
      정보를 지속적으로 제공받으며 ‘회사’가 제공한 서비스를 이용할 수 있는 자를 의미합니다.
      <br />
      3. 아이디(ID): 회원의 식별과 회원의 서비스 이용을 위하여 회원이 설정하고 ‘회사’가 승인하여
      ‘회사’에 등록된 영문, 숫자 또는 영문과 숫자의 조합을 의미합니다.
      <br />
      4. 비밀번호: 회원 가입 여부를 확인하고, 회원 가입 정보를 포함한 비밀을 보호하기 위하여 회원이
      스스로가 설정해 ‘일빵’에 등록한 영문과 숫자의 조합을 의미합니다.
      <br />
      5. 운영자: ‘회사’에서 제공하는 서비스의 전반적인 관리와 원활한 운영을 위하여 ‘회사’에서 선정한
      자를 의미합니다.
      <br />
      6. 회원등급: ‘회사’가 제공하는 서비스를 이용한 결과에 따라 일정한 기준에 따라 ‘회사’가
      회원에게 부여하는 등급을 의미합니다.
      <br />
      7. 상품평: 상품구매 후 해당 상품에 대한 종합적인 평가를 기록한 글, 이미지, 영상물 등의
      게시물을 의미합니다.
      <br />
      8. 구매확정기간: 구매회원이 거래에 대하여 구매종료(구매확정, 반품, 교환)의 의사표시를 해야
      하는 기간으로, 물품수령일로부터 7일이 되는 날(설치상품 등 제품 수령일을 확인할 수 없을 때에는
      발송완료일로부터 15일이 지나 ‘회사’가 상품수령확인 요청을 한 날로부터 5일이 되는 날)까지를
      의미합니다.
      <br />
      9. 본인확인: ‘스판’에서 지정하는 인증절차를 통해 회원이 제 8조에서를 확인하거나 사업자 회원의
      상호와 사업자 등록번호의 일치 여부를 확인하고 제8조에서 정한 기재항목을 확인하는 것을
      의미합니다.
      <br />
      10. 영업일: ‘회사’가 정상적으로 서비스를 제공한 날로써 토요일, 일요일 및 법정 공휴일을 제외한
      날을 의미합니다.
      <br />
      11. 결제대금 보호서비스: ‘회사’가 구매회원이 결제한 결제대금을 보호하기 위하여 일정기간
      결제대금을 예치하는 서비스를 의미합니다.
      <br />
      12. 안전거래센터: 서비스 이용 중 거래의 안전과 관련하여 발생할 수 있는 문제를 조정하기 위한
      기구를 의미합니다.
      <br />
      13. 분쟁조정센터: ‘회사’ 또는 ‘일빵’을 통한 거래에서 분쟁이 발생하면 판매회원과 구매회원이
      자율적으로 해결하는 것이 원칙이지만, 분쟁의 합리적이고 원활한 조정을 위하여 예외적으로
      ‘회사’가 설치•운영하는 분쟁조정기구를 의미합니다.
      <br />
      14. 제1장에서 정의되지 않은 용어의 의미는 관련 법령 및 일반적인 거래관행과 ‘회사’가 제정한
      약관 및 이용방침에 의거합니다.
      <br />
      <br />
      **제 3조 (적용 범위 및 효력 우선순위)**
      <br />
      1. 본 약관에서 제1장(총칙), 제3장(기타사항)은 전체 서비스에 적용되며, 제2장은 ‘일빵’
      (통신판매중개) 서비스에 구분하여 적용됩니다.
      <br />
      2. 제1장 및 제3장의 규정과 개별서비스인 제2장, 제3장의 규정이 상충할 때에는 개별서비스 관련
      규정(제2장)이 전체서비스 관련 규정(제1장 및 제3장)보다 우선적인 효력을 갖습니다.
      <br />
      <br />
      **제 4조 (명시, 효력 및 변경)**
      <br />
      1. ‘회사’는 정의된 약관을 모든 이용자가 인지할 수 있도록 ‘일빵’의 지정된 영역에 게시합니다.
      <br />
      2. ‘회사’는 합리적인 사유가 발생하면 약관의 규제에 관한 법률, 전자거래기본법, 전자서명 법,
      정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등
      관련 법령에 위배되지 않는 범위 안에서 본 약관을 변경 할 수 있으며, ‘회사’가 약관을 변경할
      때에는 적용일자와 변경사유를 밝혀 그 적용일자 15일 전부터 서비스 사이트에 공지합니다. 다만,
      이용자에게 불리한 약관을 변경할 때에는 해당 내용을 그 적용일자 30일 전부터 공지하며, 이
      메일(e-mail), 문자메시지(SMS) 등으로 이용자에게 개별 통지합니다(이용자의 연락처 미 기재, 변경
      등으로 개별 통지가 어려울 때에는 본 약관에 따라 공지를 하는 것을 개별 통지한 것으로
      간주합니다).
      <br />
      3. 본 약관에 동의하는 것은 ‘회사’가 상시 운영하는 ‘일빵’의 방문을 통해 약관의 변경사항 확인 및
      동의함을 의미하며, 변경된 약관에 대한 정보를 인지하지 못한 상황에서 발생하거나 발생된 회원
      또는 이용자의 피해에 대해 ‘회사’는 책임을 지지 않습니다.
      <br />
      4. 변경된 약관에 동의하지 않는 회원은 회원 탈퇴(이용계약의 해지)를 요청할 수 있습니다.
      <br />
      5. 기타, 본 항목에 의거하여 ‘회사’는 이용자가 변경된 약관에 동의한 것으로 간주합니다.
      <br /> ① ‘회사’가 제2항에 따라 이용자에게 약관 변경 적용일 까지 거부의사를 표시하지 않을 경우
      <br /> ② 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 이용자가
      명시적으로 약관 변경에 대한 거부의사를 표시하지 않을 경우
      <br />
      <br />
      **제 5조 (약관 외 준칙)**
      <br />
      1. 본 약관에 규정되지 않은 사항에 대해서는 전자상거래 등에서의 소비자보호에 관한 법률 등 관련
      법령의 규정과 일반 상 관례를 따릅니다.
      <br />
      2. ‘회사’는 필요할 경우, 특정 서비스에 적용될 사항(이하 “개별약관”)을 정하여 이를 ‘일빵’ 또는
      ‘회사’가 운영하는 타 매체를 활용(고객센터 및 기타)하여 공지할 수 있습니다.
      <br />
      3. 개별약관이나 이용정책의 변경이 있을 경우, ‘회사’는 변경 내용의 효력발생일 7일 이전에
      ‘일빵’에서 해당 변경 사항을 공지합니다.
      <br />
      4. 이용자는 항상 개별약관이나 이용정책의 내용에 변경이 있는지 여부를 주시하여야 하며,
      변경사항의 공지가 있을 때에는 이를 확인해야 합니다.
      <br />
      <br />
      **제6조 (이용계약의 성립)**
      <br />
      1. ‘회사’가 제공하는 서비스에 관한 이용계약(이하 ‘이용계약’)은 서비스 이용자의 이용신청을
      ‘회사’가 승낙함으로써 성립하며, 이때 ‘일빵’ 이용자는 ‘회사’가 온라인으로 제공하는 회원가입
      신청 양식에 따라 필요한 각각의 항목에 해당 내용을 기재한 후 본 약관에 대한 동의 의사를
      표시해야 합니다.
      <br />
      3. 회원가입은 구매회원의 경우 만 14세 이상 가능하며, 판매회원(개인사업자 및 법인사업자 포함)은
      가입 신청일 기준, 정상적인 영업활동이 가능해야 신청할 수 있습니다.
      <br />
      4. 회원은 다수의 최초 본인 확인절차가 완료된 단일 아이디(ID)에 한하여 가능합니다. 판매회원은
      본인 1인(본인확인 기준, 법인사업자와 개인사업자의 경우에는 각각 법인번호, 사업자번호 기준)당
      1개 아이디를 사용하는 것을 원칙으로 합니다.
      <br />
      5. 이용계약을 종료(해지 포함)한 후 재가입하는 회원은 최종 이용할 때 사용한 아이디(ID)는 사용할
      수 없으며 새로운 아이디를 사용하는 것을 원칙으로 합니다.
      <br />
      6. 판매회원이 회원 등록 정보를 확인할 경우에는 상호와 사업자 등록번호의 일치 여부를 확인하고
      제 8조에 정한 기재항목을 확인하는 방식으로 본인확인절차를 진행합니다. 또한 ‘회사’는
      본인확인절차를 따를 수 없는 이용신청이 있으면 해당 신청자에게 증빙자료를 요청할 수 있습니다.
      <br />
      7. 탈퇴 후 회원 재가입, 임의 해지 등을 반복하거나 다수의 아이디(ID)로 가입하여 ‘회사’가
      제공하는 각종 할인쿠폰, 이벤트 혜택 등으로 경제적 이익을 편법으로 수취하고, 이 과정에서 명의를
      무단으로 사용하는 등 편법과 불법 행위를 하는 회원을 차단하고자 ‘회사’는 다수의 아이디(ID)보유
      회원에 대하여 최초 본인 확인이 완료된 단일 아이디(ID)에 한하여 일부 서비스 이용을 허용하거나
      제한할 수 있으며, 회원 탈퇴 후 1개월이 지나지 않으면 회원의 서비스 가입신청을 거부할 수
      있습니다.
      <br />
      <br />
      **제7조 (개인정보의 변경, 보호)**
      <br />
      1. 회원은 이용신청 시 기재한 사항이 변경되었을 경우에는 상당한 기간 이내에 회원정보 수정 등의
      방법으로 그 변경사항을 수정하여야 합니다. 단, 이름, 아이디(ID) 등 변경할 수 없는 사항은 수정할
      수 없습니다.
      <br />
      2. 수정하지 않은 정보로 말미암아 발생하는 손해는 해당 회원이 부담하며, ‘회사’는 이에 대해
      아무런 책임을 지지 않습니다.
      <br />
      3. ‘회사’는 이용자의 정보를 서비스를 제공하기 위한 목적 이외의 용도로 사용하거나 이용자의 동의
      없이 제3자에게 제공하지 않습니다. 다만 관련 법령에 의거하거나 별도로 정하는 경우에는 예외로
      합니다.
      <br />
      4. ‘회사’는 관련법령이 정하는 바에 따라서 이용자의 개인정보를 보호하기 위하여 노력하며,
      이용자의 개인정보를 보호하기 위한 정책을 수립하고 개인정보보호 책임자를 지정하여 이를
      게시합니다.
      <br />
      5. 이용자의 개인정보보호는 관련 법령 및 ‘회사’가 정하는 개인정보취급방침에서 정한 바를
      따릅니다.
      <br />
      6. ‘일빵’을 통해 거래가 성사 되었을 때 ‘회사’는 판매회원과 구매회원 간에 물품거래와 배송 등의
      이행과 관련하여 필요한 범위 내에서 구매회원의 개인정보를 비롯한 모든 정보는 판매회원에게
      제공합니다.
      <br />
      <br />
      **제8조 (서비스 이용신청)**
      <br />
      1. ‘일빵’서비스에 회원으로 가입하고자 할 경우에는 ‘회사’에서 제공한 회원가입 신청양식에 따라
      필요사항을 기재해야 합니다. 회원가입 양식은 필수 기재 항목과 선택 기재 항목으로 구분 되어
      있으며 필수 기재 항목은 반드시 입력 해야 합니다.
      <br />
      2. 구매회원의 필수 기재 항목: 회원아이디 (ID), 비밀번호(Password), 성명, 생년월일, 배송지
      주소, 사업자등록증 일반 및 휴대전화번호
      <br />
      3. 판매회원의 필수 기재 항목: 사업자 유형, 회원아이디(ID), 비밀번호(Password), 비밀번호 확인,
      휴대폰전화번호, 상호(사업체 명), 대표자 성명, 사업자등록증, 사업자등록번호, 사업장 주소
      <br />
      4. 이용자는 사업자회원으로 가입신청을 하여야 하며, 사업자회원으로 가입하고자 하는 자는
      ‘회사’의 요청에 따라 사업자등록증 사본, (개인사업자의 경우)대표자의 신분증 사본,
      법인등기부등본, 법인인감증명서, 결제를 위한 대표자 또는 법인명의의 계좌정보 등의 증빙자료와
      추가 정보를 제공하여야 합니다.
      <br />
      5. ‘회사’는 이용자의 회원 가입 시 제1항 또는 제2항에 표시된 필수항목 외에 추가적인 정보(예:
      성별, 유선전화번호 등)를 요청할 수 있으며, 이용신청자는 그러한 추가적인 정보의 제공을 거절할
      수 있습니다. 추가적인 정보는 회원에게 다양하고 유익한 정보를 제공하기 위해서만 사용되며 그
      밖의 목적으로 사용할 수 없습니다.
      <br />
      6. ‘회사’는 모든 회원에게 광고성 이메일(E-mail) 수신여부, 광고성 문자메시지(SMS) 수신여부,
      제휴회사 및 개인정보 위탁관리회사에 대한 개인정보 제공∙이용에 대한 동의 여부를 확인할 수
      있습니다.
      <br />
      <br />
      **제9조 (이용신청의 승낙, 거부, 유보)**
      <br />
      1. ‘회사’는 본 약관 제8조에서 정한 기재항목을 사실대로 정확하게 기재하고 본 약관에 동의한 자의
      가입 신청에 대하여 서비스의 이용을 승낙함을 원칙으로 합니다.
      <br />
      2. ‘회사’는 판매회원의 가입신청에 대해서는 법령에 따라 이용 할 수 있는 본인확인기관을 통하여
      본인 여부를 확인하는 방식의 본인확인절차를 이행하며, 사업자회원의 가입신청에 대해서 제8조
      제4항에 따라 제공받은 증빙자료 등으로 본인확인절차를 이행합니다. ‘회사’는 본인확인절차를
      이행할 수 없는 이용신청에 대해서는 신청자에게 증빙자료를 요청할 수 있습니다.
      <br />
      3. ‘회사’는 다음과 같은 사유가 있으면, 회원 가입 및 이용 신청에 대한 승낙을 거부할 수
      있습니다.
      <br /> ① 만 14세 미만의 이용자 신청을 하는 경우
      <br /> ② 상기 제 2항의 본인확인절차에서 본인이 확인된 경우
      <br /> ③ 이미 가입된 판매회원 또는 사업자구매회원과 성명 및 CI(Connecting Information,
      연계정보, 서비스 연계를 위해 본인확인기관에서 부여하는 개인식별정보), DI(Duplication
      Information, 중복가입 확인정보) 또는 사업자등록번호, 법인등록번호가 동일한 경우
      <br /> ④ 신청, 등록 내용에 허위, 기재누락, 오기 등이 있는 경우
      <br /> ⑤ 이용계약이 해지된 날로부터 1개월 이내에 재이용신청을 하는 경우
      <br /> ⑥ ‘회사’로부터 회원자격정지 조치 등을 받은 회원이 그 조치 기간에 이용계약을 임의
      해지하고 재이용신청을 하는 경우(개인구매회원에게 재이용이 허락되더라도, 서비스 이용과정에서
      본인확인 후 회원자격정지 조치를 받았던 사실이 확인되면 해당 ID의 이용계약이 해지될 수 있음)
      <br /> ⑦ 기타 본 구매 및 구매확정 기간의 적용을 받는 자가 해당 약관을 위반하였거나, 약관에
      위배되거나 위법 또는 부당한 이용신청임이 확인된 경우
      <br />
      4. ‘회사’는 다음과 같은 사유가 있으면, 이용신청에 대한 승낙을 유보할 수 있습니다. 이 때,
      ‘회사’는 이용신청자에게 승낙유보의 사유, 승낙 가능 시기 또는 승낙에 필요한 추가요청정보나 자료
      등 승낙유보와 관련된 사항을 해당 서비스 화면에 게시하거나 이 메일(E-mail)로 통지합니다.
      <br /> ① 설비에 여유가 없는 경우
      <br /> ② 기술상 지장이 있는 경우
      <br /> ③ ‘회사’의 본인확인절차에서 본인 여부가 확인되지 않는 경우
      <br /> ④ 기타 ‘회사’가 합리적인 판단으로 의하여 필요하다고 인정하는 경우
      <br />
      <br />
      **제10조 (이용계약의 종료)**
      <br />
      1. 회원은 다음 각호에서 정한 바에 따라 이용계약을 해지할 수 있습니다.
      <br /> ① 회원은 언제든지 ‘회사’에 해지의 의사를 통지하여 이용계약을 해지할 수 있습니다. 단,
      해지의사를 통지하기 전에 현재 진행 중인 모든 상품의 거래를 완료하거나 철회 또는 취소하여야
      하며, 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 또한, 회원이
      ‘회사’에 대한 채무를 전부 이행하지 않으면 회원이 해당 채무를 전부 이행할 때까지 ‘회사’는
      회원의 이용계약 해지를 제한할 수 있으며, 회원이 본 약관과 관계 법령 등을 위반하여 이용이
      정지된 경우 ‘회사’가 재발방지를 위하여 회원의 이용계약 해지를 제한할 수 있습니다.
      <br /> ② ‘회사’가 해지할 때에는 ‘회사’는 회원에게 이 메일(E-mail), 전화, 팩스, 기타의 방법으로
      해지 사유를 밝혀 해지 의사를 통지합니다. 거래의 안전을 위하여 ‘회사’는 동일인 소유로 확인되는
      회원 아이디(ID)에 대하여 일괄 해지 의사를 통지할 수 있습니다. 이용계약은 ‘회사’의 해지의사를
      회원에게 통지한 시점에 종료됩니다.
      <br /> ③ 회원의 의사로 해지한 후 회원 재가입, 임의 해지 등을 반복적으로 행하여 ‘회사’가
      제공하는 각종 할인쿠폰, 이벤트 혜택 등 경제적 이익을 편법으로 수취하거나 이 과정에서 명의를
      무단으로 사용하는 등 편법과 불법행위를 하는 것을 차단하고자 ‘회사’는 회원에게 회원 탈퇴한
      날로부터 1개월이 지나야 재가입 할 수 있게 하고 있으며 회원은 본 약관이 정하는 회원가입절차와
      관련 조항에 따라 ‘회사’에 통지된 재 이용 의사를 ‘회사’로부터 승낙 받은 이후에 서비스를
      재이용할 수 있습니다.
      <br /> ④ 회원 재가입, 임의 해지 등을 반복적으로 행하여 ‘회사’가 제공하는 각종 할인쿠폰, 이벤트
      혜택 등의 경제적 이익을 편법으로 수취하거나 이 과정에서 명의를 무단으로 사용하는 등 편법과
      불법 행위를 하는 것을 차단하고자 회원탈퇴 후 1개월 동안 해당 회원의 아이디, 성명, 주소,
      연락처, 탈퇴일자, 탈퇴사유 등 개인정보를 보관합니다.
      <br />
      2. ‘회사’는 다음 각호에서 정한 바에 따라 이용계약을 해지할 수 있습니다.
      <br /> ① ‘회사’는 회원에게 다음 각 항목의 1에 해당하는 사유가 발생하거나 확인되면 이용계약을
      해지할 수 있습니다.
      <br /> 가) ‘회사’(직원/상담원 포함)의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국
      법령 또는 공서양속에 위배되는 행위를 한 경우
      <br /> 나) 서비스의 원활한 진행을 방해하거나 방해할 우려가 있는 아래의 행위 등을 하거나 시도한
      경우
      <br /> I. 합리적 사유 없이 상습적/악의적으로 이의를 제기하는 행위
      <br /> II. 구매한 상품 또는 서비스에 특별한 하자가 없음에도, 상습적으로 사용 후 취소, 반품
      등을 하는 행위
      <br /> III. 그 외 ‘회사’가 정한 안전거래 이용규칙을 위반한 경우
      <br /> 다) 회원의 누적 신용도가 일정 점수 이하에 이른 경우
      <br /> 라) 고의로 ‘회사’의 영업을 방해 하거나 판매부적합 상품을 등록 할 경우
      <br /> 마) 회원이 본 약관에 위배되는 행위를 하거나 본 약관에서 정한 해지사유가 발생한
      경우(판매회원 약관의 적용을 받는 자가 해당 약관을 위반한 경우 포함)
      <br /> 바) 회원에게 제9조에서 정한 이용계약의 승낙거부사유가 있음이 확인된 경우
      <br /> 사) 기타 ‘회사’가 합리적인 판단에 따라 서비스의 제공을 거부할 필요가 있다고 인정할 경우
      <br /> ② ‘회사’가 해지할 때에는 ‘회사’는 회원에게 이 메일(E-mail), 전화, 팩스, 기타의 방법으로
      해지 사유를 밝혀 해지 의사를 통지합니다. 이용계약은 ‘회사’의 해지의사를 회원에게 통지한 시점에
      종료됩니다.
      <br /> ③ 본 항에 따라 ‘회사’가 이용계약을 해지하더라도, 해지 이전에 이미 체결된 매매계약의
      완결에 관해서는 본 약관이 계속 적용됩니다.
      <br /> 가) 본 항에서 정한 바에 따라 이용계약이 종료될 때에는 ‘회사’는 별도 통지 없이 해당
      회원과 관련된 광고와 거래를 취소할 수 있고, 구매회원이 상품구매대금을 신용카드로 결제했을
      때에는 해당 신용카드매출을 취소할 수 있으며, 회원에게 부가적으로 제공한 각종 혜택을 회수할 수
      있습니다.
      <br /> 나) 본 항에서 정한 바에 따라 이용계약이 종료되면 ‘회사’는 회원의 재이용 신청을 그
      승낙하지 않을 수 있습니다.
      <br />
      3. 이용계약의 종료와 관련하여 발생한 손해에 대해서는 이용계약이 종료된 해당 회원이 책임을 져야
      하고, ‘회사’는 일절 책임을 지지 않습니다.
      <br />
      <br />
      **제11조 (회원등급 및 평가제도)**
      <br />
      1. ‘회사’는 대한민국MRO협동조합 가입여부 및 정회원 여부에 따라, 회원등급 및 등급에 따른 일정
      혜택 등을 회원에게 부여할 수 있습니다. 회원등급 및 그에 따른 혜택 등은 서비스 이용실적, 기타
      ‘회사’가 정하는 이용정책에서 정한 기준에 따라 부여되며, 자세한 내용은 해당 서비스 화면에
      게재하거나 ‘회사’가 운영하는 인터넷 사이트 초기화면에 공지합니다(회원등급 정책 및 그에 따른
      혜택 등에 대한 변경이 있으면 본 약관 제5조 제4항에 따라 변경 공지합니다).
      <br />
      2. 해당 회원이 자신에게 부여된 등급 구성요소에 대하여 이의를 제기하면 ‘회사’는 소명내용, 해당
      회원의 신용도 등 제반 상황을 고려하여 등급 구성요소의 전부 또는 일부를 조정할 수 있습니다.
      <br />
      3. 회원의 등급은 구매 및 판매를 포함한 서비스 이용 시 참고자료로 활용될 뿐이며, 회원의 신용을
      보증하거나 금융상의 신용상태를 의미하는 것은 아닙니다.
      <br />
      4. 거래확정 때 구매회원은 해당 거래에 대한 만족도를 ‘회사’가 정한 단계별로 표시할 수 있으며,
      표시된 만족도는 해당 상품 판매회원의 평가에 반영됩니다. 거래만족도 항목 및 단계는 해당 서비스
      화면에 표시합니다.
      <br />
      5. 상품 평은 상품을 구매한 구매회원이 작성할 수 있으며 공개를 원칙으로 합니다. 상품 평
      작성자는 자신이 작성한 상품 평을 삭제할 수 있습니다. 다만, 다수 구매자 편의를 위해 공개된
      상품평의 삭제는 제한될 수 있습니다. 상품 평과 첨부된 의견의 내용이 욕설, 비방, 명예훼손 등의
      내용이거나 상품 평가와 무관한 내용을 담고 있어 공개가 부적절하다고 판단되면, ‘회사’는 해당
      상품 평과 첨부된 의견을 삭제할 수 있습니다. 다만 상품에 대한 불만, 판매회원에게 불리한
      내용이라는 이유만으로는 삭제하지 않습니다. 상품 평을 삭제할 때에는 상품평에 첨부된 의견도 함께
      삭제됩니다. 이와 관련한 자세한 내용은 본 이용약관과 해당 서비스 화면에 게재합니다.
      <br />
      6. 거래 상대방(판매회원 또는 구매회원)에 대한 거래만족도 평가와 상품 평 작성은 전적으로 평가
      당사자의 책임 하에 이루어지며, ‘회사’는 내용의 사실 여부에 개입하지 않습니다.
      <br />
      7. 회원이 회원등급 및 회원평가제도의 목적과 취지에 어긋나는 행위를 하면 ‘회사’는 해당
      평가결과를 삭제하고 관련 회원에 대한 서비스 이용자격을 정지하는 등 제재를 가할 수 있습니다.
      <br />
      8. 아래와 같은 내용에 해당하면 상품 평을 삭제하거나 회원의 이용자격이 정지될 수 있습니다.
      <br /> ① 높은 만족도를 주고 상품 평을 쓰는 대가로 금전 또는 기타의 보상을 받거나 받기로
      약정하고 이루어진 평가결과 및 그 평가행위
      <br /> ② 상품 평을 통하여 타인을 모욕하거나 타인의 명예와 신용을 훼손하는 등의 행위 및 그
      평가결과
      <br /> ③ 기타 회원평가제도의 목적과 취지에 반하여 평가제도를 이용하는 행위
      <br />
      <br />
      **제12조 (회원관리)**
      <br />
      1. ‘회사’는 본 약관과 관계 법령 및 상거래의 일반원칙을 위반한 회원에게 다음과 같은 조치를 할
      수 있습니다.
      <br /> ① 신용점수 차감
      <br /> ② ‘회사’가 부가적으로 제공한 혜택의 일부 또는 전부의 회수
      <br /> ③ 특정 서비스 이용제한
      <br /> ④ 이용계약의 해지
      <br /> ⑤ 손해배상의 청구
      <br />
      2. ‘회사’가 제1항 각 호에 정한 조치를 할 때는 미리 회원에게 전화 또는 이 메일(E-mail)로
      통보하며, 회원과의 연락이 안되거나 긴급을 요구하는 등 부득이할 때에는 해당 사항을 먼저 조치한
      후 나중에 통보할 수 있습니다. 단, 신용점수 차감은 통보 없이 할 수 있습니다.
      <br />
      3. 회원은 본 조에 따른 ‘회사’의 조치에 대해 항변의 사유가 있으면 항변을 할 수 있습니다.
      <br />
      4. ‘회사’는 회원이 1년 이상 로그인 하지 않으면, 휴면계정으로 간주하고 ‘회사’가 제공하는 서비스
      이용을 제한할 수 있습니다. 휴면계정으로 지정된 이후 다시 서비스를 이용하려면 거래안전을 위해
      ‘회사’가 정하는 본인확인 절차 등을 이행하여야 합니다.
      <br />
      <br />
      **13조 (서비스 기간과 중단)**
      <br />
      1. 회원의 서비스 기간은 ‘일빵’에 회원가입을 신청하여 이용승낙을 받은 날로부터 서비스
      이용계약에 대한 해지가 완료된 일자 또는 기간 입니다.
      <br />
      2. ‘회사’는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가
      발생하면 서비스를 일시적으로 중단할 수 있으며 그럴 경우 서비스 일시 중단 사실과 그 사유를
      사이트 초기 화면에 통지합니다.
      <br />
      3. ‘회사’는 천재지변 또는 이와 같은 불가항력으로 서비스를 제공할 수 없을 경우, 제공을
      제한하거나 일시 중단할 수 있습니다.
      <br />
      <br />
      **제14조 (서비스 이용료)**
      <br />
      1. ‘회사’는 필요 시, ‘일빵’내에서 일부 특정 기능을 제공할 경우 서비스 이용료를 부과할 수
      있습니다
      <br />
      2. 본 항목의 이용요금은 본 조 및 개별 약관 또는 별도 신청서에서 정하는 바를 따릅니다.
      <br />
      <br />
      **제15조 (할인쿠폰의 사용)**
      <br />
      1. ‘회사’ 또는 판매회원은 구매회원에게 상품 구매 시 일정금액 또는 일정비율을 할인 받을 수 있는
      할인 쿠폰을 발급할 수 있습니다.
      <br />
      2. 할인 쿠폰은 회원 본인의 구매에만 사용할 수 있으며, 어떠한 경우에도 타인에게 매매하거나
      양도할 수 없습니다.
      <br />
      3. 할인 쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며 유효기간이 지난 후에는 사용할
      수 없고, 구매확정 기간 취소나 반품으로 환불이 이루어졌을 때에는 재사용을 할 수 없습니다.
      <br />
      4. 구매회원에게 제공된 할인 쿠폰은 한 상품 구매 때 중복으로 사용할 수 없습니다. 단, 할인
      쿠폰의 발행자가 서로 다르면 중복으로 사용할 수도 있습니다.
      <br />
      <br />
      **제16조 (접근매체의 관리)**
      <br />
      1. 전자지급결제대행 서비스를 제공할 때 ‘회사’는 접근매체를 선정하여 이용자의 신원, 권한 및
      거래지시의 내용 등을 확인할 수 있습니다.
      <br />
      2. 이용자는 접근매체를 제3자에게 대여하거나 그 사용을 위임하거나 양도 또는 담보 목적으로
      제공할 수 없습니다.
      <br />
      3. 이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치해서는 안 되며, 접근매체의
      도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
      <br />
      4. ‘회사’는 이용자로부터 접근매체의 분실이나 도난 등의 통지를 받았을 때에는 그때부터 제3자가
      그 접근매체를 사용하여 이용자에게 끼친 손해를 배상할 책임이 있습니다.
      <br />
      <br />
      **제17조 (저작권정책)**
      <br />
      1. ‘회사’는 제공하는 서비스와 관련하여 저작권자의 저작권 보호를 위한 정책을 수립해 운영하며
      회원은 ‘회사’의 저작권 정책을 지켜야 합니다.
      <br />
      2. 회원이 ‘회사’가 제공하는 서비스를 이용하면서 작성한 각종 게시물의 저작권은 회원 본인에게
      있으며, 해당 게시물의 저작권 침해를 비롯한 민/형사상 모든 책임은 회원 본인이 집니다.
      <br />
      3. ‘회사’는 회원이 등록한 게시물을 검색사이트나 다른 사이트에 노출할 수 있으며, 판촉, 홍보 및
      기타의 자료로 무상으로 사용할 수 있습니다. 또한, ‘회사’가 제공하는 서비스 내에서 회원 게시물을
      복제, 전시, 전송, 배포할 수 있으며 2차적 저작물과 편집저작물로 작성 할 수 있습니다. 다만, 해당
      게시물을 등록한 회원이 게시물의 삭제 또는 사용중지를 요청하면 ‘회사’는 관련 법률에 따라
      보존해야 하는 사항을 제외하고 즉시 삭제 또는 사용을 중지합니다.
      <br />
      4. 본 조 제3항에 언급된 ‘회사’의 저작권은 ‘일빵’을 운영하는 동안에만 유효합니다.
      <br />
      5. ‘회사’가 본 조 제3항 이외의 방법으로 회원의 게시물을 상업적으로 이용하거나, 저작권을
      소유하고자 할 경우에는, ‘회원’이 명확히 인지할 수 있도록 별도로 표기하거나 전화, 팩스, 이
      메일(E-mail)등의 방법으로 미리 회원의 동의를 얻어야 합니다. 단, 회원정보에 등록된 연락처가
      사실과 다르거나 회원이 ‘회사’의 연락에 응하지 않아 미리 동의를 구하지 못했다면, ‘회사’는
      사후에 동의 절차의 이행을 요청할 수 있습니다. ‘회사’가 본 항에 따라 회원의 게시물을 상업적으로
      이용할 때 ‘회사’는 별도의 보상제도를 운영할 수 있습니다.
      <br />
      6. ‘회사’가 작성한 저작물에 대한 저작권과 지적 재산권은 ‘회사’에 귀속합니다.
      <br />
      7. 회원을 포함한 모든 이용자가 ‘일빵’을 이용하여 얻은 정보를 ‘회사’의 승낙 없이 복제, 송신,
      출판, 배포, 방송, 기타 방법으로 이용하거나 ‘회사’가 별도로 허용하지 않은 제3자가 이용하도록 할
      경우, ‘회사’는 본 책임을 문제를 일으킨 당사자에게 물을 수 있습니다.
      <br />
      8. 회원 및 이용자는 ‘일빵’ 내에서 자신의 저작권이 침해되면 ‘회사’가 운영하는 신고센터 제도를
      이용하여 자신의 정당한 권리를 보호받을 수 있습니다.
      <br />
      9. 게시물의 내용이 다음 각 호의 내용을 담고 있을 때 ‘회사’는 게시물을 삭제하거나 게시자에게
      특정 서비스의 이용제한 또는 정지, 이용계약의 해지 등의 조치를 취할 수 있습니다. 이때 게시물이
      삭제되면 해당 게시물과 관련된 게시물(답변 글, 댓글 등)도 모두 삭제됩니다.
      <br /> ① 대한민국의 법령을 위반하는 내용을 포함하는 경우
      <br /> ② 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 경우
      <br /> ③ 정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함하는 경우
      <br /> ④ 사회 공공질서나 미풍양속에 위배되는 경우
      <br /> ⑤ 기타 제1호 내지 제4호에 준하는 중대한 사유로 말미암아 ‘회사’가 제공하는 서비스의
      원활한 운영을 방해하는 것으로 판단되는 경우
      <br /> 10. ‘회사’가 제9항에 따라 게시물을 삭제했을 경우, 해당 게시자는 ‘회사’에 이의를
      제기하여 구제받을 수 있습니다.
      <br />
      <br />
      <br />
      **[제2장 일빵 (통신 판매/거래 중개) 서비스]**
      <br />
      <br />
      **제18조 (서비스의 종류)**
      <br />
      ‘회사’에서 회원에게 제공하는 서비스의 종류는 다음과 같습니다.
      <br />
      1. E-Commerce 서비스: ‘회사’가 ‘일빵’을 통하여 회원 간 제품의 구매 및 거래가 이루어질 수
      있도록 온라인 거래장소를 제공하는 서비스 및 관련 부가서비스를 의미합니다.
      <br /> ① 판매 관련 업무지원 서비스
      <br /> ② 구매 관련 지원 서비스
      <br /> ③ 견적거래 관련 지원 서비스
      <br /> ④ 계약체결, 결제 지원 및 결제대금 보호 서비스
      <br /> ⑤ 상품검색정보 서비스
      <br /> ⑥ 기타 전자상거래 관련 서비스
      <br /> ⑦ 기타 광고 및 프로모션 서비스
      <br />
      2. 기타 서비스: 상품문의게시판 서비스, 구매고객과 판매회원간의 Q&A 서비스, 상품 평 등 기타
      정보제공 서비스, ‘회사’가 직접 또는 제휴사와 공동으로 제공되는 서비스를 의미합니다.
      <br />
      <br />
      **제19조 (대리행위 및 보증인의 부인)**
      <br />
      1. ‘회사’는 구매고객과 판매회원의 자유로운 거래를 위한 시스템을 운영 및 관리, 제공할 뿐이므로
      구매고객 또는 판매회원을 대리하지 않으며, 회원 사이에 성립된 거래와 관련된 책임과 회원이
      제공한 정보에 대한 책임은 해당 회원이 직접 부담해야 합니다.
      <br />
      2. ‘회사’는 ‘일빵’을 통해 진행되는 구매고객과 판매회원 간의 거래와 관련하여 판매의사 또는
      구매의사의 존재여부 및 진정성, 등록 상품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한
      비 침해성, 구매고객 또는 판매회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된
      자료의 진실성 또는 적법성 등 일체를 보증하지 않으며, 이와 관련한 일체의 위험과 책임은 해당
      이용자가 전적으로 부담합니다.
      <br />
      3. ‘회사’는 구매고객과 판매회원간의 거래의 안전성과 신뢰성을 높이는 도구만을 개발하여
      제공합니다. 단, ‘회사’가 판매하는 직매입 상품의 경우 구매고객에 대하여 ‘회사’는 판매회원의
      지위를 갖게 됩니다.
      <br />
      <br />
      **제20조 (구매고객의 서비스 이용)**
      <br />
      1. 구매고객은 구매의사 없이 판매회원의 상품에 입찰하거나 구매표시를 해서는 안 되며, 구매의사
      없이 입찰행위를 통하여 상품 가격을 올리거나 구매의사를 표시하여 판매회원의 상품판매 기회를
      방해하거나 다른 구매고객의 구매기회를 방해한 것이 적발되면 ‘회사’는 해당 아이디(ID)[해당
      아이디(ID)와 동일인 소유로 확인되는 아이디(ID) 포함]에 대하여 회원자격을 정지하는 등의 조치를
      취할 수 있습니다
      <br />
      2. 구매고객은, 제품 구매 전, 반드시 판매회원이 사이트 내에 작성한 상품의 상세 내용과 거래의
      조건을 정확하게 확인해야 합니다. 구매하고자 하는 상품의 내용과 거래의 조건을 확인하지 않고
      구매하여 발생한 모든 손실과 손해에 대해 ‘회사’는 책임지지 않습니다.
      <br />
      3. 구매고객은 본 약관과 ‘회사’가 서비스 화면에서 알리는 내용을 준수해야 하며, 본 약관을 포함한
      공지사항을 위반하거나 이행하지 않아 발생되는 모든 손실과 손해에 대한 책임을 집니다.
      <br />
      4. ‘회사’는 판매회원이 등록한 상품의 내용과 거래조건에 대해서 어떠한 보증이나 대리를 하지 않기
      때문에 구매고객은 스스로 책임지고 상품을 구매해야 합니다.
      <br />
      5. 구매고객은 구매한 상품에 청약철회의 원인이 발생하면 수령한 상품을 임의로 사용하거나
      훼손되도록 방치해서는 안 됩니다. 청약철회 상품의 임의사용이나 상품보관의 미흡으로 상품이
      훼손되었을 때에는 그에 합당한 비용을 부담해야 합니다.
      <br />
      6. 구매고객은 판매회원과 제품 거래 절차에서 분쟁이 발생했을 경우, 분쟁의 해결을 위해 성실히
      임해야 하며, 분쟁해결의 불성실로 판매회원과 ‘회사’에 손실과 손해가 발생했을 경우, 해당하는
      모든 책임을 져야 합니다.
      <br />
      7. 제품 구매 시, 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제수단을 임의로 사용
      해서는 안 됩니다. 타인의 결제수단을 임의로 사용하여 발생하는 ‘회사’, 결제수단 소유자,
      판매회원의 손실과 손해에 대한 모든 책임은 구매고객에게 있습니다.
      <br />
      8. 매매대금의 결제와 관련하여 구매고객이 입력한 정보 및 그 정보와 관련하여 발생한 책임과
      불이익은 전적으로 구매고객이 감당해야 합니다.
      <br />
      9. 제품 주문 후 5영업일(토요일과 공휴일 제외) 이내에 구매 및 거래대금을 결제하지 않으면 해당
      주문건은 자동으로 취소됩니다.
      <br />
      10. 견적 구매 시, 판매회원이 명시하는 결제기한 또는 견적서의 유효기간 내 거래 대금이 결제되지
      않은 경우, 진행된 모든 거래는 무효가 될 수 있습니다.
      <br />
      11. ‘회사’는 구매고객이 매매대금 결제 때 사용한 결제수단에 대해 정당한 사용권한이 있는지
      확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래진행을 중지하거나 해당 거래를 취소할 수
      있습니다.
      <br />
      12. ‘회사’는 구매고객에게 서비스가 안전하게 제공 될 수 있도록 각종 설비와 자료를 관리해야
      하며, 서비스가 제공 목적에 맞게 이용되고 있는지 확인하고 구매고객에게 이용 목적에 위반되는
      부분이 있는 것으로 확인되면 사유의 소명을 요청할 수 있습니다.
      <br />
      13. ‘회사’가 구매고객의 서비스 이용 편의를 높이기 위해 제휴업체로부터 정보를 제공받아
      게재하거나 제3자가 제공하는 방식으로 ‘일빵’ 내 또는 링크 방식으로 참조용 정보나 콘텐츠를
      제공하더라도, 구매고객은 상품 구매 때 자신의 판단과 책임으로 결정하여 구매해야 하며, ‘회사’는
      어떠한 경우에도 구매고객의 구매결정에 대하여 책임을 지지 않습니다.
      <br />
      <br />
      **제21조 (결제대금 보호서비스의 이용)**
      <br />
      1. ‘회사’는 ‘일빵’의 서비스 신뢰성을 높이고 안전한 구매, 거래가 이루어질 수 있도록 결제대금
      보호 서비스를 제공합니다. 결제대금 보호 서비스란, 매매계약이 체결되고 구매고객이 대금을 결제
      하였을 경우, 구매고객이 판매회원으로부터 해당 상품을 받았다면 ‘회사’는 미리 정한 서비스
      이용료를 공제한 후 해당 판매회원에게 대금을 정산해 주고, 구매고객이 판매회원으로부터 상품을
      받지 못했거나 받았더라도 판매회원에게 반품한 후 환불을 요청했을 때에는 구매고객이 결제한
      금액을 ‘회사’가 구매고객에게 환불해주는 서비스를 의미합니다.
      <br />
      2. 결제대금 보호 서비스는 ‘일빵’내 에서 구매하는 모든 상품에 적용 됩니다.
      <br />
      3. 결제대금 보호 서비스는 회원 간에 이루어지는 상품매매의 안전성과 신뢰성을 높이고, 상품을
      인수하기 전에 대금을 결제해야 하는 구매고객을 보호하기 위해 제공합니다.
      <br />
      4. ‘회사’에서 제공하는 결제대금 보호 서비스는 판매회원이나 구매고객을 대리하는 것이 아니며,
      상품의 매매와 관련하여 판매회원이나 구매고객의 의무를 대행하는 것도 아닙니다.
      <br />
      5. ‘회사’의 결제대행 서비스를 통하여 상품구매대금을 결제하는 과정에서 발생하는 송금수수료 등은
      대금을 송금하는 구매고객이 부담해야 합니다.
      <br />
      6. 결제대금 보호 서비스를 제공하는 과정에서 ‘회사’가 결제대금을 보관하여 이자 등이 발생했을 때
      구매고객은 ‘회사’에 그 반환을 청구할 수 없습니다.
      <br />
      7. 결제대금 보호 서비스를 이용하는 구매고객은 제품 배송 또는 탁송 및 인계가 완료되었을 때
      ‘일빵’을 통해 구매확정, 교환 또는 반품에 대한 의사를 표시해야 합니다
      <br />
      8. 구매고객에게 구매확정의 의사가 있거나 구매확정의 의사가 있는 것으로 간주하는 상황이
      발생하면 결제대금 보호 서비스는 종료됩니다.
      <br />
      9. 배송 및 탁송, 인계가 완료 되었음에도 구매확정기간 내에 구매고객으로부터 교환 또는 반품의
      의사표시가 없을 경우, ‘회사’는 구매고객에게 구매확정의 의사가 있다고 간주합니다.
      <br />
      10. ‘회사’는 구매고객에게 구매확정의 의사가 있거나 구매확정의 의사가 있다고
      간주하면’전자상거래 등에서의 소비자보호에 관한 법률’의 관련 규정에 따라 관련 대금을 상품의
      판매회원에게 송금할 수 있습니다.
      <br />
      11. 결제대금 보호 서비스가 종료된 이후에 청약철회, 취소, 해제, 무효 등의 사유가 발생한다면
      구매고객은 판매회원과 직접 청약철회, 취소, 해제 및 결제대금 환불 등에 관한 절차를 진행해야
      합니다.
      <br />
      <br />
      **제22조 (배송, 탁송, 인계 및 거래완료)**
      <br />
      1. 배송, 탁송 및 인계(이하 ‘배송’) 소요기간은 입금이 확인된 날의 다음 날을 기산일로 하여
      배송이 완료되기까지의 기간을 의미합니다. 공휴일과 기타 휴무일 또는 천재지변 등 불가항력의
      사유가 발생하면 그 해당 기간은 배송 소요 기간에서 제외됩니다.
      <br />
      2. 배송과 관련하여 판매회원과 구매고객, 배송업체, 금융기관 등의 사이에 분쟁 등이 발생하면 관련
      당사자가 해결해야 합니다. ‘회사’는 이에 관여하지 않으며 어떠한 책임도 부담하지 않습니다.
      <br />
      3. 구매 및 거래절차 완료 후, 구매확정이 이루어진 이후에 상품의 하자 등 결제대금의 환불사유가
      발생하면 ‘회사’는 이에 개입하지 않습니다. 구매확정 이후에 발생하는 판매회원과 구매고객 간 모든
      분쟁은 거래 당사자인 판매회원과 구매고객이 해결해야 합니다.
      <br />
      4. 구매확정 기간 내에 구매고객에게서 구매확정, 교환 또는 반품에 대한 의사표시가 없으면
      ‘회사’는 해당 거래에 대하여 구매확정의 의사가 있는 것으로 간주하여 다음 영업일에
      자동구매확정으로 처리(이하 ‘자동구매확정’)할 수 있습니다. 다만, 구매확정 기간 내에 구매고객이
      실제 상품을 수령하지 못했을 경우에는 주문 제품 미 도착 신고를 ‘회사’ 또는 ‘일빵’을 통해 수행할
      수 있습니다.
      <br />
      5. 자동구매확정 처리가 완료되면, ‘회사’는 보관 중인 결제대금 중 판매회원에게 지급할
      상품판매대금을 판매회원이 지정한 방법으로 지급할 수 있습니다.
      <br />
      6. 자동구매확정이 이루어졌다면 구매고객은 상품 미 수령, 반품 등의 사유로 ‘회사’에 이의를
      제기할 수 없으며, 상품구매대금의 환불, 상품의 교환 등 모든 문제는 판매회원과 직접 해결해야
      합니다.
      <br />
      <br />
      **제23조 (반품/교환/환불/취소)**
      <br />
      1. ’전자상거래 등에서의 소비자보호에 관한 법률’ 제17조에 따라 구매고객은 상품을 배송 받은
      날로부터 7일 이내에 반품 또는 교환을 요청할 수 있으며, 반품에 관한 일반적인 사항은’전자상거래
      등에서의 소비자보호에 관한 법률’ 등 관련 법령이 판매회원이 제시한 조건보다 우선합니다. 단,
      다음 각 호의 경우에는 구매고객이 반품이나 교환을 요청할 수 없습니다.
      <br /> ① 구매고객의 귀책사유로 말미암아 상품이 훼손된 경우
      <br /> ② 구매고객의 사용 또는 일부 소비로 말미암아 상품의 가치가 현저히 감소한 경우
      <br /> ③ 시간이 지나 재판매가 어려울 정도로 상품의 가치가 현저히 감소한 경우
      <br /> ④ 복제가 가능한 상품의 포장을 훼손한 경우
      <br /> ⑤ 기타 구매고객이 환불이나 교환을 요청할 수 없는 합리적인 사유가 있는 경우
      <br />
      2. ‘회사’는 구매고객으로부터 교환 또는 반품의 의사표시를 접수하면, 즉시 그러한 사실을
      판매회원에게 통보합니다.
      <br />
      3. 반품이나 교환에 필요한 왕복 배송비와 기타 비용은 귀책사유를 유발한 측에서 부담하는 것을
      원칙으로 하며, 구매고객과 판매회원의 협의를 통해 부담하도록 합니다.
      <br />
      4. 택배 또는 운송사를 이용한 반품 시, 반품송장번호를 기재하지 않으면 반품처리와 환불이 지연될
      수 있습니다.
      <br />
      5. 교환신청을 하더라도 판매회원에게 교환할 물품의 재고가 없으면 교환할 수 없으며, 이 때는
      반품으로 처리됩니다. 단 이 같은 경우에는 구매한 제품이 심각한 하자가 발생되었거나, 구매한
      제품과 다른 종류 또는 품질의 제품이 배송된 경우에 해당합니다.
      <br />
      6. 주문제작 제품의 경우와 같이 교환, 반품, 환불이 불가능한 제품에 대해서는 ‘판매회원’은 이를
      반드시 모든 이용자가 인지할 수 있도록 표시해야 하며, 만약 본 내용이 명시되지 않은 경우라 해도
      ‘구매고객’은 이에 대한 상세 정보를 확인할 필요가 있습니다.
      <br />
      7. 교환에 소비되는 비용은 물품하자의 경우 판매회원이 왕복 배송비를 부담하나 구매고객의 변심에
      의한 경우에는 구매고객 부담을 원칙으로 합니다.
      <br />
      8. 배송으로 인해 구매고객에 피해가 발생된 경우 그에 대한 책임은 해당 배송업체를 지정한 판매
      회원에게 있습니다.
      <br />
      9. ‘회사’가 판매회원에게 견적 및 구매고객의 구매대금 결제 확인에 대해 통지한 후 판매회원이
      상당 기간 구매고객의 주문 정보 또는 견적정보를 확인하지 않거나 배송 절차를 이행하지 않아
      구매고객이 거래 취소를 요구하면 ‘회사’는 해당 거래를 취소할 수 있으며, 거래가 취소될 경우에는
      보관 중인 제품 구매대금은 구매고객에게 환불됩니다. 아울러, ‘회사’는 개별적인 정책으로
      구매고객의 요구 없이도 자동 환불처리 등의 조치를 할 수 있으며, 그럴 경우 미리 해당 내용을
      구매고객에게 공지합니다.
      <br />
      10. ‘회사’는 판매회원이 구매고객의 교환, 반품에 대한 사실확인 진행절차에 적극적으로 임하지
      않거나 지연할 경우, 구매고객에게서 교환이나 반품의 원인을 파악한 후 해당 거래를 취소하여
      구매고객에게 보관 중인 결제대금을 환불할 수 있습니다.
      <br />
      11. ‘회사’는 구매고객의 교환 또는 반품의 요청이 정당하지 않다고 판단하면 구매고객의 의사표시를
      취소하여 보관 중인 결제대금을 판매회원에게 지급할 수 있습니다. 또한, 구매고객의 교환, 반품
      등이 접수된 날로부터 14일이 지날 때까지 이미 수령한 상품을 판매회원에게 반환하지 않거나 전화,
      이메일(E-mail) 등의 수단으로 연락되지 않으면 교환, 반품의 의사를 철회한 것으로 간주하여 보관
      중인 결제대금을 판매회원에게 지급할 수 있습니다.
      <br />
      12. 확인된 거래가 취소되어 결제대금을 환불할 경우는 ‘회사’는 거래가 취소된 날로부터 2영업일
      이내에 구매고객에게 환불에 필요한 조치를 합니다. 신용카드로 결제했을 때는 환불을 요청한 즉시
      결제 승인이 취소되고, ‘일빵’내 선불전자지급수단으로 결제한 거래가 취소되었을 때에는 즉시
      환불조치를 합니다.
      <br />
      13. 카드결제로 구매건의 환불은 카드결제 취소 절차 후 가능하며, 어떠한 경우에도 현금잔고로
      환불되지 않습니다.
      <br />
      14. 구매고객은 상품 발송 전까지 구매를 취소할 수 있으며, 배송 중이면 취소가 아닌 반품절차에
      의해 처리됩니다. 단 판매회원이 명시한 구매 및 결제 완료 후 취소 불가 제품 또는 ‘회사’에서
      명시한 거래 진행 후 취소 및 환불 불가 제품의 경우에는 구매 또는 거래 취소 의사를 밝혀도
      ‘회사’와 ‘판매회원’은 이를 수용할 의무가 없습니다. 그러나 구매 제품이 상품으로서의 가치가
      현격히 하락하였거나 본래의 기능을 수행하지 못할 경우 판매회원은 구매고객의 구매 및 거래 취소
      요청에 성실히 응해야 합니다.
      <br />
      15. 결제완료 상태에서 주문 및 거래 취소를 요청할 경우, 즉시 취소 처리가 완료됩니다.
      <br />
      16. 배송준비 중 상태에서는 즉시 취소처리가 완료되는 것이 원칙이나 이미 주문 제품이
      발송되었다면 발송된 제품의 왕복 배송비는 구매고객이 부담해야 합니다.
      <br />
      <br />
      **제24조 (금지행위)**
      <br />
      1. ‘회사’가 제공하는 결제대금 보호 서비스를 이용하지 않고 판매회원과 구매고객이 직접 거래하는
      행위(직거래)는 거래의 안전을 위하여 금지되고, 직거래를 통하여 발생한 제반 문제에 대한 책임은
      거래 당사자에게 있으며 ‘회사’는 이에 대해 어떠한 책임도 지지 않습니다.
      <br />
      2. 직거래를 하거나 이를 유도한 것으로 확인된 판매회원은 서비스 이용이 정지되거나 이용계약이
      해지될 수 있으며, 회원은 직거래를 하거나 유도한 판매자를 ‘회사’가 운영하는 안전거래센터에
      신고할 수 있습니다.
      <br />
      3. ‘회사’는 구매 및 거래, 공동구매 등에 대한 부정행위가 확인될 경우 구매 및 거래에 대한 권한이
      있으며 당 이용 계약의 해지 등의 조치를 취할 수 있습니다.
      <br />
      4. ‘회사’가 제공하는 이용방법을 준수하지 않고 비정상적인 방법으로 서비스를 이용하거나 시스템에
      접근하는 행위는 금지됩니다.
      <br />
      5. ‘일빵’내 부정행위가 확인되면 ‘회사’는 해당 회원에게 부가적으로 제공한 혜택의 일부 또는
      전부를 회수하거나, 특정 서비스의 이용을 제한하고, 이용계약을 해지하는 등의 조치를 취할 수
      있습니다.
      <br />
      6. 타인의 명의, 카드정보, 계좌정보 등을 무단으로 사용하여 ‘회사’가 제공하는 구매서비스를
      이용하는 행위는 금지됩니다.
      <br />
      7. 물품판매 또는 용역제공을 가장하여 자금을 융통하는 것과 같이 여신전문금융업 법 등 법령에
      따라 금지된 방법으로 비정상적인 결제를 하는 것은 금지되며, 해당 내용이 확인되었을 때 ‘회사’는
      회원의 거래를 취소하거나 중지시키고 관련기관에 통보할 수 있습니다.
      <br />
      8. 실제 상품의 배송이 없는 등 상품의 실질적인 구매의사가 없는 구매행위와 ‘회사’가 제공하는
      할인율 등을 이용하여 비정상적인 거래를 하는 것은 금지되며, 해당 내용이 확인되면 ‘회사’는 해당
      거래를 취소하고 서비스이용을 제한하거나 이용계약을 해지할 수 있습니다.
      <br />
      9. 판매회원 본인의 상품 구매 또는 실제 상품의 이동 없이 판매점수 및 광고효과를 높이기 위한
      구매 또는 부당한 할인 획득을 위해 제3자의 아이디를 이용한 구매 행위는 금지되며, 해당 내용이
      확인된 경우 이미 성사된 거래도 취소할 수 있으며 서비스 이용제한 등의 조치를 취할 수 있습니다.
      <br />
      <br />
      **제25조 (안전거래센터)**
      <br />
      1. ‘회사’는 타인의 권리 침해, 법령의 위반 등 회원 및 이용자가 본 서비스를 이용할 때 발생할 수
      있는 거래의 안전과 관련된 법적 문제를 조정하기 위하여 안전거래센터를 운영합니다.
      <br />
      2. 안전거래센터는 다음과 같은 역할을 수행 합니다.
      <br /> ① 매매부적합물의 판매에 대한 조치
      <br /> ② 타인의 권리를 침해하는 행위에 대한 조치
      <br /> ③ 약관 위반 또는 법령을 위반하는 행위에 대한 조치
      <br /> ④ 기타 거래의 안전 및 사이트의 안전 확보 등을 위한 조치
      <br /> ⑤ 회원 또는 거래의 안전 확인 및 안전이 확인될 때 까지 거래 보류하거나 거래 취소할 수
      있으며, 서비스 이용을 제한하거나 이용계약의 해지 등의 조치
      <br />
      3. 안전거래센터의 조정에 회원은 신의성실의 원칙에 따라 성실히 응해야 합니다.
      <br />
      <br />
      **제26조 (분쟁조정 센터)**
      <br />
      1. ‘회사’는 회원 상호 간 또는 회원과 제3자 간의 분쟁을 조정하기 위하여 분쟁조정센터를 운영
      합니다.
      <br />
      2. 분쟁조정센터를 통하여 분쟁을 조정할 때 ‘회사’는 제3자의 입장에서 공정하게 분쟁의 조정에
      임해야 합니다.
      <br />
      3. 분쟁조정센터의 조정에 회원은 신의성실의 원칙에 따라 성실히 응해야 합니다.
      <br />
      <br />
      **제27조 (회사의 면책)**
      <br />
      1. 상품의 매매와 관련하여 발생하는 상품의 배송, 청약철회, 반품 등의 거래진행은 거래 당사자인
      판매회원과 구매고객 간에 수행되어야 하며, ‘회사’는 거래진행에 관여하지 않으며 어떠한 책임도
      부담하지 않습니다.
      <br />
      2. ‘회사’는 제13조 제1항에 따른 서비스나 기능의 전부 또는 일부를 수정하거나 중단하는 것에 대해
      책임을 지지 않습니다.
      <br />
      3. 제13조 제2항의 사유로 서비스를 일시적으로 중단하여 이용자 또는 제3자가 손해를 입었을 때
      ‘회사’는 이에 대해 책임을 지지 않습니다.
      <br />
      4. 제13조 제3항의 사유로 서비스를 제한하거나 중단하게 된다면 ‘회사’는 불가항력을 이유로 그
      책임을 지지 않습니다.
      <br />
      5. ‘회사’는 회원 및 이용자의 귀책사유로 ‘일빵’ 이용에 장애가 발생할 경우에 대한 책임을 지지
      않습니다.
      <br />
      6. 회원 및 이용자가 자신의 개인정보를 타인에게 유출하거나 제공하여 발생하는 피해에 대해
      ‘회사’는 일절 책임을 지지 않습니다.
      <br />
      7. ‘회사’의 서비스 화면에서 링크, 배너 등을 통하여 연결된 ‘회사’(이하 피 연결회사)와
      이용자간에 이루어진 거래에 ‘회사’는 개입하지 않으며, 해당 거래에 대하여 책임을 지지 않습니다.
      <br />
      8. ‘회사’는 서비스 화면에 표시되는 판매회원 또는 제3자가 제공하는 상품 또는 정보 등의 정확성,
      적시성, 타당성 등을 보증하지 않으며, 그와 관련하여 어떠한 책임도 지지 않습니다.
      <br />
      <br />
      <br />
      **[제3장 기타사항]**
      <br />
      <br />
      **제28조 (준거법 및 관할법원)**
      <br />
      1. 본 약관과 ‘회사’와 회원 간의 서비스 이용 계약, 회원 상호 간의 물품거래에는 대한민국 법령이
      적용됩니다.
      <br />
      2. 본 서비스와 관련하여 ‘회사’와 이용자 또는 회원 사이에 분쟁이 발생하여 소송이 제기되었을
      때에는 민사소송법에 따라 관할법원을 정합니다.
      <br />
      <br />
      **제29조 (기타 조항)**
      <br />
      1. ‘회사’는 필요 시, 특정 서비스나 기능의 전부 또는 일부를 ‘일빵’을 통해 미리 공지한 후 일시적
      또는 영구적으로 수정하거나 중단할 수 있습니다.
      <br />
      2. 각 당사자는 상대방의 서면 동의 없이 본 약관상의 권리와 의무를 제3자에게 양도하거나 처분할
      수 없습니다.
      <br />
      3. 본 약관과 관련하여 당사자 간의 합의에 따라 추가로 작성된 계약서, 협정서, 통보서 등과
      ‘회사’의 정책변경, 법령의 제/개정 또는 공공기관의 고시나 지침 등에 따라 회사가 본
      ‘일빵’서비스를 통해 공지하는 내용도 이용계약의 일부를 구성합니다.
      <br />
      4. ‘회사’는 구매고객이 서비스를 이용할 때 발생할 수 있는 정당한 의견이나 불만 사항을
      적극적으로 수렴하여 해결하고, 회원 상호 간의 분쟁을 조정하기 위하여 고객센터를 설치하고
      운영합니다.
      <br />
      5. ‘회사’는 회원이 제기하는 각종 불만사항과 의견을 적극적으로 검토한 후 정당하다고 판단될
      때에는 이를 신속하게 처리하며, 즉시 처리하기가 어려운 사항이 있으면 그 사유와 처리기간을 이
      메일(E-mail) 혹은 전화로 회원에게 통보 합니다.
      <br />
    </P>
  );
};

export default POLICY_ELECTRONIC;
