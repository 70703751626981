import { MEMBER_ROLE } from '../constant/TYPE_CONSTANT';

// 맴버등급체크
export const checkMemberRole = (member_role_number) => {
  switch (member_role_number) {
    case MEMBER_ROLE.NORMAL:
      return '일반회원(준 조합원)';
    case MEMBER_ROLE.REGULAR:
      return '조합 회원 (정 조합원)';
    case MEMBER_ROLE.MIN_ADMIN:
      return '중간 관리자';
    case MEMBER_ROLE.ADMIN:
      return '최고 관리자';
    default:
      break;
  }
};
