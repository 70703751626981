import React, { useEffect, useState } from 'react';

import '../../../../assets/css/Sub.css';

import { useNavigate } from 'react-router-dom';

import { endPointMutation } from '../../../../api/endPoint';
import MyPageButtonComponent from '../../../../components/MyPageButtonComponent.jsx';
import { usePopup } from '../../../../components/popup/usePopup.js';
import { useMutationInstance } from '../../../../react-query/useMutationInstance';
import { IsLoggedIn } from '../../../../utils/IsLoggedIn.js';
import ValidateInput from '../../../../utils/ValidateInput';

function MemberInfoEdit1() {
  const { openPopup } = usePopup();
  const navigate = useNavigate();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const [password, setPassword] = useState('');

  const { mutate: infoCheckApi } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.memberInfoCheck,
    onErrorFn: (error) => {
      const errorData = error.response.data;
      openPopup({
        title: '안내',
        content: errorData.message,
      });
    },
    onSuccessFn: (response) => {
      if (response) {
        // TODO localStorage 접근이 쉬워서 나중에 바꿔야할꺼같음
        localStorage.setItem('member_edit', response);
        navigate('/pages/member/mypage/edit2');
      } else {
        openPopup({ title: '안내', content: '비밀번호가 일치하지 않습니다.' });
      }
    },
  });

  const onCheckMember = async (e) => {
    e.preventDefault();

    if (!ValidateInput(password)) {
      return openPopup({ title: '안내', content: '비밀번호를 입력해주세요.' });
    }

    infoCheckApi({ apiQueryParams: { password } });
  };

  return (
    <div className="content">
      <div className="container section">
        <div className="my_page">
          <MyPageButtonComponent
            dataList={[
              { title: '회원정보수정', current: true, url: '/pages/member/mypage/edit1' },
              { title: '주문내역', current: false, url: '/pages/member/mypage/order_list' },
              { title: '판매내역', current: false, url: '/pages/member/mypage/sales_list' },
              { title: '내상품', current: false, url: 'pages/member/mypage/product_list' },
              { title: '내가 쓴 글', current: false, url: '/pages/member/mypage/my_board_list' },
              { title: '견적문의', current: false, url: '/pages/member/mypage/my_estimate_index' },
            ]}
          />

          <div className="con-list">
            <div className="con-item">
              <div className="item-head">
                <p className="title">회원정보 수정</p>
              </div>
            </div>
          </div>

          <form>
            <div className="input-item" style={{ marginTop: '100px' }}>
              <div className="input-head" style={{ marginBottom: '12px' }}>
                <span className="title">비밀번호 입력</span>
              </div>
              <div className="input-body">
                <input
                  type="password"
                  placeholder="비밀번호"
                  className="input-box"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="page-btn" style={{ width: '15%', marginLeft: 'auto' }}>
              <button type="submit" className="page-btn-item next" onClick={onCheckMember}>
                확인
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MemberInfoEdit1;
