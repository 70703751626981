import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import Draggable from 'react-draggable';
import { ImCancelCircle } from 'react-icons/im';
import styled from 'styled-components';

import { colorList } from '../../assets/style/theme';
import InputBox from '../InputBox';
import SectionLayout from '../layout/SectionLayout';
import TextEditor_Quill_ReadOnly from '../TextEditor_Quill_ReadOnly';
import { useDragPopupAd } from './useDragPopupAd';

const PopContainer = styled.div`
  position: fixed;
  display: block;
  top: 10vh;
  right: 20vw;
  width: fit-content;
  height: 700px;
  background-color: #fefefe;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 0;
  z-index: 999;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
`;

const HeadLine = styled.div`
  position: relative;
  left: -1px;
  width: 100%;
  height: 40px;
  background-color: ${colorList.main_dark};
  border-radius: 20px 20px 0px 0px;
  padding: 0 1px;
  cursor: pointer;
  box-shadow: 0px 0.5px 3px 0.5px rgba(0, 0, 0, 0.5);
`;

const CancelBtnContainer = styled.div`
  text-align: right;
  padding: 10px 20px;
`;

const Icon = styled.span`
  cursor: pointer;
  &:active {
    opacity: 0.3;
  }
`;

const Title = styled.p`
  text-align: center;
  font-size: 24px;
  letter-spacing: 1px;
  padding-bottom: 20px;
`;

const QuillReadContainer = styled.div`
  position: relative;
  width: 500px;
  height: 540px;
  overflow: scroll;
  /* border: 0.5px solid #e0e0e0; */
  border-radius: 10px;
`;

// 쿠키의 유효기한을 지정하는 함수
const getExpiredDate = (days) => {
  const date = new Date(); // 현재 시간
  // 날짜 추가
  date.setDate(date.getDate() + days);

  return date;
};

const DragPopupAd = () => {
  const { dragPopup, closeDragPopup } = useDragPopupAd();
  const { show } = dragPopup;
  const [popupLimit, setPopupLimit] = useState(false);
  // 쿠키 만들기 훅 set함수
  const [_, setCookies] = useCookies(['ad1']);

  const popUpHandler = () => {
    if (!popupLimit) return closeDragPopup();

    const expires = getExpiredDate(1);
    setCookies('ad1', true, { path: '/', expires });

    closeDragPopup();
  };

  if (!show) return;

  return (
    <Draggable bounds="body" handle=".dragPoint">
      <PopContainer>
        <HeadLine className="dragPoint">
          <CancelBtnContainer>
            <Icon onClick={popUpHandler}>
              <ImCancelCircle style={{ fontSize: '24px', color: colorList.white }} />
            </Icon>
          </CancelBtnContainer>
        </HeadLine>
        <SectionLayout padding="20px" height="100%">
          <Title>팝업광고예시</Title>
          <QuillReadContainer>
            <TextEditor_Quill_ReadOnly
              width="auto"
              value={'<p>공지</p><br/><br/><br/><br/><p>예시폼</p>'}
            />
          </QuillReadContainer>
          <InputBox
            containerMargin="15px 0 0 0"
            inputType="checkbox"
            inputWidth="15px"
            htmlFor="popupCancel"
            containerHeight="20px"
            titleFontSize="12px"
            justifyContent="right"
            containerPadding="0 10px"
            onChangeEvent={(e) => setPopupLimit(e.target.checked)}
          >
            오늘 하루 보지 않기
          </InputBox>
        </SectionLayout>
      </PopContainer>
    </Draggable>
  );
};

export default React.memo(DragPopupAd);
