import React from 'react';
import styled from 'styled-components';

const SectionLayoutStyle = styled.section`
  position: relative;
  display: ${({ display }) => display};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items:${alignItems}`};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  box-sizing: border-box;
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: ${({ minHeight }) => minHeight};
  gap: ${({ gap }) => gap};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom: ${({ borderBottom }) => borderBottom};
`;

const SectionLayout = ({
  children,
  display = 'block',
  margin = '0 auto',
  padding = '70px 50px',
  justifyContent,
  alignItems,
  flexDirection,
  width = 'auto',
  maxWidth = '1200px',
  minWidth = 'none',
  height = 'auto',
  maxHeight = 'none',
  minHeight = 'none',
  gap,
  backgroundColor,
  borderBottom,
}) => {
  return (
    <SectionLayoutStyle
      borderBottom={borderBottom}
      display={display}
      margin={margin}
      padding={padding}
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      height={height}
      maxHeight={maxHeight}
      minHeight={minHeight}
      gap={gap}
      backgroundColor={backgroundColor}
    >
      {children}
    </SectionLayoutStyle>
  );
};
export default React.memo(SectionLayout);
