import React from 'react';
import { TiArrowLeftThick } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { colorList } from '../assets/style/theme';
import { changeEnteredNumComma } from '../utils/numberComma';
import BtnNormal from './BtnNormal';
import InputBox from './InputBox';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  right: ${({ containerRight }) => containerRight};
  left: ${({ containerLeft }) => containerLeft};
  top: ${({ containerTop }) => containerTop};
  bottom: ${({ containerBottom }) => containerBottom};
`;

const Wrap = styled.div`
  width: 300px;
  position: sticky;
  top: ${({ boxTop }) => boxTop};
  box-sizing: border-box;
  padding: 30px;
  border: 1px solid #c0c0c0;
  background-color: white;
  z-index: 10;
  border-radius: 20px;
  margin: auto;
`;

const TotalContainer = styled.div`
  box-sizing: border-box;
  padding: 42px 0;
`;

const TotalTitle = styled.p`
  font-size: 24px;
  font-family: 'Pretendard-bold';
  margin-bottom: 24px;
`;

const TotalPrice = styled.p`
  font-size: 18px;
`;

const TotalPriceFont = styled.span`
  font-size: 34px;
  color: #c32922;
  font-family: 'Pretendard-bold';
`;

const InfoQuantity = styled.p`
  margin-top: 20px;
`;

const BtnBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 20px;
`;

const BuyBtn = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #ddd;
  font-size: 20px;
  font-weight: 700;
  border: none;
  background-color: #2e4995;
  color: #fff;
`;

const RadioBoxContainer = styled.div`
  display: flex;
  width: ${({ radioBoxContainerWidth }) => radioBoxContainerWidth};
  justify-content: left;
`;

const FloatingMenuCart = ({
  onClickBuy,
  containerRight = 'none',
  containerLeft = 'none',
  containerTop = 'none',
  containerBottom = 'none',
  boxTop = 'none',
  totalPrice = 0,
  checkedLength = 0,
  totalQuantity = 0,
  isPayTypeRadioBox = false,
  payTypeRadioOnChange = null,
  payTypeRadioValue = '',
  radioBoxContainerWidth = '100%',
}) => {
  const navigate = useNavigate();

  return (
    <Container
      containerRight={containerRight}
      containerLeft={containerLeft}
      containerTop={containerTop}
      containerBottom={containerBottom}
    >
      <Wrap boxTop={boxTop}>
        <BtnNormal
          bgColor={colorList.main}
          onClickEvent={() => navigate(-1)}
          padding="10px"
          fontWeight="200"
        >
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}
          >
            <TiArrowLeftThick style={{ fontSize: '20px' }} color={colorList.white} />
            <span style={{ fontSize: '15px' }}>뒤로가기</span>
          </div>
        </BtnNormal>
        <TotalContainer>
          <TotalTitle>
            총 합계 <span style={{ fontSize: '20px' }}>(VAT 별도)</span>
          </TotalTitle>
          <TotalPrice>
            <TotalPriceFont>{changeEnteredNumComma(totalPrice)}</TotalPriceFont> 원
          </TotalPrice>
          <InfoQuantity>
            {checkedLength}개 제품 / 총 {totalQuantity}개 수량
          </InfoQuantity>
        </TotalContainer>

        {isPayTypeRadioBox && (
          <RadioBoxContainer radioBoxContainerWidth={radioBoxContainerWidth}>
            <InputBox
              inputType="radio"
              htmlFor="payTypeTransferFloat"
              inputWidth="20px"
              inputValue="transfer"
              inputChecked={payTypeRadioValue === 'transfer'}
              onChangeEvent={(e) =>
                payTypeRadioOnChange((cur) => (e.target.checked ? 'transfer' : ''))
              }
            >
              계좌이체
            </InputBox>
            <InputBox
              inputType="radio"
              htmlFor="payTypeCardFloat"
              inputWidth="20px"
              inputValue="card"
              inputChecked={payTypeRadioValue === 'card'}
              onChangeEvent={(e) => payTypeRadioOnChange((cur) => (e.target.checked ? 'card' : ''))}
            >
              카드결제
            </InputBox>
          </RadioBoxContainer>
        )}

        <BtnBox>
          <BuyBtn type="button" onClick={onClickBuy}>
            바로구매
          </BuyBtn>
        </BtnBox>
      </Wrap>
    </Container>
  );
};

export default React.memo(FloatingMenuCart);
