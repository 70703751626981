// seoConstants.js
const { REACT_APP_DOMAIN, REACT_APP_NAVER_SITE_VERIFICATION, REACT_APP_GOOGLE_SITE_VERIFICATION } =
  process.env;

const IMAGE_ALT = '일빵대표이미지';

const OG_TWITTER_IMAGE_URL = `${REACT_APP_DOMAIN}/og_ilppang.jpg`;

const FAVICON_IMAGE_URL = `${REACT_APP_DOMAIN}/ilppang-favicon.ico`;

export const DEFAULT_CONSTANT_DATA = {
  ogImage: OG_TWITTER_IMAGE_URL,
  ogImageAlt: IMAGE_ALT,
  twitterCardImage: OG_TWITTER_IMAGE_URL,
  twitterCardImageAlt: IMAGE_ALT,
  url: REACT_APP_DOMAIN,
  googleSiteVerification: REACT_APP_GOOGLE_SITE_VERIFICATION,
  naverSiteVerification: REACT_APP_NAVER_SITE_VERIFICATION,
  faviconImage: FAVICON_IMAGE_URL,
};

// IDX등 파람스 들어오는 것은 각 컴포넌트에서 따로 해줘야함
export const SEO_STATIC_DATA = {
  '/': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵',
    contentTitle: 'MRO 제품의 모든 것',
    description: 'MRO 도매유통 플랫폼 서비스',
    keywords: '일빵, MRO, 소모성자재, 도매',
  },
  '/pages/document/guide': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 가이드',
    contentTitle: '일빵 이용가이드',
    description: '일빵 이용가이드',
    keywords: '일빵, 이용가이드, MRO',
  },
  '/pages/document/story': {
    ...DEFAULT_CONSTANT_DATA,
    title: `일빵 | '일빵'이란?`,
    contentTitle: '일빵 플랫폼 상세설명',
    description: '일빵의 의미와 일빵 플랫폼이 무엇인지 상세히 알려드립니다.',
    keywords: '일빵, 일빵이란?, 공동구매, 조합주관, 회원주관',
  },
  '/pages/board/notice': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 공지사항',
    contentTitle: '공지사항 게시판',
    description: '모두가 알아야 할 정보를 공지하는 게시판(관리자만 작성 가능)',
    keywords: '일빵, MRO, 공지사항',
  },
  '/pages/board/community': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 커뮤니티',
    contentTitle: '커뮤니티 게시판',
    description: '회원이면 누구나 작성 가능한 자유로운 소통 공간',
    keywords: '일빵, MRO, 커뮤니티, 회원소통, 일반게시판',
  },
  '/pages/board/inquiry': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 문의하기',
    contentTitle: '문의하기 게시판',
    description: '제품에 대한 문의하는 게시판',
    keywords: '일빵, MRO, 커뮤니티, 회원소통, 문의하기',
  },
  '/pages/board/community/write': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 글쓰기',
    contentTitle: '글 등록',
    description: '게시판에 글을 등록할 수 있는 페이지',
    keywords: '일빵, MRO, 글쓰기, 공지사항작성, 게시판글작성',
  },
  '/pages/board/faq': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 자주 묻는 질문',
    contentTitle: 'FAQ',
    description: ' 자주 묻는 질문들에 대해 답변을 정리한 게시판',
    keywords: '일빵, MRO, 자주묻는질문, FAQ, 게시판',
  },
  '/pages/member/join1': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 회원가입 약관동의',
    contentTitle: '회원가입 약관동의',
    description: '회원가입 약관동의을 위한 페이지',
    keywords: '일빵, 회원가입, 약관동의, 소모성자재, MRO',
  },
  '/pages/member/join2': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 회원가입 상세입력',
    contentTitle: '회원가입 상세입력',
    description: '회원가입을 희망하시는 분이 회원가입을 위해 회원정보를 입력하는 페이지',
    keywords: '일빵, 회원가입, 상세정보입력, 소모성자재, MRO',
  },
  '/pages/member/login': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 로그인',
    contentTitle: '로그인',
    description: '로그인 진행을 위한 페이지',
    keywords: '일빵, 로그인, MRO, 소모성자재',
  },
  '/pages/product/list': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 도매판매',
    contentTitle: '도매판매 상품목록',
    description: '도매판매 상품목록',
    keywords: '일빵, 도매판매, 상품목록, 소모성자재품목, MRO',
  },
  '/pages/product/write': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 상품등록',
    contentTitle: '상품등록',
    description: '팔고자 하는 상품을 상세히 등록하는 페이지',
    keywords: '일빵, MRO, 도매판매, 상품등록',
  },
  '/pages/member/mypage/edit1': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 회원정보수정',
    contentTitle: '회원정보수정 1차 비밀번호 체크',
    description: '회원정보를 수정하는 페이지 이동을 위한 1차 비밀번호 체크',
    keywords: '일빵, MRO, 회원정보수정',
  },
  '/pages/member/mypage/edit2': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 회원정보수정',
    contentTitle: '회원정보수정',
    description: '회원정보를 수정하는 페이지',
    keywords: '일빵, MRO, 회원정보수정',
  },
  '/pages/member/mypage/order_list': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 나의 주문내역',
    contentTitle: '나의 주문내역',
    description: '내가 주문한 상품을 리스트업',
    keywords: '일빵, MRO, 주문내역',
  },
  '/pages/member/mypage/sales_list': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 나의 판매내역',
    contentTitle: '나의 판매내역',
    description: '내가 판매한 상품을 리스트업',
    keywords: '일빵, MRO, 판매내역, 매출',
  },
  '/pages/member/mypage/product_list': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 내 상품',
    contentTitle: '내 상품 리스트',
    description: '내가 등록한 상품을 리스트업',
    keywords: '일빵, MRO, 내상품목록, 내상품, 등록상품리스트',
  },
  '/pages/member/mypage/cart': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 장바구니',
    contentTitle: '장바구니',
    description: '장바구니에 담긴 상품들을 보고 일괄 주문을 할 수 있는 페이지',
    keywords: '일빵, MRO, 장바구니, 내가찜한상품, 일괄결제구매',
  },
  '/address/search_address': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 주소검색',
    contentTitle: '다음주소검색',
    description: '배송지 주소를 조회하기 위한 다음 주소 검색창',
    keywords: '일빵, MRO, 주소검색, 다음주소, 상품구매위한주소',
  },
  '/pages/product/detail/:idx': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 상품상세',
    contentTitle: '상품상세',
    description: '상품에 대한 상세한 정보',
    keywords: '일빵, MRO, 상품상세, 소모성자재, 도매판매',
  },

  // TODO 관리자 페이지 부분 - seo탐색에서 제외할지 논의
  '/admin/': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 관리자모드',
    contentTitle: '관리자모드 메인',
    description: '관리자 페이지',
    keywords: '',
  },
  '/admin/payment/payment_list': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 관리자모드: 결제내역',
    contentTitle: '관리자모드 모든 결제내역 리스트',
    description: '관리자 페이지에서 모든 결제내역 보기',
    keywords: '',
  },
  '/admin/member/list': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 관리자모드: 회원관리',
    contentTitle: '관리자모드 모든 회원리스트 및 관리',
    description:
      '관리자 페이지에서 모든 회원정보를 리스트업하고 관리자 권한으로 회원 정보 수정이 가능한 페이지',
    keywords: '',
  },
  '/admin/category/category_list': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 관리자모드: 카테고리관리',
    contentTitle: '관리자모드 카테고리관리',
    description: '카테고리 수정, 삭제, 추가를 하는 페이지',
    keywords: '',
  },
  '/admin/board/notice/write': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 관리자모드: 공지사항 글쓰기',
    contentTitle: '관리자모드 공지사항 글쓰기',
    description: '관리자모드에서만 공지사항 글을 작성하는 페이지',
    keywords: '',
  },
  '/admin/popup': {
    ...DEFAULT_CONSTANT_DATA,
    title: '일빵 | 관리자모드: 팝업광고 등록',
    contentTitle: '팝업광고 등록',
    description: '관리자모드에서만 팝업광고를 등록하는 곳',
    keywords: '',
  },
};
