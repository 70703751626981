import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/Login.module.scss';
import { colorList } from '../../../assets/style/theme';
import BtnNormal from '../../../components/BtnNormal.jsx';
import InputBox from '../../../components/InputBox.jsx';
import { usePopup } from '../../../components/popup/usePopup.js';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { decodingCrypto, generateCrypto } from '../../../utils/crypto';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';

export default function Login() {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      openPopup({ title: '안내', content: '이미 로그인 중 입니다.' });
      navigate('/');
      return;
    } else {
      localStorage.clear();
    }
  }, [isLoggedIn]);

  // 사용자 정보 입력
  const memberIdRef = useRef();
  const passwordRef = useRef();

  // 아이디 저장 체크 박스
  const [saveId, setSaveId] = useState(false);

  // 아이디 저장여부 확인
  // 쿠키 존재여부
  const [cookies, setCookie, removeCookie] = useCookies(['saveId']);

  const { mutate: loginAPI } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.auth.login,
    onErrorFn: () => {
      openPopup({ title: '안내', content: '아이디 또는 비밀번호를 확인해주세요.' });
    },
    onSuccessFn: (response) => {
      saveId
        ? setCookie('saveId', generateCrypto(response.data.member_id), {
            path: '/',
            maxAge: 365 * 24 * 60 * 60,
          })
        : null;

      if (response?.code === 200) {
        const accessToken = generateCrypto(response.data.access_token);
        localStorage.setItem('idx', generateCrypto(response.data.idx));
        localStorage.setItem('token', accessToken);
        localStorage.setItem('id', generateCrypto(response.data.member_id));
        localStorage.setItem('member_name', generateCrypto(response.data.name));
        localStorage.setItem('company_name', generateCrypto(response.data.company_name));
        localStorage.setItem('member_phone_number', generateCrypto(response.data.phone_number));
        localStorage.setItem('member_role', generateCrypto(response.data.user_role));
        const accessTokenExpireIn = generateCrypto(response.data.access_token_expires_in);
        localStorage.setItem('token_expires', accessTokenExpireIn);
        localStorage.setItem('isLoggedIn', 'true');
        const event = new Event('login');
        window.dispatchEvent(event);
        navigate('/');
      }
    },
  });

  const onLogin = (e) => {
    e.preventDefault();

    const data = {
      member_id: memberIdRef.current.value,
      //TODO 백엔드 decrypto 진행 후 주석 풀기
      // password: generateCrypto(password),
      password: passwordRef.current.value,
    };

    loginAPI({ apiBody: data });
  };

  // 아이디 저장 셋
  useEffect(() => {
    if (!cookies.saveId) return setSaveId(false);

    setSaveId(true);
    memberIdRef.current.value = decodingCrypto(cookies.saveId);
  }, []);

  const saveIdHandler = (e) => {
    if (!e.target.checked) {
      setSaveId(e.target.checked);
      removeCookie('saveId', { path: '/' });
      return;
    }

    setSaveId(e.target.checked);
  };

  return (
    <div className={style.login_container}>
      <div className={style.login_box}>
        <h1 className={style.login_h1}>로그인</h1>
        <form onSubmit={onLogin}>
          <InputBox
            htmlFor="login_input"
            placeholder="아이디를 입력해주세요"
            inputRef={memberIdRef}
          />
          <InputBox
            htmlFor="pw"
            inputType="password"
            placeholder="비밀번호를 입력해주세요"
            inputRef={passwordRef}
          />
          <div className={style.extra_links}>
            <InputBox
              conFlex="1"
              inputChecked={saveId}
              inputType="checkbox"
              checkedPosition="right"
              inputWidth="14px"
              htmlFor="saveId"
              containerMargin="0"
              titleFontSize="14px"
              titleColor="rgb(60, 60, 60)"
              containerPadding="0"
              onChangeEvent={saveIdHandler}
            >
              아이디 저장
            </InputBox>
            <div className={style.find_box}>
              <span onClick={() => navigate('/pages/member/find_id')}>아이디 찾기</span>
              <span onClick={() => navigate('/pages/member/find_pw')}>비밀번호 찾기</span>
            </div>
          </div>
          <div className={style.btn_box}>
            <BtnNormal
              buttonType="submit"
              bgColor={colorList.main_dark}
              fontSize="16px"
              padding="15px 0px"
              onClickEvent={onLogin}
            >
              로그인
            </BtnNormal>
            <BtnNormal
              fontSize="16px"
              color={colorList.main_dark}
              padding="14px 0px"
              bgColor="none"
              border={`1px solid ${colorList.main_dark}`}
              onClickEvent={() => navigate('/pages/member/join1')}
            >
              회원가입
            </BtnNormal>
          </div>
        </form>
      </div>
    </div>
  );
}
