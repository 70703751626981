import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { endPointMutation } from '../../../api/endPoint';
import BtnNormal from '../../../components/BtnNormal';
import HeaderTitle from '../../../components/HeaderTitle';
import InputBox from '../../../components/InputBox';
import SectionLayout from '../../../components/layout/SectionLayout';
import { usePopup } from '../../../components/popup/usePopup';
import TextEditor_Quill from '../../../components/TextEditor_Quill';
import { WRITE_TYPE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';
import ValidateInput from '../../../utils/ValidateInput';

const BtnWrap = styled.div`
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  width: 100%;
  text-align: center;
`;

const PopupWrite = () => {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const queryClient = useQueryClient();

  // 언마운트시 Quill이미지 처리용
  // send체크
  const [sendCheck, setSendCheck] = useState(false);
  // useRef를 사용하여 클린업함수 제어
  const sendCheckRef = useRef(false);

  // 팝업 제목
  const [title, setTitle] = useState('');
  // 팝업 본문
  const [content, setContent] = useState('<p><br></p>');

  // check
  const validateProductWrite = () => {
    var check = false;

    if (!ValidateInput(title)) {
      openPopup({ title: '안내', content: '제목을 입력해주세요.' });
      return;
    }

    if (content === '<p><br></p>') {
      openPopup({ title: '안내', content: '본문을 입력해주세요.' });
      return;
    }

    check = true;

    return check;
  };

  // TODO 엔드포인트 나오면 다시 작성
  // 최종 팝업 내용 등록 요청 api
  // const { mutate: onSendPopupApi } = useMutationInstance({
  //   onErrorFn: () => {
  //     openPopup({
  //       title: '오류',
  //       content: '다시 시도해주세요.',
  //     });
  //   },
  //   onSuccessFn: () => {
  //     queryClient.refetchQueries([queryKeys.boardCommunityList], { force: true });
  //     queryClient.refetchQueries([queryKeys.boardNoticeList], { force: true });
  //     setSendCheck(true);
  //     sendCheckRef.current = true;
  //   },
  // });

  // 요청 핸들
  const onSendPopup = () => {
    if (!validateProductWrite()) {
      return;
    }

    openPopup({ title: '안내', content: '서비스 준비 중' });

    // let data = {
    //   board_type: boardType,
    //   title: boardTitle,
    //   content: editorData,
    // };

    // if (ValidateInput(idx)) {
    //   data = { ...data, board_idx: idx };
    // }

    // 더블클릭 방지 됨
    // onSendPopupApi({
    //  엔드포인트는 다시 작성
    // });
  };

  // *모두* 언마운트시 작성 중이었던 이미지 삭제
  // mutation 이미지삭제 Post 요청
  const { mutate: removeIMGMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.quill.removeUploadFile,
    onErrorFn: () => {
      return openPopup({
        title: '오류',
        content: '사진을 다시 업로드해주세요.',
      });
    },
    onSuccessFn: (_, variables) => {
      const { apiBody } = variables;
      // 쿼리 상태보관에서 삭제
      if (apiBody && apiBody?.length > 0) {
        const currentQuery = queryClient.getQueryData([queryKeys.popupWrite]);
        if (currentQuery) {
          const updateQuery = currentQuery.filter(
            (el) => !apiBody.some((item) => item.url === el.url),
          );
          if (updateQuery.length > 0)
            return queryClient.setQueryData([queryKeys.popupWrite], updateQuery);
          return queryClient.removeQueries([queryKeys.popupWrite]);
        }
      }
    },
  });

  // if (editIsError) return <ErrorFetching isError={editIsError} />;

  // *수정시* 작성 중인 이미지 삭제 함수 ------
  const { mutate: deleteEditimageMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.quill.removeUploadFile,
    onError: (error) => {},
    onSuccess: () => {
      queryClient.removeQueries([queryKeys.deleteImageUrlEdit]);
    },
  });

  const deleteEditImageAPI = async () => {
    const deleteImageUrl = await queryClient.getQueryData([queryKeys.deleteImageUrlEdit]);

    if (!deleteImageUrl) return;
    // deleteImageUrl가 있다면 아래 요청 진행
    deleteEditimageMutation({
      apiBody: deleteImageUrl,
    });
  };

  useEffect(() => {
    // 샌드요청이면 아래 진행,
    if (sendCheck) {
      if (idx) {
        deleteEditImageAPI();
        // queryClient.invalidateQueries([queryKeys.boardDetail, idx], { force: true });
      }
      navigate('/');
      return;
    }
    // --------------

    // 에디터 이미지 언마운트시 설정(quill컴포넌트에서 제어가 불가하여 부모 컴포넌트에서 처리)
    // 아니라면 언마운트시 이미지 s3에서 삭제
    return () => {
      if (!sendCheckRef.current) {
        queryClient.removeQueries([queryKeys.deleteImageUrlEdit]);
        const removeURLs = queryClient.getQueryData([queryKeys.popupWrite]);
        if (removeURLs) {
          removeIMGMutation({
            apiBody: removeURLs,
          });
        }
      } else {
        queryClient.removeQueries([queryKeys.popupWrite]);
      }
    };
  }, [sendCheckRef.current]);

  return (
    <SectionLayout padding="50px 0" maxWidth="80%">
      <HeaderTitle>팝업등록</HeaderTitle>
      <InputBox
        containerWidth="500px"
        textAlign="center"
        inputFontSize="24px"
        inputValue={title}
        onChangeEvent={(e) => setTitle(e.target.value)}
        letterSpacing="1px"
      >
        제목
      </InputBox>
      <TextEditor_Quill
        queryKey={queryKeys.popupWrite}
        width="500px"
        height="540px"
        value={content}
        onChangeEvent={setContent}
        typeCode={WRITE_TYPE.BOARD_WRITE}
      />
      <BtnWrap>
        <BtnNormal padding="15px 230px" onClickEvent={onSendPopup}>
          등록
        </BtnNormal>
      </BtnWrap>
    </SectionLayout>
  );
};

export default PopupWrite;
