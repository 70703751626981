import { BOARD_TYPE } from '../constant/TYPE_CONSTANT';

export const checkBoardType = (type) => {
  switch (type) {
    case BOARD_TYPE.ESTIMATE_INQUIRY:
      return '견적요청';
    case BOARD_TYPE.INQUIRY:
      return '상품문의';
    case BOARD_TYPE.COMMUNITY:
      return '자유게시판';
    case BOARD_TYPE.NOTICE:
      return '공지사항';
    default:
      return '오류';
  }
};
