import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import '../../../assets/css/Sub.css';

import { endPoint } from '../../../api/endPoint';
import EmptyDataPage from '../../../components/EmptyDataPage';
import ErrorFetching from '../../../components/error/ErrorFetching';
import Grid from '../../../components/Grid';
import SectionLayout from '../../../components/layout/SectionLayout';
import { usePopup } from '../../../components/popup/usePopup';
import { CACHE_TIME } from '../../../constant/NUMBER_CONSTANT';
import { BOARD_TYPE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';

const labelData = [
  { label: '번호', labelKey: 'board_idx', flex: 0.5 },
  { label: '제목', labelKey: 'title', flex: 6 },
  { label: '작성자', labelKey: 'reg_by_member_name', flex: 1 },
  { label: '작성날짜', labelKey: 'reg_by_time', flex: 2, time: true },
  { label: '조회수', labelKey: 'view_count', flex: 1 },
];

const Notice = () => {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  // 파람스 get
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page');
  const count = params.get('count');

  // 공지사항 리스트
  const {
    data: { data_list: noticeListData },
    isError,
  } = useQueryInstance({
    queryKey: [queryKeys.boardNoticeList, page, count],
    apiMethod: 'get',
    apiEndPoint: endPoint.board.getBoardList,
    apiQueryParams: {
      board_type: BOARD_TYPE.NOTICE,
      page,
      count,
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: CACHE_TIME.DEFAULT,
  });

  // 페이지네이션
  const itemsPerPage = 5;
  const totalPages = Math.ceil(noticeListData?.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    navigate(`/pages/board/notice?page=${newPage}`);
  };

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout>
      <Title>공지사항</Title>
      <BtnContainer>
        {/* <BtnNormal onClickEvent={() => navigate('/pages/board/write')} bgColor="#ff7200">
          글쓰기
        </BtnNormal> */}
      </BtnContainer>
      {noticeListData?.length === 0 ? (
        <EmptyDataPage title="작성된 글이 없습니다." />
      ) : (
        <Grid
          data={noticeListData}
          navigateUrl={'/pages/board/detail'}
          idxName="board_idx"
          labelData={labelData}
          containerMargin="0px 0px 50px 0px"
        />
      )}

      {noticeListData?.length > 0 && (
        <div className="pagination">
          <div className="page-box">
            <a className="page-btn page-first" onClick={() => handlePageChange(1)}></a>
            <a
              className="page-btn page-prev"
              onClick={() => handlePageChange(Number(page) - 1)}
            ></a>
          </div>
          <div className="page-box">
            {Array.from({ length: totalPages }).map((_, index) => (
              <a
                key={index}
                className={`page-num ${Number(page) === index + 1 ? 'current' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </a>
            ))}
          </div>
          <div className="page-box">
            <a
              className="page-btn page-next"
              onClick={() => handlePageChange(Number(page) + 1)}
            ></a>
            <a className="page-btn page-last" onClick={() => handlePageChange(totalPages)}></a>
          </div>
        </div>
      )}
    </SectionLayout>
  );
};

// style 영역 ------
const Title = styled.p`
  text-align: center;
  font-size: 30px;
  font-family: 'Pretendard-medium';
  padding-bottom: 20px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0px;
  border-bottom: 2px solid black;
`;

export default Notice;
