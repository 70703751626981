import React from 'react';
import { BiError } from 'react-icons/bi';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
`;

const Section = styled.div`
  text-align: center;
`;

const ErrorMessage = styled.p`
  font-size: 30px;
  font-weight: 700;
  margin: 0;
`;

const ErrorCode = styled.p`
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
`;

const ErrorMessageTwo = styled.p`
  font-size: 24px;
  margin: 0;
  margin-top: 30px;
`;

const ErrorFetching = ({
  isError = false,
  title = '접속 오류',
  errorData = '',
  content = '다시 시도해주세요.',
}) => {
  if (!isError) return <></>;

  return (
    <Container>
      <Section>
        <BiError style={{ fontSize: '100px' }} />
        <ErrorMessage>{title}</ErrorMessage>
        {errorData ? <ErrorCode>{`(${errorData})`}</ErrorCode> : <></>}
        <ErrorMessageTwo>{content}</ErrorMessageTwo>
      </Section>
    </Container>
  );
};

export default React.memo(ErrorFetching);
