import React from 'react';
import { useRecoilState } from 'recoil';

import style from '../../assets/css/Footer.module.scss';
import { useMediaQueryCustom } from '../../components/mediaQueryCustom/useMediaQueryCustom';
import { footerPolicyPopUp } from '../../recoil/footerPolicyPopUp';

export const Footer = () => {
  const [policyPopUp, setPolicyPopUp] = useRecoilState(footerPolicyPopUp);
  const { SMALL_SCREEN } = useMediaQueryCustom();

  return (
    <footer className={style.footer}>
      <div className={style.container}>
        <img className={style.logo_gray} src="/ilppang_logo_gray.png" alt="footer로고" />
        {SMALL_SCREEN ? (
          <>
            <div className={style.react_footer_info_box}>
              <ul className={style.react_statement_box}>
                <li>고객센터</li>
                <li onClick={() => (policyPopUp === 'none' ? setPolicyPopUp('term') : null)}>
                  이용약관
                </li>
                <li onClick={() => (policyPopUp === 'none' ? setPolicyPopUp('personal') : null)}>
                  개인정보처리방침
                </li>
                <li onClick={() => (policyPopUp === 'none' ? setPolicyPopUp('financial') : null)}>
                  전자금융거래 이용약관
                </li>
              </ul>
              <p className={style.react_info_detail}>
                한국MRO유통협동조합 ∣ 이사장:이재연
                <br />
                101동1709호(신중동역랜드마크푸르지오시티)
                <br />
                사업자등록번호 : 255-88-03018
                <br />
                <br />
                사이트관리: 스판(SPAN) ∣ 대표자:오원기
                <br />
                사업자등록번호:604-12-10701
                <br />
                이메일:nobusy.info@gmail.com ∣전화: 032-679-7890
              </p>
            </div>
            <p className={style.copyright}>
              본 콘텐츠의 저작권은 저자 또는 제공처에 있으며,
              <br />
              이를 무단 이용하는 경우 저작권등에 따라 법적 책임을 질 수 있습니다.
            </p>
          </>
        ) : (
          <>
            <ul className={style.statement_box}>
              <li>고객센터</li>
              <li onClick={() => (policyPopUp === 'none' ? setPolicyPopUp('term') : null)}>
                이용약관
              </li>
              <li onClick={() => (policyPopUp === 'none' ? setPolicyPopUp('personal') : null)}>
                개인정보처리방침
              </li>
              <li onClick={() => (policyPopUp === 'none' ? setPolicyPopUp('financial') : null)}>
                전자금융거래 이용약관
              </li>
            </ul>

            <p className={style.info_detail}>
              한국MRO유통협동조합 ∣ 이사장:이재연 ∣ 경기도 부천시 신흥로 223,
              101동1709호(신중동역랜드마크푸르지오시티) ∣ 사업자등록번호 : 255-88-03018
              <br />
              사이트관리: 스판(SPAN) ∣ 대표자:오원기 ∣ 사업자등록번호:604-12-10701 ∣
              이메일:nobusy.info@gmail.com ∣전화: 032-679-7890
            </p>
            <p className={style.copyright}>
              본 콘텐츠의 저작권은 저자 또는 제공처에 있으며, 이를 무단 이용하는 경우 저작권등에
              따라 법적 책임을 질 수 있습니다.
            </p>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
