export const WRITE_TYPE = {
  PRODUCT_WRITE: '1',
  BOARD_WRITE: '2',
};

export const BOARD_TYPE = {
  NOTICE: 10,
  COMMUNITY: 20,
  INQUIRY: 30,
  ESTIMATE_INQUIRY: 40,
};

export const PAYMENT_TYPE = {
  CARD: 10,
  TRANSFER: 20,
};

export const MEMBER_ROLE = {
  ADMIN: 10, // 최고 관리자
  MIN_ADMIN: 15, // 중간 관리자
  REGULAR: 20, // 조합 회원 (정 조합원)
  NORMAL: 30, // 일반회원 (준 조합원)
};

export const ORDER_STATUS = {
  ORDER_WAIT: 100, // 주문대기
  ORDER: 200, // 주문
  ORDER_COMPLETE: 210, // 주문확정
  CANCEL: 300, // 취소 (주문전체)
  PARTIAL_CANCEL: 310, // 부분취소
  ERROR: 500, // 에러
};

export const ORDER_ITEM_STATUS = {
  ORDER_WAIT: 100, // 입금대기
  ORDER: 200, // 입금완료 (주문)
  ORDER_COMPLETE: 210, // 주문확정
  SETTLEMENT_COMPLETE: 220, // 정산완료
  CANCEL: 300, // 주문취소 (구매자가 취소시)
  REFUND_REQUEST: 400, // 환불 신청
  REFUND: 410, // 환불완료 (판매자가 취소시)
  ERROR: 500, // 에러
};

export const PRODUCT_TYPE = {
  GENERAL: 10, // 일반판매
  GROUP: 20, // 공동구매
  URGENT: 30, // 긴급구매
};

export const PARAMS_TYPE = {
  MY_DATA: 1,
  IS_ADMIN: 1,
};

export const WS_MESSAGE_TYPE = {
  TEXT: 10, //일반 문자
  INQUIRY: 20, //상품관련 처음 대화(상품 문의)
  ESTIMATE: 30, //견적서
  PAY_COMPLETE: 40, //결제완료건
};
