import CryptoJS from 'crypto-js';

const { REACT_APP_CRYPTO_SECRET: key } = process.env;

export const generateCrypto = (value) => {
  if (!value) return;

  return CryptoJS.AES.encrypt(String(value), key).toString();
};

export const decodingCrypto = (value) => {
  if (!value) return;

  return CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
};
