import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../../assets/css/Join1.css';
import '../../../assets/css/Sub.css';

import { usePopup } from '../../../components/popup/usePopup.js';
import POLICY_ELECTRONIC from '../../../constant/POLICY_ELECTRONIC.jsx';
import POLICY_PERSONAL from '../../../constant/POLICY_PERSONAL.jsx';
import POLICY_TERMS from '../../../constant/POLICY_TERMS.jsx';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';

export default function Join1() {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      openPopup({ title: '안내', content: '이미 로그인 중 입니다.' });
      navigate('/');
      return;
    } else {
      localStorage.clear();
    }
  }, [isLoggedIn]);

  // 모두 체크용
  const [isAllChecked, setIsAllChecked] = useState(false);
  // 이하 개별체크용
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const joinNextPage = () => {
    if (!isChecked1 || !isChecked2 || !isChecked3) {
      openPopup({ title: '안내', content: '이용약관에 모두 동의해주셔야합니다.' });
      return;
    }

    navigate('/pages/member/join2');
    window.scrollTo(0, 0);
  };

  return (
    <div className="content">
      <div className="container section">
        <div className="join">
          <div className="con-head">
            <p className="con-title">회원가입</p>
          </div>
          <div className="con-head-sub">
            <p className="con-title-sub"><span style={{ color: 'red ' }}>*</span> 사업자등록증을 준비해주세요!</p>
          </div>
          <label htmlFor="chkAll" className="chk-box">
            <input
              type="checkbox"
              id="chkAll"
              className="chkBoxItem"
              checked={isAllChecked}
              onChange={() => {
                setIsAllChecked((cur) => {
                  if (cur) {
                    setIsChecked1(false);
                    setIsChecked2(false);
                    setIsChecked3(false);
                    setIsAllChecked(false);
                  } else {
                    setIsChecked1(true);
                    setIsChecked2(true);
                    setIsChecked3(true);
                    setIsAllChecked(true);
                  }
                });
              }}
            />
            <div className="chk-box__icon"></div>
            <div className="chk-box__txt">
              <p className="txt--m4" style={{ fontSize: '18px' }}>
                <span style={{ color: 'red ' }}>*</span>필수항목 모두 동의하기
              </p>
            </div>
          </label>
          <div className="terms-box">
            <div className="terms-item">
              <div className="terms-head">
                <label htmlFor="chk1" className="chk-box">
                  <input
                    type="checkbox"
                    id="chk1"
                    className="chkBoxItem"
                    checked={isChecked1}
                    onChange={() => setIsChecked1((cur) => !cur)}
                  />
                  <span className="chk-box__icon"></span>
                  <span className="chk-box__txt">
                    <span className="txt--m4">이용약관 (필수)</span>
                  </span>
                </label>
              </div>
              <div className="terms-body">
                <POLICY_TERMS />
              </div>
            </div>
            <div className="terms-item">
              <div className="terms-head">
                <label htmlFor="chk2" className="chk-box">
                  <input
                    type="checkbox"
                    id="chk2"
                    className="chkBoxItem"
                    checked={isChecked2}
                    onChange={() => setIsChecked2((cur) => !cur)}
                  />
                  <span className="chk-box__icon"></span>
                  <span className="chk-box__txt">
                    <span className="txt--m4">개인정보 수집 및 이용 (필수)</span>
                  </span>
                </label>
              </div>
              <div className="terms-body">
                <POLICY_PERSONAL />
              </div>
            </div>
            <div className="terms-item">
              <div className="terms-head">
                <label htmlFor="chk3" className="chk-box">
                  <input
                    type="checkbox"
                    id="chk3"
                    className="chkBoxItem"
                    checked={isChecked3}
                    onChange={() => setIsChecked3((cur) => !cur)}
                  />
                  <span className="chk-box__icon"></span>
                  <span className="chk-box__txt">
                    <span className="txt--m4">전자금융서비스 이용 (필수)</span>
                  </span>
                </label>
              </div>
              <div className="terms-body">
                <POLICY_ELECTRONIC />
              </div>
            </div>
          </div>

          <div className="page-btn">
            <button className="page-btn-item next" onClick={joinNextPage}>
              다음
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
