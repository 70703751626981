import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import icon_first from '../assets/img/icon/icon-first.svg';
import icon_last from '../assets/img/icon/icon-last.svg';
import icon_next from '../assets/img/icon/icon-next_page.svg';
import icon_prev from '../assets/img/icon/icon-prev.svg';

const Pagination = ({ naviPath, totalCount = 6000, count = '20', page = '1', conMargin }) => {
  const navigate = useNavigate();
  const totalPages = Math.ceil(totalCount / Number(count));
  const [currentPage, setCurrentPage] = useState(Number(page));

  // 한 번에 보여줄 페이지 번호 개수
  const pagesToShow = 20;
  const currentRange = Math.floor((currentPage - 1) / pagesToShow);

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    window.scrollTo(0, 0);
    setCurrentPage(newPage);
    navigate(naviPath + `page=${String(newPage)}&count=${count}`);
  };

  const handleNextRange = () => {
    const nextPage = (currentRange + 1) * pagesToShow + 1;
    if (nextPage <= totalPages) handlePageChange(nextPage);
  };

  const handlePrevRange = () => {
    const prevPage = currentRange * pagesToShow;
    if (prevPage >= 1) handlePageChange(prevPage);
  };

  const getPages = () => {
    const startPage = currentRange * pagesToShow + 1;
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);
    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };

  return (
    <Container conMargin={conMargin}>
      <PageBox>
        <PageBtn imgUrl={icon_first} onClick={() => handlePageChange(1)} />
        <PageBtn imgUrl={icon_prev} onClick={handlePrevRange} />
      </PageBox>

      <PageBox>
        {getPages().map((pageNum) => (
          <PageNum
            key={pageNum}
            current={currentPage === pageNum}
            onClick={() => handlePageChange(pageNum)}
          >
            {pageNum}
          </PageNum>
        ))}
      </PageBox>

      <PageBox>
        <PageBtn imgUrl={icon_next} onClick={handleNextRange} />
        <PageBtn imgUrl={icon_last} onClick={() => handlePageChange(totalPages)} />
      </PageBox>
    </Container>
  );
};

export default React.memo(Pagination);

// 스타일
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin: ${({ conMargin }) => conMargin};
`;

const PageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PageBtn = styled.span`
  width: 32px;
  height: 32px;
  background-size: 28px;
  background-position: center center;
  margin: 0;
  background-image: url(${({ imgUrl }) => imgUrl});
  cursor: pointer;
`;

const PageNum = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  color: ${({ current }) => (!current ? '#666666' : '#2e4995')};
  &::before {
    content: '';
    position: absolute;
    display: ${({ current }) => (!current ? 'none' : 'block')};
    width: 100%;
    height: 2px;
    background-color: #2e4995;
    left: 0;
    bottom: 0;
  }
`;
