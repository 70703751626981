import React, { forwardRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

const TextAreaCustom = forwardRef(
  (
    {
      onKeyDownTextArea,
      onKeyUpTextArea,
      textAreaPlaceholder,
      onChangeTextArea,
      valueTextArea,
      disabled = false,
      minRows = 1,
      maxRows = 3,
      style,
      children,
      isRequiredMark = false,
      titleFontSize = '14px',
      titlePadding = '10px 0px',
      titleColor = 'black',
      defaultValue,
    },
    ref,
  ) => {
    return (
      <>
        {children && (
          <Title titleFontSize={titleFontSize} titlePadding={titlePadding} titleColor={titleColor}>
            {children}{' '}
            {isRequiredMark ? <span style={{ color: 'rgb(238, 106, 123)' }}>*</span> : null}
          </Title>
        )}
        <TextareaAutosize
          minRows={minRows}
          maxRows={maxRows}
          disabled={disabled}
          style={{ resize: 'none', ...style }}
          value={valueTextArea}
          onChange={onChangeTextArea}
          placeholder={textAreaPlaceholder}
          onKeyDown={onKeyDownTextArea}
          ref={ref}
          onKeyUp={onKeyUpTextArea}
          defaultValue={defaultValue}
        />
      </>
    );
  },
);

export default React.memo(TextAreaCustom);
TextAreaCustom.displayName = 'TextAreaCustom';

const Title = styled.p`
  font-size: ${({ titleFontSize }) => titleFontSize};
  padding: ${({ titlePadding }) => titlePadding};
  color: ${({ titleColor }) => titleColor};
`;
