import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { endPoint } from '../../../../api/endPoint';
import ErrorFetching from '../../../../components/error/ErrorFetching';
import Grid from '../../../../components/Grid';
import HeaderTitle from '../../../../components/HeaderTitle';
import SectionLayout from '../../../../components/layout/SectionLayout';
import MyPageButtonComponent from '../../../../components/MyPageButtonComponent';
import Pagination from '../../../../components/Pagination';
import { CACHE_TIME } from '../../../../constant/NUMBER_CONSTANT';
import { BOARD_TYPE, PARAMS_TYPE } from '../../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../../../react-query/useQueryInstance';
import { IsLoggedIn } from '../../../../utils/IsLoggedIn';

// 셀렉터 가이드
const selectorGuide = [
  { label: '견적요청', value: 0 },
  { label: '상품문의', value: 1 },
];

// 그리드 헤드
const labelData = [
  { label: '번호', labelKey: 'board_idx', flex: 0.5 },
  { label: '게시판', labelKey: 'board_type', flex: 1 },
  { label: '제목', labelKey: 'title', flex: 5 },
  { label: '작성자', labelKey: 'reg_by_member_name', flex: 1 },
  { label: '작성날짜', labelKey: 'reg_by_time', flex: 2, time: true },
  { label: '조회수', labelKey: 'view_count', flex: 1 },
];

const MyBoardList = () => {
  const navigate = useNavigate();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const [selectorValue, setSelectorValue] = useState(0);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');

  const {
    data: { data_list, total_count },
    isError,
  } = useQueryInstance({
    queryKey: [
      queryKeys.getMyBoardList,
      selectorValue === 0 ? BOARD_TYPE.ESTIMATE_INQUIRY : BOARD_TYPE.INQUIRY,
      page,
      count,
    ],
    apiEndPoint: endPoint.board.getBoardList,
    apiMethod: 'get',
    apiQueryParams: {
      my_board_list: PARAMS_TYPE.MY_DATA,
      board_type: selectorValue === 0 ? BOARD_TYPE.ESTIMATE_INQUIRY : BOARD_TYPE.INQUIRY,
      page,
      count,
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: CACHE_TIME.DEFAULT,
  });

  const selectorHandler = useCallback((e) => {
    const value = Number(e.target.value);

    switch (value) {
      case 0:
        setSelectorValue(value);
        navigate('/pages/member/mypage/my_board_list');
        break;
      case 1:
        setSelectorValue(value);
        navigate('/pages/member/mypage/my_board_list');
        break;
      default:
        break;
    }
  }, []);

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout maxWidth="1400px">
      <MyPageButtonComponent
        dataList={[
          { title: '회원정보수정', current: false, url: '/pages/member/mypage/edit1' },
          { title: '주문내역', current: false, url: '/pages/member/mypage/order_list' },
          { title: '판매내역', current: false, url: '/pages/member/mypage/sales_list' },
          { title: '내상품', current: false, url: '/pages/member/mypage/product_list' },
          { title: '내가 쓴 글', current: true, url: '/pages/member/mypage/my_board_list' },
          { title: '견적문의', current: false, url: '/pages/member/mypage/my_estimate_index' },
        ]}
      />

      <HeaderTitle
        conMargin="70px 0 0 0"
        selectorData={selectorGuide}
        selectorValue={selectorValue}
        selectorChangeEvent={selectorHandler}
      >
        내가 쓴 글 목록
      </HeaderTitle>

      <Grid
        labelData={labelData}
        data={data_list}
        containerMargin="0px 0px 50px 0px"
        idxName="board_idx"
        navigateUrl="/pages/board/detail"
        emptyTitle="등록된 글이 없습니다."
      />

      <Pagination
        conMargin="50px 0 0 0"
        page={page}
        totalCount={total_count}
        count={count}
        naviPath="/pages/member/mypage/my_board_list?"
      />
    </SectionLayout>
  );
};

export default MyBoardList;
