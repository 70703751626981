//천단위 콤마생성
export const changeEnteredNumComma = (el) => {
  const comma = (el) => {
    el = String(el);
    return el.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const uncomma = (el) => {
    el = String(el).replace(/,/g, '');
    el = el.replace(/^0+/, '');
    if (el === '' || parseInt(el, 10) === 0) return '';
    return el;
  };

  let result = uncomma(el);
  if (result === '') return '';
  return comma(result);
};

export const commaInput = (e, fn, ref) => {
  const cursorPosition = e.target.selectionStart;
  const oldValue = e.target.value;
  const newValue = changeEnteredNumComma(e.target.value);

  fn(newValue);

  const oldCommaCount = (oldValue.substring(0, cursorPosition).match(/,/g) || []).length;
  const newCommaCount = (newValue.substring(0, cursorPosition).match(/,/g) || []).length;
  const cursorOffset = newCommaCount - oldCommaCount;

  setTimeout(() => {
    if (ref) {
      ref.setSelectionRange(cursorPosition + cursorOffset, cursorPosition + cursorOffset);
    }
  }, 0);
};

//콤마제거하고 연산 가능한 숫자로 바꾸기
export const resultCommaRemove = (el) => {
  return Number(el.split(',').reduce((cur, acc) => cur + acc, ''));
};
//-------
