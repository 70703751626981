import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { colorList } from '../../assets/style/theme';
import StarMark from '../icon_svg/StarMark';
import EllipsisText from '../textLimit/EllipsisText';
import { useMobileCustom } from '../useMobileCustom/useMobileCustom';

const ProductLayout = ({
  ellipsisTextLength,
  onClickEvent,
  imgSrc,
  alt,
  idx,
  name,
  companyName,
  price,
  productType = '',
  isRecommend = false,
  isFamous = false,
  titleFontSize = '18px',
  markSize,
  markFontSize,
  markBottom,
  markTop,
  markLeft,
  markRight,
  isRecommendColor = '#ff5656',
  isFamousColor = colorList.green,
  isSoldOut = false,
  isSoldOutFontSize = 'clamp(18px, 2vw, 26px)',
}) => {
  const isMobile = useMobileCustom();

  return (
    <ProductItem isMobile={isMobile} data-key={idx} onClick={onClickEvent} isSoldOut={isSoldOut}>
      {isRecommend && (
        <StarMark
          text="추천"
          conPosition="absolute"
          conZIndex="3"
          size={markSize}
          fontSize={markFontSize}
          conBottom={markBottom}
          conTop={markTop}
          conLeft={markLeft}
          conRight={markRight}
          color={isRecommendColor}
        />
      )}
      {isFamous && (
        <StarMark
          text="인기"
          size={markSize}
          fontSize={markFontSize}
          conPosition="absolute"
          conZIndex="3"
          conBottom={markBottom}
          conTop={markTop}
          conLeft={markLeft}
          conRight={markRight}
          color={isFamousColor}
        />
      )}
      <ThumbnailWrap>
        {isSoldOut && <SoldOutMark isSoldOutFontSize={isSoldOutFontSize}>품절</SoldOutMark>}
        {!imgSrc ? (
          <SkeletonWrap>
            <Skeleton height="100%" />
          </SkeletonWrap>
        ) : (
          <Thumbnail isSoldOut={isSoldOut} src={imgSrc} alt={alt} loading="eager" />
        )}
      </ThumbnailWrap>
      <Info>
        <Price isSoldOut={isSoldOut}>{price}</Price>
        <ItemNameTextWrap titleFontSize={titleFontSize}>
          {!ellipsisTextLength ? (
            <ItemName>{name}</ItemName>
          ) : (
            <EllipsisText text={name} maxLength={ellipsisTextLength} />
          )}
        </ItemNameTextWrap>
        <CompanyName>{companyName}</CompanyName>
        {productType && <SaleType>{productType}</SaleType>}
      </Info>
    </ProductItem>
  );
};

export default React.memo(ProductLayout);

// style
const ProductItem = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  transition: 0.1s ease;
  ${({ isMobile, isSoldOut }) =>
    !isMobile &&
    !isSoldOut &&
    `&:hover {
    opacity: 0.7;
  }`}
`;

const ThumbnailWrap = styled.div`
  position: relative;
  display: block;
  // 1:1 비율유지
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 0.5px solid rgb(153, 153, 153);
`;

const Thumbnail = styled.img`
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
  opacity: ${({ isSoldOut }) => isSoldOut && '0.4'};
`;

const Info = styled.div`
  position: relative;
`;

const ItemNameTextWrap = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  font-size: ${({ titleFontSize }) => titleFontSize};
  color: ${colorList.dark};
  overflow: hidden;
  margin-bottom: 10px;
`;

const SoldOutMark = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  color: ${colorList.darkGray};
  font-size: ${({ isSoldOutFontSize }) => isSoldOutFontSize};
  font-weight: 700;
  width: 30%;
  height: auto;
  text-align: center;
  padding: 10px;
  z-index: 3;
  border: 2px solid ${colorList.darkGray};
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(-140deg);
    transform-origin: top;
    background-color: ${colorList.darkGray};
    width: 2px;
    height: 400%;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(-140deg);
    transform-origin: bottom;
    background-color: ${colorList.darkGray};
    width: 2px;
    height: 400%;
  }
`;

const ItemName = styled.p`
  line-height: 1.5;
  word-break: break-all;
`;

const CompanyName = styled.p`
  color: ${colorList.gray};
  font-size: 15px;
  margin-bottom: 10px;
`;

const SaleType = styled.div`
  text-align: center;
  color: ${colorList.white};
  background-color: ${colorList.main};
  font-size: 12px;
  width: fit-content;
  height: fit-content;
  border-radius: 12px;
  padding: 5px 10px;
`;

const Price = styled.p`
  position: relative;
  font-size: 20px;
  margin: 15px 0;
  color: ${({ isSoldOut }) => (!isSoldOut ? colorList.dark : colorList.gray)};
  text-decoration: ${({ isSoldOut }) => (!isSoldOut ? 'none' : 'line-through')};
`;

const SkeletonWrap = styled.div`
  position: absolute;
  display: block;
  width: auto;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;
