import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import 'react-loading-skeleton/dist/skeleton.css';

import { colorList } from '../../assets/style/theme';

const ThumbnailWrap = styled.div`
  position: relative;
  display: block;
  // 1:1 비율유지
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 0.5px solid rgb(153, 153, 153);
`;

const Thumbnail = styled.img`
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
  opacity: ${({ isSoldOut }) => isSoldOut && '0.4'};
`;

const SkeletonWrap = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
`;

const SoldOutMark = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  color: ${colorList.darkGray};
  font-size: ${({ isSoldOutFontSize }) => isSoldOutFontSize};
  font-weight: 700;
  width: 30%;
  height: auto;
  text-align: center;
  padding: 10px;
  z-index: 3;
  border: 2px solid ${colorList.darkGray};
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(-140deg);
    transform-origin: top;
    background-color: ${colorList.darkGray};
    width: 2px;
    height: 400%;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(-140deg);
    transform-origin: bottom;
    background-color: ${colorList.darkGray};
    width: 2px;
    height: 400%;
  }
`;

const ImageLayout = ({ imgSrc, isSoldOut, isSoldOutFontSize, alt, wrapStyle }) => {
  return (
    <ThumbnailWrap style={{ ...wrapStyle }}>
      {isSoldOut && <SoldOutMark isSoldOutFontSize={isSoldOutFontSize}>품절</SoldOutMark>}
      {!imgSrc ? (
        <SkeletonWrap>
          <Skeleton />
        </SkeletonWrap>
      ) : (
        <Thumbnail isSoldOut={isSoldOut} src={imgSrc} alt={alt} />
      )}
    </ThumbnailWrap>
  );
};

export default React.memo(ImageLayout);
