export const TIME = {
  SET_TIME: 5,
};

export const COUNT = {
  GET_PRODUCT_COUNT: 20,
};

export const PAGE = {
  GET_PRODUCT_PAGE: 1,
};

export const CACHE_TIME = {
  DEFAULT: 5 * 60 * 1000,
};

export const BREAK_POINT = {
  FULL_SCREEN: 1200,
  LARGE_SCREEN: 940,
  MEDIUM_SCREEN: 810,
  TABLET_SCREEN: 768,
  MEDIUM_SMALL_SCREEN: 680,
  SMALL_SCREEN: 576,
  EXTRA_SMALL_SCREEN: 480,
  PHONE_SCREEN: 375,
  PHONE_FOLDER_SCREEN: 280,
};

export const FILE_SIZE = {
  MAX_UPLOAD_FILE_SIZE: 5 * 1024 * 1024, // 5MB
  MAX_IMAGE_FILE_SIZE: 3 * 1024 * 1024, // 3MB
};

export const SHOW_COUNT = {
  ESTMATE_INQUIRY: 10,
  COMPANY_LIST: 8,
};
