import React from 'react';
import styled from 'styled-components';

import { colorList } from '../assets/style/theme';
import InputBox from './InputBox';
import { useMediaQueryCustom } from './mediaQueryCustom/useMediaQueryCustom';

const Layout = styled.div`
  position: fixed;
  width: 100vw;
  height: fit-content;
  bottom: 0;
  box-sizing: border-box;
  padding: 15px 10px;
  border: 2px solid ${colorList.lightGray};
  background-color: ${colorList.white};
  border-radius: 20px 20px 0 0;
  left: 50%;
  transform: ${({ scrollPoint }) =>
    scrollPoint ? 'translate(-50%, 0%)' : 'translate(-50%, 100%)'};
  transition: transform 0.5s ease;
  z-index: 20;
`;

const BotInfoContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
`;

const TotalText = styled.p`
  font-size: ${({ totalFontSize }) => totalFontSize};
  text-align: center;
  margin-bottom: 5px;
  line-height: 1.2;
  span {
    font-size: ${({ totalFontSize }) => `${Number(totalFontSize?.replace('px', '')) - 6}px`};
  }
`;

const TotalQuantity = styled.p`
  font-size: ${({ totalQuantityFontSize }) => totalQuantityFontSize};
  text-align: center;
  margin-bottom: 5px;
  line-height: 1.2;
`;

const TotalPriceWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TotalPice = styled.p`
  text-align: center;
  font-size: ${({ totalPriceSize }) => totalPriceSize};
  color: ${({ isSoldOut }) => (!isSoldOut ? '#b70000' : colorList.darkGray)};
  word-break: break-all;
  line-height: 1.2;
  margin-bottom: 5px;
  text-decoration: ${({ isSoldOut }) => (!isSoldOut ? 'none' : 'line-through')};
`;

const TotalPriceWon = styled.span`
  color: ${colorList.dark};
  font-size: ${({ totalPriceSize }) => `${Number(totalPriceSize.replace('px', '')) - 8}px`};
`;

const TitleBox = styled.div`
  flex: ${({ titleBoxFlex }) => titleBoxFlex};
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: #f8f7f6;
  border-radius: 10px;
`;

const OrderBox = styled.div`
  flex: ${({ orderBoxFlex }) => orderBoxFlex};
  position: relative;
  width: 100%;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(-5px, -50%);
    left: 0;
    width: 1px;
    height: 100px;
    background-color: ${colorList.lightGray};
  }
`;

const RadioBox = styled.div`
  position: relative;
  width: 100%;
`;

const BtnWrap = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

const BuyBtn = styled.button`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background-color: ${colorList.darkBlue};
  color: ${colorList.white};
  font-size: ${({ btnFontSize }) => btnFontSize};
  margin: auto;
  margin-bottom: 5px;
  cursor: pointer;
`;

const BottomMenuCart = ({
  totalPrice = 500000, //
  onClickBuy,
  isPayTypeRadioBox = false,
  payTypeRadioOnChange = null,
  payTypeRadioValue = '',
  isSoldOut,
  scrollPoint = false,
  totalFontSize = '18px',
  totalQuantityFontSize = '18px',
  totalPriceSize = '26px',
  titleBoxFlex = '8',
  orderBoxFlex = '2',
  btnFontSize = '15px',
  radioLabelFontSize,
  checkedLength = 0,
  totalQuantity = 0,
}) => {
  const { EXTRA_SMALL_SCREEN } = useMediaQueryCustom();

  return (
    <Layout scrollPoint={scrollPoint}>
      <BotInfoContainer>
        <TitleBox titleBoxFlex={titleBoxFlex}>
          <div
            style={{
              display: 'flex',
              flexDirection: EXTRA_SMALL_SCREEN ? 'column' : undefined,
              gap: EXTRA_SMALL_SCREEN ? '0' : '10px',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '5px',
            }}
          >
            <TotalText totalFontSize={totalFontSize}>
              총 합계 <span>(vat별도)</span>
            </TotalText>
            {isSoldOut && (
              <TotalPriceWrap>
                <TotalPice totalPriceSize={totalPriceSize} isSoldOut={true}>
                  {totalPrice?.toLocaleString('ko-KR')}
                </TotalPice>
                <TotalPriceWon totalPriceSize={totalPriceSize}>원</TotalPriceWon>
              </TotalPriceWrap>
            )}
            {!isSoldOut && (
              <TotalPriceWrap>
                <TotalPice totalPriceSize={totalPriceSize}>
                  {totalPrice?.toLocaleString('ko-KR')}
                </TotalPice>
                <TotalPriceWon totalPriceSize={totalPriceSize}>원</TotalPriceWon>
              </TotalPriceWrap>
            )}
          </div>

          <TotalQuantity totalQuantityFontSize={totalQuantityFontSize}>
            {checkedLength}개 제품 / 총 {totalQuantity}개 수량
          </TotalQuantity>
        </TitleBox>

        <OrderBox orderBoxFlex={orderBoxFlex}>
          {isPayTypeRadioBox && !isSoldOut && (
            <RadioBox>
              <InputBox
                inputType="radio"
                htmlFor="payTypeTransferFloat"
                inputWidth="20px"
                inputValue="transfer"
                justifyContent="center"
                titleFontSize={radioLabelFontSize}
                containerMargin="0 0 10px 0"
                inputChecked={payTypeRadioValue === 'transfer'}
                onChangeEvent={(e) =>
                  payTypeRadioOnChange((cur) => (e.target.checked ? 'transfer' : ''))
                }
              >
                계좌이체
              </InputBox>
              <InputBox
                inputType="radio"
                htmlFor="payTypeCardFloat"
                inputWidth="20px"
                inputValue="card"
                justifyContent="center"
                titleFontSize={radioLabelFontSize}
                containerMargin="0 0 10px 0"
                inputChecked={payTypeRadioValue === 'card'}
                onChangeEvent={(e) =>
                  payTypeRadioOnChange((cur) => (e.target.checked ? 'card' : ''))
                }
              >
                카드결제
              </InputBox>
            </RadioBox>
          )}

          <BtnWrap>
            {isSoldOut && (
              <BuyBtn btnFontSize={btnFontSize} disabled={true} type="button">
                품절
              </BuyBtn>
            )}
            {!isSoldOut && (
              <BuyBtn btnFontSize={btnFontSize} type="button" onClick={onClickBuy}>
                바로구매
              </BuyBtn>
            )}
          </BtnWrap>
        </OrderBox>
      </BotInfoContainer>
    </Layout>
  );
};

export default React.memo(BottomMenuCart);
