import React, { useEffect, useState } from 'react';

import '../../../assets/css/Sub.css';

import { useNavigate } from 'react-router-dom';

import axios from '../../../api/axios.js';
import { endPointMutation } from '../../../api/endPoint.js';
import { usePopup } from '../../../components/popup/usePopup.js';
import { MEMBER_ROLE } from '../../../constant/TYPE_CONSTANT.js';
import { useMutationInstance } from '../../../react-query/useMutationInstance.js';
import { decodingCrypto } from '../../../utils/crypto.js';
import { IsLoggedIn } from '../../../utils/IsLoggedIn.js';
import ValidateInput from '../../../utils/ValidateInput.js';

export default function JoinAdmin() {
  const navigate = useNavigate();

  const { openPopup, closePopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  const memberRole = Number(decodingCrypto(localStorage.getItem('member_role')));

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
      return;
    }

    if (memberRole !== MEMBER_ROLE.ADMIN) {
      navigate(-1);
      openPopup({ title: '안내', content: '권한이 없습니다.' });
      return;
    }
  }, [isLoggedIn, memberRole]);

  // 아이디 중복확인 했는지 확인용
  const [validationIdCheck, setValidationIdCheck] = useState(false);

  // 아이디가 변경될 때마다 중복 확인 플래그를 초기화
  const onMemberIdChange = (e) => {
    setMemberId(e.target.value);
    setValidationIdCheck(false); // 아이디 변경 시 플래그 초기화
  };

  const [memberId, setMemberId] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');

  // 회원가입 API 호출
  const { mutate: onCreateMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.createMember,
    onErrorFn: (error) => {
      const errorData = error.response.data;
      openPopup({
        title: '오류',
        content: errorData.message,
      });
    },
    onSuccessFn: () => {
      openPopup({ title: '안내', content: '회원가입이 완료되었습니다.' });
      navigate(-1);
    },
  });

  const onCancel = () => {
    const goToMain = () => {
      closePopup();
      navigate('/');
    };

    openPopup({
      title: '안내',
      content: '정말 취소하시겠습니까?',
      onComplete: () => goToMain(),
      twoButton: true,
    });
  };

  // 회원가입 API 호출 전 세팅
  const onJoin = async () => {
    if (!validateJoin()) {
      return;
    }

    const data = {
      member_id: memberId.trim(),
      password: password.trim(),
      name: name.trim(),
      phone_number: phoneNumber.trim(),
    };

    onCreateMutation({
      apiBody: data,
    });
  };

  // 아이디 중복확인
  const idValidateCheck = () => {
    if (!ValidateInput(memberId)) {
      openPopup({ title: '안내', content: '아이디를 입력해주세요.' });
      return;
    }

    let idFilters = /^[a-zA-Z](?=.*[a-zA-Z]).{2,12}$/g;

    if (!idFilters.test(memberId)) {
      openPopup({
        title: '안내',
        content: '3자 이상 12자 이하의 영문 혹은 영문과 숫자를 조합해서 입력해주세요.',
      });
      return;
    }

    axios
      .get(`/api/member/checkIdDuplication?id=${memberId}`)
      .then((response) => {
        const checkValue = response.data;

        if (!checkValue) {
          openPopup({ title: '안내', content: '이미 사용중인 아이디입니다.' });
        } else {
          setValidationIdCheck(true);
          openPopup({ title: '안내', content: '사용 가능한 아이디입니다.' });
        }
      })
      .catch((error) => {
        const errorData = error.response.data;
        openPopup({
          title: '안내',
          content: errorData.message,
        });
      });
  };

  // 비밀번호 영문,숫자,특수문자 8자리 이상 15자리 이하
  function isValidPassword(password) {
    let regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
    return regex.test(password);
  }

  const validateJoin = () => {
    var check = false;

    if (!ValidateInput(memberId)) {
      openPopup({ title: '안내', content: '아이디를 입력해주세요.' });
      return;
    }
    if (!validationIdCheck) {
      openPopup({ title: '안내', content: '아이디를 중복을 확인해주세요.' });
      return;
    }
    if (!ValidateInput(name)) {
      openPopup({ title: '안내', content: '이름을 입력해주세요.' });
      return;
    }
    if (!ValidateInput(password)) {
      openPopup({ title: '안내', content: '비밀번호를 입력해주세요.' });
      return;
    } else {
      if (!isValidPassword(password)) {
        openPopup({
          title: '안내',
          content:
            '비밀번호를 영문, 숫자, 특수문자 조합으로 8자리 이상 15자리 이하로 입력해주세요.',
        });
        return;
      }
    }

    if (!ValidateInput(confirmPassword)) {
      openPopup({ title: '안내', content: '비밀번호확인을 입력해주세요.' });
      return;
    }

    if (password !== confirmPassword) {
      openPopup({ title: '안내', content: '비밀번호와 비밀번호확인이 일치하지않습니다.' });
      return;
    }
    if (!ValidateInput(phoneNumber)) {
      openPopup({ title: '안내', content: '휴대폰 번호를 입력해주세요.' });
      return;
    }

    check = true;

    return check;
  };

  return (
    <div className="content">
      <div className="container section">
        <div className="join">
          <div
            className="con-head"
            style={{ position: 'relative', display: 'block', textAlign: 'center' }}
          >
            <p className="con-title" style={{ margin: '5px' }}>
              회원가입
            </p>
            <p className="con-title" style={{ fontSize: '18px', fontWeight: '100' }}>
              * 관리자 권한
            </p>
          </div>
          <div className="input-list">
            <div className="input-item">
              <div className="input-head">
                <span className="title">아이디</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body input-button-div">
                <input type="text" className="input-box input-type" onChange={onMemberIdChange} />
                <button type="button" className="input-btn" onClick={idValidateCheck}>
                  중복확인
                </button>
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">이름</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">비밀번호</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="password"
                  className="input-box"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">비밀번호확인</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="password"
                  className="input-box"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">휴대폰</span>
                <span className="required"> *</span>
              </div>
              {/*<div className="input-body input-button-div">*/}
              <div className="input-body">
                <input
                  // style={{ width: '100%' }}
                  value={phoneNumber}
                  type="number"
                  className="input-box input-type"
                  onWheel={(e) => e.target.blur()}
                  placeholder="- 를 제외해서 입력해주세요."
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="page-btn">
            <button className="page-btn-item prev" onClick={onCancel}>
              취소
            </button>
            <button className="page-btn-item next" onClick={onJoin}>
              회원가입
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
