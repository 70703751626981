import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiOutlineMinusSquare,
  AiOutlinePlusSquare,
} from 'react-icons/ai';
import styled from 'styled-components';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/AdminPage_CategoryList.module.scss';
import noneIcon from '../../../assets/img/icon/icon-none.svg';
import BtnNormal from '../../../components/BtnNormal';
import EmptyDataPage from '../../../components/EmptyDataPage.jsx';
import ErrorFetching from '../../../components/error/ErrorFetching';
import { usePopup } from '../../../components/popup/usePopup.js';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { IsLoggedIn } from '../../../utils/IsLoggedIn.js';

function CategoryList() {
  const { data, isError, refetch } = useQueryInstance({
    queryKey: [queryKeys.getProductCategoryList],
    apiMethod: 'get',
    apiEndPoint: endPoint.category.getProductCategoryList,
  });

  const { openPopup, closePopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  // 카테고리명 수정값(newValue) 저장
  const [newNameState, setNewNameState] = useState({});
  const newNameEdit = (e, idx) => {
    const value = e.target.value.replace(/\n/g, '');
    setNewNameState((cur) => ({ ...cur, [idx]: value }));
  };

  // react-query요청 카테고리 신규추가 또는 수정 ---
  const { mutate: addOrEditMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.category.saveProductCategory,
    onErrorFn: (error) => {
      const status = error.response.status;
      const serverMessage = error.response.data.message;
      if (status === 2101) return openPopup({ title: '오류', content: serverMessage });
      return openPopup({
        title: '오류',
        content: '다시 시도해주세요.',
      });
    },
    onSuccessFn: (_, variables) => {
      const { name, category_idx, parent_idx } = variables.apiBody;
      // 수정인지, 추가인지에 따라 요청 성공 후 처리가 달라 분류 필요
      // 새 카테고리 추가시 조건
      if ((name && !category_idx && !parent_idx) || (name && !category_idx && parent_idx)) {
        refetch();
        return closePopup();
      } else {
        refetch();
        return editBoxStateHandler(category_idx);
      }
    },
  });

  // name이 이전 name과 같은 지 검증 로직
  const addEditMutationHandler = ({ name, category_idx, parent_idx, preValue }) => {
    // 이름이 같지 않다면 api요청
    if (name !== preValue) {
      addOrEditMutation({
        apiBody: {
          name,
          category_idx,
          parent_idx,
        },
      });
    } else {
      // 이름이 같다면 그냥 종료
      editBoxStateHandler(category_idx);
    }
  };

  // 리액트쿼리 삭제요청 ---
  const { mutate: removeMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.category.removeProductCategory,
    onErrorFn: () => {
      return openPopup({
        title: '오류',
        content: '다시 시도해주세요.',
      });
    },
    onSuccessFn: (response) => {
      if (response) {
        refetch();
        closePopup();
        return;
      } else {
        openPopup({ title: '안내', content: '해당 카테고리에 상품이 존재합니다.' });
      }
    },
  });

  // 카테고리 삭제 confirm용 핸들
  const removeMutationConfirm = useCallback((productCategoryIdx) => {
    openPopup({
      title: '안내',
      content: '정말 삭제하시겠습니까?',
      onComplete: () =>
        removeMutation({
          apiBody: productCategoryIdx,
        }),
      twoButton: true,
    });
  }, []);

  // 카테고리 수정 전환 훅
  const [editBoxState, setEditBoxState] = useState({});
  const editBoxStateHandler = (idx, preValue) => {
    setEditBoxState((cur) => {
      // true로 처음부터 넘어오면 text벨류 초기화 필요
      if (editBoxState[idx]) setNewNameState((cur) => ({}));
      // 아니라면 기본 진행
      setNewNameState((pre) => ({ ...pre, [idx]: preValue }));
      return { ...cur, [idx]: !cur[idx] };
    });
  };

  // 구조 맵을 그려주는 선(line)의 동적 생성을 위한 DOM요소 접근
  // DOM접근하여 3뎁스의 유닛 값(높이값)을 가져옴
  // map을 위한 접근을 위해 useRef를 배열로 생성
  const [secondUnitHeight, setSecondUnitHeight] = useState([]);
  const [thirdUnitHeight, setThirdUnitHeight] = useState([]);
  // 2뎁스, 3뎁스 DOM접근
  const secondRef = useRef([]);
  const thirdRef = useRef([]);
  // 접근한 DOM에서 높이값 추출
  useEffect(() => {
    // 비동기 처리를 위한 의존성 배열설정 및 조건문 설정
    if (data && secondRef.current.length > 0 && thirdRef.current.length > 0) {
      const secondDepth = secondRef.current.map((firstDom) => {
        if (!firstDom) return null;
        return firstDom.map((seconDom) => (seconDom ? seconDom.offsetHeight : null));
      });
      setSecondUnitHeight(secondDepth);

      const thirdDepth = thirdRef.current.map((firstDom) => {
        if (!firstDom) return [];
        return firstDom.map((secondDom) => {
          if (!secondDom) return [];
          return secondDom.map((thirdDom) => (thirdDom ? thirdDom.offsetHeight : null));
        });
      });
      setThirdUnitHeight(thirdDepth);
    }
    // 비동기 처리를 위한 의존성 배열설정 및 조건문 설정
  }, [data]);

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <div className={style.content}>
      <div className={style.section}>
        <p className={style.title}>카테고리 관리</p>
        <div className={style.addBtnBox}>
          <BtnNormal
            onClickEvent={() =>
              openPopup({
                title: '신규 카테고리 추가',
                content: '카테고리명을 입력해주세요.',
                input: true,
                twoButton: true,
                setInputValue: (newName) =>
                  addOrEditMutation({
                    apiBody: {
                      name: newName,
                      category_idx: undefined,
                      parent_idx: undefined,
                    },
                  }),
              })
            }
          >
            + # 1 카테고리 추가
          </BtnNormal>
        </div>
        <div className={style.item_list_body}>
          {data?.data_list?.length === 0 ? (
            <EmptyDataPage title="등록된 카테고리가 없습니다." />
          ) : (
            //  첫번째 뎁스
            <ul className={style.item_list_ul}>
              {data?.data_list?.map((categoriesOne, index) => {
                // 첫 뎁스 ref초기화
                if (!secondRef.current[index]) {
                  secondRef.current[index] = [];
                }

                return (
                  <li className={style.categories_li} key={categoriesOne.category_idx}>
                    <CategoryNameBox
                      onClickRemoveCategory={() =>
                        removeMutationConfirm(categoriesOne.category_idx)
                      }
                      onClickAddCategory={() =>
                        openPopup({
                          title: '#2 카테고리 추가',
                          content: '카테고리명을 입력해주세요.',
                          twoButton: true,
                          input: true,
                          setInputValue: (newName) =>
                            addOrEditMutation({
                              apiBody: {
                                name: newName,
                                category_idx: undefined,
                                parent_idx: categoriesOne.category_idx,
                              },
                            }),
                        })
                      }
                      onClickEditConfirm={() =>
                        addEditMutationHandler({
                          name: newNameState[categoriesOne.category_idx],
                          category_idx: categoriesOne.category_idx,
                          parent_idx: undefined,
                          preValue: categoriesOne.name,
                        })
                      }
                      onChangeTextArea={(e) => newNameEdit(e, categoriesOne.category_idx)}
                      valueTextArea={newNameState[categoriesOne.category_idx]}
                      editBoxStateValue={editBoxState}
                      idx={categoriesOne.category_idx}
                      onClickEditInputToggle={() =>
                        editBoxStateHandler(categoriesOne.category_idx, categoriesOne.name)
                      }
                    >
                      {categoriesOne.name}
                    </CategoryNameBox>

                    {/* 두번쨰 뎁스 */}
                    <ul>
                      {categoriesOne?.child_category_list
                        ? categoriesOne.child_category_list.map((categoriesTwo, indexTwo) => {
                            if (!thirdRef.current[index]) {
                              thirdRef.current[index] = [];
                            }
                            if (!thirdRef.current[index][indexTwo]) {
                              thirdRef.current[index][indexTwo] = [];
                            }

                            return (
                              <li className={style.categories_li} key={categoriesTwo.category_idx}>
                                <div className={style.lineWrap}>
                                  {categoriesOne.child_category_list?.length > 0 ? (
                                    indexTwo === 0 ? (
                                      <div className={style.lineFirst} />
                                    ) : (
                                      <div className={style.lineTwo}>
                                        <div className={style.row} />
                                        <div
                                          className={style.col}
                                          style={
                                            // 상위에서 뒤에 1개 들어올 경우
                                            categoriesOne?.child_category_list[indexTwo - 1]
                                              .child_category_list?.length === 1
                                              ? {
                                                  // 상위 높이 값 기준으로 잡음
                                                  height: `${
                                                    secondUnitHeight.length !== 0
                                                      ? secondUnitHeight[index][indexTwo - 1] + 40
                                                      : null
                                                  }px`,
                                                  top: `-${
                                                    secondUnitHeight.length !== 0
                                                      ? secondUnitHeight[index][indexTwo - 1] - 7
                                                      : null
                                                  }px`,
                                                }
                                              : // 상위에서 뒤에 2개이상 있을 경우
                                              categoriesOne?.child_category_list[indexTwo - 1]
                                                  .child_category_list?.length > 1
                                              ? {
                                                  // 상위 뒤에 박스 높이를 합산
                                                  height: `${
                                                    secondUnitHeight?.length !== 0
                                                      ? secondUnitHeight[index][indexTwo - 1] >=
                                                        thirdUnitHeight[index][indexTwo - 1].reduce(
                                                          (acc, cur) => acc + cur,
                                                          0,
                                                        )
                                                        ? secondUnitHeight[index][indexTwo - 1] + 20
                                                        : thirdUnitHeight[index][
                                                            indexTwo - 1
                                                          ].reduce(
                                                            (acc, cur) => acc + cur,
                                                            categoriesOne.child_category_list[
                                                              indexTwo - 1
                                                            ].child_category_list.length <= 2
                                                              ? 58
                                                              : 58 +
                                                                  (categoriesOne
                                                                    .child_category_list[
                                                                    indexTwo - 1
                                                                  ].child_category_list?.length -
                                                                    2) *
                                                                    20,
                                                          )
                                                      : null
                                                  }px`,
                                                  top: `-${
                                                    secondUnitHeight?.length !== 0
                                                      ? secondUnitHeight[index][indexTwo - 1] >=
                                                        thirdUnitHeight[index][indexTwo - 1].reduce(
                                                          (acc, cur) => acc + cur,
                                                          0,
                                                        )
                                                        ? secondUnitHeight[index][indexTwo - 1] - 27
                                                        : thirdUnitHeight[index][
                                                            indexTwo - 1
                                                          ].reduce(
                                                            (acc, cur) => acc + cur,
                                                            categoriesOne.child_category_list[
                                                              indexTwo - 1
                                                            ].child_category_list.length <= 2
                                                              ? 12
                                                              : 12 +
                                                                  (categoriesOne
                                                                    .child_category_list[
                                                                    indexTwo - 1
                                                                  ].child_category_list?.length -
                                                                    2) *
                                                                    20,
                                                          )
                                                      : null
                                                  }px`,
                                                }
                                              : //디폴트
                                                {
                                                  height: `${
                                                    secondUnitHeight.length !== 0
                                                      ? secondUnitHeight[index][indexTwo - 1] + 20
                                                      : null
                                                  }px`,
                                                  top: `-${
                                                    secondUnitHeight.length !== 0
                                                      ? secondUnitHeight[index][indexTwo - 1] - 27
                                                      : null
                                                  }px`,
                                                }
                                          }
                                        />
                                      </div>
                                    )
                                  ) : null}
                                </div>
                                <CategoryNameBox
                                  onClickRemoveCategory={() =>
                                    removeMutationConfirm(categoriesTwo.category_idx)
                                  }
                                  onClickAddCategory={() =>
                                    openPopup({
                                      title: '#3 카테고리 추가',
                                      content: '카테고리명을 입력해주세요.',
                                      twoButton: true,
                                      input: true,
                                      setInputValue: (newName) =>
                                        addOrEditMutation({
                                          apiBody: {
                                            name: newName,
                                            category_idx: undefined,
                                            parent_idx: categoriesTwo.category_idx,
                                          },
                                        }),
                                    })
                                  }
                                  onClickEditConfirm={() =>
                                    addEditMutationHandler({
                                      name: newNameState[categoriesTwo.category_idx],
                                      category_idx: categoriesTwo.category_idx,
                                      parent_idx: categoriesOne.category_idx,
                                      preValue: categoriesTwo.name,
                                    })
                                  }
                                  onChangeTextArea={(e) =>
                                    newNameEdit(e, categoriesTwo.category_idx)
                                  }
                                  valueTextArea={newNameState[categoriesTwo.category_idx]}
                                  editBoxStateValue={editBoxState}
                                  idx={categoriesTwo.category_idx}
                                  onClickEditInputToggle={() =>
                                    editBoxStateHandler(
                                      categoriesTwo.category_idx,
                                      categoriesTwo.name,
                                    )
                                  }
                                  boxRef={(el) => (secondRef.current[index][indexTwo] = el)}
                                >
                                  {categoriesTwo.name}
                                </CategoryNameBox>

                                {/* 세번째 뎁스 */}
                                <ul>
                                  {categoriesTwo?.child_category_list
                                    ? categoriesTwo.child_category_list.map(
                                        (categoriesThree, indexThree) => (
                                          <li
                                            className={style.categories_li}
                                            key={categoriesThree.category_idx}
                                          >
                                            <div className={style.lineWrap}>
                                              {categoriesTwo.child_category_list?.length > 0 &&
                                              indexThree === 0 ? (
                                                <div className={style.lineFirst} />
                                              ) : (
                                                <div className={style.lineTwo}>
                                                  <div className={style.row} />
                                                  <div
                                                    className={style.col}
                                                    style={
                                                      indexThree >= 1
                                                        ? {
                                                            height: `${
                                                              thirdUnitHeight.length !== 0
                                                                ? thirdUnitHeight[index][indexTwo][
                                                                    indexThree - 1
                                                                  ] + 19
                                                                : null
                                                            }px`,
                                                            top: `-${
                                                              thirdUnitHeight.length !== 0
                                                                ? thirdUnitHeight[index][indexTwo][
                                                                    indexThree - 1
                                                                  ] - 28
                                                                : null
                                                            }px`,
                                                          }
                                                        : null
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            <CategoryNameBox
                                              onClickRemoveCategory={() =>
                                                removeMutationConfirm(categoriesThree.category_idx)
                                              }
                                              onClickEditConfirm={() =>
                                                addEditMutationHandler({
                                                  name: newNameState[categoriesThree.category_idx],
                                                  category_idx: categoriesThree.category_idx,
                                                  parent_idx: categoriesTwo.category_idx,
                                                  preValue: categoriesThree.name,
                                                })
                                              }
                                              onChangeTextArea={(e) =>
                                                newNameEdit(e, categoriesThree.category_idx)
                                              }
                                              valueTextArea={
                                                newNameState[categoriesThree.category_idx]
                                              }
                                              plus={false}
                                              boxRef={(el) =>
                                                (thirdRef.current[index][indexTwo][indexThree] = el)
                                              }
                                              editBoxStateValue={editBoxState}
                                              idx={categoriesThree.category_idx}
                                              onClickEditInputToggle={() =>
                                                editBoxStateHandler(
                                                  categoriesThree.category_idx,
                                                  categoriesThree.name,
                                                )
                                              }
                                            >
                                              {categoriesThree.name}
                                            </CategoryNameBox>
                                          </li>
                                        ),
                                      )
                                    : null}
                                </ul>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryList;

// 카테고리 이름별 박스 레이아웃(수정가능한 textArea도 포함 옵션제공) ----------
const clickPrevent = (valueArr) => {
  // 수정 중 클릭 방지용
  return Object.values(valueArr).includes(true);
};

const CategoryNameBox = ({
  // 네임박스 메인 컴포넌트
  editBoxStateValue = false,
  onClickEditInputToggle,
  children,
  plus = true,
  minus = true,
  textAreaPlaceholder,
  boxRef,
  idx,
  onClickEditConfirm,
  onClickAddCategory,
  onClickRemoveCategory,
  onChangeTextArea,
  valueTextArea,
}) => {
  const handleKeyUp = (e) => {
    if (e.key === 'Enter') return onClickEditConfirm();
  };

  return (
    <WholeContainer>
      <BtnBox plus={plus} minus={minus}>
        {plus && editBoxStateValue[idx] !== true && (
          <BtnHover>
            <AiOutlinePlusSquare
              style={{ cursor: 'pointer' }}
              onClick={clickPrevent(editBoxStateValue) ? null : onClickAddCategory}
            />
          </BtnHover>
        )}
        {minus && editBoxStateValue[idx] !== true && (
          <BtnHover>
            <AiOutlineMinusSquare
              style={{ cursor: 'pointer' }}
              onClick={clickPrevent(editBoxStateValue) ? null : onClickRemoveCategory}
            />
          </BtnHover>
        )}
        {editBoxStateValue[idx] && (
          <BtnHover>
            <AiOutlineCheckSquare style={{ cursor: 'pointer' }} onClick={onClickEditConfirm} />
          </BtnHover>
        )}
        {editBoxStateValue[idx] && (
          <BtnHover>
            <AiOutlineCloseSquare style={{ cursor: 'pointer' }} onClick={onClickEditInputToggle} />
          </BtnHover>
        )}
      </BtnBox>
      <Categories_box
        ref={boxRef}
        onClick={clickPrevent(editBoxStateValue) ? null : onClickEditInputToggle}
        editBoxStateValue={editBoxStateValue}
        idx={idx}
      >
        {editBoxStateValue[idx] ? (
          <CategoryEditInput
            onKeyUp={handleKeyUp}
            placeholder={textAreaPlaceholder}
            onChange={onChangeTextArea}
            value={valueTextArea}
          />
        ) : (
          <CategoryName>{children}</CategoryName>
        )}
      </Categories_box>
    </WholeContainer>
  );
};

const WholeContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  z-index: 1;
`;

const BtnBox = styled.div`
  position: absolute;
  box-sizing: border-box;
  padding: 0px 10px;
  width: 100%;
  top: 5px;
  display: flex;
  justify-content: ${({ plus, minus }) =>
    (!plus && minus) || (plus && !minus) ? 'right' : 'space-between'};
  font-size: 25px;
  z-index: 5;
`;

const BtnHover = styled.span`
  display: inline-block;
  position: relative;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    opacity: 0.3;
  }
`;

const Categories_box = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  min-height: 50px;
  height: fit-content;
  padding: 35px 20px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.1);
  ${({ editBoxStateValue, idx }) => (editBoxStateValue[idx] ? '' : 'cursor: pointer;')}
  ${({ editBoxStateValue, idx }) =>
    editBoxStateValue[idx]
      ? ''
      : `&:hover {
    text-decoration: underline;
    opacity: 0.5;
  };`}
  ${({ editBoxStateValue, idx }) =>
    editBoxStateValue[idx]
      ? ''
      : `&:active {
    opacity: 0.2;
  }`}
  z-index: 3;
`;

const CategoryName = styled.p`
  font-size: 18px;
  word-break: break-all;
  line-height: 1.5;
`;

const CategoryEditInput = styled.textarea`
  height: 49px !important;
  min-height: 49px !important;
  box-sizing: border-box;
  padding: 5px 10px !important;
  word-break: break-all;
  resize: none;
  ::placeholder {
    font-size: 15px;
  }
`;
// ------------------------
