import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/FindId.module.scss';
import { colorList } from '../../../assets/style/theme.jsx';
import BtnNormal from '../../../components/BtnNormal.jsx';
import InputBox from '../../../components/InputBox.jsx';
import { usePopup } from '../../../components/popup/usePopup.js';
import { TIME } from '../../../constant/NUMBER_CONSTANT.js';
import { isRegexCheck, REGEX } from '../../../constant/REGEX.js';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';

export default function FindID() {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      openPopup({ title: '안내', content: '이미 로그인 중 입니다.' });
      navigate('/');
      return;
    } else {
      localStorage.clear();
    }
  }, [isLoggedIn]);

  // 사용자 정보입력
  const [memberName, setMemberName] = useState('');
  const [phoneNum, setPhoneNum] = useState('');

  // 인증번호 입력 모드를 위한 훅
  const [onAuthTime, setOnAuthTime] = useState(false);

  // 카운트 다운 값
  const [time, setTime] = useState(moment.duration(TIME.SET_TIME, 'minutes'));

  // 인증번호 받기
  const [authNum, setAuthNum] = useState('');

  // 인증 성공 후 찾은 아이디 저장
  const [findId, setFindId] = useState('');

  // 인증번호 성공시 성공페이지로 전환
  const [isSuccess, setIsSuccess] = useState(false);

  const { mutate: onSendMessage } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.findMemberSendMessage,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;

      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: (_, variable) => {
      if (variable.type) {
        setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
        openPopup({
          title: '인증번호 재전송 완료',
          content: '새로운 인증번호로 다시 입력해주세요.',
        });
        setAuthNum('');
        setOnAuthTime(true);
        return;
      }

      setOnAuthTime(true);
    },
  });

  // 인증번호 문자 요청 API
  const phoneAuthApiHandler = useCallback(
    (e, type) => {
      e.preventDefault();
      if (!memberName) return openPopup({ title: '안내', content: '이름을 입력해주세요.' });

      onSendMessage({
        apiBody: {
          member_name: memberName,
          phone_number: phoneNum,
        },
        type,
      });
    },
    [memberName, phoneNum],
  );

  const { mutate: verifyMemberWithCode } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.verifyMemberWithCode,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: (response) => {
      setFindId(response.data.member_id);
      setOnAuthTime(false);
      setAuthNum('');
      setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
      setIsSuccess(true);
    },
  });

  // 전화번호 최종 인증 API요청
  const finalAuthApiHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (!authNum) return openPopup({ title: '안내', content: '인증번호를 입력해주세요.' });

      const data = {
        member_name: memberName,
        phone_number: phoneNum,
        verify_code: authNum,
      };

      verifyMemberWithCode({
        apiBody: data,
      });
    },
    [authNum],
  );

  // 카운트다운 컨트롤 훅
  useEffect(() => {
    if (!onAuthTime) return;

    const interval = setInterval(() => {
      setTime((pre) => {
        const newTime = pre.clone().subtract(1, 'second');
        if (newTime.asSeconds() <= 0) {
          clearInterval(interval);
          setOnAuthTime(false);
          setAuthNum('');
          return moment.duration(TIME.SET_TIME, 'minutes');
        }
        return newTime;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [onAuthTime]);

  // 인풋 핸들러 모음 (input태그는 정규식 함수 적용)
  const inputNameHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyTextCheck_Kor_Eng,
      fn: (value) => setMemberName(value),
    });
  }, []);

  const inputPhoneHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyNumCheck,
      fn: (value) => setPhoneNum(value),
      checkLengthOpt: { length: 11, comparison: '<=' },
    });
  }, []);

  const inputAuthHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyNumCheck,
      fn: (value) => setAuthNum(value),
      checkLengthOpt: { length: 6, comparison: '<=' },
    });
  }, []);

  if (!isSuccess)
    return (
      <div className={style.login_container}>
        <div className={style.login_box}>
          <h1 className={style.login_h1}>아이디 찾기</h1>
          <form>
            <InputBox
              htmlFor="nameInput"
              inputValue={memberName}
              placeholder="이름을 입력해주세요"
              onChangeEvent={inputNameHandler}
              inputDisabled={onAuthTime}
            >
              이름
            </InputBox>
            <InputBox
              inputValue={phoneNum}
              htmlFor="phonNumber"
              placeholder="'-'를 제외하고 휴대폰 번호를 입력해주세요"
              onChangeEvent={inputPhoneHandler}
              inputDisabled={onAuthTime}
            >
              휴대폰 번호
            </InputBox>
            {onAuthTime && (
              <>
                <div className={style.authContainer}>
                  <div className={style.authBoxWrap}>
                    {/* 카운트다운 영역 */}
                    <p className={style.countDown}>
                      {moment.utc(time.asMilliseconds()).format('mm:ss')}
                    </p>
                    <InputBox
                      inputValue={authNum}
                      htmlFor="auth"
                      placeholder="인증번호 6자리"
                      onChangeEvent={inputAuthHandler}
                    >
                      인증번호
                    </InputBox>
                  </div>
                  <BtnNormal
                    flex="2"
                    fontSize="15px"
                    fontWeight="500"
                    bgColor={colorList.darkBlue}
                    color={colorList.white}
                    padding="15px 5px"
                    className={style.resend_btn}
                    onClickEvent={(e) => phoneAuthApiHandler(e, true)}
                  >
                    재전송
                  </BtnNormal>
                </div>
                <p className={style.auth_subtitle}>※ 인증번호를 입력해주세요.</p>
              </>
            )}
            <div className={style.btn_box}>
              {!onAuthTime ? (
                <BtnNormal
                  buttonType="submit"
                  bgColor={phoneNum.length >= 10 ? colorList.main_dark : colorList.gray}
                  fontSize="16px"
                  padding="15px 0px"
                  disabled={phoneNum.length < 10}
                  onClickEvent={(e) => phoneAuthApiHandler(e)}
                >
                  인증번호 받기
                </BtnNormal>
              ) : (
                <BtnNormal
                  buttonType="submit"
                  fontSize="16px"
                  padding="15px 0px"
                  bgColor={authNum.length >= 6 ? colorList.main_dark : colorList.gray}
                  onClickEvent={finalAuthApiHandler}
                >
                  인증하기
                </BtnNormal>
              )}
            </div>
          </form>
        </div>
      </div>
    );

  if (isSuccess)
    return (
      <div className={style.login_container}>
        <div className={style.success_box}>
          <h1 className={style.success_h1}>고객님의 아이디를 찾았습니다.</h1>
          <h3 className={style.success_h3}>아이디 확인 후 로그인해 주세요.</h3>
          <div className={style.information_box}>
            <p className={style.id_info}>
              <span>ID:</span> &nbsp; {findId}
            </p>
          </div>
          <div className={style.btn_box}>
            <BtnNormal
              fontSize="16px"
              padding="15px 0px"
              color={colorList.main_dark}
              bgColor="white"
              border="1px solid"
              onClickEvent={() => navigate('/pages/member/find_pw')}
            >
              비밀번호 찾기
            </BtnNormal>
            <BtnNormal
              bgColor={colorList.main_dark}
              fontSize="16px"
              padding="15px 0px"
              onClickEvent={() => navigate('/pages/member/login')}
            >
              로그인
            </BtnNormal>
          </div>
        </div>
      </div>
    );
}
