import { useEffect, useRef, useState } from 'react';

const useScrollObserver = ({ options, isOnlyTop = true }) => {
  const [isInView, setIsInView] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    if (!elementRef.current) return;

    const observer = new IntersectionObserver(([entry]) => {
      const { boundingClientRect, isIntersecting, rootBounds } = entry;
      if (isOnlyTop) {
        // 스크롤이 아래로 갈때만 작동
        isIntersecting || (!isIntersecting && boundingClientRect.top < rootBounds.top)
          ? setIsInView(true)
          : setIsInView(false);
      } else {
        setIsInView(isIntersecting);
      }
    }, options);

    // 관찰설정
    observer.observe(elementRef.current);

    // 클린업
    return () => {
      observer.disconnect();
    };
  }, [options]);

  return { isInView, elementRef };
};

export default useScrollObserver;
