import React from 'react';
import styled from 'styled-components';

import plusIcon from '../assets/img/icon/icon-add.svg';
import minusIcon from '../assets/img/icon/icon-remove.svg';
import { colorList } from '../assets/style/theme';
import InputBox from './InputBox';
import SelectCustom from './SelectCustom';
import ellipsisTextString from './textLimit/ellipsisTextString';

const Layout = styled.div`
  position: fixed;
  width: 100vw;
  height: fit-content;
  bottom: 0;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid ${colorList.lightGray};
  background-color: ${colorList.white};
  border-radius: 20px 20px 0 0;
  transform: ${({ scrollPoint }) => (scrollPoint ? 'translateY(0%)' : 'translateY(100%)')};
  transition: transform 0.5s ease;
  z-index: 20;
`;

const BotInfoContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
`;

const TotalBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  box-sizing: border-box;
  border-bottom: 0.5px solid ${colorList.lightGray};
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  margin-bottom: 10px;
  gap: 10px;
`;

const TotalText = styled.p`
  font-size: ${({ totalFontSize }) => totalFontSize};
  text-align: center;
  margin-bottom: 5px;
  line-height: 1.2;
  span {
    font-size: ${({ totalFontSize }) => `${Number(totalFontSize?.replace('px', '')) - 6}px`};
  }
`;

const TotalPriceWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TotalPice = styled.p`
  text-align: center;
  font-size: ${({ totalPriceSize }) => totalPriceSize};
  color: ${({ isSoldOut }) => (!isSoldOut ? '#b70000' : colorList.darkGray)};
  word-break: break-all;
  line-height: 1.2;
  margin-bottom: 5px;
  text-decoration: ${({ isSoldOut }) => (!isSoldOut ? 'none' : 'line-through')};
`;

const TotalPriceWon = styled.span`
  color: ${colorList.dark};
  font-size: ${({ totalPriceSize }) => `${Number(totalPriceSize?.replace('px', '')) - 8}px`};
`;

const TitleBox = styled.div`
  flex: ${({ titleBoxFlex }) => titleBoxFlex};
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: #f8f7f6;
  border-radius: 10px;
`;

const ProductName = styled.p`
  font-size: ${({ productNameFontSize }) => productNameFontSize};
  text-align: center;
  line-height: 1.3;
  word-break: break-all;
  margin-bottom: 10px;
`;

const NumBox = styled.div`
  position: relative;
  margin: auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ddd;
`;

const MinusBtn = styled.button`
  width: ${({ plusMinusBtnSize }) => plusMinusBtnSize};
  height: ${({ plusMinusBtnSize }) => plusMinusBtnSize};
  background-color: #fff;
  background-size: ${({ plusMinusBtnSize }) =>
    `${Number(plusMinusBtnSize?.replace('px', '')) - 10}px`};
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${minusIcon});
`;

const PlusBtn = styled.button`
  width: ${({ plusMinusBtnSize }) => plusMinusBtnSize};
  height: ${({ plusMinusBtnSize }) => plusMinusBtnSize};
  background-color: #fff;
  background-size: ${({ plusMinusBtnSize }) =>
    `${Number(plusMinusBtnSize?.replace('px', '')) - 10}px`};
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${plusIcon});
`;

const CountInput = styled.input`
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  width: ${({ numInputWidth }) => numInputWidth};
  height: 32px;
  text-align: center;
  padding: ${({ numInputPadding }) => numInputPadding};
`;

const IndiPrice = styled.p`
  text-align: center;
  font-size: 15px;
  margin-bottom: 10px;
`;

const OrderBox = styled.div`
  flex: ${({ orderBoxFlex }) => orderBoxFlex};
  position: relative;
  width: 100%;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(-5px, -50%);
    left: 0;
    width: 1px;
    height: 100px;
    background-color: ${colorList.lightGray};
  }
`;

const RadioBox = styled.div`
  position: relative;
  width: 100%;
`;

const BtnWrap = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

const BuyBtn = styled.button`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: ${({ btnFontSize }) => btnFontSize};
  margin: auto;
  margin-bottom: 5px;
  cursor: pointer;
`;

const CartBtn = styled.button`
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: ${({ btnFontSize }) => btnFontSize};
  width: 100%;
  padding: 10px;
  background-color: #2e4995;
  color: #fff;
  margin: auto;
  margin-bottom: 5px;
  cursor: pointer;
`;

const BottomMenuProductDetail = ({
  optionData,
  bottomMenuOptionValue,
  bottomOptionOnChange,
  productName,
  price,
  onClickMinus,
  onClickPlus,
  onChangeCount,
  onChangeValue,
  onClickBuy,
  onClickCart,
  isPayTypeRadioBox = false,
  payTypeRadioOnChange = null,
  payTypeRadioValue = '',
  isSoldOut,
  numInputWidth = '80px',
  plusMinusBtnSize = '30px',
  numInputPadding = '0 5px',
  scrollPoint = false,
  totalFontSize = '18px',
  totalPriceSize = '26px',
  titleBoxFlex = '8',
  orderBoxFlex = '2',
  btnFontSize = '15px',
  radioLabelFontSize,
  productNameFontSize = '16px',
  isLoggedIn,
}) => {
  return (
    <Layout scrollPoint={scrollPoint}>
      <TotalBox>
        <TotalText totalFontSize={totalFontSize}>
          총 합계 <span>(vat별도)</span>
        </TotalText>
        {isSoldOut && isLoggedIn && (
          <TotalPriceWrap>
            <TotalPice totalPriceSize={totalPriceSize} isSoldOut={true}>
              {(price * onChangeValue)?.toLocaleString('ko-KR')}
            </TotalPice>
            <TotalPriceWon totalPriceSize={totalPriceSize}>원</TotalPriceWon>
          </TotalPriceWrap>
        )}
        {!isSoldOut && isLoggedIn && (
          <TotalPriceWrap>
            <TotalPice totalPriceSize={totalPriceSize}>
              {(price * onChangeValue)?.toLocaleString('ko-KR')}
            </TotalPice>
            <TotalPriceWon totalPriceSize={totalPriceSize}>원</TotalPriceWon>
          </TotalPriceWrap>
        )}
      </TotalBox>
      <BotInfoContainer>
        <TitleBox titleBoxFlex={titleBoxFlex}>
          {isLoggedIn && (
            <div
              style={{
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>
                <span style={{ color: 'red' }}>*</span>옵션
              </p>
              <SelectCustom
                fontSize="14px"
                maxWidth="100%"
                height="25px"
                padding="0 10px"
                data={[
                  { label: '선택해 주세요.', value: 0 },
                  ...(optionData?.map((el) => ({
                    label: ellipsisTextString({ text: el.name, maxLength: 12 }),
                    value: el.product_option_idx,
                  })) || []),
                ]}
                value={bottomMenuOptionValue}
                onChangeEvent={bottomOptionOnChange}
              />
            </div>
          )}
          <ProductName productNameFontSize={productNameFontSize}>{productName}</ProductName>
          <IndiPrice>{isLoggedIn ? `${price?.toLocaleString('ko-KR')} 원` : null}</IndiPrice>
          {!isSoldOut && isLoggedIn && (
            <NumBox>
              <MinusBtn plusMinusBtnSize={plusMinusBtnSize} type="button" onClick={onClickMinus} />
              <CountInput
                numInputPadding={numInputPadding}
                numInputWidth={numInputWidth}
                type="number"
                onWheel={(e) => e.target.blur()}
                value={onChangeValue}
                onChange={onChangeCount}
              />
              <PlusBtn
                plusMinusBtnSize={plusMinusBtnSize}
                type="button"
                onClick={onClickPlus}
              ></PlusBtn>
            </NumBox>
          )}
        </TitleBox>

        <OrderBox orderBoxFlex={orderBoxFlex}>
          {isPayTypeRadioBox && !isSoldOut && isLoggedIn && (
            <RadioBox>
              <InputBox
                inputType="radio"
                htmlFor="payTypeTransferFloat"
                inputWidth="20px"
                inputValue="transfer"
                justifyContent="center"
                titleFontSize={radioLabelFontSize}
                containerMargin="0 0 10px 0"
                inputChecked={payTypeRadioValue === 'transfer'}
                onChangeEvent={(e) =>
                  payTypeRadioOnChange((cur) => (e.target.checked ? 'transfer' : ''))
                }
              >
                계좌이체
              </InputBox>
              <InputBox
                inputType="radio"
                htmlFor="payTypeCardFloat"
                inputWidth="20px"
                inputValue="card"
                justifyContent="center"
                titleFontSize={radioLabelFontSize}
                containerMargin="0 0 10px 0"
                inputChecked={payTypeRadioValue === 'card'}
                onChangeEvent={(e) =>
                  payTypeRadioOnChange((cur) => (e.target.checked ? 'card' : ''))
                }
              >
                카드결제
              </InputBox>
            </RadioBox>
          )}

          <BtnWrap>
            {isSoldOut && (
              <BuyBtn btnFontSize={btnFontSize} disabled={true} type="button">
                품절
              </BuyBtn>
            )}
            {!isSoldOut && (
              <BuyBtn btnFontSize={btnFontSize} type="button" onClick={onClickBuy}>
                바로구매
              </BuyBtn>
            )}
            <CartBtn btnFontSize={btnFontSize} type="button" onClick={onClickCart}>
              장바구니
            </CartBtn>
          </BtnWrap>
        </OrderBox>
      </BotInfoContainer>
    </Layout>
  );
};

export default React.memo(BottomMenuProductDetail);
