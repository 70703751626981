import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import { colorList } from '../../../assets/style/theme';
import BtnNormal from '../../../components/BtnNormal';
import EmptyDataPage from '../../../components/EmptyDataPage';
import ErrorFetching from '../../../components/error/ErrorFetching';
import SectionLayout from '../../../components/layout/SectionLayout';
import { usePopup } from '../../../components/popup/usePopup';
import SEOHelmet from '../../../components/seo/SEOHelmet';
import TextAreaCustom from '../../../components/TextAreaCustom';
import TextEditor_Quill_ReadOnly from '../../../components/TextEditor_Quill_ReadOnly';
import ellipsisTextString from '../../../components/textLimit/ellipsisTextString';
import { CACHE_TIME } from '../../../constant/NUMBER_CONSTANT';
import { BOARD_TYPE, MEMBER_ROLE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { decodingCrypto } from '../../../utils/crypto';
import { htmlTagRemove } from '../../../utils/htmlTagRemove';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';
import ValidateInput from '../../../utils/ValidateInput';

const CompanyDetail = () => {
  const navigate = useNavigate();

  const { idx } = useParams();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  // 최초 게시글 데이터 패칭
  const {
    data: { data: detailData },
    isError,
  } = useQueryInstance({
    queryKey: [queryKeys.getMember, idx],
    apiMethod: 'get',
    apiEndPoint: endPoint.member.getMember,
    // apiPathParams: idx,
  });

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <>
      <SEOHelmet
        title={`일빵 | 조합사 상세`}
        contentTitle="글 읽기"
        description="조합사정보"
        keywords="일빵, MRO, 조합, 조합사, 목록"
      />
      <SectionLayout maxWidth="800px" padding="40px 0px">
        <div>조합사 디테일 페이지</div>
      </SectionLayout>
    </>
  );
};

export default CompanyDetail;
