import React from 'react';
import styled from 'styled-components';

const MainLayoutStyle = styled.main`
  position: relative;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  padding: ${({ padding }) => padding};
  min-height: ${({ minHeight }) => minHeight};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const MainLayout = ({
  children,
  padding = '0px 0px',
  minHeight = '55vh',
  width = '100vw',
  height,
}) => {
  return (
    <MainLayoutStyle padding={padding} minHeight={minHeight} width={width} height={height}>
      {children}
    </MainLayoutStyle>
  );
};
export default React.memo(MainLayout);
