import { useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { BsCart, BsPersonGear } from 'react-icons/bs';
import { IoMdArrowDropdown } from 'react-icons/io';
import MediaQuery from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';

import '../../assets/font/style.css';
import '../../assets/css/Main.css';
import '../../assets/css/Basic.css';

import { endPoint } from '../../api/endPoint';
import style from '../../assets/css/Header.module.scss';
import mainImg from '../../assets/img/index/ilppang.png';
import { colorList } from '../../assets/style/theme';
import BtnNormal from '../../components/BtnNormal';
import MenuHamburger from '../../components/icon_svg/MenuHamburger';
import InputSearch from '../../components/InputSearch';
import NavLayout from '../../components/layout/NavLayout';
import { useMediaQueryCustom } from '../../components/mediaQueryCustom/useMediaQueryCustom';
import { usePopup } from '../../components/popup/usePopup';
import { useExternalClick } from '../../components/useExternalClick/useExternalClick';
import { useMobileCustom } from '../../components/useMobileCustom/useMobileCustom';
import ScrollObserverFadeIn from '../../components/useScrollObserver/ScrollObserverFadeIn';
import { CACHE_TIME } from '../../constant/NUMBER_CONSTANT';
import { MEMBER_ROLE } from '../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../react-query/useQueryInstance';
import { decodingCrypto } from '../../utils/crypto';
import { IsLoggedIn } from '../../utils/IsLoggedIn';

export const Header = ({ headerUpdated, setHeaderUpdated, data }) => {
  const queryClient = useQueryClient();

  const isLoggedIn = IsLoggedIn();

  const { openPopup } = usePopup();

  const navigate = useNavigate();

  // 반응형 BreakPoint 훅
  const { MEDIUM_SCREEN, MEDIUM_SMALL_SCREEN, SMALL_SCREEN, EXTRA_SMALL_SCREEN } =
    useMediaQueryCustom();

  // 모바일 환경 체크 훅
  const isMobile = useMobileCustom();

  // 장바구니 수량
  // 카트 상품 불러오기
  const {
    data: { total_count },
    isError,
  } = useQueryInstance({
    queryKey: [queryKeys.cartItems],
    apiMethod: 'get',
    apiEndPoint: endPoint.cart.getCartItemList,
    queryEnable: isLoggedIn,
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: 0,
  });

  // 검색핸들러
  const searchProductNameRef = useRef();

  const onSearchProductName = (e) => {
    e.preventDefault();
    {/* 
      description: 
      - 현 시점 기획으로 검색창에 아이템을 검색할 시 기존 로그인을 해야만 검색을 할 수 있는 정책에서
        비로그인 환경에서도 아이템을 검색할 수 있도록 변경
      - 코드의 완전 제거가 아닌 주석으로 한 이유는 정책이 바뀔 수 있는 여지가 있기 때문에 임시 주석으로 설정
      
      createdAt: 2024.08.19
      author: oereo
    */}

    // if (!isLoggedIn) {
    //   openPopup({
    //     title: '안내',
    //     content: '로그인을 해주세요.',
    //   });
    //   searchProductNameRef.current.value = '';
    //   navigate('/pages/member/login');
    //   return;
    // }

    const queryParams = new URLSearchParams();
    queryParams.set('search_product_name', searchProductNameRef.current.value);
    queryParams.set('page', 1);
    queryParams.set('count', 20);

    navigate(`/pages/product/list/?${queryParams.toString()}`);

    searchProductNameRef.current.value = '';
  };

  const memberRole = Number(decodingCrypto(localStorage.getItem('member_role')));

  var isAdmin = false;

  if (memberRole <= MEMBER_ROLE.MIN_ADMIN) {
    isAdmin = true;
  }

  const [activeSubCategories, setActiveSubCategories] = useState([]);

  const [isGnbBoxOpen, setIsGnbBoxOpen] = useState(false);
  const [activeDepth1Idx, setActiveDepth1Idx] = useState(null);
  const [activeDepth2Idx, setActiveDepth2Idx] = useState(null);
  const [activeDepth3Idx, setActiveDepth3Idx] = useState(null);

  const [activeSubSubCategories, setActiveSubSubCategories] = useState([]);

  // 처음 눌렀을때
  const toggleGnbBox = () => {
    setIsGnbBoxOpen(!isGnbBoxOpen);
    setActiveDepth1Idx(null);
    setActiveDepth2Idx(null);
    setActiveDepth3Idx(null);
  };

  // 1뎁스 아이템에 마우스 올렸을 때
  const handleGnb1DepthHover = (idx) => {
    const hoveredItem = data.data_list[idx];
    // child_category_list가 없으면 아무 것도 하지 않음
    if (hoveredItem && !hoveredItem.child_category_list) return;

    setActiveDepth1Idx(idx);
    setActiveDepth2Idx(null);
    setActiveDepth3Idx(null);
    // 해당 인덱스의 하위 카테고리를 설정
    if (hoveredItem && hoveredItem.child_category_list) {
      setActiveSubCategories(hoveredItem.child_category_list);
    } else {
      setActiveSubCategories([]);
    }
  };

  const handleGnb2DepthHover = (idx) => {
    const hoveredItem = activeSubCategories[idx];
    setActiveDepth2Idx(idx);
    setActiveDepth3Idx(null);
    // 2뎁스 아이템의 child_category_list가 없으면 3뎁스를 비움
    if (hoveredItem && !hoveredItem.child_category_list) {
      // setActiveDepth3Idx(null);
      setActiveSubSubCategories([]);
    } else {
      setActiveSubSubCategories(hoveredItem.child_category_list);
    }
  };

  const handleGnb3DepthHover = (idx) => {
    setActiveDepth3Idx(idx);
  };

  // 마우스 나가면 사라지게
  const handleGnbBoxMouseLeave = () => {
    setIsGnbBoxOpen(false);
    setActiveDepth1Idx(null);
    setActiveDepth2Idx(null);
    setActiveDepth3Idx(null);
  };

  const onLogout = async () => {
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.clear();
    const event = new Event('logout');
    window.dispatchEvent(event);
    queryClient.clear();
    window.location.reload();
    navigate('/');
  };

  const handleSearchByDepthIdx = (idx) => {
    const queryParams = new URLSearchParams();
    queryParams.set('search_category_idx', idx);
    queryParams.set('page', 1);
    queryParams.set('count', 20);
    navigate(`/pages/product/list/?${queryParams.toString()}`);
    setIsGnbBoxOpen(false);
  };

  // 반응형시 top 버거 메뉴
  const [reactBurger, setReactBurger] = useState(false);

  // top버거메뉴 회부화면 클릭시
  const reactBurgerRef = useRef(null);
  const reactBurgerMenuRef = useRef(null);
  useExternalClick([reactBurgerRef, reactBurgerMenuRef], () => setReactBurger(false));

  // 카테고리 외부화면 클릭
  const categoryMenuRef = useRef(null);
  const categoryMenuBtnRef = useRef(null);
  useExternalClick([categoryMenuRef, categoryMenuBtnRef], handleGnbBoxMouseLeave);

  return (
    <ScrollObserverFadeIn>
      <header className={style.header}>
        <div className={style.top_container}>
          <img
            className={style.main_logo}
            src={mainImg}
            onClick={() => navigate('/')}
            alt="대표로고"
          />
          <div className={style.right_box}>
            {!EXTRA_SMALL_SCREEN && (
              <InputSearch
                boxWidth={MEDIUM_SMALL_SCREEN ? '200px' : MEDIUM_SCREEN ? '250px' : '340px'}
                boxHeight={SMALL_SCREEN ? '30px' : MEDIUM_SMALL_SCREEN ? '35px' : undefined}
                inputRef={searchProductNameRef}
                iconOnClickEvent={onSearchProductName}
                placeholderFontSize={MEDIUM_SCREEN ? '14px' : undefined}
                iconSize={MEDIUM_SMALL_SCREEN ? '15' : '20'}
              />
            )}

            <MediaQuery minWidth={1029}>
              <div className={style.login_btn_box}>
                {!isLoggedIn ? (
                  <>
                    <BtnNormal
                      fontSize="15px"
                      borderRadius="4px"
                      bgColor={colorList.main}
                      activeOpacity="none"
                      onClickEvent={() => navigate('/pages/member/login')}
                    >
                      로그인
                    </BtnNormal>
                    <BtnNormal
                      fontSize="15px"
                      borderRadius="4px"
                      bgColor={colorList.green}
                      activeOpacity="none"
                      onClickEvent={() => navigate('/pages/member/join1')}
                    >
                      회원가입
                    </BtnNormal>
                  </>
                ) : (
                  <>
                    {isLoggedIn && isAdmin && (
                      <div className={style.icon_wrap} onClick={() => navigate('/admin/')}>
                        <BsPersonGear />
                      </div>
                    )}
                    <BtnNormal
                      fontSize="15px"
                      borderRadius="4px"
                      bgColor={colorList.main}
                      activeOpacity="none"
                      onClickEvent={() => navigate('/pages/member/mypage/order_list')}
                    >
                      마이페이지
                    </BtnNormal>
                    <div className={style.cart_btn_wrap}>
                      {total_count !== 0 && !isError && isLoggedIn && (
                        <div className={style.cart_count}>{total_count}</div>
                      )}
                      <BtnNormal
                        fontSize="15px"
                        borderRadius="4px"
                        bgColor={colorList.main}
                        activeOpacity="none"
                        onClickEvent={() => navigate('/pages/member/mypage/cart')}
                      >
                        장바구니
                      </BtnNormal>
                    </div>
                    <BtnNormal
                      fontSize="15px"
                      borderRadius="4px"
                      bgColor={colorList.green}
                      activeOpacity="none"
                      onClickEvent={onLogout}
                    >
                      로그아웃
                    </BtnNormal>
                  </>
                )}
              </div>
            </MediaQuery>

            <MediaQuery maxWidth={1030}>
              <div
                className={style.login_btn_box}
                style={{
                  gap: MEDIUM_SMALL_SCREEN ? '10px' : '15px',
                }}
              >
                {!isLoggedIn ? (
                  <>
                    <BtnNormal
                      fontSize={MEDIUM_SMALL_SCREEN ? '12px' : '15px'}
                      borderRadius="4px"
                      padding={MEDIUM_SMALL_SCREEN ? '10px' : undefined}
                      bgColor={colorList.main}
                      activeOpacity="none"
                      onClickEvent={() => navigate('/pages/member/login')}
                    >
                      로그인
                    </BtnNormal>
                    <BtnNormal
                      fontSize={MEDIUM_SMALL_SCREEN ? '12px' : '15px'}
                      borderRadius="4px"
                      padding={MEDIUM_SMALL_SCREEN ? '10px' : undefined}
                      bgColor={colorList.green}
                      activeOpacity="none"
                      onClickEvent={() => navigate('/pages/member/join1')}
                    >
                      회원가입
                    </BtnNormal>
                  </>
                ) : (
                  <>
                    <div className={style.cart_btn_wrap}>
                      {total_count !== 0 && !isError && isLoggedIn && (
                        <div className={style.cart_count}>{total_count}</div>
                      )}
                      <div
                        style={{ padding: '5px 15px' }}
                        className={style.icon_wrap}
                        onClick={() => navigate('/pages/member/mypage/cart')}
                      >
                        <BsCart />
                      </div>
                    </div>
                    <div
                      className={style.react_header_top_hamburger}
                      onClick={() => setReactBurger((cur) => !cur)}
                      ref={reactBurgerRef}
                    >
                      <MenuHamburger
                        color={colorList.main}
                        reverse={true}
                        size={SMALL_SCREEN ? '25' : undefined}
                      />
                      <IoMdArrowDropdown
                        color={colorList.main}
                        size="20"
                        style={{
                          transform: !reactBurger ? 'rotate(-180deg)' : 'rotate(0deg)',
                          transition: '0.5s ease-in-out',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </MediaQuery>
          </div>
        </div>

        {EXTRA_SMALL_SCREEN && (
          <div className={style.react_search_box_container}>
            <InputSearch
              conBackgroundColor="white"
              conZIndex="10"
              conPadding="10px 0"
              boxWidth="90vw"
              boxHeight="40px"
              conMargin="auto"
              inputRef={searchProductNameRef}
              iconOnClickEvent={onSearchProductName}
            />
          </div>
        )}

        <MediaQuery maxWidth={1030}>
          <ul
            ref={reactBurgerMenuRef}
            onMouseLeave={() => !isMobile && setReactBurger((cur) => false)}
            className={
              !reactBurger
                ? `${style.react_header_top_dropdown}`
                : `${style.react_header_top_dropdown} ${style.dropdown_active}`
            }
          >
            {isLoggedIn && isAdmin && (
              <li
                onClick={() => {
                  navigate('/admin/');
                  setReactBurger((cur) => false);
                }}
              >
                *관리자모드
              </li>
            )}
            <li
              onClick={() => {
                navigate('/pages/member/mypage/order_list');
                setReactBurger((cur) => false);
              }}
            >
              마이페이지
            </li>

            <li
              onClick={() => {
                onLogout();
                setReactBurger((cur) => false);
              }}
            >
              로그아웃
            </li>
          </ul>
        </MediaQuery>

        <nav className={style.nav_container}>
          <div className={style.nav_flex_wrap}>
            <div
              ref={categoryMenuBtnRef}
              className={style.category_menu_btn}
              onClick={toggleGnbBox}
            >
              <MenuHamburger
                size={EXTRA_SMALL_SCREEN ? '20' : MEDIUM_SMALL_SCREEN ? '25' : undefined}
              />
            </div>
            <ul className={style.nav_ul_box}>
              <li>
                <Link to="/pages/document/story">일빵이야기</Link>
              </li>
              <li onClick={() => openPopup({ title: '안내', content: '서비스 준비 중' })}>
                공동구매
              </li>
              <li onClick={() => openPopup({ title: '안내', content: '서비스 준비 중' })}>
                긴급구매
              </li>
              <li>
                <Link to="/pages/product/list">도매판매</Link>
              </li>
              <li onClick={() => openPopup({ title: '안내', content: '서비스 준비 중' })}>
                우리끼리 사고팔기
              </li>
            </ul>
          </div>
        </nav>

        {!isMobile ? (
          <div
            className={isGnbBoxOpen ? `${style.nav_box} ${style.active}` : `${style.nav_box}`}
            onMouseLeave={handleGnbBoxMouseLeave}
          >
            <NavLayout
              padding={SMALL_SCREEN ? '0px 10px' : '0px 50px'}
              display="flex"
              backgroundColor="white"
            >
              <div className={style.nav_1depth}>
                {data?.data_list?.map((item1, idx1) => (
                  <button
                    key={item1.category_idx}
                    type="button"
                    className={style.nav_1depth_item}
                    onMouseEnter={() => handleGnb1DepthHover(idx1)}
                    onClick={() => handleSearchByDepthIdx(item1.category_idx)}
                  >
                    <span
                      className={
                        activeDepth1Idx === idx1
                          ? `${style.title} ${style.hover_action}`
                          : `${style.title}`
                      }
                    >
                      {item1.name}
                    </span>
                    <span className={activeDepth1Idx === idx1 ? `${style.next_icon}` : ''}></span>
                  </button>
                ))}
              </div>
              <div
                className={
                  activeDepth1Idx !== null
                    ? `${style.nav_2depth} ${style.depth_active}`
                    : `${style.nav_2depth}`
                }
              >
                {activeSubCategories.map((item2, idx2) => (
                  <button
                    key={idx2}
                    type="button"
                    className={style.nav_2depth_item}
                    onMouseEnter={() => handleGnb2DepthHover(idx2)}
                    onClick={() => handleSearchByDepthIdx(item2.category_idx)}
                  >
                    <span
                      className={
                        activeDepth2Idx === idx2
                          ? `${style.title} ${style.hover_action}`
                          : `${style.title}`
                      }
                    >
                      {item2.name}
                    </span>
                    <span className={activeDepth2Idx === idx2 ? `${style.next_icon}` : ''}></span>
                  </button>
                ))}
              </div>
              <div
                className={
                  activeDepth1Idx !== null && activeDepth2Idx !== null
                    ? `${style.nav_3depth} ${style.depth_active}`
                    : `${style.nav_3depth}`
                }
              >
                {activeSubSubCategories.map((item3, idx3) => (
                  <button
                    key={idx3}
                    type="button"
                    className={style.nav_3depth_item}
                    onMouseEnter={() => handleGnb3DepthHover(idx3)}
                    onClick={() => handleSearchByDepthIdx(item3.category_idx)}
                  >
                    <span
                      className={
                        activeDepth3Idx === idx3
                          ? `${style.title} ${style.hover_action}`
                          : `${style.title}`
                      }
                    >
                      {item3.name}
                    </span>
                  </button>
                ))}
              </div>
            </NavLayout>
          </div>
        ) : (
          // 모바일 환경 카테고리
          // 모바일환경에서 mouse이벤트 바꿔야함
          <div
            ref={categoryMenuRef}
            className={isGnbBoxOpen ? `${style.nav_box} ${style.active}` : `${style.nav_box}`}
          >
            <NavLayout
              padding={SMALL_SCREEN ? '0px 10px' : '0px 50px'}
              display="flex"
              backgroundColor="white"
            >
              <div className={style.nav_1depth}>
                {data?.data_list?.map((item1, idx1) => (
                  <div key={item1.category_idx} className={style.nav_1depth_item}>
                    <div
                      className={style.title}
                      onTouchEnd={() => handleSearchByDepthIdx(item1.category_idx)}
                    >
                      {item1.name}
                    </div>
                    <div
                      className={style.next_icon}
                      onTouchEnd={() => handleGnb1DepthHover(idx1)}
                    ></div>
                  </div>
                ))}
              </div>
              <div
                className={
                  activeDepth1Idx !== null
                    ? `${style.nav_2depth} ${style.depth_active}`
                    : `${style.nav_2depth}`
                }
              >
                {activeSubCategories.map((item2, idx2) => (
                  <div key={idx2} className={style.nav_2depth_item}>
                    <div
                      className={style.title}
                      onTouchEnd={() => handleSearchByDepthIdx(item2.category_idx)}
                    >
                      {item2.name}
                    </div>
                    <div
                      className={style.next_icon}
                      onTouchEnd={() => handleGnb2DepthHover(idx2)}
                    ></div>
                  </div>
                ))}
              </div>
              <div
                className={
                  activeDepth1Idx !== null && activeDepth2Idx !== null
                    ? `${style.nav_3depth} ${style.depth_active}`
                    : `${style.nav_3depth}`
                }
              >
                {activeSubSubCategories.map((item3, idx3) => (
                  <div key={idx3} className={style.nav_3depth_item}>
                    <div
                      className={style.title}
                      onTouchEnd={() => handleSearchByDepthIdx(item3.category_idx)}
                    >
                      {item3.name}
                    </div>
                  </div>
                ))}
              </div>
            </NavLayout>
          </div>
        )}
      </header>
    </ScrollObserverFadeIn>
  );
};

export default Header;
