import React from 'react';
import styled from 'styled-components';

import { colorList } from '../../assets/style/theme';

const StarMark = ({
  color = '#F17823',
  text,
  size = '30%',
  fontSize = '16px',
  conPosition = 'relative',
  conTop,
  conBottom,
  conRight,
  conLeft,
  conZIndex,
}) => {
  return (
    <Container
      size={size}
      conPosition={conPosition}
      conTop={conTop}
      conBottom={conBottom}
      conRight={conRight}
      conLeft={conLeft}
      conZIndex={conZIndex}
    >
      {text && <Text fontSize={fontSize}>{text}</Text>}
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 60 60" fill="none">
        <path
          d="M30 0L35.2674 3.5188L41.4805 2.28361L45.0004 7.55032L51.2132 8.7868L52.4497 14.9996L57.7164 18.5195L56.4812 24.7326L60 30L56.4812 35.2674L57.7164 41.4805L52.4497 45.0004L51.2132 51.2132L45.0004 52.4497L41.4805 57.7164L35.2674 56.4812L30 60L24.7326 56.4812L18.5195 57.7164L14.9996 52.4497L8.7868 51.2132L7.55032 45.0004L2.28361 41.4805L3.5188 35.2674L0 30L3.5188 24.7326L2.28361 18.5195L7.55032 14.9996L8.7868 8.7868L14.9996 7.55032L18.5195 2.28361L24.7326 3.5188L30 0Z"
          fill={color}
        />
      </svg>
    </Container>
  );
};

export default React.memo(StarMark);

const Container = styled.div`
  position: ${({ conPosition }) => conPosition};
  top: ${({ conTop }) => conTop};
  bottom: ${({ conBottom }) => conBottom};
  right: ${({ conRight }) => conRight};
  left: ${({ conLeft }) => conLeft};
  width: ${({ size }) => size};
  z-index: ${({ conZIndex }) => conZIndex};
`;

const Text = styled.p`
  position: absolute;
  color: ${colorList.white};
  text-align: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 500;
`;
