import React from 'react';
import styled from 'styled-components';

import { active, pointer } from '../assets/style/pointer';
import { useMobileCustom } from './useMobileCustom/useMobileCustom';

// style
const Button = styled.button`
  box-sizing: border-box;
  flex: ${({ flex }) => flex};
  background-color: ${({ bgColor }) => bgColor};
  padding: ${({ padding }) => padding};
  border-radius: ${({ borderRadius }) => borderRadius};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  border: ${({ border }) => border};
  margin: ${({ margin }) => margin};
  ${({ isMobile, activeOpacity }) =>
    !isMobile && `&: active{ opacity: ${activeOpacity}; transition: 0.1s;}`};
`;

const BtnNormal = ({
  children,
  bgColor = '#00356a',
  padding = '10px 20px',
  borderRadius = '10px',
  color = 'white',
  fontSize = '18px',
  className,
  onClickEvent,
  fontWeight = '700',
  flex = 'none',
  disabled = false,
  border = 'none',
  buttonType = 'button',
  margin,
  activeOpacity = '1',
}) => {
  const isMobile = useMobileCustom();

  return (
    <Button
      isMobile={isMobile}
      className={className}
      bgColor={bgColor}
      padding={padding}
      borderRadius={borderRadius}
      color={color}
      fontSize={fontSize}
      onClick={onClickEvent}
      fontWeight={fontWeight}
      flex={flex}
      disabled={disabled}
      border={border}
      type={buttonType}
      margin={margin}
      activeOpacity={activeOpacity}
    >
      {children}
    </Button>
  );
};

export default React.memo(BtnNormal);
