import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { endPoint, endPointMutation } from '../../../api/endPoint.js';
import BtnNormal from '../../../components/BtnNormal.jsx';
import EmptyDataPage from '../../../components/EmptyDataPage.jsx';
import ErrorFetching from '../../../components/error/ErrorFetching.jsx';
import Grid from '../../../components/Grid.jsx';
import HeaderTitle from '../../../components/HeaderTitle.jsx';
import SectionLayout from '../../../components/layout/SectionLayout.jsx';
import Pagination from '../../../components/Pagination.jsx';
import { usePopup } from '../../../components/popup/usePopup.js';
import { MEMBER_ROLE } from '../../../constant/TYPE_CONSTANT.js';
import { queryKeys } from '../../../react-query/queryKeyConstant.js';
import { useMutationInstance } from '../../../react-query/useMutationInstance.js';
import { useQueryInstance } from '../../../react-query/useQueryInstance.js';
import { decodingCrypto } from '../../../utils/crypto.js';
import { IsLoggedIn } from '../../../utils/IsLoggedIn.js';

const labelData = [
  { label: '순번', labelKey: 'row_number', flex: 0.2 },
  { label: '아이디', labelKey: 'member_id', flex: 1 },
  { label: '이름', labelKey: 'name', flex: 0.5 },
  { label: '회사명', labelKey: 'company_name', flex: 1 },
  { label: '휴대폰', labelKey: 'phone_number', flex: 1 },
  { label: '최근 접속 일시', labelKey: 'last_login_time', flex: 1, time: true },
  { label: '회원등급', labelKey: 'member_role', flex: 1 },
];

const selectorData = [
  {
    index: 6,
    values: [
      { label: '일반회원(준 조합원)', value: MEMBER_ROLE.NORMAL, disable: false },
      { label: '조합 회원 (정 조합원)', value: MEMBER_ROLE.REGULAR, disable: false },
    ],
  },
];

const MemberList = () => {
  const { openPopup } = usePopup();

  const navigate = useNavigate();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const memberRole = Number(decodingCrypto(localStorage.getItem('member_role')));

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');
  // 다시 설정
  const search_member_id = params.get('search_member_id');
  const search_company_name = params.get('search_company_name');
  const search_name = params.get('search_name');
  const search_business_number = params.get('search_business_number');

  const {
    data: { data_list, total_count },
    isError,
    refetch,
  } = useQueryInstance({
    queryKey: [
      queryKeys.memberList_admin,
      page,
      count,
      search_member_id,
      search_company_name,
      search_name,
      search_business_number,
    ],
    apiMethod: 'get',
    apiEndPoint: endPoint.member.getMemberList,
    apiQueryParams: {
      page,
      count,
      search_member_id,
      search_company_name,
      search_name,
      search_business_number,
    },
  });

  // 검색 후 처음으로 돌아가기
  const searchReset = useCallback(() => {
    navigate('/admin/member/list');
  }, []);

  // 검색창 타입 셀렉터
  const [searchType, setSearchType] = useState('0');

  // 검색어 담기
  const searchValue = useRef('');

  // 검색창 핸들
  const searchHandler = (e) => {
    e.preventDefault();

    const value = searchValue.current.value;

    if (!value) return navigate('/admin/member/list');

    navigate(
      `/admin/member/list/?page=1&count=20${
        searchType === '0'
          ? `&search_member_id=${value}`
          : searchType === '1'
          ? `&search_company_name=${value}`
          : searchType === '2'
          ? `&search_name=${value}`
          : `&search_business_number=${value}`
      }`,
    );

    searchValue.current.value = '';
  };

  const { mutate: updateMemberRole } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.updateMemberRole,
    onSuccessFn: () => {
      refetch();
    },
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;

      openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
  });

  const inputHandler = useCallback(
    (e, index, _) => {
      const memberIdx = data_list[index].idx;
      const changeMemberRole = Number(e.target.value);

      updateMemberRole({
        apiQueryParams: {
          member_idx: memberIdx,
          member_role: changeMemberRole,
        },
      });
    },
    [data_list],
  );

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout maxWidth="100%" padding="50px">
      <HeaderTitle
        searchAndFilterMode={true}
        searchParamsValueArr={[
          search_member_id,
          search_company_name,
          search_name,
          search_business_number,
        ]}
        searchAndFilterLabelArr={{
          searchTypeLabel: [
            { label: '아이디', value: '0' },
            { label: '회사명', value: '1' },
            { label: '이름', value: '2' },
            { label: '사업자번호', value: '3' },
          ],
        }}
        onClickEventSearchReset={searchReset}
        searchAndFilterInputRef={searchValue}
        searchAndFilterTypeSelectorValue={searchType}
        onChangeEventSearchAndFilterTypeSelector={(e) => setSearchType(e.target.value)}
        onClickEventSearchAndFilterInput={searchHandler}
      >
        회원 리스트
      </HeaderTitle>
      {data_list?.length === 0 ? (
        <EmptyDataPage title="회원정보가 없습니다." padding="150px 20px" />
      ) : (
        <>
          {memberRole === MEMBER_ROLE.ADMIN && (
            <HeaderBtnBox>
              <BtnNormal
                fontSize="18px"
                fontWeight="500"
                onClickEvent={() => navigate('join_admin')}
              >
                회원등록
              </BtnNormal>
            </HeaderBtnBox>
          )}

          <Grid
            labelData={labelData}
            data={data_list}
            selectorEdit={true}
            selectorOnChangeEvent={inputHandler}
            selectorOptArr={selectorData}
            selectValue={data_list}
            navigateUrl="/admin/member/list/detail"
            idxName="idx"
          />
          <Pagination
            conMargin="50px 0"
            totalCount={total_count}
            page={page}
            count={count}
            naviPath={`/admin/member/list/?${
              search_member_id ? `search_member_id=${search_member_id}&` : ''
            }${search_company_name ? `search_company_name=${search_company_name}&` : ''}${
              search_name ? `search_name=${search_name}&` : ''
            }${search_business_number ? `search_name=${search_name}&` : ''}`}
          />
        </>
      )}
    </SectionLayout>
  );
};

export default MemberList;

const HeaderBtnBox = styled.div`
  position: relative;
  margin: 10px 4%;
  text-align: right;
`;
