import React from 'react';
import DaumPostcode from 'react-daum-postcode';

const PROXY =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000'
    : window.location.hostname === 'www.ilppang.com'
    ? 'https://www.ilppang.com'
    : 'https://www.ilppang.kr';
const URL = `${PROXY}`;

function Postcode() {
  const handleAddress = (data) => {
    // todo 보안 상의 이유로, 실제 운영 환경에서는 postMessage의
    //  두 번째 인자로 특정 도메인을 지정하는 것이 좋습니다.
    //  위의 예제에서는 어느 도메인에서든 메시지를 받을 수 있도록 '*'을 사용했습니다.

    window.opener.postMessage(data, URL);
    window.close();
  };

  return <DaumPostcode onComplete={handleAddress} />;
}

export default Postcode;
