export const endPoint = {
  category: {
    //get: 카테고리 모두 불러오기
    getProductCategoryList: '/api/category/getProductCategoryList',
  },
  product: {
    // get: 모든 상품 불러오기
    // 쿼리 params: {
    //   search_product_name
    //   search_category_idx
    //   page
    //   count
    //   my_product_list: 1 (마이페이지용)
    // }
    // ** response.data.data_list;
    getProductList: '/api/product/getProductList',
    // get: 개별 상품 정보 idx값으로 불러오기 => 2군데 사용, 상품디테일 / 상품수정
    // pathParams: idx
    // ** response.data.data
    getProduct: '/api/product/getProduct',
  },
  cart: {
    // post: 카트 아이템 모두 불러오기
    // ** response.data.data_list;
    getCartItemList: '/api/cart/getCartItemList',
  },
  member: {
    // post: 모든 회원 정보 불러오기
    // body: null
    // params: null
    getMemberList: '/api/member/getMemberList',
    // post: 개별회원
    // ** response.data.data;
    getMember: '/api/member/getMember',
    getCompanyList: '/api/member/getCompanyList',
  },
  order: {
    // post 모든 주문내역 불러오기
    // 1. 내가 주문한 내역 불러오기
    // body: null
    // params: {
    //   my_order_list: 1,
    // },
    // 2. 모든 회원 결제내역 상품 불러오기
    // body: null
    // params:null
    // ** response.data.data_list
    getOrderList: '/api/order/getOrderList',
    getOrderItem: '/api/order/getOrderItem',
  },
  sales: {
    //*
    // post: 내가 판매한 내역 불러오기
    // body: null
    // params: {
    //   my_sales_list: 1,
    //   page,
    //   count,
    // },
    // ** response.data.data_list;
    getSalesList: '/api/sales/getSalesList',
    getSalesItem: '/api/sales/getSalesItem',
  },
  board: {
    getBoard: '/api/board/getBoard',
    getBoardList: '/api/board/getBoardList',
  },
  chat: {
    getChatList: '/api/chat/getChatMessageList',
    getChatRoomList: '/api/chat/getChatRoomList',
    getEstimateProductList: '/api/chat/getEstimateProductList',
    getChatRoom: '/api/chat/getChatRoom',
  },
};

// ---------------------------------------

export const endPointMutation = {
  board: {
    saveBoard: '/api/board/saveBoard',
    saveComment: '/api/board/saveComment',
    removeComment: '/api/board/removeComment',
    removeBoard: '/api/board/removeBoard',
  },
  quill: {
    // post: quill에디터 이미지 삭제
    // body: []로 보냄
    removeUploadFile: 'api/upload/removeUploadFile',
    // post: quill에디터 이미지 업로드
    // body: formdata
    // multipartOK
    uploadFile: 'api/upload/uploadFile',
  },
  order: {
    addOrder: '/api/order/addOrder',
    // post: 주문취소
    // body
    orderCancel: '/api/order/orderCancel',
    checkBeforeOrder: '/api/order/checkBeforeOrder',
    // post: 모든 회원 결제내역 입금완료
    // body
    orderComplete: '/api/order/orderComplete',
    updateOrderItemStatus: '/api/order/updateOrderItemStatus',
  },
  cart: {
    saveCart: '/api/cart/saveCart',
    // post: 카트 물품 삭제
    // body: 없음
    // params: { cart_item_idx: parseInt(cartItemIdx) }
    removeCartItem: '/api/cart/removeCartItem',
    // post: 수량 추가
    // body: 없음
    // params  { cart_item_idx: parseInt(cartItemIdx), count: parseInt(count) }
    updateCartItemCount: '/api/cart/updateCartItemCount',
  },
  category: {
    // post: 신규 카테고리 추가 및 기존 카테고리 수정 (하나로 통일)
    // body: {name, category_idx, parent_idx,}
    saveProductCategory: '/api/category/saveProductCategory',
    // post: 카테고리삭제
    // body: productCategoryIdx
    // **200번으로 2개가 옴  true 삭제완료, false 삭제 불가(상품있음)
    removeProductCategory: '/api/category/removeProductCategory',
  },
  product: {
    removeProduct: '/api/product/removeProduct',
    // post: 상품등록
    // body: formData
    // header: multipart true
    saveProduct: '/api/product/saveProduct',
    // post: 상품수정
    // body: formData
    // header: multipart true
    editProduct: '/api/product/editProduct',
  },
  auth: { login: '/api/member/login' },
  member: {
    // post: 회원가입
    // body: formData
    // header: mulitpart true
    join: '/api/member/join',
    // 관리자가 임시 회원 등록 api
    createMember: '/api/member/createMember',
    // post: 회원정보 수정
    // body: data
    editMemberInfo: '/api/member/editMemberInfo',
    memberInfoCheck: '/api/member/memberInfoCheck',
    findMemberSendMessage: '/api/member/findMemberSendMessage',
    verifyMemberWithCode: '/api/member/verifyMemberWithCode',
    changePassword: '/api/member/changePassword',
    updateMemberRole: '/api/member/updateMemberRole',
  },
  sales: {
    saveShippingNumber: '/api/sales/saveShippingNumber',
  },
  send: {
    sendVerifyPhoneNumber: '/api/send/sendVerifyPhoneNumber',
    verifyPhoneNumberWithCode: 'api/send/verifyPhoneNumberWithCode',
  },
  chat: {
    createRoom: '/api/chat/createChatRoom',
    validateEstimateOrder: '/api/chat/validateEstimateOrder',
    validateEstimate: '/api/chat/validateEstimate',
    removeEstimate: '/api/chat/removeEstimate',
  },
};
