import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import '../../assets/css/Popup.css';

import { usePopup } from './usePopup';

function Popup() {
  const { popup, closePopup } = usePopup();

  const {
    show,
    title,
    content,
    onComplete,
    twoButton,
    input,
    inputMaxLength,
    selectEtcInput,
    select,
    setInputValue,
    setSelectValue,
    confirmButtonText,
    cancelButtonText,
    closeButtonText,
  } = popup;

  const [localInputValue, setLocalInputValue] = useState('');

  const [localSelectValue, setLocalSelectValue] = useState('');

  useEffect(() => {
    if (select) {
      setLocalSelectValue(select.length > 0 ? select[0].value : '');
    }
  }, [select]);

  const onKeyUpHandler = (e) => {
    if (e.keyCode === 13) return handleConfirm();
  };

  const handleInputChange = (e) => {
    setLocalInputValue(e.target.value);
  };

  const handleSelectChange = (e) => {
    setLocalSelectValue(e.target.value);
  };

  const handleConfirm = useCallback(
    _.throttle(async () => {
      if (setInputValue) {
        await setInputValue(localInputValue);
      }

      if (setSelectValue) {
        // value 1개만 들어오므로 기타인지만 체크
        // 기타는 인풋을 통해 직접 택배사 입력 가능
        const selectName =
          localSelectValue === '기타' && selectEtcInput ? localInputValue : localSelectValue;
        // value키 값만 보냄
        await setSelectValue(selectName);
      }

      if (onComplete) {
        await onComplete();
        closePopup();
      }
    }, 1000),
    [
      localInputValue,
      localSelectValue,
      setInputValue,
      setSelectValue,
      onComplete,
      select,
      selectEtcInput,
    ],
  );

  const onClosePopup = () => {
    if (onComplete) {
      onComplete();
    }
    closePopup();
  };

  // throttle 타이머 언마운트시 삭제
  useEffect(() => {
    return () => {
      handleConfirm.cancel();
    };
  }, [handleConfirm]);

  useEffect(() => {
    if (setInputValue) {
      setLocalInputValue('');
    }
  }, [setInputValue]);

  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-view">
        <div className="popup-title">{title}</div>
        <div className="popup-content">{content}</div>
        {select && select?.length > 0 ? (
          <select
            onChange={handleSelectChange}
            onKeyUp={onKeyUpHandler}
            defaultChecked={localSelectValue}
          >
            {select.map((el, index) => (
              <option key={index} value={el.value}>
                {el.value}
              </option>
            ))}
          </select>
        ) : null}
        {input || (selectEtcInput && localSelectValue === '기타') ? (
          <input
            type="text"
            onKeyUp={onKeyUpHandler}
            onChange={handleInputChange}
            maxLength={inputMaxLength}
          />
        ) : null}
        {twoButton ? (
          <div className="popup-two-button-div">
            <button
              className="popup-confirm-btn"
              style={{ backgroundColor: '#ff7200' }}
              onClick={handleConfirm}
            >
              {confirmButtonText || '확인'}
            </button>
            <button className="popup-cancel-btn" onClick={closePopup}>
              {cancelButtonText || '취소'}
            </button>
          </div>
        ) : (
          <button className="popup-close-btn" onClick={onClosePopup}>
            {closeButtonText || '닫기'}
          </button>
        )}
      </div>
    </div>
  );
}

export default Popup;
