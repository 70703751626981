import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import instance from '../api/axios';

export const useQueryInstance = ({
  queryKey,
  onSuccess,
  selectFn,
  apiMethod,
  apiEndPoint,
  apiQueryParams,
  apiPathParams,
  apiBody = null,
  apiMultipartPost,
  queryEnable = true,
  refetchOnMount = false,
  refetchOnReconnect = false,
  staleTime = Infinity,
  cacheTime = Infinity,
}) => {
  // api 세팅
  const apiSet = async () => {
    switch (apiMethod) {
      case 'get': {
        const response = await instance.get(
          `${apiEndPoint}${apiPathParams ? '/' + apiPathParams : ''}`,
          {
            params: apiQueryParams,
          },
        );
        return response.data;
      }

      case 'post': {
        const response = await instance.post(
          `${apiEndPoint}${apiPathParams ? '/' + apiPathParams : ''}`,
          apiBody,
          {
            params: apiQueryParams,
            headers: apiMultipartPost && {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return response.data;
      }
    }
  };

  // useQuery 세팅 및 요청
  const fallback = [];
  const {
    data = fallback,
    isError,
    error,
    refetch,
    isRefetching,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: apiSet,
    enabled: queryEnable,
    refetchOnMount,
    refetchOnReconnect,
    staleTime,
    cacheTime,
    select: selectFn,
  });

  // 커스텀 onSuccess
  useEffect(() => {
    if (queryEnable && data && !isRefetching && typeof onSuccess === 'function') {
      onSuccess(data);
    }
  }, [data, isRefetching, queryEnable]);

  return { data, isError, error, refetch, isLoading };
};
