import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import style from '../../../assets/css/PayFinal.module.scss';
import { colorList } from '../../../assets/style/theme';
import BtnNormal from '../../../components/BtnNormal';
import HeaderTitle from '../../../components/HeaderTitle';
import SectionLayout from '../../../components/layout/SectionLayout';
import { payFinalData, payFinalDataSelector } from '../../../recoil/payFinalData';

const PayFinal = () => {
  const navigate = useNavigate();

  const finalData = useRecoilValue(payFinalDataSelector);
  const setFinalData = useSetRecoilState(payFinalData);

  if (!finalData.PCD_PAY_RST)
    return (
      <SectionLayout minHeight="400px" maxWidth="900px">
        <HeaderTitle textAlign="center" fontSize="24px" fontWeight="500">
          페이지 오류
        </HeaderTitle>
        <div className={style.mainContainer}>
          <p className={style.title}>
            페이지 오류가 있습니다.
            <br />
            <br />
            '마이페이지 / 주문내역'을 확인해주세요.
          </p>
        </div>
        <div className={style.btnWrap}>
          <BtnNormal onClickEvent={() => navigate('/pages/member/mypage/order_list')} flex="1">
            주문내역
          </BtnNormal>
        </div>
      </SectionLayout>
    );

  if (finalData.PCD_PAY_RST === 'success')
    return (
      <SectionLayout minHeight="400px" maxWidth="900px">
        <HeaderTitle textAlign="center" fontSize="24px" fontWeight="500">
          결제 완료
        </HeaderTitle>
        <div className={style.mainContainer}>
          <p className={style.title}>결제가 정상적으로 완료되었습니다.</p>
          <div className={style.textBoxWrap}>
            <p className={style.memberName}>
              회원명: <span>{finalData.PCD_PAYER_NAME}</span>
            </p>
            <p className={style.payGoodsName}>
              상품명: <span>{finalData.PCD_PAY_GOODS}</span>
            </p>
            {finalData.OptionName && (
              <p className={style.payGoodsName}>
                옵션: <span>{finalData.OptionName}</span>
              </p>
            )}
            <p className={style.payOid}>
              주문번호: <span>{finalData.PCD_PAY_OID}</span>
            </p>
            <p className={style.payTime}>
              주문일시: <span>{finalData.PCD_PAY_TIME}</span>
            </p>
            <p className={style.payType}>
              결제형태: <span>{finalData.PCD_PAY_TYPE === 'card' ? '카드결제' : '계좌이체'}</span>
            </p>
            {finalData?.PCD_PAY_TYPE === 'card' ? (
              <p className={style.payCard}>
                결제카드:
                <span>{`(${finalData.PCD_PAY_CARDNAME}) ${finalData.PCD_PAY_CARDNUM}`}</span>
              </p>
            ) : (
              <p className={style.payCard}>
                계좌이체정보:
                <span>{`(${finalData.PCD_PAY_BANKNAME}) ${finalData.PCD_PAY_BANKNUM}`}</span>
              </p>
            )}
            <p className={style.payProccess}>
              결제상태: <span>{finalData.PCD_PAY_MSG}</span>
            </p>
            <p className={style.payDelivery}>
              택배사: <span>{finalData.Delivery}</span>
            </p>
            <p className={style.payTotalPrice}>
              총 결제금액:
              <span>{Number(finalData?.PCD_PAY_TOTAL)?.toLocaleString('ko-KR')} ₩</span>
            </p>
          </div>
        </div>
        <div className={style.btnWrap}>
          <BtnNormal
            onClickEvent={() => {
              setFinalData((cur) => ({
                ...cur,
                PCD_PAY_RST: '',
                PCD_PAY_MSG: '',
                PCD_PAYER_NAME: '',
                PCD_PAY_OID: '',
                PCD_PAY_GOODS: '',
                PCD_PAY_TIME: '',
                PCD_PAY_TYPE: '',
                PCD_PAY_BANKNAME: '',
                PCD_PAY_BANKNUM: '',
                PCD_PAY_CARDNAME: '',
                PCD_PAY_CARDNUM: '',
                PCD_PAY_TOTAL: '',
                Delivery: '',
                OptionName: '',
              }));
              navigate('/pages/member/mypage/order_list');
            }}
            flex="1"
          >
            주문내역
          </BtnNormal>
          <BtnNormal
            flex="1"
            bgColor={colorList.main}
            onClickEvent={() => {
              navigate('/pages/product/list');
              setFinalData((cur) => ({
                ...cur,
                PCD_PAY_RST: '',
                PCD_PAY_MSG: '',
                PCD_PAYER_NAME: '',
                PCD_PAY_OID: '',
                PCD_PAY_GOODS: '',
                PCD_PAY_TIME: '',
                PCD_PAY_TYPE: '',
                PCD_PAY_BANKNAME: '',
                PCD_PAY_BANKNUM: '',
                PCD_PAY_CARDNAME: '',
                PCD_PAY_CARDNUM: '',
                PCD_PAY_TOTAL: '',
                Delivery: '',
                OptionName: '',
              }));
            }}
          >
            쇼핑계속하기
          </BtnNormal>
        </div>
      </SectionLayout>
    );
};

export default PayFinal;
