import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { endPoint } from '../../../api/endPoint';
import { colorList } from '../../../assets/style/theme';
import BtnNormal from '../../../components/BtnNormal';
import ErrorFetching from '../../../components/error/ErrorFetching';
import HeaderTitle from '../../../components/HeaderTitle';
import SectionLayout from '../../../components/layout/SectionLayout';
import { PARAMS_TYPE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { checkMemberRole } from '../../../utils/checkMemberRole';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';

const MemberDetail = () => {
  const navigate = useNavigate();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const { idx } = useParams();

  const {
    data: { data: memberData, isError },
  } = useQueryInstance({
    queryKey: [queryKeys.getMember, idx],
    apiEndPoint: endPoint.member.getMember,
    apiMethod: 'get',
    apiQueryParams: {
      is_admin: PARAMS_TYPE.IS_ADMIN,
      member_idx: idx,
    },
  });

  if (isError) return <ErrorFetching isError={isError} />;

  if (memberData)
    return (
      <SectionLayout minHeight="500px">
        <HeaderTitle
          textAlign="center"
          fontSize="24px"
          fontWeight="500"
          btnTitle="뒤로가기"
          btnFontSize="15px"
          btnFontWeight="500"
          btnOnClickEvent={() => navigate(-1)}
        >
          회원 상세 정보
        </HeaderTitle>
        <Container>
          <Wrap flex="1">
            <TextWrap>
              <Text>사업자등록번호:</Text>
              <TextData flex="2" textAlign="left">
                {memberData.business_number ? memberData.business_number : '미등록*'}
              </TextData>
            </TextWrap>
            <Img src={memberData.business_img_url} alt="사업자등록증" />
          </Wrap>

          <Wrap flex="1.3">
            <TextWrap>
              <Text>ID:</Text>
              <TextData>{memberData.member_id}</TextData>
            </TextWrap>
            <TextWrap>
              <Text>이름:</Text>
              <TextData>{memberData.name}</TextData>
            </TextWrap>
            <TextWrap>
              <Text>상호명:</Text>
              <TextData>{memberData.company_name}</TextData>
            </TextWrap>
            <TextWrap>
              <Text>회원등급:</Text>
              <TextData>{checkMemberRole(Number(memberData.member_role))}</TextData>
            </TextWrap>
            <TextWrap>
              <Text>주요품목:</Text>
              <TextData>{memberData.main_product ? memberData.main_product : ''}</TextData>
            </TextWrap>
            <TextWrap>
              <Text>회사번호:</Text>
              <TextData>
                {memberData.office_phone_number ? memberData.office_phone_number : ''}
              </TextData>
            </TextWrap>
            <TextWrap>
              <Text>휴 대 폰 :</Text>
              <TextData>{memberData.phone_number}</TextData>
            </TextWrap>
            <TextWrap>
              <Text>E-Mail:</Text>
              <TextData>{memberData.email ? memberData.email : ''}</TextData>
            </TextWrap>
            <TextWrap>
              <Text>주소:</Text>
              <TextData>
                {memberData.address.address1 ? memberData.address.address1 : ''}{' '}
                {memberData.address.address2 ? memberData.address.address2 : ''}
                <br />
                (우편번호: {memberData.address.post_code ? memberData.address.post_code : ''})
              </TextData>
            </TextWrap>
          </Wrap>
        </Container>
      </SectionLayout>
    );
};

export default MemberDetail;

const Container = styled.div`
  position: relative;
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${colorList.gray};
  padding: 50px;
`;

const Wrap = styled.div`
  flex: ${({ flex }) => flex};
  position: relative;
`;

const TextWrap = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const Text = styled.p`
  flex: ${({ flex }) => (flex ? flex : '1')};
  font-size: 18px;
  text-align: right;
`;

const TextData = styled.p`
  flex: ${({ flex }) => (flex ? flex : '2.5')};
  font-size: 18px;
  color: #585858;
  line-height: 1.5;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
`;

const Img = styled.img`
  position: relative;
  width: 100%;
  border-radius: 10px;
`;
