export const checkProxy = () => {
  const SERVER_PROXY =
    window.location.hostname === 'localhost'
      ? 'http://localhost:8080'
      : window.location.hostname === 'www.ilppang.com'
      ? 'https://nobusyserver.shop'
      : 'https://nobusyserver-dev.shop';
  const SERVER_URL = `${SERVER_PROXY}`;
  return { SERVER_URL };
};
