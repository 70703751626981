import React from 'react';

export const FAQ_Data = [
  {
    q: (
      <p>
        <span>Q.</span> 회원가입을 하지 않아도 이용가능한가요?
      </p>
    ),
    a: (
      <div>
        <p>
          A. 일빵에서는 로그인 하지 않아도 글 내용이나 리스트는 볼 수 있지만, 회원 가입하여 로그인한
          후에 판매 및 구매 활동을 할 수 있습니다.
        </p>
        <p>
          또한 일빵은 MRO협동조합원들에게 좋은 물건을 합리적인 가격에 판매/구매할 수 있도록 만든
          플랫폼이기 때문에, 조합가입은 필수입니다.
        </p>
        <p>
          회원가입 양식이나 절차가 복잡하지 않으므로 손쉽게 회원가입 후, 이용하는 것을 추천드립니다
          :)
        </p>
      </div>
    ),
  },
  {
    q: (
      <p>
        <span>Q.</span> 로그인 아이디 및 비밀번호가 생각나지 않아요.
      </p>
    ),
    a: (
      <div>
        <p>
          A. 로그인 페이지에서 하단의 아이디/비밀번호 찾기를 클릭하게 되면 해당 페이지로 이동하게
          됩니다.
        </p>
        <p>이 후, 인증절차를 따라주시면 아이디 및 비밀번호를 확인하실 수 있습니다.</p>
        <p>
          (비밀번호는 인증 완료 후 임시비밀번호가 발급되며, 이는 회원정보 변경 페이지에서 수정
          가능합니다)
        </p>
      </div>
    ),
  },
  {
    q: (
      <p>
        <span>Q.</span> 제품을 구매하려면 어떻게 해야하나요?
      </p>
    ),
    a: (
      <div>
        <p>
          A. 제품 상세페이지에서 원하는 제품과 수량 선택 및 추가 옵션을 선택하신 다음 [바로
          구매]버튼 바로 주문하여 구매하실 수 있습니다.
        </p>
        <p>
          이후, 결제페이지에서 결제 정보를 입력하시면 주문이 완료되며, 결제가 완료된 주문 내역은
          마이 페이지 &gt; 주문 내역 메뉴에서 확인하실 수 있습니다.
        </p>
      </div>
    ),
  },
  {
    q: 'Q. 제품 거래 할 때 수수료가 생기나요?',
    a: (
      <div>
        <p>A. 대형 커머스 대비 최소 수수료(카드,계좌 동일 4.8%)가 발생되며,</p>
        <p>거래 대금이 클 수록 낮은 수수료가 부과됩니다.</p>
        <p>수수료 지불은 물품 거래 시, 판매자측에 부과됩니다.</p>
      </div>
    ),
  },
  {
    q: 'Q. 제품을 등록하고 싶습니다. 어떻게 하면 되나요?',
    a: (
      <div>
        <p>
          A. 회원가입 및 로그인 후, 도매판매 &gt; 상품등록(우측상단)에서 판매하실 제품을 등록할 수
          있습니다.
        </p>
        <br />
        <p>STEP.1</p>
        <p>
          상품 등록페이지에서는 카테고리를 설정하고 상품명과 규격 및 단위 등 필수 입력조건을
          입력합니다.
        </p>
        <br />
        <p>STEP.2</p>
        <p>
          상세 설명 및 이미지를 삽입합니다. 사진은 한 장당 3mb이하로 첨부해야 합니다. 이후
          대표이미지를 삽입후 추가로 이해를 도울 수 있는 첨부파일을 삽입한 후 판매상태를 체크하여
          등록 합니다.
        </p>
      </div>
    ),
  },
  {
    q: 'Q. 등록한 제품을 수정하고 싶습니다. 어떻게 하면 되나요?',
    a: (
      <div>
        A. 등록한 제품 수정은 제품 등록과 같은 프로세스이며, 마이페이지 &gt; 내상품에서 등록한
        제품을 수정할 수 있습니다.
      </div>
    ),
  },
  {
    q: 'Q. 제품 등록 중 오류 메시지가 나오면서 오류가 발생되었습니다. 어떻게 하면 되나요?',
    a: (
      <div>
        <p>A. 첨부파일과 이미지 용량을 확인해주시기 바랍니다.</p>
        <p>
          (한 장당 최대 3MB) 해결이 되지 않는다면 고객센터(010-2475-3532)로 연락주시면
          안내해드리겠습니다.
        </p>
      </div>
    ),
  },
  {
    q: 'Q. 찾는 품목이 일빵에 없을 때 어떤 방법이 있나요?',
    a: (
      <div>
        <p>
          A. 필요한 품목을 검색 또는 각 카테고리에서 찾았는데도 나오지 않을 때에는 커뮤니티 서비스를
          활용해서 찾는 품목에 대한 글을 등록해 주세요.
        </p>
        <p>
          다양한 업체에서 고객님의 글을 읽고 댓글 또는 기재해 주신 연락처로 연락을 할 수 있습니다:)
        </p>
      </div>
    ),
  },
  {
    q: 'Q. 기타 문의사항이 있을 때 어떻게 하나요?',
    a: (
      <div>
        <p>
          A. 사이트를 이용하면서 어려움이나 잘 모르는 부분,개선사항 제안 등 문의사항이 생길 것으로
          생각됩니다.
        </p>
        <p>
          그러실 때는 일빵 페이지 하단의 유선전화 및 이메일 정보를 통해 문의 주신다면 빠르게 조치를
          취하겠습니다. 감사합니다.
        </p>
      </div>
    ),
  },
];
