import React from 'react';

export const exportCategoryPathAndName = (categoriesData, idx, name = []) => {
  if (categoriesData?.length > 0 && idx) {
    const paramsIdx = Number(idx);

    for (let el of categoriesData) {
      if (el.category_idx === paramsIdx) {
        let final = [...name, el.name];

        switch (final?.length) {
          case 1:
            return `${final[0]}`;

          case 2:
            return (
              <>
                <span
                  style={{
                    display: 'block',
                    fontSize: '12px',
                    fontWeight: '400',
                    marginBottom: '10px',
                    color: '#7e7e7e',
                  }}
                >
                  {final[0]} &gt;
                </span>
                <span style={{ display: 'block' }}> {final[1]}</span>
              </>
            );

          case 3:
            return (
              <>
                <span
                  style={{
                    display: 'block',
                    fontSize: '12px',
                    fontWeight: '400',
                    marginBottom: '10px',
                    color: '#7e7e7e',
                  }}
                >
                  {final[0]} &gt; {final[1]} &gt;
                </span>
                <span style={{ display: 'block' }}> {final[2]}</span>
              </>
            );

          default:
            break;
        }
      }

      if (el.child_category_list && el.child_category_list.length > 0) {
        let result = exportCategoryPathAndName(el.child_category_list, idx, [...name, el.name]);
        if (result) return result;
      }
    }
    return null;
  }
};
