import React from 'react';
import ReactQuill from 'react-quill';
import DOMPurify from 'isomorphic-dompurify';
import styled from 'styled-components';

import 'react-quill/dist/quill.snow.css';
import '../assets/css/CustomToolBar.css';

//스타일
const DetailTextContain = styled.div`
  position: relative;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;

const TextEditor_Quill_ReadOnly = ({
  value,
  right = 'none',
  left = 'none',
  top = 'none',
  bottom = 'none',
  padding = '0 0',
  maxWidth = '800px',
  height = 'auto',
  margin = '0',
}) => {
  return (
    <DetailTextContain
      margin={margin}
      maxWidth={maxWidth}
      right={right}
      left={left}
      top={top}
      bottom={bottom}
      padding={padding}
      height={height}
    >
      <ReactQuill readOnly={true} value={DOMPurify.sanitize(value)} modules={{ toolbar: false }} />
    </DetailTextContain>
  );
};

export default React.memo(TextEditor_Quill_ReadOnly);
