import { atom } from 'recoil';

export const useMediaQueryState = atom({
  key: 'useMediaQueryState',
  default: {
    FULL_SCREEN: false, // 1200px
    LARGE_SCREEN: false, // 940px
    MEDIUM_SCREEN: false, // 810px
    MEDIUM_SMALL_SCREEN: false, // 680px
    TABLET_SCREEN: false, // 768px
    SMALL_SCREEN: false, // 576px
    EXTRA_SMALL_SCREEN: false, // 480px
    PHONE_SCREEN: false, // 375px
    PHONE_FOLDER_SCREEN: false, // 280px
  },
});
