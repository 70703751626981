import React from 'react';

import { colorList } from '../../assets/style/theme';

const MenuHamburger = ({ color = colorList.white, size = '30', reverse = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={String(Number(size) - 7)}
      viewBox="0 0 30 23"
      fill="none"
    >
      {!reverse ? (
        <>
          <line y1="1.5" x2="20" y2="1.5" stroke={color} strokeWidth="3" />
          <line y1="11.5" x2="30" y2="11.5" stroke={color} strokeWidth="3" />
          <line y1="21.5" x2="26" y2="21.5" stroke={color} strokeWidth="3" />
        </>
      ) : (
        <g transform="scale(-1, 1) translate(-30, 0)">
          <line y1="1.5" x2="20" y2="1.5" stroke={color} strokeWidth="3" />
          <line y1="11.5" x2="30" y2="11.5" stroke={color} strokeWidth="3" />
          <line y1="21.5" x2="26" y2="21.5" stroke={color} strokeWidth="3" />
        </g>
      )}
    </svg>
  );
};

export default React.memo(MenuHamburger);
