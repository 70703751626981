import { atom } from 'recoil';

export const usePopupState = atom({
  key: 'usePopupState',
  default: {
    show: false,
    title: '',
    content: '',
    onComplete: null,
    twoButton: false,
    input: false,
    inputMaxLength: null,
    selectEtcInput: false,
    // 셀렉터는 형식에 맞는 객체 배열로 해당 옵션 값들을 넣어줘야함 -> [{name:000, value: 000}] value는 회사코드명과 같음
    // 배열 형식을 안맞추면 오류가 남
    select: [],
    setInputValue: null,
    setSelectValue: null,
    confirmButtonText: '',
    cancelButtonText: '',
    closeButtonText: '',
  },
});
