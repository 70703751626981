import { useEffect } from 'react';

// 콜백은 항상 화살표 함수로 받아줘야 함
export const useExternalClick = (refs, callback) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (refs.every((ref) => ref.current && !ref.current.contains(event.target))) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [refs, callback]);
};
