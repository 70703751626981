import { useEffect, useState } from 'react';

export const IsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(localStorage.getItem('isLoggedIn') === 'true');
    };

    window.addEventListener('login', handleStorageChange);
    window.addEventListener('logout', handleStorageChange);

    return () => {
      window.removeEventListener('login', handleStorageChange);
      window.removeEventListener('logout', handleStorageChange);
    };
  }, []);

  return isLoggedIn;
};
