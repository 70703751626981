import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { endPoint, endPointMutation } from '../../../../api/endPoint';
import { colorList } from '../../../../assets/style/theme.jsx';
import BottomMenuCart from '../../../../components/BottomMenuCart.jsx';
import BtnBack from '../../../../components/BtnBack.jsx';
import EmptyDataPage from '../../../../components/EmptyDataPage';
import ErrorFetching from '../../../../components/error/ErrorFetching';
import FloatingMenuCart from '../../../../components/FloatingMenuCart';
import HeaderTitle from '../../../../components/HeaderTitle.jsx';
import ImageLayout from '../../../../components/layout/ImageLayout.jsx';
import SectionLayout from '../../../../components/layout/SectionLayout.jsx';
import { useMediaQueryCustom } from '../../../../components/mediaQueryCustom/useMediaQueryCustom.js';
import { usePopup } from '../../../../components/popup/usePopup.js';
import EllipsisText from '../../../../components/textLimit/EllipsisText';
import { DELIVERY_CODE } from '../../../../constant/DELIVERY_CODE.js';
import { CACHE_TIME } from '../../../../constant/NUMBER_CONSTANT.js';
import { REGEX } from '../../../../constant/REGEX';
import { ORDER_STATUS, PAYMENT_TYPE } from '../../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../../react-query/useQueryInstance';
import { payFinalData } from '../../../../recoil/payFinalData';
import { decodingCrypto } from '../../../../utils/crypto';
import { IsLoggedIn } from '../../../../utils/IsLoggedIn';
import VATCalculate from '../../../../utils/VATCalculate';

const { REACT_APP_CLIENT_TEST_KEY } = process.env;
const { REACT_APP_CLIENT_REAL_KEY } = process.env;

const calculate = (checkItem, choice) => {
  switch (choice) {
    case 'checkedLength':
      return checkItem.length;
    case 'totalPrice':
      return checkItem.reduce((acc, cur) => acc + cur.price * cur.count, 0);
    // return checkItem.reduce(
    //   (acc, cur) => acc + Math.round((cur.price + cur.price * 0.048) / 10) * 10 * cur.count,
    //   0,
    // );
    case 'totalQuantity':
      return checkItem.reduce((acc, cur) => acc + cur.count, 0);
    default:
      return 0;
  }
};

function CartList() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const isLoggedIn = IsLoggedIn();

  const { openPopup } = usePopup();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  // 미디어쿼리 훅
  const { LARGE_SCREEN, EXTRA_SMALL_SCREEN } = useMediaQueryCustom();

  // 개별 아이템 체크
  const [checkItem, setCheckItem] = useState([]);

  // input수량 체크
  const [quantity, setQuantity] = useState([]);

  // 체크인풋 상태 컨트롤
  const [checkCtr, setCheckCtr] = useState([null]);

  // 카트 상품 불러오기
  const {
    data: list,
    isError,
    refetch,
  } = useQueryInstance({
    queryKey: [queryKeys.cartItems],
    apiMethod: 'get',
    apiEndPoint: endPoint.cart.getCartItemList,
    onSuccess: (list) => {
      if (list?.data_list) {
        setQuantity(list?.data_list?.map((el) => el.count));
        if (checkCtr[0] === null) {
          setCheckCtr(() =>
            list?.data_list?.map((el) =>
              el.stock_quantity >= el.min_order_quantity ? false : 'not',
            ),
          );
        }
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: CACHE_TIME.DEFAULT,
  });

  // 플로팅 메뉴에 체크된 상태값을 계산하기 위한 함수
  // 반환받고 싶은 값을 스트링으로 입력
  // (checkedLength : 선택된 상품 갯수, totalPrice: 선택된 상품의 총 합계 가격, totalQuantity: 선택된 상품의 총 수량)
  // 컴포넌트 리랜더링에 따른 반복적 계산을 막기 위해 useMemo (length는 가벼운 계산이라 memo안함)
  const totalPrice = useMemo(() => {
    return calculate(checkItem, 'totalPrice');
  }, [checkItem]);

  const totalQuantity = useMemo(() => {
    return calculate(checkItem, 'totalQuantity');
  }, [checkItem]);

  // 아이템 개별 인풋 컨트롤 핸들
  const checkInputCtrHandler = (index) => {
    const newCheckArr = [
      ...checkCtr.slice(0, index),
      !checkCtr[index],
      ...checkCtr.slice(index + 1),
    ];
    setCheckCtr(newCheckArr);

    if (newCheckArr[index]) {
      setCheckItem((cur) => {
        if (list.data_list[index].product_option_price) {
          console.log('real??');
          return [
            ...cur,
            {
              cart_item_idx: list.data_list[index].cart_item_idx,
              product_name: list.data_list[index].product_name,
              product_idx: list.data_list[index].product_idx,
              price:
                list.data_list[index].price +
                Math.round(
                  (list.data_list[index].product_option_price +
                    list.data_list[index].product_option_price * 0.048) /
                    10,
                ) *
                  10,
              count: list.data_list[index].count,
              product_option_idx: list.data_list[index].product_option_idx ?? null,
            },
          ];
        } else {
          return [
            ...cur,
            {
              cart_item_idx: list.data_list[index].cart_item_idx,
              product_name: list.data_list[index].product_name,
              product_idx: list.data_list[index].product_idx,
              price: list.data_list[index].price,
              count: list.data_list[index].count,
              product_option_idx: list.data_list[index].product_option_idx ?? null,
            },
          ];
        }
      });
    } else {
      setCheckItem((cur) => {
        return cur.filter((el) => el.cart_item_idx !== list.data_list[index].cart_item_idx);
      });
    }
  };

  // 전체선택기능 핸들
  const checkedTotalHandler = (e) => {
    const checkedStatus = e.target.checked;

    if (checkedStatus) {
      setCheckCtr((cur) => {
        return cur.map((el) => (el === false ? true : el));
      });
      setCheckItem(() => {
        return list.data_list
          .map((el) =>
            el.stock_quantity >= el.min_order_quantity
              ? {
                  cart_item_idx: el.cart_item_idx,
                  product_name: el.product_name,
                  product_idx: el.product_idx,
                  price: el.price,
                  count: el.count,
                  product_option_idx: el.product_option_idx ?? null,
                }
              : null,
          )
          .filter((el) => el !== null);
      });
    } else {
      setCheckCtr((cur) => {
        return cur.map((el) => (el === true ? false : el));
      });
      setCheckItem((cur) => {
        let copy = [...cur];
        return (copy = []);
      });
    }
  };

  // 카트 물품 삭제 요청 ---
  const { mutate: onDelete } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.cart.removeCartItem,
    onErrorFn: () => {
      return openPopup({
        title: '오류',
        content: '다시 시도해주세요.',
      });
    },
    onSuccessFn: (_, variables) => {
      refetch();
      setCheckCtr((cur) => {
        return cur.filter((_, number) => number !== variables.index);
      });
      setCheckItem((cur) => {
        return cur.filter((el) => el.cart_item_idx !== variables.cart_item_idx);
      });
    },
  });

  // 인풋 쪽 컨트롤을 위한 REF
  const inputRef = useRef([]);

  // 장바구니 수량변경시 적용
  const { mutate: updateCartItemCount } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.cart.updateCartItemCount,
    onErrorFn: () => {
      return openPopup({
        title: '오류',
        content: '다시 시도해주세요.',
      });
    },
    onSuccessFn: () => {
      setInputIndex(null);
      refetch();
    },
  });

  const [inputIndex, setInputIndex] = useState(null);

  const handleIncrease = async (cart_item_idx, count) => {
    setCheckItem((cur) => {
      return cur.map((el) =>
        el.cart_item_idx === cart_item_idx ? { ...el, count: el.count + 1 } : el,
      );
    });

    updateCartItemCount({
      apiQueryParams: {
        cart_item_idx,
        count: await (count + 1),
      },
    });
  };

  const handleDecrease = async (cart_item_idx, count) => {
    if (count > 1) {
      setCheckItem((cur) => {
        return cur.map((el) =>
          el.cart_item_idx === cart_item_idx ? { ...el, count: el.count - 1 } : el,
        );
      });

      updateCartItemCount({
        apiQueryParams: {
          cart_item_idx,
          count: await (count - 1),
        },
      });
    }
  };

  const handleInputChange = (index, event, cart_item_idx) => {
    // 숫자와 빈 문자열 빼고 모두 삭제
    let inputValue = event.target.value.replace(REGEX.notNumEmptyStringRemove, '');

    if (inputValue !== '') {
      inputValue = Number(inputValue);
    }

    setQuantity((cur) => {
      return cur.map((el, indexNum) => (indexNum === index ? inputValue : el));
    });

    setCheckItem((cur) => {
      return cur.map((el) =>
        el.cart_item_idx === cart_item_idx ? { ...el, count: !inputValue ? 1 : inputValue } : el,
      );
    });
  };

  // 외부클릭시 인풋 요청
  useEffect(() => {
    const inputRefDomHandler = (e) => {
      if (!inputRef?.current?.some((dom) => dom && dom.contains(e.target))) {
        // 인풋값을 안 클릭할 경우
        if (inputIndex === null) return;

        if (
          list?.data_list[inputIndex]?.count === quantity[inputIndex] &&
          quantity[inputIndex] !== ''
        ) {
          return setInputIndex(null);
        }
        if (quantity[inputIndex] === '' || quantity[inputIndex] === 0) {
          updateCartItemCount({
            apiQueryParams: {
              cart_item_idx: list?.data_list[inputIndex]?.cart_item_idx,
              count: 1,
            },
          });
          return;
        }
        if (quantity[inputIndex] !== '') {
          updateCartItemCount({
            apiQueryParams: {
              cart_item_idx: list?.data_list[inputIndex]?.cart_item_idx,
              count: quantity[inputIndex],
            },
          });
          return;
        }
      } else {
        // 같은 인풋값 클릭했을 경우
        if (inputIndex === null) return;

        if (
          list?.data_list[inputIndex]?.count === quantity[inputIndex] &&
          quantity[inputIndex] !== ''
        ) {
          return setInputIndex(null);
        }
        if (quantity[inputIndex] === '' || quantity[inputIndex] === 0) {
          updateCartItemCount({
            apiQueryParams: {
              cart_item_idx: list?.data_list[inputIndex]?.cart_item_idx,
              count: 1,
            },
          });
          return;
        }
        if (quantity[inputIndex] !== '') {
          updateCartItemCount({
            apiQueryParams: {
              cart_item_idx: list?.data_list[inputIndex]?.cart_item_idx,
              count: quantity[inputIndex],
            },
          });
          return;
        }
      }
    };

    document.addEventListener('mousedown', inputRefDomHandler);

    return () => {
      document.removeEventListener('mousedown', inputRefDomHandler);
    };
  }, [inputIndex, quantity]);

  // 인풋 엔터도 가능하게
  const keyPressInputHandler = (e, cart_item_idx, count) => {
    let value = e.target.value;

    if (value !== '') {
      value = Number(value);
    }

    if (e.key === 'Enter') {
      if (count === value && value !== '' && inputIndex !== null) {
        setInputIndex(null);
        return e.target.blur(); // 현재 인풋 포커스 해제
      }

      if ((value === '' || value === 0) && inputIndex !== null) {
        updateCartItemCount({
          apiQueryParams: {
            cart_item_idx,
            count: 1,
          },
        });
        return e.target.blur(); // 현재 인풋 포커스 해제
      }

      if (value !== '' && inputIndex !== null) {
        updateCartItemCount({
          apiQueryParams: {
            cart_item_idx,
            count: Number(value),
          },
        });
        return e.target.blur(); // 현재 인풋 포커스 해제
      }

      setInputIndex(null);
      e.target.blur(); // 현재 인풋 포커스 해제
      return;
    }
  };

  // 결제방식 선택
  const [payType, setPayType] = useState('transfer');

  const onPayple = () => {
    let obj = new Object();

    obj.PCD_PAY_TYPE = payType;
    // 계좌결제
    // obj.PCD_PAY_TYPE = "transfer";
    obj.PCD_PAY_WORK = 'PAY';

    /* 01 : 페이플 간편결제 */
    // obj.PCD_CARD_VER = "01"
    /* 02 : 페이플 앱카드결제 */
    obj.PCD_CARD_VER = payType === 'card' ? '02' : '01';

    obj.PCD_PAYER_NAME = decodingCrypto(localStorage.getItem('member_name'));
    obj.PCD_PAYER_HP = decodingCrypto(localStorage.getItem('member_phone_number'));
    // obj.PCD_PAYER_EMAIL = '';
    obj.PCD_PAY_GOODS = `${checkItem[0]?.product_name} ${checkItem?.length !== 1 ? '외 다수' : ''}`;
    obj.PCD_PAY_TOTAL = VATCalculate(totalPrice);
    obj.PCD_PAY_ISTAX = 'Y';

    /* 결과를 콜백 함수로 받고자 하는 경우 함수 설정 추가 */
    obj.callbackFunction = getPaypleResult; // 콜백 함수

    obj.clientKey =
      window.location.hostname === 'www.ilppang.com'
        ? REACT_APP_CLIENT_REAL_KEY
        : REACT_APP_CLIENT_TEST_KEY;

    obj.PCD_RST_URL = 'pages/member/mypage/cart'; // 결제결과 수신 URL

    PaypleCpayAuthCheck(obj);
  };

  const getPaypleResult = (result) => {
    if (result) {
      if (result.PCD_PAY_RST === 'close') {
        openPopup({
          title: '안내',
          content: '결제를 취소하였습니다.',
        });
      } else if (result.PCD_PAY_RST === 'success') {
        // api 호출
        apiOn(result);
      } else {
        openPopup({
          title: '안내',
          content: result.PCD_PAY_MSG,
        });
      }
    }
  };

  // selct는 2개를 받음
  const selectDelivery = (name) => {
    if (name) {
      const deliveryData = {
        deliveryName: name,
      };

      // 세션스토리지에 택배사 데이터 저장
      sessionStorage.setItem('delivery', JSON.stringify(deliveryData));

      openPopup({
        title: '안내',
        content: '택배사 : ' + name + '\n\n정말 결제하시겠습니까?',
        onComplete: () => onPayple(),
        twoButton: true,
      });
    } else {
      openPopup({
        title: '안내',
        content: '택배사를 입력해주세요.',
      });
    }
  };

  const { mutate: apiOnPayFinal } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.addOrder,
    onSuccessFn: (response) => {
      setPayFinalData((cur) => ({ ...cur, PCD_PAY_OID: response?.data?.order_number }));
      queryClient.refetchQueries([queryKeys.orderListMypage], { force: true });
      // 데이터 전송 되었으므로 세션스토리지 초기화
      sessionStorage.removeItem('delivery');
      navigate('/pages/product/pay/final');
    },
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      // 데이터 오류이므로 세션스토리지 초기화
      sessionStorage.removeItem('delivery');

      openPopup({ title: '오류', content: serverMessage });
    },
  });

  // 리코일로 결제정보데이터 보관
  const setPayFinalData = useSetRecoilState(payFinalData);

  const apiOn = (paymentResult) => {
    const deliveryData = sessionStorage.getItem('delivery');
    const { deliveryName } = deliveryData && JSON.parse(deliveryData);

    // 개별 상품에 부가세 추가
    const orderItemList = checkItem.map((el) => ({
      ...el,
      price: VATCalculate(el.price * el.count),
    }));

    const properties = {
      shipping_company_name: deliveryName,
      payment_result: paymentResult,
    };

    // 최종 데이터
    const addOrderData = {
      order_item_list: orderItemList,
      payment_type: payType === 'card' ? PAYMENT_TYPE.CARD : PAYMENT_TYPE.TRANSFER,
      order_status: ORDER_STATUS.ORDER,
      properties: JSON.stringify(properties),
    };

    // 결제 파이널 페이지로 넘어가는 것을 위해 전역상태보관
    const {
      PCD_PAY_RST,
      PCD_PAY_MSG,
      PCD_PAYER_NAME,
      PCD_PAY_GOODS,
      PCD_PAY_TIME,
      PCD_PAY_TYPE,
      PCD_PAY_BANKNAME,
      PCD_PAY_BANKNUM,
      PCD_PAY_CARDNAME,
      PCD_PAY_CARDNUM,
      PCD_PAY_TOTAL,
    } = paymentResult;

    setPayFinalData((cur) => ({
      ...cur,
      PCD_PAY_RST,
      PCD_PAY_MSG,
      PCD_PAYER_NAME,
      PCD_PAY_GOODS,
      PCD_PAY_TIME,
      PCD_PAY_TYPE,
      PCD_PAY_BANKNAME,
      PCD_PAY_BANKNUM,
      PCD_PAY_CARDNAME,
      PCD_PAY_CARDNUM,
      PCD_PAY_TOTAL,
      Delivery: deliveryName,
    }));

    apiOnPayFinal({
      apiBody: addOrderData,
    });
  };

  // 주문전 상품 체크
  const { mutate: onCheckBeforeOrder } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.checkBeforeOrder,
    onSuccessFn: () => {
      openPopup({
        title: '안내',
        content: '택배사를 입력해주세요. \n * 택배비는 착불입니다.',
        twoButton: true,
        input: false,
        select: DELIVERY_CODE,
        selectEtcInput: true,
        setSelectValue: selectDelivery,
      });
    },
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;

      openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
  });

  const onCheckBeforeOrderFunc = () => {
    const data = {
      order_item_list: checkItem,
    };

    // 재고수량 및 구매 가능 여부 선체크 여기서 true 여야 결제 진행해야함
    onCheckBeforeOrder({
      apiBody: data,
    });
  };

  const addOrder = () => {
    // 세션스토리지 초기화
    sessionStorage.removeItem('delivery');

    if (checkItem?.length === 0) {
      return openPopup({ title: '안내', content: `구매하실 상품을 '체크' 해주세요.` });
    }

    onCheckBeforeOrderFunc();
  };

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout padding="0" minHeight="700px">
      <div style={{ width: '100%', boxSizing: 'border-box', padding: '20px 10px 0 10px' }}>
        <BtnBack />
      </div>
      <HeaderTitle
        fontSize="34px"
        borderBottom="none"
        conJustfyContent="center"
        conPadding="50px 30px"
      >
        장바구니
      </HeaderTitle>

      <div
        style={{
          width: '100%',
          display: 'flex',
          boxSizing: 'border-box',
          gap: '10px',
          justifyContent: 'space-between',
          minHeight: '700px',
          padding: '0 10px',
        }}
      >
        <article style={{ width: '100%', flex: LARGE_SCREEN ? '1' : '8' }}>
          <div
            style={{
              width: '100%',
              boxSizing: 'border-box',
              padding: '5px 20px',
              borderRadius: '5px',
              border: `1px solid ${colorList.lightGray}`,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
            >
              <input
                checked={checkCtr.every((el) => el === 'not') ? false : checkCtr.every(Boolean)}
                type="checkbox"
                style={{
                  width: EXTRA_SMALL_SCREEN ? '20px' : '30px',
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
                onChange={checkedTotalHandler}
                id="checkTotal"
              />
              <label
                htmlFor="checkTotal"
                style={{ fontSize: EXTRA_SMALL_SCREEN ? '15px' : '18px' }}
              >
                전체선택 / 전체해제
              </label>
            </div>
          </div>

          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              padding: '10px 0',
            }}
          >
            {list?.data_list?.length === 0 ? (
              <EmptyDataPage title="장바구니에 담긴 상품이 없습니다." padding="0 0 300px 0" />
            ) : (
              list?.data_list?.map((item, index) => {
                return (
                  <CartItemLayout
                    item={item}
                    index={index}
                    key={item?.cart_item_idx}
                    checkInputCtrHandlerOnChange={() => checkInputCtrHandler(index)}
                    checkValue={checkCtr[index]}
                    onDeleteClick={() =>
                      onDelete({
                        apiQueryParams: {
                          cart_item_idx: parseInt(item?.cart_item_idx),
                        },
                        cart_item_idx: item?.cart_item_idx,
                        index,
                      })
                    }
                    inputIndex={inputIndex}
                    countDownBtnClick={() => handleDecrease(item.cart_item_idx, item.count)}
                    countUpBtnClick={() => handleIncrease(item.cart_item_idx, item.count)}
                    quantity={quantity}
                    inputRef={inputRef}
                    countInputOnChange={(event) =>
                      handleInputChange(index, event, item.cart_item_idx)
                    }
                    countInputOnClick={() => setInputIndex(index)}
                    countInputOnKeyDown={(e) =>
                      keyPressInputHandler(e, item.cart_item_idx, item.count)
                    }
                  />
                );
              })
            )}
          </div>
        </article>

        {LARGE_SCREEN ? (
          <BottomMenuCart
            orderBoxFlex={EXTRA_SMALL_SCREEN ? '4' : undefined}
            scrollPoint={true}
            totalPrice={totalPrice}
            totalQuantity={totalQuantity}
            isPayTypeRadioBox={true}
            payTypeRadioValue={payType}
            payTypeRadioOnChange={setPayType}
            checkedLength={calculate(checkItem, 'checkedLength')}
            onClickBuy={addOrder}
            totalFontSize={EXTRA_SMALL_SCREEN ? '15px' : undefined}
            totalQuantityFontSize={EXTRA_SMALL_SCREEN ? '15px' : undefined}
            totalPriceSize={EXTRA_SMALL_SCREEN ? '22px' : undefined}
          />
        ) : (
          <article style={{ position: 'relative', display: 'block', width: '100%', flex: '3' }}>
            <FloatingMenuCart
              containerRight="0px"
              containerTop="0px"
              boxTop="150px"
              // 반환받고 싶은 값을 스트링으로 입력
              // (checkedLength : 선택된 상품 갯수, totalPrice: 선택된 상품의 총 합계 가격, totalQuantity: 선택된 상품의 총 수량)
              checkedLength={calculate(checkItem, 'checkedLength')}
              totalPrice={totalPrice}
              totalQuantity={totalQuantity}
              onClickBuy={addOrder}
              isPayTypeRadioBox={true}
              payTypeRadioValue={payType}
              payTypeRadioOnChange={setPayType}
            />
          </article>
        )}
      </div>
    </SectionLayout>
  );
}

export default CartList;

// 아이템 레이아웃 별도로 뺌
const CartItemLayout = React.memo(
  ({
    checkInputCtrHandlerOnChange,
    item,
    index,
    checkValue,
    onDeleteClick,
    inputIndex,
    countDownBtnClick,
    countUpBtnClick,
    quantity,
    inputRef,
    countInputOnChange,
    countInputOnClick,
    countInputOnKeyDown,
  }) => {
    // 미디어쿼리 훅
    const { SMALL_SCREEN } = useMediaQueryCustom();
    const navigate = useNavigate();

    const quantityCheck = !item?.product_option_idx
      ? Number(item?.product_stock_quantity) < Number(item?.product_min_order_quantity)
      : Number(item?.product_option_stock_quantity) <
        Number(item?.product_option_min_order_quantity);
    let totalCount = 0;
    if (item?.product_option_price) {
      totalCount = (
        (item?.price +
          Math.round((item?.product_option_price + item?.product_option_price * 0.048) / 10) * 10) *
        item?.count
      ).toLocaleString();
    } else {
      totalCount = (item?.price * item?.count).toLocaleString();
    }
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          boxSizing: 'border-box',
          border: `1px solid ${colorList.lightGray}`,
          borderRadius: '5px',
          padding: '20px',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            gap: SMALL_SCREEN ? '10px' : '20px',
          }}
        >
          {quantityCheck ? (
            <input
              checked={false}
              type="checkbox"
              style={{
                width: SMALL_SCREEN ? '40px' : '25px',
                cursor: 'pointer',
                alignSelf: 'center',
              }}
              disabled={true}
            />
          ) : (
            <input
              checked={checkValue || false}
              type="checkbox"
              style={{ width: '40px', cursor: 'pointer', alignSelf: 'center' }}
              onChange={checkInputCtrHandlerOnChange}
            />
          )}
          <div
            style={{
              position: 'relative',
              width: 'fit-content',
              height: 'fit-content',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/pages/product/detail/${item?.product_idx}`);
            }}
          >
            <div style={{ position: 'relative', width: '120px', paddingTop: '10px' }}>
              <ImageLayout imgSrc={item?.img_url} isSoldOut={quantityCheck} />
            </div>
          </div>

          {/* //------두번째 덩어리 */}
          <div
            style={{
              position: 'relative',
              width: '100%',
              alignSelf: 'center',
            }}
          >
            <p style={{ fontSize: SMALL_SCREEN ? '11px' : '14px', marginBottom: '8px' }}>
              {item?.company_name}
            </p>

            <EllipsisText
              text={item?.product_name}
              maxLength={20}
              fontSize={SMALL_SCREEN ? '15px' : '18px'}
              margin="0 0 5px 0"
            />

            {/* 옵션내용 */}
            {item?.product_option_name && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  fontSize: SMALL_SCREEN ? '14px' : '16px',
                  color: colorList.darkGray,
                }}
              >
                <span>옵션:</span>
                <EllipsisText
                  color={colorList.darkGray}
                  text={item?.product_option_name}
                  maxLength={20}
                  fontSize={SMALL_SCREEN ? '14px' : '16px'}
                  margin="0"
                />
              </div>
            )}

            <p
              style={{
                color: !quantityCheck ? colorList.dark : colorList.darkGray,
                textDecoration: quantityCheck && 'line-through',
                fontSize: SMALL_SCREEN ? '18px' : '20px',
                marginTop: '15px',
              }}
            >
              {totalCount}
            </p>
          </div>

          {/* //--------- 마지막덩어리 */}
          {!SMALL_SCREEN && (
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <RxCross2
                style={{
                  position: 'relative',
                  width: '32px',
                  height: '32px',
                  right: '0px',
                  top: '0px',
                  cursor: 'pointer',
                }}
                onClick={onDeleteClick}
              />

              {quantityCheck ? (
                <div
                  style={{
                    fontWeight: '700',
                    color: '#d20000',
                    position: 'relative',
                    right: '0',
                    bottom: '0',
                    width: '126px',
                    textAlign: 'center',
                  }}
                >
                  SOLD-OUT
                </div>
              ) : (
                <div style={{ position: 'relative', right: '0', bottom: '0' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      boxSizing: 'border-box',
                      border: '1px solid #ddd',
                      width: '126px',
                    }}
                  >
                    <button
                      style={{ width: '100%' }}
                      type="button"
                      onClick={!inputIndex ? countDownBtnClick : null}
                    >
                      <FiMinus size={15} />
                    </button>
                    {quantity && (
                      <input
                        id={index}
                        type="number"
                        ref={(el) => (inputRef.current[index] = el)}
                        value={quantity[index] || ''}
                        style={{
                          border: '1px solid #ddd',
                          borderTop: 'none',
                          borderBottom: 'none',
                          boxSizing: 'border-box',
                          width: '60px',
                          height: '32px',
                          textAlign: 'center',
                          borderRadius: '0',
                        }}
                        onChange={countInputOnChange}
                        onWheel={(e) => e.target.blur()}
                        onClick={countInputOnClick}
                        onKeyDown={countInputOnKeyDown}
                      />
                    )}

                    <button
                      style={{ width: '100%' }}
                      type="button"
                      onClick={!inputIndex ? countUpBtnClick : null}
                    >
                      <FiPlus size={15} />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {SMALL_SCREEN && (
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <RxCross2
                style={{
                  position: 'relative',
                  width: '20px',
                  height: '20px',
                  right: '0px',
                  top: '0px',
                  cursor: 'pointer',
                }}
                onClick={onDeleteClick}
              />
            </div>
          )}
        </div>
        {!item.product_option_idx &&
          item.count < item.product_min_order_quantity &&
          !quantityCheck && (
            <p style={{ textAlign: 'center', color: '#e20000' }}>
              *최소주문수량 미달(최소: {item.product_min_order_quantity}개)
            </p>
          )}
        {item.product_option_idx &&
          item.count < item.product_option_min_order_quantity &&
          !quantityCheck && (
            <p style={{ textAlign: 'center', color: '#e20000' }}>
              *최소주문수량 미달(최소: {item.product_option_min_order_quantity}개)
            </p>
          )}
        {!item.product_option_idx && item.count > item.product_stock_quantity && !quantityCheck && (
          <p style={{ textAlign: 'center', color: '#e20000' }}>
            *재고수량 초과({item.product_stock_quantity}개 주문가능)
          </p>
        )}
        {item.product_option_idx &&
          item.count > item.product_option_stock_quantity &&
          !quantityCheck && (
            <p style={{ textAlign: 'center', color: '#e20000' }}>
              *재고수량 초과({item.product_option_stock_quantity}개 주문가능)
            </p>
          )}
        {SMALL_SCREEN && quantityCheck ? (
          <div
            style={{
              position: 'relative',
              fontWeight: '700',
              color: '#d20000',
              right: '0',
              bottom: '0',
              width: '126px',
              textAlign: 'center',
            }}
          >
            SOLD-OUT
          </div>
        ) : SMALL_SCREEN && !quantityCheck ? (
          <div style={{ position: 'relative', display: 'flex', justifyContent: 'right' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                boxSizing: 'border-box',
                border: '1px solid #ddd',
                width: '126px',
              }}
            >
              <button
                style={{ width: '100%' }}
                type="button"
                onClick={!inputIndex ? countDownBtnClick : null}
              >
                <FiMinus size={15} />
              </button>
              {quantity && (
                <input
                  id={index}
                  type="number"
                  ref={(el) => (inputRef.current[index] = el)}
                  value={quantity[index] || ''}
                  style={{
                    border: '1px solid #ddd',
                    borderTop: 'none',
                    borderBottom: 'none',
                    boxSizing: 'border-box',
                    width: '60px',
                    height: '32px',
                    textAlign: 'center',
                    borderRadius: '0',
                  }}
                  onChange={countInputOnChange}
                  onWheel={(e) => e.target.blur()}
                  onClick={countInputOnClick}
                  onKeyDown={countInputOnKeyDown}
                />
              )}

              <button
                style={{ width: '100%' }}
                type="button"
                onClick={!inputIndex ? countUpBtnClick : null}
              >
                <FiPlus size={15} />
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  },
);
CartItemLayout.displayName = 'CartItemLayout';
