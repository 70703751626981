import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IsLoggedIn } from '../../utils/IsLoggedIn';
import { usePopup } from '../popup/usePopup';

export const useLoginCheck = () => {
  const isLoggedIn = IsLoggedIn();
  const navigate = useNavigate();
  const { openPopup } = usePopup();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  return { isLoggedIn };
};
