import React, { useCallback } from 'react';

import '../../../assets/css/Sub.css';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { endPoint } from '../../../api/endPoint';
import EmptyDataPage from '../../../components/EmptyDataPage';
import ErrorFetching from '../../../components/error/ErrorFetching';
import HeaderTitle from '../../../components/HeaderTitle';
import ProductLayout from '../../../components/layout/ProductLayout';
import { useMediaQueryCustom } from '../../../components/mediaQueryCustom/useMediaQueryCustom';
import Pagination from '../../../components/Pagination';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { exportCategoryPathAndName } from '../../../utils/exportCategoryName';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';
import ValidateInput from '../../../utils/ValidateInput';

// 본문 ---
function ProductList({ categoryData }) {
  const navigate = useNavigate();

  const isLoggedIn = IsLoggedIn();

  // 반응형 BreakPoint훅
  const { SMALL_SCREEN } = useMediaQueryCustom();

  // 파람스 데이터 꺼내기
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchProductName = params.get('search_product_name');
  const searchCategoryIdx = params.get('search_category_idx');
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');

  const goToDetail = (e) => {
    const key = e.currentTarget.getAttribute('data-key');
    navigate(`/pages/product/detail/${key}`);
  };

  const { data, isError } = useQueryInstance({
    queryKey: [queryKeys.productList, searchProductName, searchCategoryIdx, page, count],
    apiMethod: 'get',
    apiEndPoint: endPoint.product.getProductList,
    apiQueryParams: {
      search_product_name: searchProductName,
      search_category_idx: searchCategoryIdx,
      page,
      count,
    },
  });

  const selectorHandler = useCallback(
    (e) => {
      const value = e.target.value;
      switch (value) {
        case '20':
          return navigate(
            `/pages/product/list/?${
              searchCategoryIdx ? `search_product_idx=${searchCategoryIdx}&` : ''
            }${
              searchProductName ? `search_product_name=${searchProductName}&` : ''
            }page=1&count=${value}`,
          );
        case '40':
          return navigate(
            `/pages/product/list/?${
              searchCategoryIdx ? `search_product_idx=${searchCategoryIdx}&` : ''
            }${
              searchProductName ? `search_product_name=${searchProductName}&` : ''
            }page=1&count=${value}`,
          );
        case '60':
          return navigate(
            `/pages/product/list/?${
              searchCategoryIdx ? `search_product_idx=${searchCategoryIdx}&` : ''
            }${
              searchProductName ? `search_product_name=${searchProductName}&` : ''
            }page=1&count=${value}`,
          );
      }
    },
    [searchCategoryIdx, searchProductName],
  );

  if (isError) {
    return <ErrorFetching isError={isError} />;
  }

  return (
    <div className="content">
      <div className="container section">
        <h1 style={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>도매판매</h1>
        <HeaderTitle
          selectorData={[
            { label: '20개씩 보기', value: '20' },
            { label: '40개씩 보기', value: '40' },
            { label: '60개씩 보기', value: '60' },
          ]}
          selectorMaxWidth="135px"
          selectorFontSize="15px"
          selectorFontColor="#5a5a5a"
          selectorChangeEvent={selectorHandler}
          selectorValue={
            count === '20' ? '20' : count === '40' ? '40' : count === '60' ? '60' : '20'
          }
        >
          {!searchProductName && !searchCategoryIdx
            ? '모든 상품'
            : !searchCategoryIdx && searchProductName
            ? `검색 [${searchProductName}] 에 대한 결과`
            : searchCategoryIdx && !searchProductName
            ? exportCategoryPathAndName(categoryData?.data_list, searchCategoryIdx)
            : null}
        </HeaderTitle>
        <div className="content-head">
          <div className="btn-list">
            <Link to="/pages/product/write">
              <button type="button" className="btn-item btn-add">
                상품등록
              </button>
            </Link>
          </div>
        </div>

        {data?.data_list?.length === 0 && !searchProductName ? (
          <EmptyDataPage />
        ) : data?.data_list?.length === 0 && searchProductName ? (
          <EmptyDataPage title="검색 결과가 없습니다." />
        ) : (
          <div className="product-list-grid">
            {data?.data_list?.map((item, index) => {
              const imgUrl = item.img_url;

              var imageUrl = '';

              if (ValidateInput(imgUrl)) {
                imageUrl = imgUrl;
              }

              return (
                <ProductLayout
                  alt="도매판매상품이미지"
                  isSoldOut={Number(item?.stock_quantity) < Number(item?.min_order_quantity)}
                  isSoldOutFontSize={SMALL_SCREEN ? '10vw' : undefined}
                  key={item.idx}
                  imgSrc={imageUrl}
                  idx={item.idx}
                  name={item.name}
                  price={!isLoggedIn ? null : `${item?.price?.toLocaleString('ko-KR')} 원`}
                  companyName={item.company_name}
                  onClickEvent={goToDetail}
                  productType="도매판매"
                />
              );
            })}
          </div>
        )}
        {data?.data_list?.length > 0 && (
          <Pagination
            conMargin="50px 0 0 0"
            page={page}
            totalCount={data?.total_count}
            count={count}
            naviPath={`/pages/product/list/?${
              searchCategoryIdx ? `search_product_idx=${searchCategoryIdx}&` : ''
            }${searchProductName ? `search_product_name=${searchProductName}&` : ''}`}
          />
        )}
      </div>
    </div>
  );
}

export default ProductList;
