import { useMutation } from '@tanstack/react-query';

import instance from '../api/axios';

export const useMutationInstance = ({
  onSuccessFn,
  onErrorFn,
  onMutateFn,
  apiMethod,
  apiEndPoint,
  apiMultipartPost,
}) => {
  // api 세팅
  const apiSet = async ({ apiQueryParams, apiPathParams, apiBody = null }) => {
    switch (apiMethod) {
      case 'get': {
        const response = await instance.get(
          `${apiEndPoint}${apiPathParams ? '/' + apiPathParams : ''}`,
          {
            params: apiQueryParams,
          },
        );
        return response.data;
      }

      case 'post': {
        const response = await instance.post(
          `${apiEndPoint}${apiPathParams ? '/' + apiPathParams : ''}`,
          apiBody,
          {
            params: apiQueryParams,
            headers: apiMultipartPost && {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return response.data;
      }

      default:
        break;
    }
  };

  // useMutation 세팅 및 요청
  const { mutate, isLoading } = useMutation({
    mutationFn: ({ apiQueryParams, apiPathParams, apiBody }) =>
      isLoading
        ? null
        : apiSet({
            apiQueryParams,
            apiPathParams,
            apiBody,
          }),
    onError: onErrorFn,
    onSuccess: onSuccessFn,
    onMutate: onMutateFn,
  });

  return { mutate };
};
