import React, { useEffect } from 'react';
import { TiArrowLeftThick } from 'react-icons/ti';
import { useNavigate, useParams } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/PayFinal.module.scss';
import { colorList } from '../../../assets/style/theme';
import BtnNormal from '../../../components/BtnNormal';
import ErrorFetching from '../../../components/error/ErrorFetching';
import HeaderTitle from '../../../components/HeaderTitle';
import SectionLayout from '../../../components/layout/SectionLayout';
import { usePopup } from '../../../components/popup/usePopup';
import { CACHE_TIME } from '../../../constant/NUMBER_CONSTANT';
import { ORDER_ITEM_STATUS } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { findBank } from '../../../utils/findBank';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';

const PaymentDetail = () => {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const { idx: order_item_idx } = useParams();

  const {
    data: item,
    isError,
    refetch,
  } = useQueryInstance({
    queryKey: [queryKeys.getOrderItem_admin, order_item_idx],
    apiMethod: 'get',
    apiEndPoint: endPoint.order.getOrderItem,
    apiQueryParams: { order_item_idx },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: CACHE_TIME.DEFAULT,
    selectFn: (data) => {
      const item = data.data;
      const newData = {
        ...item,
        order_properties: JSON.parse(item.order_properties),
        product_option_name: item.order_item_properties
            ? JSON.parse(item.order_item_properties).product_option_name
            : null,
      };
      return newData;
    },
  });


  //정산 API (수기임, 기록만)
  const { mutate: finalCalculate } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.updateOrderItemStatus,
    onErrorFn: () => {
      openPopup({ title: '오류', content: '다시 시도해주세요.' });
    },
    onSuccessFn: () => {
      openPopup({ title: '안내', content: '정산완료 되었습니다.' });
      refetch();
    },
  });

  // 정산 핸들러
  const fianlCalculateHandler = () => {
    finalCalculate({
      apiBody: {
        order_idx: item.order_idx,
        order_item_idx: item.order_item_idx,
        status_code: ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE,
      },
    });
  };

  // 환불 API (페이플로 요청 들어감)
  // 주문 취소API ----------
  const { mutate: refundMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.orderCancel,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: () => {
      openPopup({ title: '안내', content: '환불완료 되었습니다.' });
      refetch();
    },
  });

  const onRefund = () => {
    const cancelData = {
      order_idx: item.order_idx,
      order_item_idx: item.order_item_idx,
      is_admin: 1,
    };

    openPopup({
      title: '안내',
      content: '정말 취소하시겠습니까?',
      onComplete: () => {
        refundMutation({
          apiBody: cancelData,
        });
      },
      twoButton: true,
    });
  };

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <>
      <SectionLayout minHeight="400px" maxWidth="900px" padding="50px 30px 70px 30px">
        <HeaderTitle
          textAlign="center"
          fontSize="24px"
          fontWeight="500"
          btnTitle="뒤로가기"
          btnFontSize="15px"
          btnFontWeight="500"
          btnOnClickEvent={() => navigate(-1)}
        >
          결제 상세 내역
        </HeaderTitle>
        <div className={style.mainContainer}>
          <div className={style.textBoxWrap} style={{ maxWidth: '600px' }}>
            <p className={style.memberName}>
              판매자 (회사): <span>{item?.name} ({item?.company_name})</span>
            </p>
            <p className={style.memberName}>
              구매자 (회사): <span>{item?.order_member_name} ({item?.order_member_company_name})</span>
            </p>
            <p className={style.payGoodsName_detail}>
              상품명: <span>{item?.product_name} {item?.product_option_name ? '(' + item?.product_option_name + ')' : ''}</span>
            </p>
            <p className={style.payOid}>
              주문번호: <span>{item?.order_number}</span>
            </p>
            <p className={style.payTime}>
              주문일시: <span>{item?.order_time}</span>
            </p>
            <p className={style.payType}>
              결제형태: <span>{item?.payment_type === 10 ? '카드결제' : '계좌이체'}</span>
            </p>

            <p className={style.payProccess}>
              결제상태:
              <span>
                {item?.order_item_status === ORDER_ITEM_STATUS.ORDER_WAIT
                  ? '입금대기'
                  : item?.order_item_status === ORDER_ITEM_STATUS.ORDER
                  ? '입금완료'
                  : item?.order_item_status === ORDER_ITEM_STATUS.ORDER_COMPLETE
                  ? '구매확정'
                  : item?.order_item_status === ORDER_ITEM_STATUS.CANCEL
                  ? '주문취소'
                  : item?.order_item_status === ORDER_ITEM_STATUS.REFUND
                  ? '환불'
                  : '관리자 문의'}
              </span>
            </p>

            <p className={style.payType}>
              업체등록계좌:
              <span>
                ({findBank(item?.bank_standard_code)}) {item?.account_number}
              </span>
            </p>

            <p className={style.payType}>
              개별단가:
              <span>{(item?.order_price / item?.order_count).toLocaleString('ko-KR')}₩</span>
            </p>

            <p className={style.payType}>
              주문수량:
              <span>{item?.order_count} 개 </span>
            </p>

            <p className={style.payTotalPrice}>
              총 결제금액:
              <span>{item?.order_price?.toLocaleString('ko-KR')} ₩</span>
            </p>

            <p className={style.payType} style={{ fontSize: '12px' }}>
              * 모두 vat포함 금액
            </p>
          </div>
        </div>
        <div className={style.btnWrap}>
          {item?.order_item_status === ORDER_ITEM_STATUS.ORDER && (
            <>
              <BtnNormal
                onClickEvent={() => {
                  fianlCalculateHandler();
                }}
                flex="1"
              >
                정산
              </BtnNormal>
              <BtnNormal
                flex="1"
                bgColor={colorList.main}
                onClickEvent={() => {
                  onRefund();
                }}
              >
                환불
              </BtnNormal>
            </>
          )}
          {/* {(item?.order_item_status === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE ||
            item?.order_item_status === ORDER_ITEM_STATUS.REFUND_REQUEST) && (
            <BtnNormal
              flex="1"
              bgColor={colorList.main}
              onClickEvent={() => {
                openPopup({ title: '안내', content: '서비스 준비 중' });
                // fianlCalculateHandler();
              }}
            >
              취소
            </BtnNormal>
          )} */}
          {item?.order_item_status === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE && (
            <p className={style.complete_text}>정산완료되었습니다.</p>
          )}
          {item?.order_item_status === ORDER_ITEM_STATUS.CANCEL && (
            <p className={style.complete_text}>환불완료 된 상품입니다.</p>
          )}
        </div>
      </SectionLayout>
    </>
  );
};

export default PaymentDetail;
