import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MEMBER_ROLE } from '../../constant/TYPE_CONSTANT';
import { decodingCrypto } from '../../utils/crypto';

const isAuthenticated = (isAdmin) => {
  if (isAdmin) {
    const memberRole = decodingCrypto(localStorage.getItem('member_role'));

    return memberRole != null && parseInt(memberRole) <= MEMBER_ROLE.MIN_ADMIN;
  } else {
    const token = decodingCrypto(localStorage.getItem('token'));
    return token !== null;
  }
};

const ProtectedRoute = ({ children, isAdmin = false }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated(isAdmin)) {
      navigate(isAdmin ? '/' : '/pages/member/login');
    }
  }, [navigate, isAdmin]);

  if (!isAuthenticated(isAdmin)) {
    return null;
  }

  return children;
};

export default React.memo(ProtectedRoute);
