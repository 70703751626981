import React from 'react';
import styled from 'styled-components';

import SearchMagnifier from './icon_svg/SearchMagnifier';
import InputBox from './InputBox';

// style
const SearchBox = styled.div`
  position: relative;
  box-sizing: border-box;
  width: ${({ boxWidth }) => boxWidth};
  height: fit-content;
  background-color: ${({ conBackgroundColor }) => conBackgroundColor};
  z-index: ${({ conZIndex }) => conZIndex};
  padding: ${({ conPadding }) => conPadding};
  margin: ${({ conMargin }) => conMargin};
`;

const ButtonIcon = styled.button`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
`;

const InputSearch = ({
  iconOnClickEvent,
  inputRef,
  inputValue,
  inputOnChangeEvent,
  inputPadding = '10px 20px',
  inputPlacehlder = '검색어를 입력하세요',
  inputFontSize,
  placeholderFontSize,
  boxWidth = '100%',
  boxHeight = 'fit-content',
  borderRadius = '25px',
  iconSize = '20',
  conBackgroundColor,
  conZIndex,
  conPadding,
  conMargin,
  inputWidth,
}) => {
  return (
    <SearchBox
      boxWidth={boxWidth}
      boxHeight={boxHeight}
      conZIndex={conZIndex}
      conBackgroundColor={conBackgroundColor}
      conPadding={conPadding}
      conMargin={conMargin}
    >
      <form>
        <ButtonIcon type="submit" onClick={iconOnClickEvent}>
          <SearchMagnifier size={iconSize} />
        </ButtonIcon>
        <InputBox
          inputPadding={inputPadding}
          placeholder={inputPlacehlder}
          containerMargin="0px"
          borderRadius={borderRadius}
          border="2px solid #D9D9D9"
          inputHeight={boxHeight}
          inputRef={inputRef}
          inputValue={inputValue}
          onChangeEvent={inputOnChangeEvent}
          inputFontSize={inputFontSize}
          placeholderFontSize={placeholderFontSize}
          inputWidth={inputWidth}
        />
      </form>
    </SearchBox>
  );
};

export default React.memo(InputSearch);
