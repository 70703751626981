/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  line-height: 1.5;
`;

const POLICY_PERSONAL = () => {
  return (
    <P>
      제 1장 개인정보의 수집 및 이용목적에 대한 사항으로 회사는 수집한 개인정보를 다음의 목적을 위해
      활용합니다.
      <br />
      서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산콘텐츠 제공 , 구매 및 요금 결제 ,
      물품배송 또는 청구지 등 발송 , 금융거래 본인 인증 및 금융 서비스
      <br />
      회원관리회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용
      방지 , 가입 의사 확인, 불만처리 등 민원처리, 고지사항 전달
      <br />
      마케팅 및 광고에 활용신규 서비스(제품) 개발 및 특화 접속 빈도 파악 또는 회원의 서비스 이용에
      대한 통계, 이벤트 등 광고성 정보 전달
      <br />
      (회원님의 개인정보는 광고를 의뢰한 개인이나 단체에는 제공되지 않습니다.)
      <br />
      회사가 제공할 서비스를 보다 유익하고 효율적으로 이용할 수 있도록 도움을 주기위해 최소한의
      정보를 수집합니다.
      <br />
      <br />
      <br />
      제 2장 수집하는 개인정보의 항목 및 수집방법
      <br />
      <br />
      가. 수집하는 개인항목① 최초 회원가입시 회원식별 및 최적화된 서비스 제공을 위해 아래와 같은
      정보를 수집합니다.
      <br />
      필수항목 : 이름, ID, 비밀번호, 비밀번호확인, 휴대전화번호, 회사명, 사업자등록증,
      사업자등록번호, 개업입일자, 대표자명 등등
      <br />
      정보선택사항 : 이메일, 회사번호
      <br />
      서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
      <br />
      서비스 이용기록, 접속로그, 위치정보, 쿠키, 접속IP 정보, 결제기록, 불량이용 기록
      <br />
      또한 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
      <br />
      - 이용자의 브라우저 종류 및 OS, 검색어, 서비스 이용 기록
      <br />
      - IP Address, 방문 일시, 서비스 이용기록, 불량 이용 기록
      <br />
      <br />
      나. 수집방법
      <br />
      ① 홈페이지(회원가입) , 전화/팩스를 통한 회원가입 , 전시회, 경품 행사 응모 , 배송 요청 , 협회,
      제휴사로부터의제공
      <br />
      ② 생성정보 수집 툴을 통한 수집
      <br />
      <br />
      <br />
      제 3 장 개인정보의 보유 및 이용기간
      <br />
      원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
      <br />
      단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존합니다.
      <br />
      <br />
      ① 회원탈퇴시 보존 개인정보
      <br />
      1. 보존항목 : 회원님께서 가입 시, 제공한 모든 정보
      <br />
      2. 보존근거 : 불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및 수사협조
      <br />
      3. 보존기간 : 회원탈퇴 후 1년
      <br />
      <br />
      ② 상거래 관련 보존 개인정보
      <br />
      1. 보존항목 : 상거래이력
      <br />
      2. 보존근거 : 상법, 전자상거래등에서의 소비자보호에 관한 법률
      <br />
      3. 보존기간 : 계약 또는 청약철회 등에 관한 기록 : 5년, 대금결제 및 재화등의 공급에 관한 기록 :
      5년, 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
      <br />
      <br />
      <br />
      제 4 장 개인정보의 파기절차 및 방법
      <br />
      파기절차
      <br />
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에
      의한 정보보호 사유에 따라(보유 및 이용기간 참조)
      <br />
      일정 기간 저장된 후 파기되어집니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는
      이외의 다른 목적으로 이용되지 않습니다.
      <br />
      파기방법
      <br />
      - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
      <br />
      - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법 을 사용하여
      삭제합니다.
      <br />
      <br />
      <br />
      제 5 장 개인정보의 제공 및 공유
      <br />
      원칙적으로 회사는 회원님의 개인정보를 수집 및 이용목적에 한해서만 이용하며 타인 또는
      타기업ㆍ기관에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
      <br />
      <br />
      ① 이용자들이 사전에 동의한 경우
      <br />
      정보수집 또는 정보제공 이전에 회원님께 비즈니스 파트너가 누구인지, 어떤 정보가 왜 필요한지,
      그리고 언제까지 어떻게 보호/관리되는지 알려드리고 동의를 구하는 절차를 거치게 되며, 회원님께서
      동의하지 않는 경우에는 추가적인 정보를 수집하거나 비즈니스 파트너와 공유하지 않습니다.
      <br />
      ② 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가
      있는 경우
      <br />
      <br />
      <br />
      제 6 장 개인정보 취급위탁
      <br />
      서비스 향상을 위해서 회원의 개인정보를 위탁업체에서 수집, 분석하고 있으며, 관계 법령에 따라
      위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
      <br />
      - 수탁자, 수탁범위, 공유 정보의 범위 등에 관한 사항을 전자우편, 전화 또는 홈페이지를 통해
      게시합니다.
      <br />
      - 위탁계약 시 서비스 제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자
      제공의 금지 등을 규정하고 계약내용을 전자적으로 보관합니다.
      <br />
      현재 위탁업체의 업무 내용은 다음과 같습니다.
      <br />
      회원아이디, 쿠키, 이동경로 등의 로그데이터웹로그분석계약시작일로부터 계약종료일까지
      <br />
      <br />
      <br />
      제 7 장 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      <br />
      회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로
      불러오는 '쿠키(cookie)'를 사용합니다.
      <br />
      쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의
      정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
      <br />
      <br />
      ① 쿠키의 사용 목적
      <br />
      회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적,
      각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한
      <br />
      타겟 마케팅 및 개인 맞춤 서비스 제공 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다.
      <br />
      따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다
      확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      <br />
      <br />
      ② 쿠키 설정 거부 방법
      <br />
      - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을
      설정함으로써 모든 쿠키를 허용하거나,
      <br />
      쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      <br />
      - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수
      있습니다.
      <br />
      - 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)
      <br />
      ① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.
      <br />
      ② [개인정보 탭]을 클릭합니다.
      <br />
      ③ [개인정보취급 수준]을 설정하시면 됩니다.
      <br />
      <br />
      <br />
      제 8 장 개인정보보호를 위한 기술적/관리적 대책
      <br />
      <br />
      - 관리적인 대책
      <br />
      <br />
      1. 회원님 스스로도 제3자에게 비밀번호 등이 노출되지 않도록 주의하셔야 합니다.
      <br />
      특히 비밀번호 등이 공공장소에 설치한 PC를 통해 유출되지 않도록 항상 유의하시기 바랍니다.
      <br />
      회원님의 ID와 비밀번호는 반드시 본인만 사용하시고 비밀번호를 자주 바꿔주시는 것이 좋습니다.
      <br />
      2. 회사는 개인정보 취급직원을 개인정보 관리업무를 수행하는 자 및 업무상 개인정보의 취급이
      불가피 한 자로 엄격히 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고
      있으며, 직원의 개인정보 열람을 시스템적으로 감시하고, 다량의 정보가 필요한 경우, 반드시
      정보관리 책임자가 허락한 경우에만 열람이 가능하도록 내부적인 보안절차를 엄격히 준수하고
      있습니다.
      <br />
      <br />
      <br />
      제 9 장 이용자 및 법정대리인의 권리와 그 행사 방법
      <br />
      - 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며
      가입해지를 요청할 수도 있습니다.
      <br />
      - 이용자의 개인정보 조회ㆍ수정을 위해서는 회사 홈에 있는 '회원정보 수정하기'를, 가입해지시에는
      '고객센터' 를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
      <br />
      - 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
      <br />
      - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해
      개인정보를 이용 또는 제공하지 않습니다.
      <br />
      - 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이
      통지하여 정정이 이루어지도록 하겠습니다.
      <br />
      - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는
      개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수
      없도록 처리하고 있습니다.
      <br />
      <br />
      <br />
      제 10 장 개인정보관리책임자 및 상담ㆍ신고
      <br />
      <br />
      회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련
      부서 및 개인정보관리책임자를 지정하고 있습니다.
      <br />
      <br />
      개인정보 관리책임자
      <br />
      이 름 CEO 오원기
      <br />
      소 속 스판
      <br />
      연락처 010-5027-3399 nobusy.info@gmail.com
      <br />
      <br />
      귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
      개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
      <br />
      회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
      <br />
      기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
      <br />
      <br />
      - 개인분쟁조정위원회 (www.1336.or.kr / 1336)
      <br />
      - 대검찰청 인터넷범죄수사센터 (www.spo.go.kr / 02-3480-3600)
      <br />
      - 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)
      <br />
      <br />
      <br />
      제 11 장 부칙
      <br />
      <br />
      법령.정책 또는 보안기술의 변경에 따라 내용의 추가.삭제 및 수정이 있을 시에는 변경사항 시행일의
      7일전부터 회사 사이트의 공지사항을 통하여 고지합니다.
      <br />
      <br />
      - 이 개인정보취급방침은 2023년 08월 30일부터 적용됩니다.
      <br />
      - 개인정보취급방침 변경공고일자 : 2023년 08월 30일
      <br />
      - 개인정보취급방침 시행 일자 : 2023년 08월 30일
      <br />
    </P>
  );
};

export default POLICY_PERSONAL;
