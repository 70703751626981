import { useEffect, useState } from 'react';
import _ from 'lodash';

export const useScrollEvent = ({ points = [], throttle = 300, eventActive = true }) => {
  const [isPoints, setIsPoints] = useState(
    points.reduce((acc, point) => ({ ...acc, [point]: false }), {}),
  );

  useEffect(() => {
    const handler = _.throttle(() => {
      const scrollPosition = window.scrollY;
      let pointCheck = {};

      points.forEach((point) => {
        pointCheck[point] = scrollPosition >= point;
      });

      setIsPoints(() => pointCheck);
    }, throttle);

    eventActive && window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [eventActive]);

  return isPoints;
};
