import React from 'react';
import styled from 'styled-components';

import { active, hover, pointer } from '../assets/style/pointer';
import { colorList } from '../assets/style/theme';
import { ORDER_ITEM_STATUS } from '../constant/TYPE_CONSTANT';
import { dateFormatHelper } from '../utils/dateHelper';
import BtnNormal from './BtnNormal';
import EmptyDataPage from './EmptyDataPage';
import EllipsisText from './textLimit/EllipsisText';
import { useMobileCustom } from './useMobileCustom/useMobileCustom';

const Article = styled.article`
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: ${({ containerMargin }) => containerMargin};
  width: 100%;
  min-height: ${({ conMinHeight }) => conMinHeight};
`;

const HeadFlexBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: #fbfbfb;
  border-bottom: 1px solid #e0e0e0;
`;

const HeadLabel = styled.p`
  position: relative;
  flex: ${({ flex }) => flex};
  font-size: 16px;
  padding: 20px;
  text-align: center;
`;

const HeadLabelChildren = styled.div`
  position: relative;
  flex: ${({ flex }) => flex};
  font-size: 16px;
  padding: 20px;
  text-align: center;
`;

const HeadLabelSub = styled.span`
  position: relative;
  display: block;
  margin-top: 10px;
  color: ${({ soldOut }) => (!soldOut ? '#777777' : '#d20000')};
  font-size: 14px;
  text-align: center;
  cursor: ${({ sales }) => sales && 'pointer'};
  ${({ sales, isMobile }) =>
    sales && !isMobile && hover({ color: 'none', textUnderline: 'underline' })};
  ${({ sales }) => sales && active};
`;

const ContentFlexBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #eeeeee;
  ${({ isMobile }) => pointer({ isMobile: isMobile })};
  ${({ isMobile }) => !isMobile && hover};
`;

const ContentsLine = styled.div`
  position: relative;
  height: fit-content;
  flex: ${({ flex }) => flex};
  font-size: 15px;
  padding: 20px;
`;

const ProductDefaultInfo = styled.div`
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const ImgWrap = styled.div`
  position: relative;
  display: block;
  // 1:1 비율유지
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 0.5px solid rgb(153, 153, 153);
`;

const Img = styled.img`
  position: relative;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
`;

const InfoCompany = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
`;

const ShippingNumberBtn = styled.span`
  position: relative;
  display: block;
  cursor: ${({ isMobile }) => !isMobile && 'pointer'};
  padding: 5px;
  width: 100px;
  border: 0.5px solid black;
  background-color: #ededed;
  font-weight: 200;
  font-size: 15px;
  border-radius: 20px;
  ${({ isMobile }) => !isMobile && hover({ color: 'none', opacity: '0.6' })}
  ${active({ opacity: '0.4' })}
`;

const Grid_OrderList = ({
  data,
  labelData = [
    // 샘플
    {
      label: ['상품정보'],
      labelKey: ['img_url', '', 'product_name'],
      flex: 3,
      img: true,
    },
    {
      label: ['주문번호', '주문일자'],
      labelKey: ['order_number', 'order_time'],
      flex: 1.5,
      date: [false, true],
    },
    {
      // toLocale옵션은 3개 labelKey까지 가능
      label: ['주문금액', '수량'],
      labelKey: ['order_price', 'order_count', 'min_order_quantity'],
      flex: 0.8,
      toLocale: [
        [true, '원'],
        [true, '(개)'],
        [true, '(개)'],
      ],
    },
    {
      // 재고상태와 최소주문수량 비교하여 솔드아웃처리 하고 싶다면 quantityCheck 옵션
      label: ['주문금액', '수량'],
      labelKey: ['order_price', 'order_count', 'min_order_quantity'],
      flex: 0.8,
      quantityCheck: [
        [true, '원'],
        [true, '(개)'],
        [true, '(개)'],
      ],
    },
    {
      label: ['택배사', '송장번호'],
      labelKey: ['shipping_company_name', 'shipping_number'],
      flex: 1,
      shippingNumber: {
        sales: true,
        orderItemStatus: 'order_item_status',
        // 송장 입력 핸들, sales true일때만 가능
        onClickEventShippingNumber: shippingCodeBtnHandler,
        onClickEventShippingNumberEdit: shippingCodeEditHandler,
      },
    },
    {
      label: ['판매상태'],
      labelKey: ['order_item_status'],
      flex: 1,
      orderItemStatusRender: {
        onRefund: onRefund,
        onCancel: undefined,
        isOrderList: false,
      },
    },
  ],
  idx,
  conMinHeight,
  search_order_number,
  search_product_name,
  onClickEventToDetail,
}) => {
  const isMobile = useMobileCustom();

  return (
    <Article conMinHeight={conMinHeight}>
      <HeadFlexBox>
        {labelData?.map((el, index) => (
          <HeadLabel key={`label_${el.labelKey}_${index}`} flex={el.flex}>
            {el.label[0]}
            {el.label[1] ? <HeadLabelSub>{`(${el.label[1]})`}</HeadLabelSub> : null}
          </HeadLabel>
        ))}
      </HeadFlexBox>
      {data?.length !== 0 ? (
        data?.map((el, _) => (
          <ContentFlexBox
            key={el[idx]}
            isMobile={isMobile}
            onClick={() => onClickEventToDetail(el[idx])}
          >
            {labelData.map((labelObj, labelIndex) => (
              <ContentsLine
                flex={labelObj.flex}
                key={`${el[idx]}_${labelObj.labelKey}_${labelIndex}`}
              >
                {labelObj.img ? (
                  <ProductDefaultInfo>
                    <ImgWrap>
                      <Img src={el[labelObj.labelKey[0]]} alt="상품이미지" />
                    </ImgWrap>
                    <div>
                      {el[labelObj.labelKey[1]] ? (
                        <InfoCompany>{el[labelObj.labelKey[1]]}</InfoCompany>
                      ) : null}
                      {el[labelObj.labelKey[2]] ? (
                        <EllipsisText
                          fontSize="16px"
                          fontWeight="700"
                          textAlign="left"
                          text={el[labelObj.labelKey[2]]}
                          maxLength={20}
                        />
                      ) : null}
                      {el[labelObj.labelKey[3]] ? (
                        <EllipsisText
                          margin="10px 0 0 0"
                          fontSize="14px"
                          fontWeight="400"
                          textAlign="left"
                          color={colorList.darkGray}
                          text={el[labelObj.labelKey[3]]}
                          maxLength={20}
                        />
                      ) : null}
                    </div>
                  </ProductDefaultInfo>
                ) : labelObj.date ? (
                  <HeadLabel>
                    {labelObj.date[0]
                      ? dateFormatHelper(el[labelObj.labelKey[0]])
                      : el[labelObj.labelKey[0]]}

                    {el[labelObj.labelKey[1]] ? (
                      <HeadLabelSub>
                        {labelObj.date[1]
                          ? `(${dateFormatHelper(el[labelObj.labelKey[1]])})`
                          : el[labelObj.labelKey[1]]}
                      </HeadLabelSub>
                    ) : null}
                  </HeadLabel>
                ) : labelObj.toLocale ? (
                  <HeadLabel>
                    {labelObj.toLocale[0][0]
                      ? `${el[labelObj.labelKey[0]]?.toLocaleString('ko-KR')} ${
                          labelObj.toLocale[0][1]
                        }`
                      : el[labelObj.labelKey[0]]}

                    {el[labelObj.labelKey[1]] && !el[labelObj.labelKey[2]] ? (
                      <HeadLabelSub>
                        {labelObj.toLocale[1][0]
                          ? `${el[labelObj.labelKey[1]]?.toLocaleString('ko-KR')} ${
                              labelObj.toLocale[1][1]
                            }`
                          : el[labelObj.labelKey[1]]}
                      </HeadLabelSub>
                    ) : null}

                    {el[labelObj.labelKey[2]] && el[labelObj.labelKey[1]] ? (
                      <HeadLabelSub>
                        {labelObj.toLocale[2][0]
                          ? `${el[labelObj.labelKey[1]]?.toLocaleString('ko-KR')} ${
                              labelObj.toLocale[1][1]
                            } / ${el[labelObj.labelKey[2]]?.toLocaleString('ko-KR')} ${
                              labelObj.toLocale[2][1]
                            } `
                          : `${el[labelObj.labelKey[1]]} / ${el[labelObj.labelKey[2]]}`}
                      </HeadLabelSub>
                    ) : null}
                  </HeadLabel>
                ) : labelObj.quantityCheck ? (
                  <HeadLabel>
                    {labelObj.quantityCheck[0][0]
                      ? `${el[labelObj.labelKey[0]]?.toLocaleString('ko-KR')} ${
                          labelObj.quantityCheck[0][1]
                        }`
                      : el[labelObj.quantityCheck[0]]}

                    {el[labelObj.labelKey[1]] && !el[labelObj.labelKey[2]] ? (
                      <HeadLabelSub>
                        {labelObj.quantityCheck[1][0]
                          ? `${el[labelObj.labelKey[1]]?.toLocaleString('ko-KR')} ${
                              labelObj.quantityCheck[1][1]
                            }`
                          : el[labelObj.labelKey[1]]}
                      </HeadLabelSub>
                    ) : null}

                    {el[labelObj.labelKey[2]] && el[labelObj.labelKey[1]] ? (
                      <>
                        <HeadLabelSub>
                          {labelObj.quantityCheck[2][0]
                            ? `${el[labelObj.labelKey[1]]?.toLocaleString('ko-KR')} ${
                                labelObj.quantityCheck[1][1]
                              } / ${el[labelObj.labelKey[2]]?.toLocaleString('ko-KR')} ${
                                labelObj.quantityCheck[2][1]
                              } `
                            : `${el[labelObj.labelKey[1]]} / ${el[labelObj.labelKey[2]]}`}
                        </HeadLabelSub>
                        <HeadLabelSub soldOut={true}>
                          {Number(el[labelObj.labelKey[1]]) < Number(el[labelObj.labelKey[2]]) &&
                            'SOLD-OUT'}
                        </HeadLabelSub>
                      </>
                    ) : null}
                  </HeadLabel>
                ) : labelObj.shippingNumber ? (
                  <HeadLabel>
                    {el[labelObj.labelKey[0]]}

                    {!labelObj.labelKey[1] ? null : (
                      <HeadLabelSub>
                        {(!labelObj.shippingNumber.sales && !el[labelObj.labelKey[1]]) ||
                        (labelObj.shippingNumber.sales &&
                          !el[labelObj.labelKey[1]] &&
                          el[labelObj.shippingNumber.orderItemStatus] ===
                            ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE) ? (
                          '(없음)'
                        ) : !labelObj.shippingNumber.sales && el[labelObj.labelKey[1]] ? (
                          el[labelObj.labelKey[1]]
                        ) : labelObj.shippingNumber.sales &&
                          el[labelObj.shippingNumber.orderItemStatus] === ORDER_ITEM_STATUS.ORDER &&
                          !el[labelObj.labelKey[1]] ? (
                          <ShippingNumberBtn
                            onClick={(e) => {
                              e.stopPropagation();
                              labelObj.shippingNumber.onClickEventShippingNumber(el[idx]);
                            }}
                            isMobile={isMobile}
                          >
                            송장번호입력
                          </ShippingNumberBtn>
                        ) : labelObj.shippingNumber.sales &&
                          el[labelObj.shippingNumber.orderItemStatus] === ORDER_ITEM_STATUS.ORDER &&
                          el[labelObj.labelKey[1]] ? (
                          <HeadLabelSub
                            onClick={(e) => {
                              e.stopPropagation();
                              labelObj.shippingNumber.onClickEventShippingNumberEdit(el[idx]);
                            }}
                            sales={labelObj.shippingNumber.sales}
                            isMobile={isMobile}
                          >
                            ({el[labelObj.labelKey[1]]})
                          </HeadLabelSub>
                        ) : null}
                      </HeadLabelSub>
                    )}
                  </HeadLabel>
                ) : labelObj.orderItemStatusRender ? (
                  <HeadLabel>
                    {!labelObj.orderItemStatusRender.isOrderList
                      ? renderStatusContent({
                          statusCode: el[labelObj.labelKey[0]],
                          onRefund: labelObj.orderItemStatusRender.onRefund,
                          item: el,
                        })
                      : renderStatusContent({
                          statusCode: el[labelObj.labelKey[0]],
                          onCancel: labelObj.orderItemStatusRender.onCancel,
                          isOrderList: true,
                          item: el,
                        })}
                  </HeadLabel>
                ) : labelObj.isAvailable ? (
                  <HeadLabel>
                    {Number(el[labelObj.labelKey[0]]) === 1 ? '판매중' : '종료'}
                    {labelObj.labelKey[1] ? (
                      <HeadLabelSub>{el[labelObj.labelKey[1]]}</HeadLabelSub>
                    ) : null}
                  </HeadLabel>
                ) : labelObj.children ? (
                  <HeadLabelChildren>{labelObj.children(el[idx], el)}</HeadLabelChildren>
                ) : (
                  <HeadLabel>
                    {el[labelObj.labelKey[0]]}
                    {labelObj.labelKey[1] ? (
                      <HeadLabelSub>{el[labelObj.labelKey[1]]}</HeadLabelSub>
                    ) : null}
                  </HeadLabel>
                )}
              </ContentsLine>
            ))}
          </ContentFlexBox>
        ))
      ) : data?.length === 0 && !search_order_number && !search_product_name ? (
        <EmptyDataPage title="등록된 데이터가 없습니다." />
      ) : data?.length === 0 && (search_order_number || search_product_name) ? (
        <EmptyDataPage title="등록된 데이터가 없습니다." />
      ) : (
        <EmptyDataPage title="등록된 데이터가 없습니다." />
      )}
    </Article>
  );
};

export default React.memo(Grid_OrderList);

// 상태값에 따른 랜더링 컴포넌트
const renderStatusContent = ({ statusCode, item, onRefund, onCancel, isOrderList = false }) => {
  if (!isOrderList) {
    switch (statusCode) {
      case ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE:
        return <span>정산완료</span>;
      case ORDER_ITEM_STATUS.ORDER:
        return (
          <>
            <span>{!item.shipping_number ? '입금완료' : '배송 중'}</span>
            {!item.shipping_number && (
              <BtnNormal
                bgColor={colorList.white}
                margin="5px 0"
                padding="5px"
                color="#ae0000"
                border="1px solid "
                fontSize="15px"
                fontWeight="500"
                activeOpacity="0.5"
                onClickEvent={(e) => {
                  e.stopPropagation();
                  onRefund(item);
                }}
              >
                주문취소
              </BtnNormal>
            )}
            {/* {item.shipping_number && (
              <>
                <button type="button" className="confirm-btn" onClick={() => {}}>
                  구매확정
                </button>
              </>
            )} */}
          </>
        );
      case ORDER_ITEM_STATUS.ORDER_COMPLETE:
        return <span>구매확정</span>;
      case ORDER_ITEM_STATUS.CANCEL:
        return <span>주문취소</span>;
      case ORDER_ITEM_STATUS.REFUND_REQUEST:
        return <span>환불요청</span>;
      case ORDER_ITEM_STATUS.REFUND:
        return <span>환불완료</span>;
      case ORDER_ITEM_STATUS.ERROR:
        return <span>관리자 문의</span>;
    }
  } else {
    // orderList시
    switch (statusCode) {
      case ORDER_ITEM_STATUS.ORDER:
        return (
          <>
            <span>{!item.shipping_number ? '입금완료' : '배송 중'}</span>
            {!item.shipping_number && (
              <BtnNormal
                bgColor={colorList.white}
                margin="5px 0"
                padding="5px"
                color="#ae0000"
                border="1px solid "
                fontSize="15px"
                fontWeight="500"
                activeOpacity="0.5"
                onClickEvent={(e) => {
                  e.stopPropagation();
                  onCancel(item);
                }}
              >
                주문취소
              </BtnNormal>
            )}
            {/* {item.shipping_number && (
              <>
                <button type="button" className="confirm-btn" onClick={() => {}}>
                  구매확정
                </button>
              </>
            )} */}
          </>
        );
      case ORDER_ITEM_STATUS.ORDER_COMPLETE:
        return <span>구매확정</span>;
      case ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE:
        return <span>구매확정</span>;
      case ORDER_ITEM_STATUS.CANCEL:
        return <span>주문취소</span>;
      case ORDER_ITEM_STATUS.REFUND_REQUEST:
        return <span>환불신청</span>;
      case ORDER_ITEM_STATUS.REFUND:
        return <span>환불완료</span>;
      case ORDER_ITEM_STATUS.ERROR:
        return <span>관리자문의</span>;
    }
  }
};
