import React, { Suspense, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { Route, Routes } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import DaumPost from './components/DaumPost';
import DragPopupAd from './components/popup/DragPopupAd';
import Popup from './components/popup/Popup';
import Spinner from './components/spinner/Spinner';
import { BREAK_POINT } from './constant/NUMBER_CONSTANT.js';
import { useMediaQueryState } from './recoil/useMediaQueryState.js';
import { useMobileState } from './recoil/useMobileState.js';
import AdminRoutes from './views/router/AdminRoutes.jsx';
import UserRoutes from './views/router/UserRoutes.jsx';

function App() {
  // 모바일체크 전역 설정
  const setIsMobileState = useSetRecoilState(useMobileState);

  useEffect(() => {
    setIsMobileState(() => isTablet || isMobile);
  }, []);

  // 반응형 체크
  const FULL_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.FULL_SCREEN });
  const LARGE_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.LARGE_SCREEN });
  const MEDIUM_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.MEDIUM_SCREEN });
  const TABLET_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.TABLET_SCREEN });
  const MEDIUM_SMALL_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.MEDIUM_SMALL_SCREEN });
  const SMALL_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.SMALL_SCREEN });
  const EXTRA_SMALL_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.EXTRA_SMALL_SCREEN });
  const PHONE_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.PHONE_SCREEN });
  const PHONE_FOLDER_SCREEN = useMediaQuery({ maxWidth: BREAK_POINT.PHONE_FOLDER_SCREEN });

  const setMediaScreen = useSetRecoilState(useMediaQueryState);

  useEffect(() => {
    setMediaScreen((cur) => ({
      ...cur,
      FULL_SCREEN,
      LARGE_SCREEN,
      MEDIUM_SCREEN,
      TABLET_SCREEN,
      MEDIUM_SMALL_SCREEN,
      SMALL_SCREEN,
      EXTRA_SMALL_SCREEN,
      PHONE_SCREEN,
      PHONE_FOLDER_SCREEN,
    }));
  }, [
    FULL_SCREEN,
    LARGE_SCREEN,
    MEDIUM_SCREEN,
    TABLET_SCREEN,
    MEDIUM_SMALL_SCREEN,
    SMALL_SCREEN,
    EXTRA_SMALL_SCREEN,
    PHONE_SCREEN,
    PHONE_FOLDER_SCREEN,
  ]);

  return (
    <div className="App">
      <Spinner />
      <Suspense fallback={null}>
        <Routes>
          <Route path="/*" element={<UserRoutes />} />
          <Route path="/address/search_address" element={<DaumPost />} />
          <Route path="/admin/*" element={<AdminRoutes />} />
        </Routes>
        <DragPopupAd />
        <Popup />
      </Suspense>
    </div>
  );
}

export default App;
