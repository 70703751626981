export const colorList = {
  main: '#F17823',
  main_dark: '#df5b4b',
  green: '#5FBC26',
  lightGray: '#e0e0e0',
  gray: '#9d9d9d',
  darkGray: '#858585',
  darkBlue: '#00356a',
  white: '#fefefe',
  dark: '#0d0d0d',
  mild_dark: '#272727',
  section: '#f5f5f5',
};

export const shadow = {
  boxShadow: '0px 2px 5px 3px rgba(0, 0, 0, 0.3)',
};
