import React, { lazy, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { endPoint } from '../../api/endPoint';
import ErrorFetching from '../../components/error/ErrorFetching';
import FooterPopUp from '../../components/FooterPopUp';
import MainLayout from '../../components/layout/MainLayout';
import { useMediaQueryCustom } from '../../components/mediaQueryCustom/useMediaQueryCustom';
import POLICY_ELECTRONIC from '../../constant/POLICY_ELECTRONIC';
import POLICY_PERSONAL from '../../constant/POLICY_PERSONAL';
import POLICY_TERMS from '../../constant/POLICY_TERMS';
import { BOARD_TYPE } from '../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../react-query/useQueryInstance';
import { footerPolicyPopUp } from '../../recoil/footerPolicyPopUp';
import NotFound from '../NotFound';
import BoardDetail from '../pages/board/BoardDetail';
import { BoardWrite } from '../pages/board/BoardWrite';
import Community from '../pages/board/Community';
import EstmateInquiry from '../pages/board/EstmateInquiry';
import { FAQ } from '../pages/board/FAQ';
import Inquiry from '../pages/board/Inquiry';
import Notice from '../pages/board/Notice';
import CompanyDetail from '../pages/companyList/CompanyDetail';
import CompanyList from '../pages/companyList/CompanyList';
import { Guide } from '../pages/document/Guide';
import Story from '../pages/document/Story';
import Footer from '../pages/Footer';
import Header from '../pages/Header';
// import Main from '../pages/Main';
import FindID from '../pages/member/FindID';
import FindPw from '../pages/member/FindPw';
import Join1 from '../pages/member/Join1';
import Join2 from '../pages/member/Join2';
import Login from '../pages/member/Login';
import CartList from '../pages/member/mypage/CartList';
import MemberInfoEdit1 from '../pages/member/mypage/MemberInfoEdit1';
import MemberInfoEdit2 from '../pages/member/mypage/MemberInfoEdit2';
import MyBoardList from '../pages/member/mypage/MyBoardList';
import MyEstimateChat from '../pages/member/mypage/MyEstimateChat';
import MyEstimateIndex from '../pages/member/mypage/MyEstimateIndex';
import MyProductList from '../pages/member/mypage/MyProductList';
import OrderList from '../pages/member/mypage/OrderList';
import OrderSalesDetail from '../pages/member/mypage/OrderSalesDetail';
import SalesList from '../pages/member/mypage/SalesList';
import PayFinal from '../pages/payple/PayFinal';
import ProductDetail from '../pages/product/ProductDetail';
import ProductList from '../pages/product/ProductList';
import ProductWrite from '../pages/product/ProductWrite';
import ProtectedRoute from './ProtectedRoute';

const Main = lazy(() => import('../pages/Main'));

const UserRoutes = () => {
  // ProductDetail 페이지에서 장바구니에 물건을 담으면 Header에 set
  const [headerUpdated, setHeaderUpdated] = useState(false);

  const location = useLocation();
  const isMyEstimateCaht = location.pathname.includes(
    '/pages/member/mypage/my_estimate_index/detail',
  );
  const { MEDIUM_SMALL_SCREEN } = useMediaQueryCustom();

  // footer정책팝업 상태값
  const [policyPopUp, setPolicyPopUp] = useRecoilState(footerPolicyPopUp);

  const { data: categories, isError: isErrorCategory } = useQueryInstance({
    queryKey: [queryKeys.getProductCategoryList],
    apiMethod: 'get',
    apiEndPoint: endPoint.category.getProductCategoryList,
  });

  return (
    <>
      {policyPopUp !== 'none' && (
        <FooterPopUp onClickEvent={() => setPolicyPopUp('none')} backScroll={true}>
          {policyPopUp === 'term' ? (
            <POLICY_TERMS />
          ) : policyPopUp === 'personal' ? (
            <POLICY_PERSONAL />
          ) : (
            <POLICY_ELECTRONIC />
          )}
        </FooterPopUp>
      )}

      {(!isMyEstimateCaht || !MEDIUM_SMALL_SCREEN) && (
        <Header
          data={categories ? categories : []}
          headerUpdated={headerUpdated}
          setHeaderUpdated={setHeaderUpdated}
        />
      )}

      <MainLayout
        minHeight={!isMyEstimateCaht || !MEDIUM_SMALL_SCREEN ? undefined : '0'}
        height={!isMyEstimateCaht || !MEDIUM_SMALL_SCREEN ? 'none' : '100vh'}
      >
        <Routes>
          <Route
            path="/"
            element={isErrorCategory ? <ErrorFetching isError={isErrorCategory} /> : <Main />}
          />
          <Route path="/pages/document/guide" element={<Guide />} />
          <Route path="/pages/document/story" element={<Story />} />
          <Route path="/pages/document/faq" element={<FAQ />} />
          <Route
            path="/pages/board/notice"
            element={
              <ProtectedRoute>
                <Notice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/board/community"
            element={
              <ProtectedRoute>
                <Community />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/board/inquiry"
            element={
              <ProtectedRoute>
                <Inquiry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/board/estmate_inquiry"
            element={
              <ProtectedRoute>
                <EstmateInquiry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/board/detail/:idx"
            element={
              <ProtectedRoute>
                <BoardDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/board/community/write/:idx?"
            element={
              <ProtectedRoute>
                <BoardWrite boardType={BOARD_TYPE.COMMUNITY} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/board/inquiry/write/:idx?"
            element={
              <ProtectedRoute>
                <BoardWrite boardType={BOARD_TYPE.INQUIRY} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/board/estmate_inquiry/write/:idx?"
            element={
              <ProtectedRoute>
                <BoardWrite boardType={BOARD_TYPE.ESTIMATE_INQUIRY} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/company_list"
            element={
              <ProtectedRoute>
                <CompanyList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/company_list/detail/:idx?"
            element={
              <ProtectedRoute>
                <CompanyDetail />
              </ProtectedRoute>
            }
          />
          <Route path="/pages/member/join1" element={<Join1 />} />
          <Route path="/pages/member/join2" element={<Join2 />} />
          <Route path="/pages/member/login" element={<Login />} />
          <Route path="/pages/member/find_id" element={<FindID />} />
          <Route path="/pages/member/find_pw" element={<FindPw />} />
          <Route
            path="/pages/product/list"
            element={
              <ProtectedRoute>
                <ProductList categoryData={categories ? categories : []} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/product/detail/:idx"
            element={
              <ProtectedRoute>
                <ProductDetail setHeaderUpdated={setHeaderUpdated} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/product/pay/final"
            element={
              <ProtectedRoute>
                <PayFinal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/product/write/:idx?"
            element={
              <ProtectedRoute>
                <ProductWrite />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/edit1"
            element={
              <ProtectedRoute>
                <MemberInfoEdit1 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/edit2"
            element={
              <ProtectedRoute>
                <MemberInfoEdit2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/order_list"
            element={
              <ProtectedRoute>
                <OrderList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/order_list/detail/:idx"
            element={
              <ProtectedRoute>
                {/* type=true는 Order리스트 디테일 */}
                <OrderSalesDetail type={true} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/sales_list"
            element={
              <ProtectedRoute>
                <SalesList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/sales_list/detail/:idx"
            element={
              <ProtectedRoute>
                {/* type=false는 Sales리스트 디테일 */}
                <OrderSalesDetail type={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/product_list"
            element={
              <ProtectedRoute>
                <MyProductList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/my_board_list"
            element={
              <ProtectedRoute>
                <MyBoardList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/my_estimate_index"
            element={
              <ProtectedRoute>
                <MyEstimateIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/my_estimate_index/detail/:roomId?"
            element={
              <ProtectedRoute>
                <MyEstimateChat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pages/member/mypage/cart"
            element={
              <ProtectedRoute>
                <CartList />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainLayout>
      {(!isMyEstimateCaht || !MEDIUM_SMALL_SCREEN) && <Footer />}
    </>
  );
};

export default UserRoutes;
