import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { DEFAULT_CONSTANT_DATA, SEO_STATIC_DATA } from './SEOData';

const SEOHelmet = ({
  title = '',
  contentTitle = '',
  description = '텍스트 설명 없음',
  keywords = '',
}) => {
  const currentURL = useLocation().pathname;
  const pageData = SEO_STATIC_DATA[currentURL] ?? DEFAULT_CONSTANT_DATA;

  return (
    <Helmet prioritizeSeoTags>
      {/* 구글과 네이버 등 대표 사이트에 검색콘솔에 등록 */}
      {pageData.googleSiteVerification && (
        <meta name="google-site-verification" content={pageData.googleSiteVerification} />
      )}
      <meta name="naver-site-verification" content={pageData.naverSiteVerification} />
      {/* 모바일 웹 사용시 타이틀 */}
      <meta name="application-name" content={pageData.title || title} />
      {/* 중복URL 방지 위한 대표 URL 설정 -> 해당 페이지의 라우터 참조하여 넣으면 됨 */}
      <link rel="canonical" href={`${pageData.url}${currentURL}`} />
      <title>{pageData.title || title}</title>
      <meta name="title" content={pageData.contentTitle || contentTitle} />
      <meta name="description" content={pageData.description || description} />
      <meta name="keywords" content={pageData.keywords || keywords} />
      {/* 모바일환경에서 제목 */}
      <meta name="apple-mobile-web-app-title" content={pageData.title || title} />
      {/* <!-- 웹파비콘 ------------------------> */}
      {/* <!-- 레거시 호환용 파비콘 --> */}
      <link rel="shortcut icon" href={pageData.faviconImage} />
      {/* <!-- 기본 파비콘 --> */}
      <link rel="icon" href={pageData.faviconImage} />
      {/* <!-- TODO 아이콘 사이즈 맞춰서 적용해야함 --> */}
      {/* <!-- 16px 파비콘 --> */}
      {/* <!-- <link rel="icon" type="image/png" sizes="16x16" href="%PUBLIC_URL%" /> --> */}
      {/* <!-- 32px 파비콘 --> */}
      {/* <!-- <link rel="icon" type="image/png" sizes="32x32" href="%PUBLIC_URL%" /> --> */}
      {/* <!-- ------------------------- --> */}
      {/* Apple-Touch-Icon */}
      {/* 웹앱 모바일 바탕화면 설치시 이미지  */}
      {/* TODO 사이즈 만들어 추가해야함 */}
      {/* <link rel="apple-touch-icon" sizes="128x128" href="{apple128}" />
      <link rel="apple-touch-icon" sizes="144x144" href="{apple144}" />
      <link rel="apple-touch-icon" sizes="152x152" href="{apple152}" />
      <link rel="apple-touch-icon" sizes="167x167" href="{apple167}" />
      <link rel="apple-touch-icon" sizes="180x180" href="{apple180}" />
      <link rel="apple-touch-icon" sizes="192x192" href="{apple192}" />  */}
      {/* Apple-모바일 웹시 스플래쉬 화면 설정 */}
      {/* TODO 사이즈 만들어 추가해야함 */}
      {/* <link
        rel="apple-touch-startup-image"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
        href="{appleSplashImage320}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
        href="{appleSplashImage375}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
        href="{appleSplashImage621}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
        href="{appleSplashImage375812}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
        href="{appleSplashImage414xr}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
        href="{appleSplashImage414xsmax}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
        href="{appleSplashImage768}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
        href="{appleSplashImage834pro1}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
        href="{appleSplashImage1024pro2}"
      />
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
        href="{appleSplashImage834pro3}"
      /> */}

      {/* og ------ */}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={pageData.title || title} />
      <meta property="og:title" content={pageData.contentTitle || contentTitle} />
      <meta property="og:description" content={pageData.description || description} />
      <meta property="og:image" content={pageData.ogImage} />
      <meta property="og:image:alt" content={pageData.ogImageAlt} />
      <meta property="og:url" content={`${pageData.url}${currentURL}`} />
      {/* twitterCard -------- */}
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={pageData.title || title} />
      <meta property="twitter:description" content={pageData.description || description} />
      <meta property="twitter:image" content={pageData.twitterCardImage} />
      <meta name="twitter:image:alt" content={pageData.twitterCardImageAlt} />
      <meta property="twitter:url" content={`${pageData.url}${currentURL}`} />
      {/* PWA제어 파일 manifest.json 연결 하기 */}
      <link rel="manifest" href={`${pageData.url}/manifest.json`} />
    </Helmet>
  );
};

export default React.memo(SEOHelmet);
