import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

const Background = styled.div`
  display: ${({ fetching, mutating, isLoading }) =>
    fetching || mutating || isLoading ? 'flex' : 'none'};
  top: 0px;
  flex-direction: column;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  z-index: 999;
`;

const SpinnerWrap = styled.div`
  position: relative;
  width: fit-content;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
`;

const Spinner = ({
  color = '#FF7200',
  size = 100,
  top = 'none',
  bottom = 'none',
  right = 'none',
  left = 'none',
  isLoading = false,
}) => {
  // 리액트쿼리 훅
  // useQuery시 스피너 훅
  const isFetching = useIsFetching();
  // useMutate시 스피너 훅
  const isMutating = useIsMutating();

  return (
    <Background fetching={isFetching} mutating={isMutating} isLoading={isLoading}>
      <SpinnerWrap top={top} bottom={bottom} right={right} left={left}>
        <ClipLoader loading={isFetching || isMutating || isLoading} color={color} size={size} />
      </SpinnerWrap>
    </Background>
  );
};

export default React.memo(Spinner);
