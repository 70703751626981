
export default function VATCalculate(price) {

    const VAT = 10;

    if (parseInt(price) > 0) {
        const vatAmount = price * (VAT / 100);
        const roundedVatAmount = Math.round(vatAmount);

        const roundedPrice = parseInt(price) + roundedVatAmount;

        return (Math.round(roundedPrice / 10) * 10);
    }
    return null;
}