import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { MEMBER_ROLE } from '../constant/TYPE_CONSTANT';
import { addressProcess } from '../utils/addressProcess';
import { checkBoardType } from '../utils/checkBoardType';
import { checkMemberRole } from '../utils/checkMemberRole';
import EmptyDataPage from './EmptyDataPage';
import Inputbox from './InputBox';

const Article = styled.article`
  margin: ${({ containerMargin }) => containerMargin};
`;

const HeadFlexBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
`;

const HeadLabel = styled.p`
  flex: ${({ flex }) => flex};
  font-size: 15px;
  padding: 20px;
  font-weight: 700;
`;

const ContentFlexBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  cursor: ${({ navigateUrl }) => (!navigateUrl ? 'auto' : 'pointer')};
  pointer-events: ${({ navigateUrl, selectorEdit }) =>
    !navigateUrl && !selectorEdit ? 'none' : 'all'};

  ${({ navigateUrl }) =>
    navigateUrl &&
    css`
      &:hover {
        background-color: #ececec;
      }
      &:active {
        opacity: 0.5;
      }
    `}
`;

const ContentsLine = styled.div`
  flex: ${({ flex }) => flex};
  font-size: 15px;
  padding: 20px;
  text-align: ${({ textAlign }) => textAlign};
  word-break: break-all;
`;

// 중첩 객체 접근하기
const approachKey = (obj, keys) => {
  return keys.split('.').reduce((acc, part) => acc && acc[part], obj);
};

// 네비게이션을 사용하려면 data의 패스파람스의 키(idxName)를 입력해야하고,
// 이동하는 라우팅주소(navigateUrl)를 입력해야함
// labelData가 중요함 => 형식 지켜줘야함 {label: 값(스트링), labelKey: 값(스트링), flex:값(넘버)}
// 추가 설명: labelKey는 패칭 받는 data가 원하는 컬럼에 들어갈 수 있게 키를 맞춰주는 것.
// 즉, 내가 라벨 적재적소 데이터를 맞추고 싶다면 해당 라벨에 들어갈 data의 키를 넣어주면 된다.
const Grid = ({
  data,
  labelData,
  navigateUrl,
  idxName,
  query,
  containerMargin,
  selectorEdit = false,
  // 샘플 포멧
  // Index에 셀렉터 배치하고 싶은 것을 입력, 배열이므로 셀렉터는 기호에 맞게 추가 가능
  selectorOptArr = [
    {
      index: 5,
      values: [
        { label: '샘플1', value: 1, disable: false },
        { label: '샘플2', value: 2, disable: false },
        { label: '샘플3', value: 3, disable: false },
        { label: '샘플4', value: 4, disable: false },
      ],
    },
  ],
  // InputValue 같이 들어가야함
  atSelectModeInputOn = false,
  selectValue,
  selectorOnChangeEvent,
  inputValue,
  inputOnChangeEvent,
  compareColorMode = false,
  emptyTitle,
}) => {
  const navigate = useNavigate();

  if (atSelectModeInputOn && !inputValue) return <></>;

  if (selectorEdit && !selectValue) return <></>;

  return (
    <Article containerMargin={containerMargin}>
      <HeadFlexBox>
        {labelData?.map((el, index) => (
          <HeadLabel key={index} flex={el.flex}>
            {el.label}
          </HeadLabel>
        ))}
      </HeadFlexBox>
      {data?.length === 0 ? (
        <EmptyDataPage title={emptyTitle} />
      ) : (
        <>
          {!selectorEdit &&
            data?.map((el, index) => (
              <ContentFlexBox
                selectorEdit={selectorEdit}
                navigateUrl={navigateUrl}
                key={index}
                onClick={() =>
                  navigateUrl && idxName
                    ? navigate(navigateUrl + `/${el[idxName]}${query ? query : ''}`)
                    : null
                }
              >
                {labelData?.map((label, labelIndex) => (
                  <ContentsLine
                    flex={label.flex}
                    textAlign={label.textAlign || 'center'}
                    key={labelIndex}
                  >
                    {labelIndex === 0 && (label.label === '순번' || label.label === '번호')
                      ? approachKey(el, label.labelKey)
                      : label.labelKey === 'member_role'
                      ? checkMemberRole(approachKey(el, label.labelKey))
                      : label.time
                      ? approachKey(el, label.labelKey)?.replace('T', ' ') || ''
                      : label.labelKey.includes('address')
                      ? addressProcess(approachKey(el, label.labelKey))
                      : label.labelKey === 'board_type'
                      ? checkBoardType(approachKey(el, label.labelKey))
                      : approachKey(el, label.labelKey)}
                  </ContentsLine>
                ))}
              </ContentFlexBox>
            ))}
          {selectorEdit &&
            data?.map((el, index) => (
              <ContentFlexBox
                selectorEdit={selectorEdit}
                navigateUrl={navigateUrl}
                key={index}
                onClick={(e) => {
                  // 버블링 방지
                  if (e.target.tagName === 'SELECT' || e.target.closest('select')) return;

                  navigateUrl && idxName
                    ? navigate(navigateUrl + `/${el[idxName]}${query ? query : ''}`)
                    : null;
                }}
              >
                {labelData?.map((label, labelIndex) => (
                  <ContentsLine
                    flex={label.flex}
                    textAlign={label.textAlign || 'center'}
                    key={labelIndex}
                  >
                    {/* 인덱스 0이면 순번을 */}
                    {labelIndex === 0 && label.label === ('순번' || '번호') ? (
                      approachKey(el, label.labelKey)
                    ) : label.time ? (
                      approachKey(el, label.labelKey)?.replace('T', ' ') || ''
                    ) : label.labelKey.includes('address') ? (
                      addressProcess(approachKey(el, label.labelKey))
                    ) : label.labelKey === 'board_type' ? (
                      checkBoardType(approachKey(el, label.labelKey))
                    ) : (
                      <>
                        {/* {셀렉터 배열에 인덱스와 일치하면 해당 콜럼에는 셀렉터데이터를 뿌릴 수 있도록} */}
                        {selectorOptArr?.some((optEl) => optEl?.index === labelIndex) ? (
                          selectorOptArr
                            .filter((el) => el.index === labelIndex)
                            .map((arr, elIndex) =>
                              label.labelKey === 'member_role' &&
                              approachKey(el, label.labelKey) === MEMBER_ROLE.ADMIN ? (
                                <p key={elIndex}>최고 관리자</p>
                              ) : label.labelKey === 'member_role' &&
                                approachKey(el, label.labelKey) === MEMBER_ROLE.MIN_ADMIN ? (
                                '중간 관리자'
                              ) : (
                                <select
                                  style={{
                                    backgroundColor:
                                      compareColorMode &&
                                      (el[label.labelKey] === selectValue[index][label.labelKey]
                                        ? 'white'
                                        : '#ff9e9e'),
                                    padding: '5px 10px',
                                  }}
                                  value={
                                    !selectValue[index] ? '' : selectValue[index][label.labelKey]
                                  }
                                  key={elIndex}
                                  onChange={(e) => selectorOnChangeEvent(e, index, label.labelKey)}
                                  disabled={
                                    selectValue[index][label.labelKey] === MEMBER_ROLE.ADMIN ||
                                    selectValue[index][label.labelKey] === MEMBER_ROLE.MIN_ADMIN
                                  }
                                >
                                  {arr?.values?.map((opt, optIndex) => (
                                    <option value={opt.value} key={optIndex} disabled={opt.disable}>
                                      {opt.label}
                                    </option>
                                  ))}
                                </select>
                              ),
                            )
                        ) : /* 이도저도 아니면 해당 데이터를 출력하거나 다른 처리 */
                        // 인풋을 쓰려면 selectInputOn이걸로 켜주고, inputValue 제어컨틀롤을 줘야한다.
                        !atSelectModeInputOn ? (
                          el[label.labelKey]
                        ) : (
                          <Inputbox
                            containerPadding="0px"
                            containerMargin="0px"
                            inputFontSize="16px"
                            styleProp={{
                              backgroundColor:
                                compareColorMode &&
                                (el[label.labelKey] === inputValue[index][label.labelKey]
                                  ? 'white'
                                  : '#ff9e9e'),
                            }}
                            inputValue={!inputValue[index] ? '' : inputValue[index][label.labelKey]}
                            onChangeEvent={(e) => inputOnChangeEvent(e, index, label.labelKey)}
                          />
                        )}
                      </>
                    )}
                  </ContentsLine>
                ))}
              </ContentFlexBox>
            ))}
        </>
      )}
    </Article>
  );
};

export default React.memo(Grid);
