import React from 'react';

import '../assets/css/NotFound.css';

import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const goToHomePage = () => {
    navigate('/');
  };

  return (
    <div className="not-found-body">
      <div className="not-found-container">
        <h2 className="not-found-h2">죄송합니다. 요청할 수 없는 페이지입니다</h2>
        <p className="not-found-p">
          주소가 잘못입력 되었거나, 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
        </p>
        <div className="cta-buttons">
          <button onClick={goBack}>이전 페이지</button>
          <button onClick={goToHomePage}>홈으로 돌아가기</button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
