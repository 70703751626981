import React, { useEffect, useState } from 'react';
import { TiArrowLeftThick } from 'react-icons/ti';
import { useNavigate, useParams } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/BoardDetail.module.scss';
import { colorList } from '../../../assets/style/theme';
import BtnNormal from '../../../components/BtnNormal';
import EmptyDataPage from '../../../components/EmptyDataPage';
import ErrorFetching from '../../../components/error/ErrorFetching';
import SectionLayout from '../../../components/layout/SectionLayout';
import { usePopup } from '../../../components/popup/usePopup';
import SEOHelmet from '../../../components/seo/SEOHelmet';
import TextAreaCustom from '../../../components/TextAreaCustom';
import TextEditor_Quill_ReadOnly from '../../../components/TextEditor_Quill_ReadOnly';
import ellipsisTextString from '../../../components/textLimit/ellipsisTextString';
import { CACHE_TIME } from '../../../constant/NUMBER_CONSTANT';
import { BOARD_TYPE, MEMBER_ROLE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { decodingCrypto } from '../../../utils/crypto';
import { htmlTagRemove } from '../../../utils/htmlTagRemove';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';
import ValidateInput from '../../../utils/ValidateInput';

const BoardDetail = () => {
  const navigate = useNavigate();

  const { idx } = useParams();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const userName = decodingCrypto(localStorage.getItem('member_name'));
  const memeberRole = Number(decodingCrypto(localStorage.getItem('member_role')));

  // 최초 게시글 데이터 패칭
  const {
    data: { data: detailData },
    refetch,
    isError,
  } = useQueryInstance({
    queryKey: [queryKeys.boardDetail, idx],
    apiMethod: 'get',
    apiEndPoint: endPoint.board.getBoard,
    apiPathParams: idx,
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: CACHE_TIME.DEFAULT,
  });

  // 댓글 작성 저장
  const [comment, setComment] = useState('');

  // 댓글 수정 셋
  const [editComment, setEditComment] = useState({ idx: null, content: null });

  const editCommentHandler = (comment_idx, isApply) => {
    if (isApply) {
      setEditComment((cur) => ({
        ...cur,
        idx: comment_idx,
        content: detailData?.comment_dto_list?.filter((el) => el.comment_idx === comment_idx)[0]
          .content,
      }));
    } else {
      setEditComment((cur) => ({ ...cur, idx: null, content: null }));
    }
  };

  // 댓글 요청 신규 및 수정 모두 ---
  const { mutate: commentApi } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.board.saveComment,
    onErrorFn: () => {
      openPopup({ title: '오류', content: '다시 시도해주세요.' });
    },
    onSuccessFn: () => {
      setComment('');
      setEditComment((cur) => ({ ...cur, idx: null, content: null }));
      refetch();
    },
  });

  const commentApiHandler = (comment_idx) => {
    // 비어있는지 검증
    if (comment === '' && !comment_idx)
      return openPopup({ title: '오류', content: '댓글 내용을 입력해주세요.' });

    if (!editComment.content && comment_idx)
      return openPopup({ title: '오류', content: '댓글 내용을 입력해주세요.' });

    // 이후 API요청
    commentApi({
      apiBody: {
        board_idx: Number(idx),
        content: !comment_idx ? comment : editComment.content,
        comment_idx,
      },
    });
  };

  // 댓글 키보드 컨트롤
  const handlerOnKeyTextArea = (e, comment_idx) => {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();

      !comment_idx ? commentApiHandler() : commentApiHandler(comment_idx);
    }
  };

  const { mutate: removeBoardApi } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.board.removeBoard,
    onErrorFn: () => {
      openPopup({ title: '오류', content: '다시 시도해주세요.' });
    },
    onSuccessFn: () => {
      navigate(-1);
    },
  });

  const removeBoardHandler = (e) => {
    removeBoardApi({
      apiQueryParams: { board_idx: idx },
    });
  };

  function DateFormatHelper(date) {
    if (!ValidateInput(date)) {
      return '';
    }

    const dateParts = date.split('T');
    const timeParts = dateParts[1].split(':');
    return `${dateParts[0]} ${timeParts[0]}:${timeParts[1]}`;
  }

  // 댓글삭제요청 ---
  const { mutate: removeCommentApi } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.board.removeComment,
    onErrorFn: () => {
      openPopup({ title: '오류', content: '다시 시도해주세요.' });
    },
    onSuccessFn: () => {
      refetch();
    },
  });

  // 팝업으로 사용자 확인 한번 더 하고 삭제
  const removeCommentHandler = (comment_idx) => {
    removeCommentApi({
      apiQueryParams: { comment_idx },
    });
  };

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <>
      <SEOHelmet
        title={`일빵 | 글: ${ellipsisTextString({ text: detailData?.title, maxLength: 25 }) || ''}`}
        contentTitle="글 읽기"
        description={ellipsisTextString({
          text:
            htmlTagRemove(detailData?.content) !== ''
              ? htmlTagRemove(detailData?.content)
              : undefined,
          maxLength: 90,
        })}
        keywords="일빵, MRO, 게시판, 글, 공지사항, 커뮤니티"
      />
      <SectionLayout maxWidth="800px" padding="40px 0px">
        <BtnNormal
          bgColor={colorList.main}
          onClickEvent={() => navigate(-1)}
          padding="10px"
          fontWeight="200"
          margin="0 0 30px 0"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
            }}
          >
            <TiArrowLeftThick style={{ fontSize: '20px' }} color={colorList.white} />
            <span style={{ fontSize: '15px' }}>뒤로가기</span>
          </div>
        </BtnNormal>
        <div className={style.headerBox}>
          <div className={style.leftSpace}>
            <p className={style.title}>{detailData?.title}</p>
            <p className={style.boardType}>
              {detailData?.board_type === BOARD_TYPE.COMMUNITY
                ? 'Community'
                : detailData?.board_type === BOARD_TYPE.NOTICE
                ? 'Notice'
                : detailData?.board_type === BOARD_TYPE.INQUIRY
                ? '상품문의'
                : '견적요청'}
            </p>
          </div>
          <div className={style.rightSpace}>
            <p className={style.date}>{DateFormatHelper(detailData?.reg_by_time)}</p>
            <p className={style.writer}>
              <span>작성자 |</span> {detailData?.reg_by_member_name}
            </p>
            {/* 커뮤니티 글 수정 작성자만 */}
            {(detailData?.board_type === BOARD_TYPE.COMMUNITY ||
              detailData?.board_type === BOARD_TYPE.INQUIRY ||
              detailData?.board_type === BOARD_TYPE.ESTIMATE_INQUIRY) &&
              detailData?.reg_by_member_name === userName && (
                <div className={style.editBtn}>
                  <BtnNormal
                    padding="5px 10px"
                    fontWeight="200"
                    fontSize="12px"
                    bgColor={colorList.main}
                    margin="0 10px"
                    onClickEvent={() => navigate(`/pages/board/inquiry/write/${idx}`)}
                  >
                    글 수정하기
                  </BtnNormal>
                  <BtnNormal
                    padding="5px 10px"
                    fontWeight="200"
                    fontSize="12px"
                    bgColor={colorList.darkBlue}
                    onClickEvent={() => {
                      openPopup({
                        title: '안내',
                        content: '정말로 삭제하시겠습니까?',
                        twoButton: true,
                        onComplete: () => removeBoardHandler(),
                      });
                    }}
                  >
                    삭제
                  </BtnNormal>
                </div>
              )}

            {/* 공지사항 글수정, 관리자만 */}
            {detailData?.board_type === BOARD_TYPE.NOTICE &&
              detailData?.reg_by_member_name === userName &&
              memeberRole === MEMBER_ROLE.ADMIN && (
                <div className={style.editBtn}>
                  <BtnNormal
                    padding="5px 10px"
                    fontWeight="200"
                    fontSize="12px"
                    bgColor="#ff7200"
                    margin="0 10px"
                    onClickEvent={() => navigate(`/admin/board/notice/write/${idx}`)}
                  >
                    공지사항 수정하기
                  </BtnNormal>
                  <BtnNormal
                    padding="5px 10px"
                    fontWeight="200"
                    fontSize="12px"
                    bgColor={colorList.darkBlue}
                    onClickEvent={() => {
                      openPopup({
                        title: '안내',
                        content: '정말로 삭제하시겠습니까?',
                        twoButton: true,
                        onComplete: () => removeBoardHandler(),
                      });
                    }}
                  >
                    삭제
                  </BtnNormal>
                </div>
              )}
          </div>
        </div>
        <p className={style.viewCount}>조회 {detailData?.view_count}</p>
        <TextEditor_Quill_ReadOnly value={detailData?.content} padding="0 0 50px 0" />

        <div className={style.mentionContainer}>
          <p className={style.metionTitle}>댓글</p>
          <div className={style.mentionContentWrap}>
            {!detailData?.comment_dto_list ? (
              <div className={style.mentionContentBox}>
                <EmptyDataPage
                  title="댓글이 없습니다."
                  isIcon={false}
                  fontSize="15px"
                  fontWeight="200"
                  fontFamily="unset"
                  padding="0 0 50px 0"
                />
              </div>
            ) : (
              detailData?.comment_dto_list?.map((el, _) => (
                <div className={style.mentionContentBox} key={el.comment_idx}>
                  <p className={style.mentionWriter}>{el.reg_by_member_name}</p>
                  <p className={style.mentionTime}>{DateFormatHelper(el.reg_by_time)}</p>
                  {editComment.idx !== el.comment_idx ? (
                    <p className={style.mentionContent}>{el.content}</p>
                  ) : (
                    <TextAreaCustom
                      valueTextArea={editComment.content}
                      onChangeTextArea={(e) =>
                        setEditComment((cur) => ({ ...cur, content: e.target.value }))
                      }
                      onKeyDownTextArea={(e) => handlerOnKeyTextArea(e, el.comment_idx)}
                    />
                  )}
                  <div className={style.replayBtnBox}>
                    {userName === el.reg_by_member_name && !editComment.idx && (
                      <>
                        <BtnNormal
                          bgColor={colorList.main}
                          padding="5px"
                          fontSize="12px"
                          fontWeight="200"
                          onClickEvent={() => editCommentHandler(el.comment_idx, true)}
                        >
                          수정
                        </BtnNormal>
                        <BtnNormal
                          padding="5px"
                          fontSize="12px"
                          fontWeight="200"
                          onClickEvent={() =>
                            openPopup({
                              title: '안내',
                              content: '정말로 댓글을 삭제하시겠습니까?',
                              twoButton: true,
                              onComplete: () => removeCommentHandler(el.comment_idx),
                            })
                          }
                        >
                          삭제
                        </BtnNormal>
                      </>
                    )}

                    {editComment.idx && editComment.idx === el.comment_idx && (
                      <>
                        <BtnNormal
                          bgColor={colorList.main}
                          padding="5px"
                          fontSize="12px"
                          fontWeight="200"
                          onClickEvent={() => commentApiHandler(el.comment_idx)}
                        >
                          수정완료
                        </BtnNormal>
                        <BtnNormal
                          padding="5px"
                          fontSize="12px"
                          fontWeight="200"
                          onClickEvent={() => editCommentHandler(el.comment_idx, false)}
                        >
                          수정취소
                        </BtnNormal>
                      </>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className={style.mentionOnSendBox}>
          <p className={style.userName}>
            <span>댓글쓰기 작성자 |</span>
            {userName}
          </p>
          <TextAreaCustom
            disabled={editComment.idx && true}
            valueTextArea={comment}
            onChangeTextArea={(e) => setComment(() => e.target.value)}
            onKeyDownTextArea={(e) => handlerOnKeyTextArea(e)}
          />
          <div className={style.btnBox}>
            <BtnNormal
              disabled={editComment.idx && true}
              padding="10px"
              fontWeight="500"
              fontSize="14px"
              bgColor="#ff7200"
              onClickEvent={() => commentApiHandler()}
            >
              댓글등록
            </BtnNormal>
          </div>
        </div>
      </SectionLayout>
    </>
  );
};

export default BoardDetail;
