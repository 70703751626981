import React, { useEffect, useState } from 'react';
import { BiDownArrow } from 'react-icons/bi';
import { MdStart } from 'react-icons/md';
import styled from 'styled-components';

import style from '../../../assets/css/Guide.module.scss';
import SectionLayout from '../../../components/layout/SectionLayout';

// 메뉴 종류
const menu = ['시작하기', '상품등록', '장바구니', '마이페이지'];

// 가이드 섹션별 데이터
// 시작하기
const startImageData = [
  { title: '메인화면', src: '/img/guide/main/main1.png', size: '100%' },
  { title: '회원가입 시작하기', src: '/img/guide/register/register1.png', size: '100%' },
  { title: '회원가입 약관', src: '/img/guide/register/register2.png', size: '100%' },
  { title: '회원가입 양식', src: '/img/guide/register/register3.png', size: '100%' },
  { title: '로그인 시작하기', src: '/img/guide/login/login1.png', size: '100%' },
  { title: '로그인 화면', src: '/img/guide/login/login2.png', size: '50%' },
  { title: '쇼핑 시작하기', src: '/img/guide/productList/productList1.png', size: '100%' },
  { title: '카테고리 선택', src: '/img/guide/productList/productList2.png', size: '100%' },
  { title: '상품 화면', src: '/img/guide/productList/productList3.png', size: '100%' },
];
// 상품등록
const productWriteImageData = [
  { title: '상품등록 버튼', src: '/img/guide/productWrite/productWrite1.png', size: '100%' },
  { title: '상품등록 양식', src: '/img/guide/productWrite/productWrite2.png', size: '100%' },
];
// 장바구니 안내
const cartImageData = [
  { title: '장바구니 버튼', src: '/img/guide/cart/cart1.png', size: '100%' },
  { title: '장바구니 사용법', src: '/img/guide/cart/cart2.png', size: '100%' },
];
// 마이페이지 안내
const myPageImageData = [
  { title: '마이페이지 버튼', src: '/img/guide/mypage/mypage1.png', size: '100%' },
  { title: `[내 상품] 안내`, src: '/img/guide/mypage/mypage2.png', size: '100%' },
  { title: `[판매내역] 안내`, src: '/img/guide/mypage/mypage3.png', size: '100%' },
  { title: `[주문내역] 안내`, src: '/img/guide/mypage/mypage4.png', size: '100%' },
];

export const Guide = () => {
  const [menuSelector, setMenuSelector] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SectionLayout>
      <div className={style.lineDeco} />
      <p className={style.title}>이용 가이드</p>
      <div className={style.menu}>
        {menu.map((el, index) => (
          <div
            key={index}
            className={`${style.menuSelect} ${
              menuSelector === index ? style.selected : style.unSelected
            }`}
            onClick={() => setMenuSelector(index)}
          >
            {el}
          </div>
        ))}
      </div>
      {menuSelector === 0 ? (
        <Section imageData={startImageData}>일빵 시작하기 : 회원가입 및 로그인</Section>
      ) : null}
      {menuSelector === 1 ? (
        <Section imageData={productWriteImageData}>상품등록 시작하기</Section>
      ) : null}
      {menuSelector === 2 ? <Section imageData={cartImageData}>장바구니 시작하기</Section> : null}
      {menuSelector === 3 ? (
        <Section imageData={myPageImageData}>
          마이페이지 시작하기 : [내 상품], [판매내역], [주문내역]
        </Section>
      ) : null}
    </SectionLayout>
  );
};

// section 컴포넌트 분리 -------------------------
// 스타일
const SectionContainer = styled.div`
  position: relative;
  /* background-color: red; */
  padding: 30px;
  border-width: 0px 2px 2px 2px;
  border-color: black;
  border-style: solid;
`;

const TitleWrap = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  align-items: center;
  width: 100%;
`;

const Title = styled.p`
  font-size: 28px;
`;

const Article = styled.article`
  padding: 30px;
`;

const ImageContainer = styled.div`
  padding: 20px 0px;
  text-align: center;
`;

const ImageTitle = styled.p`
  display: inline-block;
  font-size: 30px;
  margin: 20px 0px;
  padding: 0px 30px;
  border-width: 0px 5px 0px 5px;
  border-style: solid;
  border-color: rgba(138, 138, 138, 0.5);
`;

const Image = styled.img`
  display: block;
  margin: auto;
  width: ${({ size }) => size};
  padding: 30px 0px;
`;

// 본문 컴포넌트
const Section = ({ imageData, children }) => {
  return (
    <SectionContainer>
      <TitleWrap>
        <MdStart style={{ fontSize: '40px', marginRight: '30px' }} />
        <Title>{children}</Title>
      </TitleWrap>
      <Article>
        {imageData.map((el, index) => (
          <ImageContainer key={index}>
            <ImageTitle>{el.title}</ImageTitle>
            <Image src={el.src} size={el.size} />
            {index !== imageData.length - 1 ? (
              <BiDownArrow style={{ fontSize: '30px', marginBottom: '50px' }} />
            ) : null}
          </ImageContainer>
        ))}
      </Article>
    </SectionContainer>
  );
};
