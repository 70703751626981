export const queryKeys = {
  getProductCategoryList: 'getProductCategoryList',
  mainProductList: 'mainProductList',
  paymentList: 'paymentList',
  cartItems: 'cartItems',
  myProductList: 'myProductList',
  orderListMypage: 'orderListMypage',
  salesListMypage: 'salesListMypage',
  productList: 'productList',
  recommendList: 'recommendList',
  product: 'product',
  getMember: 'getMember',
  productDetail: 'productDetail',
  // 에디터 이미지 저장용
  productWrite: 'productWrite',
  deleteImageUrlEdit: 'deleteImageUrlEdit',
  // 에디터 이미지 저장용
  boardWrite: 'boardWrite',
  boardEditData: 'boardEditData',
  boardCommunityList: 'boardCommunityList',
  boardNoticeList: 'boardNoticeList',
  boardInquiryList: 'boardInquiryList',
  boardEstmateList: 'boardEstmateList',
  boardDetail: 'boardDetail',
  // 에디터 이미지 저장용
  popupWrite: 'popupWrite',
  memberList_admin: 'memberList_admin',
  companyList: 'companyList',
  getOrderItem_admin: 'getOrderItem_admin',
  getOrderItem: 'getOrderItem',
  getSalesItem: 'getSalesItem',
  getMyBoardList: 'getMyBoardList',
  chatList: 'chatList',
  chatRoomList: 'chatRoomList',
  estimateProductList: 'estimateProductList',
  getChatRoom: 'getChatRoom',
};
