import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import BtnNormal from '../../../components/BtnNormal';
import Grid from '../../../components/Grid';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';

import '../../../assets/css/Sub.css';

import { endPoint } from '../../../api/endPoint';
import EmptyDataPage from '../../../components/EmptyDataPage';
import ErrorFetching from '../../../components/error/ErrorFetching';
import SectionLayout from '../../../components/layout/SectionLayout';
import Pagination from '../../../components/Pagination';
import { usePopup } from '../../../components/popup/usePopup';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../../react-query/useQueryInstance';

// 그리드 헤드
const labelData = [
  { label: '번호', labelKey: 'row_number', flex: 0.5, textAlign: 'center' },
  { label: '조합사명', labelKey: 'company_name', flex: 5, textAlign: 'center' },
  { label: '주요취급품목', labelKey: 'main_product', flex: 2, textAlign: 'center' },
  { label: '지역', labelKey: 'address.address1', flex: 2, textAlign: 'center' },
];

const CompanyList = () => {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  // const isLoggedIn = IsLoggedIn();

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate('/pages/member/login');
  //     openPopup({ title: '안내', content: '로그인을 해주세요.' });
  //   }
  // }, [isLoggedIn]);

  // 파람스 get
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderByCode = 1;
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');

  // 커뮤니티 게시글 리스트 불러오기 api
  const {
    data: { data_list: companyList, total_count },
    isError,
  } = useQueryInstance({
    queryKey: [queryKeys.companyList, orderByCode, page, count],
    apiMethod: 'get',
    apiEndPoint: endPoint.member.getCompanyList,
    apiQueryParams: {
      order_by_code: orderByCode,
      page,
      count,
    },
  });

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout>
      <Title>조합사 목록</Title>
      <BtnContainer>
        {/* <BtnNormal
          onClickEvent={() => navigate('/pages/board/estmate_inquiry/write')}
          bgColor="#ff7200"
        >
          회원가입
        </BtnNormal> */}
      </BtnContainer>
      {companyList?.length === 0 ? (
        <EmptyDataPage title="등록된 조합사가 없습니다." />
      ) : (
        <Grid
          data={companyList}
          // navigateUrl={'/pages/company_list/detail'}
          // idxName="company_idx"
          labelData={labelData}
          containerMargin="0px 0px 50px 0px"
        />
      )}

      {companyList?.length > 0 && (
        <Pagination
          conMargin="50px 0 0 0"
          page={page}
          totalCount={total_count}
          count={count}
          naviPath="/pages/company_list?"
        />
      )}
    </SectionLayout>
  );
};

// style 영역 ------
const Title = styled.p`
  text-align: center;
  font-size: 30px;
  font-family: 'Pretendard-medium';
  padding-bottom: 20px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0px;
  border-bottom: 2px solid black;
`;

export default CompanyList;
