import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../../../assets/css/Sub.css';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import BtnNormal from '../../../components/BtnNormal';
import ErrorFetching from '../../../components/error/ErrorFetching';
import InputBox from '../../../components/InputBox';
import SectionLayout from '../../../components/layout/SectionLayout';
import { usePopup } from '../../../components/popup/usePopup.js';
import SEOHelmet from '../../../components/seo/SEOHelmet';
import TextEditor_Quill from '../../../components/TextEditor_Quill';
import { BOARD_TYPE, WRITE_TYPE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { decodingCrypto } from '../../../utils/crypto';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';
import ValidateInput from '../../../utils/ValidateInput';

export const BoardWrite = ({ boardType }) => {
  const navigate = useNavigate();

  // 팝업창
  const { openPopup } = usePopup();

  // 수정시 파람스 얻기 -> 없다면 신규상품등록 모드로
  const { idx } = useParams();

  // 로그인 여부 확인

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const queryClient = useQueryClient();

  // 제목 저장
  const [boardTitle, setBoardTitle] = useState('');

  // 수정시 content내부 img url 셋
  const [preContentImagesUrl, setPreContentImagesUrl] = useState([]);

  // Quill에디터
  // 텍스트 데이터
  const [editorData, setEditorData] = useState('<p><br></p>');

  // 수정용 ----
  // idx값이 있다면 요청되는 부분
  const { isError: editIsError } = useQueryInstance({
    // idx값이 있고, ValidateInput이 true를 반환하는 충족일시 쿼리요청 실행
    queryEnable: ValidateInput(idx),
    queryKey: [queryKeys.boardDetail, idx],
    apiMethod: 'get',
    apiEndPoint: endPoint.board.getBoard,
    apiPathParams: idx,
    onSuccess: (data) => {
      setBoardTitle(data?.data?.title);
      setEditorData(data?.data?.content);

      // 본문 이미지가 있다면 셋
      if (data?.data?.properties) {
        const properties = JSON.parse(data?.data?.properties);
        setPreContentImagesUrl(properties.contentImagesUrl);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: 0,
  });

  // send체크
  const [sendCheck, setSendCheck] = useState(false);
  // useRef를 사용하여 클린업함수 제어
  const sendCheckRef = useRef(false);

  // check
  const validateProductWrite = () => {
    var check = false;

    if (!ValidateInput(boardTitle)) {
      openPopup({ title: '안내', content: '제목을 입력해주세요.' });
      return;
    }

    if (editorData === '<p><br></p>') {
      openPopup({ title: '안내', content: '본문을 입력해주세요.' });
      return;
    }

    check = true;

    return check;
  };

  // 최종 글 등록 요청 api
  const { mutate: onSendBoardApi } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.board.saveBoard,
    onErrorFn: () => {
      openPopup({
        title: '오류',
        content: '다시 시도해주세요.',
      });
    },
    onSuccessFn: () => {
      setSendCheck(true);
      sendCheckRef.current = true;
    },
  });

  // 수정시 본문 이미지 URL체크용 함수
  const checkContentImg = useCallback(
    (idx) => {
      // 수정모드 아니라면 기존 로직으로 끝
      if (!ValidateInput(idx)) return queryClient.getQueryData([queryKeys.boardWrite]);

      // 수정모드인데, 이미지 수정에 대한 흔적이 없다면 기존 프로퍼티를 그대로 넘김
      const deleteImageUrlEdit = queryClient.getQueryData([queryKeys.deleteImageUrlEdit]);
      const boardWrite = queryClient.getQueryData([queryKeys.boardWrite]);
      if (!deleteImageUrlEdit && !boardWrite) return preContentImagesUrl;

      // 지우거나 추가한 흔적이 있다면 아래 진행
      let newContentImage = [];

      if (preContentImagesUrl) {
        !deleteImageUrlEdit
          ? (newContentImage = preContentImagesUrl)
          : (newContentImage = preContentImagesUrl.filter(
              (el) => !deleteImageUrlEdit.some((del) => el.url === del.url),
            ));
      }

      if (boardWrite && boardWrite.length > 0) {
        boardWrite.forEach((el) => newContentImage.push(el));
      }

      return newContentImage;
    },
    [preContentImagesUrl],
  );

  // 요청 핸들
  const onSendBoard = () => {
    if (!validateProductWrite()) {
      return;
    }

    // 본문에 작성한 이미지 url경로 저장
    const properties = {
      contentImagesUrl: checkContentImg(idx),
    };

    let data = {
      board_type: boardType,
      title: boardTitle,
      content: editorData,
      properties: JSON.stringify(properties),
    };

    if (ValidateInput(idx)) {
      data = { ...data, board_idx: idx };
    }

    // 더블클릭 방지 됨
    onSendBoardApi({
      apiBody: data,
    });
  };

  // *모두* 언마운트시 작성 중이었던 이미지 삭제
  // mutation 이미지삭제 Post 요청

  const { mutate: removeIMGMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.quill.removeUploadFile,
    onErrorFn: () => {
      return openPopup({
        title: '오류',
        content: '사진을 다시 업로드해주세요.',
      });
    },
    onSuccessFn: (_, variables) => {
      const { apiBody } = variables;
      //  쿼리 상태보관에서 삭제
      if (apiBody && apiBody?.length > 0) {
        const currentQuery = queryClient.getQueryData([queryKeys.boardWrite]);
        if (currentQuery) {
          const updateQuery = currentQuery.filter(
            (el) => !apiBody.some((item) => item.url === el.url),
          );
          if (updateQuery.length > 0)
            return queryClient.setQueryData([queryKeys.boardWrite], updateQuery);
          return queryClient.removeQueries([queryKeys.boardWrite]);
        }
      }
    },
  });

  // *수정시* 작성 중인 이미지 삭제 함수 ------
  const { mutate: deleteEditimageMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.quill.removeUploadFile,
    onErrorFn: (error) => {
      console.error(error);
    },
    onSuccessFn: () => {
      queryClient.removeQueries([queryKeys.deleteImageUrlEdit]);
    },
  });

  const deleteEditImageAPI = async () => {
    const deleteImageUrl = await queryClient.getQueryData([queryKeys.deleteImageUrlEdit]);

    if (!deleteImageUrl) return;
    // deleteImageUrl가 있다면 아래 요청 진행
    deleteEditimageMutation({ apiBody: deleteImageUrl });
  };

  useEffect(() => {
    // 샌드요청이면 아래 진행,
    if (sendCheck) {
      if (idx) {
        deleteEditImageAPI();
        queryClient.invalidateQueries([queryKeys.boardDetail, idx], { force: true });
      }
      navigate(
        boardType === BOARD_TYPE.NOTICE
          ? '/pages/board/notice'
          : boardType === BOARD_TYPE.COMMUNITY
          ? '/pages/board/community'
          : boardType === BOARD_TYPE.INQUIRY
          ? '/pages/board/inquiry'
          : '/pages/board/estmate_inquiry',
      );
      return;
    }
    // --------------

    // 에디터 이미지 언마운트시 설정(quill컴포넌트에서 제어가 불가하여 부모 컴포넌트에서 처리)
    // 아니라면 언마운트시 이미지 s3에서 삭제
    return () => {
      if (!sendCheckRef.current) {
        queryClient.removeQueries([queryKeys.deleteImageUrlEdit]);
        const removeURLs = queryClient.getQueryData([queryKeys.boardWrite]);
        if (removeURLs) {
          removeIMGMutation({ apiBody: removeURLs });
        }
      } else {
        queryClient.removeQueries([queryKeys.boardWrite]);
      }
    };
  }, [sendCheckRef.current]);

  if (editIsError) return <ErrorFetching isError={editIsError} />;

  return (
    <>
      {idx && (
        <SEOHelmet
          title="일빵 | 글 수정"
          contentTitle="글 수정"
          description="작성한 글 수정하는 페이지"
          keywords="일빵, MRO, 게시판, 글, 공지사항, 커뮤니티, 수정"
        />
      )}

      <SectionLayout maxWidth="800px" padding="50px 0px">
        <p style={{ fontSize: '20px', borderBottom: '1px solid #bbbbbb', padding: '10px 0px' }}>
          {boardType === BOARD_TYPE.COMMUNITY
            ? '커뮤니티'
            : boardType === BOARD_TYPE.NOTICE
            ? '공지사항'
            : boardType === BOARD_TYPE.INQUIRY
            ? '상품문의'
            : '견적요청'}{' '}
          {!idx ? '글쓰기' : '글수정'}
        </p>
        <InputBox
          isRequiredMark={true}
          inputDisabled={true}
          inputValue={decodingCrypto(localStorage.getItem('member_name'))}
        >
          작성자
        </InputBox>
        <InputBox
          inputValue={boardTitle}
          onChangeEvent={(e) => setBoardTitle(e.target.value)}
          isRequiredMark={true}
        >
          제목
        </InputBox>
        <InputBox isRequiredMark={true} isInput={false} titlePadding="10px 0px 0px 0px">
          본문
        </InputBox>

        <TextEditor_Quill
          value={editorData}
          onChangeEvent={setEditorData}
          idx={idx}
          queryKey={queryKeys.boardWrite}
          typeCode={WRITE_TYPE.BOARD_WRITE}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            marginBottom: '50px',
          }}
        >
          <BtnNormal
            onClickEvent={() => navigate(-1)}
            fontSize="16px"
            fontWeight="200"
            bgColor="#f5f5f5"
            color="#bdbdbd"
            padding="18px 180px"
          >
            취소
          </BtnNormal>
          <BtnNormal
            onClickEvent={onSendBoard}
            fontSize="16px"
            fontWeight="200"
            bgColor="#ff7200"
            padding="18px 180px"
          >
            등록
          </BtnNormal>
        </div>
      </SectionLayout>
    </>
  );
};
