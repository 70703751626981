import { css } from 'styled-components';

import { colorList } from './theme';

export const hover = ({
  color = colorList.lightGray,
  transitionDuration = '0.3s',
  transitionEase = 'ease',
  textUnderline = 'none',
  textColor = 'inherit',
  textUnderlineOffset = '5px',
  opacity = '1',
}) => css`
  &:hover {
    background-color: ${color};
    transition: ${transitionDuration} ${transitionEase};
    text-decoration: ${textUnderline};
    text-underline-offset: ${textUnderlineOffset};
    color: ${textColor};
    opacity: ${opacity};
  }
`;

export const active = ({
  color = 'inherit',
  transitionDuration = '0.1s',
  transitionEase = 'ease',
  textUnderline = 'none',
  textColor = 'inherit',
  textUnderlineOffset = '5px',
  opacity = '0.5',
}) => css`
  &:active {
    background-color: ${color};
    transition: ${transitionDuration} ${transitionEase};
    text-decoration: ${textUnderline};
    text-underline-offset: ${textUnderlineOffset};
    color: ${textColor};
    opacity: ${opacity};
  }
`;

export const pointer = ({ isMobile = false }) => css`
  ${!isMobile ? 'cursor: pointer;' : ''}
`;
