import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../../api/endPoint';
import ErrorFetching from '../../../../components/error/ErrorFetching';
import Grid_OrderList from '../../../../components/Grid_OrderList';
import HeaderTitle from '../../../../components/HeaderTitle';
import SectionLayout from '../../../../components/layout/SectionLayout';
import MyPageButtonComponent from '../../../../components/MyPageButtonComponent';
import Pagination from '../../../../components/Pagination';
import { usePopup } from '../../../../components/popup/usePopup';
import { CACHE_TIME } from '../../../../constant/NUMBER_CONSTANT';
import { ORDER_ITEM_STATUS, PARAMS_TYPE } from '../../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../../react-query/useQueryInstance';
import { IsLoggedIn } from '../../../../utils/IsLoggedIn';

function SalesList() {
  const navigate = useNavigate();

  const { openPopup, closePopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');
  const status_code = params.get('status_code');
  const search_order_number = params.get('search_order_number');
  const search_product_name = params.get('search_product_name');

  // 판매내역 가져오기 --------
  const {
    data: { data_list, total_count },
    isError,
    refetch,
  } = useQueryInstance({
    queryKey: [
      queryKeys.salesListMypage,
      status_code,
      search_order_number,
      search_product_name,
      page,
      count,
    ],
    apiMethod: 'get',
    apiEndPoint: endPoint.sales.getSalesList,
    apiQueryParams: {
      my_sales_list: PARAMS_TYPE.MY_DATA,
      status_code,
      search_order_number,
      search_product_name,
      page,
      count,
    },
    selectFn: (data) => {
      const newData = {
        data_list: data?.data_list.map((el) => ({
          ...el,
          shipping_company_name: JSON.parse(el.order_properties).shipping_company_name,
          product_option_name: el.order_item_properties
            ? JSON.parse(el.order_item_properties).product_option_name
            : null,
        })),
        total_count: data.total_count,
      };
      return newData;
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: CACHE_TIME.DEFAULT,
  });

  //-----------

  // 셀렉터데이터
  const [selectValue, setSelectValue] = useState(0);

  // 셀렉터 핸들
  const selectorHandler = useCallback((e) => {
    const selectValue = Number(e.target.value);

    switch (selectValue) {
      case 0:
        navigate('/pages/member/mypage/sales_list');
        setSelectValue(0);
        break;

      // 정산완료
      case ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE:
        navigate(
          `/pages/member/mypage/sales_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE);
        break;

      // 입금완료
      case ORDER_ITEM_STATUS.ORDER:
        navigate(
          `/pages/member/mypage/sales_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.ORDER,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.ORDER);
        break;

      // 구매확정
      case ORDER_ITEM_STATUS.ORDER_COMPLETE:
        navigate(
          `/pages/member/mypage/sales_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.ORDER_COMPLETE,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.ORDER_COMPLETE);
        break;

      // 주문취소
      case ORDER_ITEM_STATUS.CANCEL:
        navigate(
          `/pages/member/mypage/sales_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.CANCEL,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.CANCEL);
        break;

      // 환불요청
      case ORDER_ITEM_STATUS.REFUND_REQUEST:
        navigate(
          `/pages/member/mypage/sales_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.REFUND_REQUEST,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.REFUND_REQUEST);
        break;

      // 환불완료
      case ORDER_ITEM_STATUS.REFUND:
        navigate(
          `/pages/member/mypage/sales_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.REFUND,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.REFUND);
        break;

      // 관리자문의
      case ORDER_ITEM_STATUS.ERROR:
        navigate(
          `/pages/member/mypage/sales_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.ERROR,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.ERROR);
        break;
      default:
        break;
    }
  }, []);

  // 검색창 타입 셀렉터
  const [searchType, setSearchType] = useState('0');

  // 검색어 담기
  const searchValue = useRef('');

  // 검색창 핸들
  const searchHandler = (e) => {
    e.preventDefault();

    const value = searchValue.current.value;

    if (!value) return navigate('/pages/member/mypage/sales_list');

    navigate(
      `/pages/member/mypage/sales_list/?page=1&count=20${
        searchType === '0' ? `&search_product_name=${value}` : `&search_order_number=${value}`
      }`,
    );
    searchValue.current.value = '';
    setSelectValue(0);
  };

  // 검색 후 처음으로 돌아가기
  const searchReset = useCallback(() => {
    navigate('/pages/member/mypage/sales_list');
  }, []);

  // 송장번호 등록 API
  const { mutate: saveShippingNumber } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.sales.saveShippingNumber,
    onErrorFn: () => {
      openPopup({ title: '오류', content: '다시 시도해주세요.' });
    },
    onSuccessFn: () => {
      refetch();
    },
  });

  // 운송장 등록하기
  const shippingCodeApi = (value, order_item_idx) => {
    saveShippingNumber({
      apiQueryParams: {
        order_item_idx: order_item_idx,
        shipping_number: value,
      },
    });
  };

  const shippingCodeFinal = (value, order_item_idx) => {
    openPopup({
      title: '안내',
      content: '송장번호: ' + value + '\n\n진행하시겠습니까?',
      onComplete: () => shippingCodeApi(value, order_item_idx),
      twoButton: true,
    });
  };

  // 송장번호입력 버튼 핸들러
  const shippingCodeBtnHandler = useCallback(
    (order_item_idx) => {
      openPopup({
        title: '송장번호 입력',
        content: '송장번호를 정확히 입력해주세요.',
        twoButton: true,
        input: true,
        setInputValue: (value) => shippingCodeFinal(value, order_item_idx),
      });
    },
    [openPopup, shippingCodeFinal],
  );

  // 송장번호 수정용
  const shippingCodeEditHandler = useCallback(
    (order_item_idx) => {
      openPopup({
        title: '송장번호 수정 입력',
        content: '수정하실 송장번호를 정확히 입력해주세요.',
        twoButton: true,
        input: true,
        setInputValue: (value) => shippingCodeFinal(value, order_item_idx),
      });
    },
    [openPopup, shippingCodeFinal],
  );

  // 주문 취소API ----------
  const { mutate: refundMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.orderCancel,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: () => {
      refetch();
      closePopup();
    },
  });

  const onRefund = useCallback((item) => {
    openPopup({
      title: '안내',
      content: '정말 취소하시겠습니까?',
      input: true,
      inputMaxLength: 12,
      setInputValue: (cancel_reason) => {
        if (!cancel_reason)
          return openPopup({ title: '오류', content: '취소사유를 입력해주세요.' });

        const cancelData = {
          order_idx: item.order_idx,
          order_item_idx: item.order_item_idx,
          cancel_reason,
        };

        refundMutation({
          apiBody: cancelData,
        });
      },
      twoButton: true,
    });
  }, []);

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout maxWidth="1400px">
      <MyPageButtonComponent
        dataList={[
          { title: '회원정보수정', current: false, url: '/pages/member/mypage/edit1' },
          { title: '주문내역', current: false, url: '/pages/member/mypage/order_list' },
          { title: '판매내역', current: true, url: '/pages/member/mypage/sales_list' },
          { title: '내상품', current: false, url: '/pages/member/mypage/product_list' },
          { title: '내가 쓴 글', current: false, url: '/pages/member/mypage/my_board_list' },
          { title: '견적문의', current: false, url: '/pages/member/mypage/my_estimate_index' },
        ]}
      />

      <HeaderTitle
        conMargin="70px 0 0 0"
        searchAndFilterMode={true}
        statusCode={status_code}
        searchParamsValueArr={[search_order_number, search_product_name]}
        searchAndFilterLabelArr={{
          searchTypeLabel: [
            { label: '상품이름검색', value: '0' },
            { label: '주문번호검색', value: '1' },
          ],
          filterLabel: [
            { label: '모두보기', value: 0 },
            { label: '정산완료', value: ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE },
            { label: '입금완료', value: ORDER_ITEM_STATUS.ORDER },
            { label: '구매확정', value: ORDER_ITEM_STATUS.ORDER_COMPLETE },
            { label: '주문취소', value: ORDER_ITEM_STATUS.CANCEL },
            // { label: '환불요청', value: ORDER_ITEM_STATUS.REFUND_REQUEST },
            { label: '환불완료', value: ORDER_ITEM_STATUS.REFUND },
            { label: '관리자문의', value: ORDER_ITEM_STATUS.ERROR },
          ],
        }}
        onChangeEventSearchAndFilterTypeSelector={(e) => setSearchType(e.target.value)}
        searchAndFilterTypeSelectorValue={searchType}
        onChangeEventSearchAndFilterSelector={selectorHandler}
        searchAndFilterSelectorValue={selectValue}
        onClickEventSearchAndFilterInput={searchHandler}
        searchAndFilterInputRef={searchValue}
        onClickEventSearchReset={searchReset}
      >
        판매내역 조회
      </HeaderTitle>

      <Grid_OrderList
        conMinHeight="500px"
        data={data_list}
        idx="order_item_idx"
        onClickEventToDetail={(idx) => {
          navigate(`detail/${idx}`);
        }}
        labelData={[
          {
            label: ['상품정보', '옵션'],
            labelKey: ['img_url', '', 'product_name', 'product_option_name'],
            flex: 3,
            img: true,
          },
          {
            label: ['주문번호', '주문일자'],
            labelKey: ['order_number', 'order_time'],
            flex: 1.5,
            date: [false, true],
          },
          {
            label: ['주문금액', '수량'],
            labelKey: ['order_price', 'order_count'],
            flex: 0.8,
            toLocale: [
              [true, '원'],
              [true, '(개)'],
            ],
          },
          {
            label: ['택배사', '송장번호'],
            labelKey: ['shipping_company_name', 'shipping_number'],
            flex: 1,
            shippingNumber: {
              sales: true,
              orderItemStatus: 'order_item_status',
              // 송장 입력 핸들, sales true일때만 가능
              onClickEventShippingNumber: shippingCodeBtnHandler,
              onClickEventShippingNumberEdit: shippingCodeEditHandler,
            },
          },
          {
            label: ['판매상태'],
            labelKey: ['order_item_status'],
            flex: 1,
            orderItemStatusRender: {
              onRefund: onRefund,
              onCancel: undefined,
              isOrderList: false,
            },
          },
        ]}
        search_order_number
        search_product_name
      />

      {data_list?.length !== 0 && (
        <Pagination
          conMargin="50px 0 0 0"
          totalCount={total_count}
          count={count}
          page={page}
          naviPath={`/pages/member/mypage/sales_list/?${
            status_code ? `status_code=${status_code}&` : ''
          }${search_order_number ? `search_order_number=${search_order_number}&` : ''}${
            search_product_name ? `search_product_name=${search_product_name}&` : ''
          }`}
        />
      )}
    </SectionLayout>
  );
}

export default SalesList;
