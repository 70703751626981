import React, { useEffect } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import styled from 'styled-components';

import { BREAK_POINT } from '../constant/NUMBER_CONSTANT';

// 개인정보처리방침 및 전자금융거래 이용약관 팝업
// 스타일
const Background = styled.div`
  position: fixed;
  top: 100px;
  z-index: 90;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const ButtonSpace = styled.div`
  background-color: white;
  border: solid 0.5px #c7c7c7;
  border-radius: 40px;
  padding-top: 30px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
`;
const CancelIconWrap = styled.div`
  text-align: right;
  font-size: 30px;
  padding: 0px 30px;
  @media screen and (max-width: ${BREAK_POINT.MEDIUM_SMALL_SCREEN}px) {
    padding: 0;
  }
`;

const CancelIcon = styled.span`
  cursor: pointer;
  &:active {
    color: gray;
  }
`;

const Container = styled.div`
  overflow: scroll;
  word-break: break-all;
  width: 80vw;
  height: 50vh;
  padding: 20px;
`;
// back blur
const BackBlur = styled.div`
  position: fixed;
  z-index: 80;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
`;

// 본문 컴포넌트
const FooterPopUp = ({ onClickEvent, children, backScroll = false }) => {
  // back 스크롤 설정 boolean
  if (backScroll) {
    useEffect(() => {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = 'auto';
      };
    }, []);
  }

  return (
    <>
      <BackBlur />
      <Background>
        <ButtonSpace>
          <CancelIconWrap>
            <CancelIcon onClick={onClickEvent}>
              <ImCancelCircle />
            </CancelIcon>
          </CancelIconWrap>
          <Container>{children}</Container>
        </ButtonSpace>
      </Background>
    </>
  );
};

export default FooterPopUp;
