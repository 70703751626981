// Delivery Tracker 조회 택배사 코드

export const DELIVERY_CODE = [
  {
    value: '- 택배사 선택 -',
  },
  {
    value: '경동택배',
  },
  {
    value: '대신택배',
  },
  {
    value: 'CJ대한통운',
  },
  {
    value: '로젠택배',
  },
  {
    value: '롯데택배',
  },
  {
    value: '우체국택배',
  },
  {
    value: '한진택배',
  },
  {
    value: '직접납품',
  },
  {
    value: '기타',
  },
];
