import moment from 'moment';
import { atom, selector } from 'recoil';

export const payFinalData = atom({
  key: 'payFinalData',
  default: {
    PCD_PAY_RST: '',
    PCD_PAY_MSG: '',
    PCD_PAYER_NAME: '',
    PCD_PAY_OID: '',
    PCD_PAY_GOODS: '',
    PCD_PAY_TIME: '',
    PCD_PAY_TYPE: '',
    PCD_PAY_BANKNAME: '',
    PCD_PAY_BANKNUM: '',
    PCD_PAY_CARDNAME: '',
    PCD_PAY_CARDNUM: '',
    PCD_PAY_TOTAL: '',
    Delivery: '',
    OptionName: '',
  },
});

export const payFinalDataSelector = selector({
  key: 'payFinalDataSelector',
  get: ({ get }) => {
    const data = get(payFinalData);
    // 시간만 형태 바꿔주기
    const newTitme = moment(data?.PCD_PAY_TIME, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm');

    // 가공된 시간으로 교체
    const newData = { ...data, PCD_PAY_TIME: newTitme };

    return newData;
  },
});
