import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../../api/endPoint';
import { colorList } from '../../../../assets/style/theme.jsx';
import BtnNormal from '../../../../components/BtnNormal.jsx';
import ErrorFetching from '../../../../components/error/ErrorFetching';
import Grid_OrderList from '../../../../components/Grid_OrderList.jsx';
import HeaderTitle from '../../../../components/HeaderTitle.jsx';
import SectionLayout from '../../../../components/layout/SectionLayout.jsx';
import MyPageButtonComponent from '../../../../components/MyPageButtonComponent.jsx';
import Pagination from '../../../../components/Pagination.jsx';
import { usePopup } from '../../../../components/popup/usePopup.js';
import { PARAMS_TYPE } from '../../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../../react-query/useQueryInstance';
import { IsLoggedIn } from '../../../../utils/IsLoggedIn';
import ValidateInput from '../../../../utils/ValidateInput';

function MyProductList() {
  const navigate = useNavigate();

  const isLoggedIn = IsLoggedIn();

  const { openPopup } = usePopup();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  // 다른 페이지 리패칭
  const queryClient = useQueryClient();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');
  const search_product_name = params.get('search_product_name');

  const onProductEditOrCopy = useCallback((e, idx, copy = false) => {
    e.preventDefault();
    e.stopPropagation();

    if (!ValidateInput(idx)) {
      openPopup({
        title: '안내',
        content: '해당 상품이 존재하지 않습니다.',
      });
    }

    navigate(`/pages/product/write/${idx}${copy ? '?copy=true' : ''}`);
  }, []);

  // 내 상품 불러오기
  const {
    data: { data_list, total_count },
    isError,
    refetch,
  } = useQueryInstance({
    queryKey: [queryKeys.myProductList, search_product_name, page, count],
    apiMethod: 'get',
    apiEndPoint: endPoint.product.getProductList,
    apiQueryParams: {
      my_product_list: PARAMS_TYPE.MY_DATA,
      search_product_name,
      page,
      count,
    },
  });

  // 셀렉터데이터
  const [selectValue, setSelectValue] = useState(0);

  // TODO 나중에 추가해야함
  // 셀렉터 핸들
  const selectorHandler = (e) => {
    const selectValue = Number(e.target.value);

    setSelectValue(selectValue);
  };

  // 검색어 담기
  const searchValue = useRef('');

  // 검색창 핸들
  const searchHandler = (e) => {
    e.preventDefault();

    const value = searchValue.current.value;

    if (!value) return navigate('/pages/member/mypage/product_list');

    navigate(`/pages/member/mypage/product_list/?page=1&count=20&search_product_name=${value}`);

    searchValue.current.value = '';
    setSelectValue(0);
  };

  // 검색 후 처음으로 돌아가기
  const searchReset = useCallback(() => {
    navigate('/pages/member/mypage/product_list');
  }, []);

  // 상품삭제 api
  const { mutate: apiOnProductRemove } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.product.removeProduct,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;

      openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: () => {
      queryClient.refetchQueries([queryKeys.productList], { force: true });
      refetch();
    },
  });

  const onProductRemove = useCallback((e, idx) => {
    e.preventDefault();
    e.stopPropagation();

    if (!ValidateInput(idx)) {
      openPopup({
        title: '안내',
        content: '해당 상품이 존재하지 않습니다.',
      });
    }

    openPopup({
      title: '안내',
      content: '정말 삭제하시겠습니까?',
      onComplete: () => {
        apiOnProductRemove({
          apiQueryParams: { product_idx: idx },
        });
      },
      twoButton: true,
    });
  }, []);

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <SectionLayout maxWidth="1400px">
      <MyPageButtonComponent
        dataList={[
          { title: '회원정보수정', current: false, url: '/pages/member/mypage/edit1' },
          { title: '주문내역', current: false, url: '/pages/member/mypage/order_list' },
          { title: '판매내역', current: false, url: '/pages/member/mypage/sales_list' },
          { title: '내상품', current: true, url: '/pages/member/mypage/product_list' },
          { title: '내가 쓴 글', current: false, url: '/pages/member/mypage/my_board_list' },
          { title: '견적문의', current: false, url: '/pages/member/mypage/my_estimate_index' },
        ]}
      />

      <HeaderTitle
        conMargin="70px 0 0 0"
        searchAndFilterMode={true}
        searchParamsValueArr={[search_product_name]}
        searchAndFilterLabelArr={{
          filterLabel: [
            { label: '모두보기', value: 0 },
            { label: '판매중', value: 200 },
            { label: '종료', value: 400 },
          ],
        }}
        searchAndFilterSelectorValue={selectValue}
        onChangeEventSearchAndFilterSelector={selectorHandler}
        searchAndFilterInputRef={searchValue}
        onClickEventSearchAndFilterInput={searchHandler}
        onClickEventSearchReset={searchReset}
        searchAndFilterinputPlacehlder="상품명을 검색하세요."
      >
        내 상품 조회
      </HeaderTitle>

      <Grid_OrderList
        conMinHeight="500px"
        idx="idx"
        data={data_list}
        onClickEventToDetail={(idx) => {
          navigate(`/pages/product/detail/${idx}`);
        }}
        labelData={[
          {
            label: ['상품정보'],
            labelKey: ['img_url', 'company_name', 'name'],
            flex: 4,
            img: true,
          },
          {
            label: ['등록일자'],
            labelKey: ['reg_by_time'],
            flex: 1.7,
            date: [true, false],
          },
          {
            label: ['등록금액', '수량 / 최수주문수량'],
            labelKey: ['price', 'stock_quantity', 'min_order_quantity'],
            flex: 1.2,
            quantityCheck: [
              [true, '원'],
              [true, ''],
              [true, '(개)'],
            ],
          },
          {
            label: ['상품상태'],
            labelKey: ['is_available'],
            flex: 1,
            isAvailable: true,
          },
          {
            label: ['옵션'],
            labelKey: ['option'],
            flex: 3,
            children: (idx) => (
              <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
                {/* <BtnNormal
                  color={colorList.dark}
                  bgColor={colorList.white}
                  border={`1px solid ${colorList.dark}`}
                  fontWeight="500"
                  fontSize="15px"
                  padding="10px 30px"
                  key={`copy_${idx}`}
                  onClickEvent={(e) => onProductEditOrCopy(e, idx, true)}
                >
                  복사
                </BtnNormal> */}
                <BtnNormal
                  color={colorList.white}
                  fontWeight="500"
                  fontSize="15px"
                  key={`edit_${idx}`}
                  padding="10px 30px"
                  onClickEvent={(e) => onProductEditOrCopy(e, idx)}
                >
                  수정
                </BtnNormal>
                <BtnNormal
                  color={colorList.white}
                  bgColor="#cb1400"
                  fontWeight="500"
                  fontSize="15px"
                  padding="10px 30px"
                  key={`delete_${idx}`}
                  onClickEvent={(e) => onProductRemove(e, idx)}
                >
                  삭제
                </BtnNormal>
              </div>
            ),
          },
        ]}
      />

      {data_list?.length !== 0 && (
        <Pagination
          conMargin="50px 0 0 0"
          totalCount={total_count}
          page={page}
          count={count}
          naviPath={`/pages/member/mypage/product_list/?${
            search_product_name ? `search_product_name=${search_product_name}&` : ''
          }`}
        />
      )}
    </SectionLayout>
  );
}

export default MyProductList;
