import React from 'react';
import styled from 'styled-components';

const EllipsisText = ({
  text,
  maxLength,
  classNameP,
  styleP,
  fontSize,
  fontWeight,
  textAlign,
  margin,
  color,
}) => {
  const editText = text?.length > maxLength ? text?.substring(0, maxLength) + '..' : text;

  return (
    <Text
      margin={margin}
      textAlign={textAlign}
      fontWeight={fontWeight}
      fontSize={fontSize}
      className={classNameP}
      style={styleP}
      color={color}
    >
      {editText}
    </Text>
  );
};

export default React.memo(EllipsisText);

const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  word-break: break-all;
  line-height: 1.5;
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => color};
`;
