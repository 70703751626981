// 팝업창 커스텀 훅

import { useRecoilState } from 'recoil';

import { useDragPopupAdState } from '../../recoil/useDragPopupAdState';

export const useDragPopupAd = () => {
  const [dragPopup, setDragPopup] = useRecoilState(useDragPopupAdState);

  const openDragPopup = () => {
    setDragPopup({
      show: true,
    });
  };

  const closeDragPopup = () => {
    setDragPopup({
      show: false,
    });
  };

  return { dragPopup, openDragPopup, closeDragPopup };
};
