import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../../../assets/css/Sub.css';

import { useNavigate } from 'react-router-dom';
import normalAxios from 'axios';
import moment from 'moment';

import axios from '../../../api/axios';
import { endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/PhoneAuth.module.scss';
import { colorList } from '../../../assets/style/theme.jsx';
import BtnNormal from '../../../components/BtnNormal.jsx';
import InputBox from '../../../components/InputBox.jsx';
import { usePopup } from '../../../components/popup/usePopup.js';
import { BankStandardCodeList } from '../../../constant/BankStandardCodeList';
import { FILE_SIZE, TIME } from '../../../constant/NUMBER_CONSTANT.js';
import { isRegexCheck, REGEX } from '../../../constant/REGEX.js';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { IsLoggedIn } from '../../../utils/IsLoggedIn';
import ValidateInput from '../../../utils/ValidateInput';

const NTS_BUSINESSMAN_SERVICE_KEY = process.env.REACT_APP_SERVICE_KEY;

export default function Join2() {
  const navigate = useNavigate();

  const { openPopup, closePopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      openPopup({ title: '안내', content: '이미 로그인 중 입니다.' });
      navigate('/');
      return;
    } else {
      localStorage.clear();
    }
  }, [isLoggedIn]);

  // 아이디 중복확인 했는지 확인용
  const [validationIdCheck, setValidationIdCheck] = useState(false);
  const [validationBusinessNumberCheck, setValidationBusinessNumberCheck] = useState(false);

  // 아이디가 변경될 때마다 중복 확인 플래그를 초기화
  const onMemberIdChange = (e) => {
    setMemberId(e.target.value);
    setValidationIdCheck(false); // 아이디 변경 시 플래그 초기화
  };

  const [memberId, setMemberId] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');

  const [email, setEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postCode, setPostCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [mainProduct, setMainProduct] = useState('');
  // 글자 수 제한
  const mainProductHandler = (e) => {
    const value = e.target.value;
    if (value.length <= 10 || value === '') {
      setMainProduct(value);
    }
  };

  const [officePhoneNumber, setOfficePhoneNumber] = useState('');
  const [bankStandardCode, setBankStandardCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [businessNumber, setBusinessNumber] = useState('');
  const [businessImgFile, setBusinessImgFile] = useState('');
  const [ceoName, setCeoName] = useState('');
  const [businessOpenDate, setBusinessOpenDate] = useState('');

  // 회원가입 API 호출
  const { mutate: onJoinMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.join,
    apiMultipartPost: true,
    onErrorFn: (error) => {
      const errorData = error.response.data;
      openPopup({
        title: '오류',
        content: errorData.message,
      });
    },
    onSuccessFn: () => {
      openPopup({ title: '안내', content: '회원가입이 완료되었습니다.' });
      navigate('/pages/member/login');
    },
  });

  const onCancel = () => {
    const goToMain = () => {
      closePopup();
      navigate('/');
      window.scrollTo(0, 0);
    };

    openPopup({
      title: '안내',
      content: '정말 취소하시겠습니까?',
      onComplete: () => goToMain(),
      twoButton: true,
    });
  };

  // 회원가입 API 호출 전 세팅
  const onJoin = async () => {
    if (!isPhoneSuccess) {
      openPopup({ title: '안내', content: '휴대폰 인증을 해주세요.' });
      return;
    }

    if (!validateJoin()) {
      return;
    }

    const data = {
      member_id: memberId.trim(),
      password: password.trim(),
      name: name.trim(),
      phone_number: phoneNumber.trim(),
      email: email,
      address1: address1,
      address2: address2,
      post_code: postCode,
      company_name: companyName.trim(),
      main_product: mainProduct,
      office_phone_number: officePhoneNumber,
      bank_standard_code: bankStandardCode,
      account_number: accountNumber,
      business_number: businessNumber.trim(),
    };

    const formData = new FormData();
    formData.append('member', JSON.stringify(data));
    formData.append('image', businessImgFile);

    if (formData) {
      onJoinMutation({
        apiBody: formData,
      });
    } else {
      openPopup({
        title: '오류',
        content: '알 수 없는 오류, 다시 시도해주세요.',
      });
    }
  };

  // 아이디 중복확인
  const idValidateCheck = () => {
    if (!ValidateInput(memberId)) {
      openPopup({ title: '안내', content: '아이디를 입력해주세요.' });
      return;
    }

    let idFilters = /^[a-zA-Z](?=.*[a-zA-Z]).{2,12}$/g;

    if (!idFilters.test(memberId)) {
      openPopup({
        title: '안내',
        content: '3자 이상 12자 이하의 영문 혹은 영문과 숫자를 조합해서 입력해주세요.',
      });
      return;
    }

    axios
      .get(`/api/member/checkIdDuplication?id=${memberId}`)
      .then((response) => {
        const checkValue = response.data;

        if (!checkValue) {
          openPopup({ title: '안내', content: '이미 사용중인 아이디입니다.' });
        } else {
          setValidationIdCheck(true);
          openPopup({ title: '안내', content: '사용 가능한 아이디입니다.' });
        }
      })
      .catch((error) => {
        const errorData = error.response.data;
        openPopup({
          title: '안내',
          content: errorData.message,
        });
      });
  };

  const [selectedImageName, setSelectedImageName] = useState('');

  const onFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (file.type !== 'image/jpeg' && file.type !== 'image/gif' && file.type !== 'image/png') {
      openPopup({
        title: '안내',
        content: `사진파일만 업로드 가능합니다.\n( jpeg, jpg, png, gif )`,
      });
      setBusinessImgFile('');
      setSelectedImageName('');
      return;
    }

    if (file.size > FILE_SIZE.MAX_IMAGE_FILE_SIZE) {
      openPopup({
        title: '안내',
        content: '파일 크기가 너무 큽니다 3MB 이하의 이미지파일을 입력해주세요.',
      });
      setBusinessImgFile('');
      setSelectedImageName('');
      return;
    }
    setBusinessImgFile(file);
    setSelectedImageName(file.name);
  };

  const fileInputRef = useRef(null);

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  // 주소데이터
  const openPostcodePopup = () => {
    const win = window.open('/address/search_address', 'postCode', 'width=500,height=500');
    window.addEventListener('message', (event) => {
      const addressData = event.data;

      setAddress1(addressData.address); // 주소
      setPostCode(addressData.zonecode); // 우편번호
      // 필요한 경우 기타 로직 추가
    });
  };

  // 검증하고 다시 재입력시 다시 검증
  useEffect(() => {
    // 감지 로직: 어느 하나의 값이 변경되면 검증 상태를 false로 변경합니다.
    setValidationBusinessNumberCheck(false);
  }, [businessNumber, ceoName, businessOpenDate]);

  // 사업자등록번호 검증
  const onBusinessNumberCheck = () => {
    if (!ValidateInput(ceoName)) {
      openPopup({ title: '안내', content: '대표자명을 입력해주세요.' });
      return;
    }
    if (!ValidateInput(businessOpenDate)) {
      openPopup({ title: '안내', content: '개업일자를 입력해주세요.' });
      return;
    }
    if (!ValidateInput(businessNumber)) {
      openPopup({ title: '안내', content: '사업자번호를 입력해주세요.' });
      return;
    }

    var sendData = [
      {
        b_no: businessNumber,
        start_dt: businessOpenDate,
        p_nm: ceoName,
      },
    ];

    axios
      .get(`/api/member/checkBusinessNumberDuplication?business_number=${businessNumber}`)
      .then((response) => {
        const checkValue = response.data;

        if (!checkValue) {
          openPopup({
            title: '안내',
            content: '입력한 사업자등록번호로 가입한 계정이 있습니다.',
          });
        } else {
          normalAxios
            .post(
              'https://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=' +
                `${NTS_BUSINESSMAN_SERVICE_KEY}`,
              JSON.stringify({ businesses: sendData }),
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((response) => {
              const validCnt = response.data.valid_cnt;

              if (validCnt === 1) {
                setValidationBusinessNumberCheck(true);
                openPopup({
                  title: '안내',
                  content: '정상적으로 검증되었습니다.',
                });
              } else {
                openPopup({
                  title: '안내',
                  content: '사업자등록번호, 대표자명, 개업일자를 확인해주세요.',
                });
              }
            })
            .catch((error) => {
              const errorData = error.response.data;
              openPopup({
                title: '안내',
                content: errorData.message,
              });
            });
        }
      })
      .catch((error) => {
        const errorData = error.response.data;
        openPopup({
          title: '안내',
          content: errorData.message,
        });
      });
  };

  // 비밀번호 영문,숫자,특수문자 8자리 이상 15자리 이하
  function isValidPassword(password) {
    let regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
    return regex.test(password);
  }

  function isValidEmail(email) {
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  const validateJoin = () => {
    var check = false;

    if (!ValidateInput(memberId)) {
      openPopup({ title: '안내', content: '아이디를 입력해주세요.' });
      return;
    }
    if (!validationIdCheck) {
      openPopup({ title: '안내', content: '아이디를 중복을 확인해주세요.' });
      return;
    }
    if (!ValidateInput(name)) {
      openPopup({ title: '안내', content: '이름을 입력해주세요.' });
      return;
    }
    if (!ValidateInput(password)) {
      openPopup({ title: '안내', content: '비밀번호를 입력해주세요.' });
      return;
    } else {
      if (!isValidPassword(password)) {
        openPopup({
          title: '안내',
          content:
            '비밀번호를 영문, 숫자, 특수문자 조합으로 8자리 이상 15자리 이하로 입력해주세요.',
        });
        return;
      }
    }

    if (!ValidateInput(confirmPassword)) {
      openPopup({ title: '안내', content: '비밀번호확인을 입력해주세요.' });
      return;
    }

    if (password !== confirmPassword) {
      openPopup({ title: '안내', content: '비밀번호와 비밀번호확인이 일치하지않습니다.' });
      return;
    }
    if (!ValidateInput(phoneNumber)) {
      openPopup({ title: '안내', content: '휴대폰 번호를 입력해주세요.' });
      return;
    }
    if (!ValidateInput(address1)) {
      openPopup({ title: '안내', content: '사업장 주소를 입력해주세요.' });
      return;
    }
    // TODO 상세주소가 필요없는 사업장도 있어서 일딴 주석처리
    // if (!ValidateInput(address2)) {
    //   openPopup({title: '안내', content: '사업장 상세주소를 입력해주세요.')
    //   return
    // }
    if (!ValidateInput(companyName)) {
      openPopup({ title: '안내', content: '회사명을 입력해주세요.' });
      return;
    }
    if (!ValidateInput(mainProduct)) {
      openPopup({ title: '안내', content: '주요 취급 품목을 입력해주세요.' });
      return;
    }
    if (ValidateInput(email)) {
      if (!isValidEmail(email)) {
        openPopup({ title: '안내', content: '이메일 형식을 맞춰주세요.' });
        return;
      }
    }
    if (!ValidateInput(bankStandardCode)) {
      openPopup({ title: '안내', content: '정산 은행을 선택해주세요.' });
      return;
    }
    if (!ValidateInput(accountNumber)) {
      openPopup({ title: '안내', content: '정산 계좌번호를 입력해주세요.' });
      return;
    }
    if (!ValidateInput(businessImgFile)) {
      openPopup({ title: '안내', content: '사업자등록증을 입력해주세요.' });
      return;
    }
    if (!validationBusinessNumberCheck) {
      openPopup({ title: '안내', content: '사업자번호 검증을 해주세요.' });
      return;
    }

    check = true;

    return check;
  };

  // 인증번호 입력 모드를 위한 훅
  const [onAuthTime, setOnAuthTime] = useState(false);

  // 카운트 다운 값
  const [time, setTime] = useState(moment.duration(TIME.SET_TIME, 'minutes'));

  // 인증번호 받기
  const [authNum, setAuthNum] = useState('');

  // 인증번호 성공시 성공 값
  const [isPhoneSuccess, setIsPhoneSuccess] = useState(false);

  // 인증번호 유효성검사 핸들
  const inputAuthHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyNumCheck,
      fn: (value) => setAuthNum(value),
      checkLengthOpt: { length: 6, comparison: '<=' },
    });
  }, []);

  // 카운트다운 컨트롤 훅
  useEffect(() => {
    if (!onAuthTime) return;

    const interval = setInterval(() => {
      setTime((pre) => {
        const newTime = pre.clone().subtract(1, 'second');
        if (newTime.asSeconds() <= 0) {
          clearInterval(interval);
          setOnAuthTime(false);
          setAuthNum('');
          return moment.duration(TIME.SET_TIME, 'minutes');
        }
        return newTime;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [onAuthTime]);

  // 인증코드 요청 api
  const { mutate: onSendMessage } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.send.sendVerifyPhoneNumber,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;

      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: (_, variable) => {
      if (variable.type) {
        setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
        openPopup({
          title: '인증번호 재전송 완료',
          content: '새로운 인증번호로 다시 입력해주세요.',
        });
        setAuthNum('');
        setOnAuthTime(true);
        return;
      }

      setOnAuthTime(true);
    },
  });

  // 인증코드 요청 API 핸들
  const phoneNumberCheck = useCallback(
    (e, type) => {
      e.preventDefault();
      if (!phoneNumber) return openPopup({ title: '안내', content: '휴대폰 번호를 입력해주세요.' });

      onSendMessage({
        apiQueryParams: {
          phone_number: phoneNumber,
        },
        type,
      });
    },
    [phoneNumber],
  );

  // 최종 전화번호 인증 api
  const { mutate: verifyMemberWithCode } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.send.verifyPhoneNumberWithCode,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: (response) => {
      setOnAuthTime(false);
      setAuthNum('');
      setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
      setIsPhoneSuccess(response.data);
    },
  });

  // 최종 전화번호 인증 api 핸들
  const finalAuthApiHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (!authNum) return openPopup({ title: '안내', content: '인증번호를 입력해주세요.' });

      const data = {
        phone_number: phoneNumber,
        verify_code: authNum,
      };

      verifyMemberWithCode({
        apiBody: data,
      });
    },
    [authNum],
  );

  return (
    <div className="content">
      <div className="container section">
        <div className="join">
          <div className="con-head">
            <p className="con-title">회원가입</p>
          </div>
          <div className="input-list">
            <div className="input-item">
              <div className="input-head">
                <span className="title">아이디</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body input-button-div">
                <input type="text" className="input-box input-type" onChange={onMemberIdChange} />
                <button type="button" className="input-btn" onClick={idValidateCheck}>
                  중복확인
                </button>
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">이름</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">비밀번호</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="password"
                  className="input-box"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">비밀번호확인</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="password"
                  className="input-box"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">휴대폰</span>
                <span className="required"> *</span>
              </div>
              {/*<div className="input-body input-button-div">*/}
              <div className="input-body input-button-div">
                <input
                  // style={{ width: '100%' }}
                  value={phoneNumber}
                  type="text"
                  className="input-box input-type"
                  placeholder="- 를 제외해서 입력해주세요."
                  onChange={(e) =>
                    isRegexCheck({
                      value: e.target.value,
                      pattern: REGEX.onlyNumCheck,
                      fn: (value) => setPhoneNumber(value),
                      checkLengthOpt: { length: 11, comparison: '<=' },
                    })
                  }
                  disabled={onAuthTime || isPhoneSuccess}
                />

                <button
                  type="button"
                  className="input-btn"
                  onClick={phoneNumberCheck}
                  style={{
                    color:
                      phoneNumber.length >= 10 && !onAuthTime && !isPhoneSuccess
                        ? '#424242'
                        : '#c2c2c2',
                    borderColor:
                      phoneNumber.length >= 10 && !onAuthTime && !isPhoneSuccess
                        ? '#424242'
                        : '#c2c2c2',
                  }}
                  disabled={phoneNumber.length < 10 || onAuthTime || isPhoneSuccess}
                >
                  인증코드 받기
                </button>
              </div>
              {onAuthTime && (
                <>
                  <div className={style.authContainer}>
                    <div className={style.authBoxWrap}>
                      {/* 카운트다운 영역 */}
                      <p className={style.countDown}>
                        {moment.utc(time.asMilliseconds()).format('mm:ss')}
                      </p>
                      <InputBox
                        inputValue={authNum}
                        htmlFor="auth"
                        placeholder="인증번호 6자리"
                        onChangeEvent={inputAuthHandler}
                      >
                        인증번호
                      </InputBox>
                    </div>
                    <BtnNormal
                      flex="2"
                      fontSize="15px"
                      fontWeight="500"
                      bgColor={colorList.white}
                      color={authNum.length >= 6 && onAuthTime ? '#424242' : '#c2c2c2'}
                      border={
                        authNum.length >= 6 && onAuthTime
                          ? '1px solid #424242'
                          : '1px solid #c2c2c2'
                      }
                      padding="15px 5px"
                      className={style.resend_btn}
                      disabled={authNum.length < 6}
                      onClickEvent={finalAuthApiHandler}
                    >
                      인증하기
                    </BtnNormal>
                    <BtnNormal
                      flex="1"
                      fontSize="15px"
                      fontWeight="500"
                      bgColor={colorList.darkBlue}
                      color={colorList.white}
                      padding="15px 5px"
                      className={style.resend_btn}
                      onClickEvent={(e) => phoneNumberCheck(e, true)}
                    >
                      재전송
                    </BtnNormal>
                  </div>
                  <p className={style.auth_subtitle}>※ 인증번호를 입력해주세요.</p>
                </>
              )}
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">이메일</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">사업장 주소</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body address">
                <input type="text" className="input-box first-address" value={address1} disabled />
                <button type="button" className="input-btn" onClick={openPostcodePopup}>
                  우편번호
                </button>
                <input
                  type="text"
                  className="input-box second-address"
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">회사명</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">주요 취급 품목</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  value={mainProduct}
                  placeholder="10자 이하 (띄어쓰기 포함)"
                  type="text"
                  className="input-box"
                  onChange={mainProductHandler}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <p className="title">회사 전화번호</p>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  value={officePhoneNumber}
                  placeholder="- 를 제외해서 입력해주세요."
                  onChange={(e) =>
                    isRegexCheck({
                      value: e.target.value,
                      pattern: REGEX.onlyNumCheck,
                      fn: (value) => setOfficePhoneNumber(value),
                    })
                  }
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">정산 은행</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <select
                  onChange={(e) => {
                    setBankStandardCode(e.target.value);
                  }}
                >
                  <option value="">- 은행 선택 -</option>
                  {BankStandardCodeList().map((options) => (
                    <option key={options.code} value={options.code}>
                      {options.bankName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">정산 계좌번호</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  value={accountNumber}
                  placeholder="- 를 제외해서 입력해주세요."
                  onChange={(e) =>
                    isRegexCheck({
                      value: e.target.value,
                      pattern: REGEX.onlyNumCheck,
                      fn: (value) => setAccountNumber(value),
                    })
                  }
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">사업자등록증(이미지)</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body type_02">
                <input
                  type="text"
                  className="input-box business-img-name"
                  disabled
                  value={selectedImageName}
                />
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={onFileChange}
                />
                <button type="button" className="input-btn" onClick={openFilePicker}>
                  이미지 등록
                </button>
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">대표자명</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  placeholder="공동대표의 경우 1인을 대표로 하여 입력해주세요."
                  onChange={(e) => setCeoName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">개업일자</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body">
                <input
                  type="text"
                  className="input-box"
                  value={businessOpenDate}
                  placeholder="YYYYMMDD (사업자등록증 상의 개업연월일)"
                  onChange={(e) =>
                    isRegexCheck({
                      value: e.target.value,
                      pattern: REGEX.onlyNumCheck,
                      fn: (value) => setBusinessOpenDate(value),
                      checkLengthOpt: { length: 8, comparison: '<=' },
                    })
                  }
                />
              </div>
            </div>
            <div className="input-item">
              <div className="input-head">
                <span className="title">사업자등록번호</span>
                <span className="required"> *</span>
              </div>
              <div className="input-body input-button-div">
                <input
                  type="text"
                  className="input-box input-type"
                  value={businessNumber}
                  placeholder="- 를 제외한 10자리수를 입력해주세요."
                  onChange={(e) =>
                    isRegexCheck({
                      value: e.target.value,
                      pattern: REGEX.onlyNumCheck,
                      fn: (value) => setBusinessNumber(value),
                      checkLengthOpt: { length: 10, comparison: '<=' },
                    })
                  }
                />
                <button type="button" className="input-btn" onClick={onBusinessNumberCheck}>
                  검증
                </button>
              </div>
            </div>
          </div>
          <div className="page-btn">
            <button className="page-btn-item prev" onClick={onCancel}>
              취소
            </button>
            <button className="page-btn-item next" onClick={onJoin}>
              회원가입
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
