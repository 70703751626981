import he from 'he';
import sanitizeHtml from 'sanitize-html';

export const htmlTagRemove = (htmlText) => {
  const clean = sanitizeHtml(htmlText, {
    allowedTags: ['p'],
    allowedAttributes: {},
  })
    .replace(/<p>|<\/p>/g, (match) => {
      switch (match) {
        case '<p>':
          return '';
        case '</p>':
          return ' ';
        default:
          return match;
      }
    })
    .trim();

  return he.decode(clean);
};
