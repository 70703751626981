import React from 'react';
import styled from 'styled-components';

import { colorList, shadow } from '../../assets/style/theme';

const Layout = styled.div`
  position: fixed;
  box-sizing: border-box;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  width: ${({ width }) => width};
  height: fit-content;
  background-color: ${colorList.white};
  left: 50%;
  top: 50%;
  transform: ${({ active }) => (active ? 'translate(-50%, -50%)' : 'translate(-50%, -20%);')};
  border-radius: 10px;
  box-shadow: ${shadow.boxShadow};
  padding: 0px 30px;
  z-index: 999;
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: 1s ease;
  pointer-events: none; /* 클릭 이벤트 무시 */
`;

const Title = styled.p`
  text-align: center;
  font-size: 20px;
  margin: 40px 0 30px 0;
`;

const Content = styled.p`
  text-align: ${({ textAlign }) => textAlign};
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
  word-break: break-all;
`;

const ToolTipPopup = ({
  active = false,
  title = '안내',
  content = '본문내용',
  width = '375px',
  textAlign = 'justify',
}) => {
  return (
    <Layout width={width} active={active}>
      {title && <Title>{title}</Title>}
      <Content textAlign={textAlign}>{content}</Content>
    </Layout>
  );
};

export default React.memo(ToolTipPopup);
