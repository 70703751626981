import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/FindId.module.scss';
import { colorList } from '../../../assets/style/theme.jsx';
import BtnNormal from '../../../components/BtnNormal.jsx';
import InputBox from '../../../components/InputBox.jsx';
import { usePopup } from '../../../components/popup/usePopup.js';
import { TIME } from '../../../constant/NUMBER_CONSTANT.js';
import { isRegexCheck, REGEX } from '../../../constant/REGEX.js';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { IsLoggedIn } from '../../../utils/IsLoggedIn.js';

export default function FindPw() {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      openPopup({ title: '안내', content: '이미 로그인 중 입니다.' });
      navigate('/');
      return;
    } else {
      localStorage.clear();
    }
  }, [isLoggedIn]);

  // 사용자 입력 값
  const [memberName, setMemberName] = useState('');
  const idRef = useRef();
  const [phoneNum, setPhoneNum] = useState('');

  // 인증번호 입력 모드를 위한 훅
  const [onAuthTime, setOnAuthTime] = useState(false);

  // 카운트 다운 값
  const [time, setTime] = useState(moment.duration(TIME.SET_TIME, 'minutes'));

  // 인증번호 받기
  const [authNum, setAuthNum] = useState('');

  // 인증번호 성공 후 모드를 위한 훅
  const [isSuccess, setIsSuccess] = useState(false);

  // 새로운 패스워드 ref
  const passwordRef = useRef();
  const passwordCheckRef = useRef();

  const { mutate: onSendMessage } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.findMemberSendMessage,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;

      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: (_, variable) => {
      if (variable.type) {
        setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
        openPopup({
          title: '인증번호 재전송 완료',
          content: '새로운 인증번호로 다시 입력해주세요.',
        });
        setAuthNum('');
        setOnAuthTime(true);
        return;
      }

      setOnAuthTime(true);
    },
  });

  // 인증번호 요청 api
  const phoneAuthApiHandler = useCallback(
    (e, type) => {
      e.preventDefault();

      if (!memberName) return openPopup({ title: '안내', content: '이름을 입력해주세요.' });

      if (!idRef.current.value)
        return openPopup({ title: '안내', content: '아이디를 입력해주세요' });

      onSendMessage({
        apiBody: {
          member_id: idRef.current.value,
          member_name: memberName,
          phone_number: phoneNum,
        },
        type,
      });
    },
    [memberName, idRef.current, phoneNum],
  );

  const { mutate: verifyMemberWithCode } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.verifyMemberWithCode,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: () => {
      setOnAuthTime(false);
      setAuthNum('');
      setTime((pre) => moment.duration(TIME.SET_TIME, 'minutes'));
      setIsSuccess(true);
    },
  });

  // 전화번호 최종 인증 API요청
  const finalAuthApiHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (!authNum) return openPopup({ title: '안내', content: '인증번호를 입력해주세요.' });

      const data = {
        member_name: memberName,
        phone_number: phoneNum,
        verify_code: authNum,
      };

      verifyMemberWithCode({
        apiBody: data,
      });
    },
    [authNum],
  );

  const { mutate: changePasswordApi } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.member.changePassword,
    onErrorFn: (error) => {
      const serverMessage = error.response.data.message;
      return openPopup({
        title: '오류',
        content: serverMessage,
      });
    },
    onSuccessFn: () => {
      openPopup({
        title: '비밀번호 변경 완료',
        content: '로그인을 진행해주세요.',
        onComplete: () => navigate('/pages/member/login'),
      });
      setIsSuccess(false);
    },
  });

  // 비밀번호 변경 API
  const changePwApiHandler = (e) => {
    e.preventDefault();

    if (!passwordRef.current.value)
      return openPopup({ title: '안내', content: '새로운 비밀번호를 입력해주세요.' });

    if (!passwordCheckRef.current.value)
      return openPopup({ title: '안내', content: '새로운 비밀번호를 한번 더 입력해주세요.' });

    if (!REGEX.password.test(passwordRef.current.value)) {
      openPopup({
        title: '안내',
        content: '비밀번호를 영문, 숫자, 특수문자 조합으로 8자리 이상 15자리 이하로 입력해주세요.',
      });
      return;
    }

    if (passwordRef.current.value !== passwordCheckRef.current.value)
      return openPopup({
        title: '안내',
        content: '비밀번호가 일치하지 않습니다. \n\n 정확하게 다시 두 번 입력해주세요.',
      });

    changePasswordApi({
      apiBody: { member_id: idRef.current.value, password: passwordCheckRef.current.value },
    });
  };

  // 카운트다운 컨트롤 훅
  useEffect(() => {
    if (!onAuthTime) return;

    const interval = setInterval(() => {
      setTime((pre) => {
        const newTime = pre.clone().subtract(1, 'second');
        if (newTime.asSeconds() <= 0) {
          clearInterval(interval);
          setOnAuthTime(false);
          setAuthNum('');
          return moment.duration(TIME.SET_TIME, 'minutes');
        }
        return newTime;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [onAuthTime]);

  // 핸들모음
  // 인풋 핸들러 모음 (input태그는 정규식 함수 적용)
  const inputNameHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyTextCheck_Kor_Eng,
      fn: (value) => setMemberName(value),
    });
  }, []);

  const inputPhoneHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyNumCheck,
      fn: (value) => setPhoneNum(value),
      checkLengthOpt: { length: 11, comparison: '<=' },
    });
  }, []);

  const inputAuthHandler = useCallback((e) => {
    isRegexCheck({
      value: e.target.value,
      pattern: REGEX.onlyNumCheck,
      fn: (value) => setAuthNum(value),
      checkLengthOpt: { length: 6, comparison: '<=' },
    });
  }, []);

  return (
    <div className={style.login_container}>
      <div className={style.login_box}>
        <h1 className={style.login_h1}>비밀번호 찾기</h1>
        <form>
          <InputBox
            htmlFor="nameInput"
            inputValue={memberName}
            placeholder="이름을 입력해주세요"
            onChangeEvent={inputNameHandler}
            inputDisabled={onAuthTime || isSuccess}
          >
            이름
          </InputBox>
          <InputBox
            htmlFor="idInput"
            inputType="text"
            inputRef={idRef}
            placeholder="아이디를 입력해주세요"
            inputDisabled={onAuthTime || isSuccess}
          >
            아이디
          </InputBox>
          <InputBox
            inputValue={phoneNum}
            htmlFor="phonNumber"
            placeholder="'-'를 제외하고 휴대폰 번호를 입력해주세요"
            onChangeEvent={inputPhoneHandler}
            inputDisabled={onAuthTime || isSuccess}
          >
            휴대폰 번호
          </InputBox>
          {onAuthTime && (
            <>
              <div className={style.authContainer}>
                <div className={style.authBoxWrap}>
                  {/* 카운트다운 영역 */}
                  <p className={style.countDown}>
                    {moment.utc(time.asMilliseconds()).format('mm:ss')}
                  </p>
                  <InputBox
                    inputValue={authNum}
                    htmlFor="auth"
                    placeholder="인증번호 6자리"
                    onChangeEvent={inputAuthHandler}
                  >
                    인증번호
                  </InputBox>
                </div>
                <BtnNormal
                  flex="2"
                  fontSize="15px"
                  fontWeight="500"
                  bgColor={colorList.darkBlue}
                  color={colorList.white}
                  padding="15px 5px"
                  className={style.resend_btn}
                  onClickEvent={(e) => phoneAuthApiHandler(e, true)}
                >
                  재전송
                </BtnNormal>
              </div>

              <p className={style.auth_subtitle}>※ 인증번호를 입력해주세요.</p>
            </>
          )}

          {isSuccess && (
            <>
              <p className={style.auth_subtitle}>※ 사용자 인증완료</p>
              <InputBox
                inputRef={passwordRef}
                inputType="password"
                htmlFor="pw"
                placeholder="새로운 비밀번호를 입력해주세요."
              >
                새로운 비밀번호
              </InputBox>
              <InputBox
                inputRef={passwordCheckRef}
                inputType="password"
                htmlFor="pwCheck"
                placeholder="새로운 비밀번호를 다시 한번 입력해주세요."
              >
                새로운 비밀번호 확인
              </InputBox>
            </>
          )}

          <div className={style.btn_box}>
            {!onAuthTime && !isSuccess ? (
              <BtnNormal
                buttonType="submit"
                bgColor={phoneNum.length >= 10 ? colorList.main_dark : colorList.gray}
                fontSize="16px"
                padding="15px 0px"
                disabled={phoneNum.length < 10}
                onClickEvent={(e) => phoneAuthApiHandler(e)}
              >
                인증번호 받기
              </BtnNormal>
            ) : onAuthTime && !isSuccess ? (
              <BtnNormal
                buttonType="submit"
                fontSize="16px"
                padding="15px 0px"
                bgColor={authNum.length >= 6 ? colorList.main_dark : colorList.gray}
                disabled={authNum.length !== 6}
                onClickEvent={finalAuthApiHandler}
              >
                인증하기
              </BtnNormal>
            ) : (
              <BtnNormal
                buttonType="submit"
                fontSize="16px"
                padding="15px 0px"
                bgColor={colorList.main_dark}
                onClickEvent={changePwApiHandler}
              >
                비밀번호 변경하기
              </BtnNormal>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
