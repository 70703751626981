import React from 'react';
import styled from 'styled-components';

const SelectCustom = ({
  data = [
    { label: '샘플1', value: 'sample1' },
    { label: '샘플2', value: 'sample2' },
    { label: '샘플3', value: 'sample3' },
  ],
  maxWidth = '200px',
  height = '40px',
  onChangeEvent,
  value,
  fontSize = '16px',
  padding,
  color = 'black',
  flex,
  disabled = false,
}) => {
  return (
    <Select
      value={value}
      padding={padding}
      maxWidth={maxWidth}
      height={height}
      onChange={onChangeEvent}
      fontSize={fontSize}
      color={color}
      flex={flex}
      disabled={disabled}
    >
      {data?.map((el, index) => (
        <option key={index} value={el.value}>
          {el.label}
        </option>
      ))}
    </Select>
  );
};

export default React.memo(SelectCustom);

const Select = styled.select`
  position: relative;
  display: block;
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ padding }) => padding};
  color: ${({ color }) => color};
  flex: ${({ flex }) => flex};
`;
