import React from 'react';
import styled from 'styled-components';

import plusIcon from '../assets/img/icon/icon-add.svg';
import minusIcon from '../assets/img/icon/icon-remove.svg';
import { colorList } from '../assets/style/theme';
import { changeEnteredNumComma } from '../utils/numberComma';
import InputBox from './InputBox';
import SelectCustom from './SelectCustom';
import ellipsisTextString from './textLimit/ellipsisTextString';

const Container = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: ${({ backTop }) => backTop};
  right: ${({ backRight }) => backRight};
  width: fit-content;
  height: 100%;
  z-index: 10;
`;

const Layout = styled.div`
  position: sticky;
  width: ${({ menuWidth }) => menuWidth};
  height: fit-content;
  top: ${({ stickyTop }) => stickyTop};
  right: 0;
  box-sizing: border-box;
  padding: 30px 15px;
  border: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 20px;
  z-index: 12;
`;

const InfoBox = styled.div`
  width: 100%;
  background-color: #f8f7f6;
  padding: 24px 10px;
  box-sizing: border-box;
  border-radius: 20px;
`;

const ProductName = styled.p`
  font-size: 18px;
  font-family: 'Pretendard-regular';
  font-weight: 700;
  word-break: break-all;
  text-align: justify;
  line-height: 1.3;
  margin-bottom: 10px;
`;

const NumBox = styled.div`
  position: relative;
  margin: auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ddd;
`;

const MinusBtn = styled.button`
  width: ${({ plusMinusBtnSize }) => plusMinusBtnSize};
  height: ${({ plusMinusBtnSize }) => plusMinusBtnSize};
  background-color: #fff;
  background-size: ${({ plusMinusBtnSize }) =>
    `${Number(plusMinusBtnSize.replace('px', '')) - 10}px`};
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${minusIcon});
`;

const PlusBtn = styled.button`
  width: ${({ plusMinusBtnSize }) => plusMinusBtnSize};
  height: ${({ plusMinusBtnSize }) => plusMinusBtnSize};
  background-color: #fff;
  background-size: ${({ plusMinusBtnSize }) =>
    `${Number(plusMinusBtnSize.replace('px', '')) - 10}px`};
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${plusIcon});
`;

const CountInput = styled.input`
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  width: ${({ numInputWidth }) => numInputWidth};
  height: 32px;
  text-align: center;
  padding: ${({ numInputPadding }) => numInputPadding};
`;

const Price = styled.p`
  font-size: 15px;
  margin-top: 24px;
  text-align: center;
`;

const PriceFont = styled.span`
  font-size: 18px;
  font-family: 'Pretendard-bold';
`;

const TotalContainer = styled.div`
  margin-top: ${({ isSoldeOut }) => (isSoldeOut ? '30px' : '50px')};
  border-top: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 42px 0;
`;

const TotalTitle = styled.p`
  font-size: ${({ totalTitleFontSize }) => totalTitleFontSize};
  font-family: 'Pretendard-bold';
  margin-bottom: 24px;
  text-align: ${({ totalTextAlign }) => totalTextAlign};
`;

const TotalPrice = styled.p`
  font-size: ${({ totalPriceFontSize }) =>
    `${Number(totalPriceFontSize.replace('px', '')) - 16}px`};
  text-align: ${({ totalTextAlign }) => totalTextAlign};
`;

const TotalPriceFont = styled.span`
  font-size: ${({ totalPriceFontSize }) => totalPriceFontSize};
  color: ${({ isSoldeOut }) => (!isSoldeOut ? '#b70000' : colorList.darkGray)};
  text-decoration: ${({ isSoldeOut }) => (!isSoldeOut ? 'none' : 'line-through')};
  font-family: 'Pretendard-bold';
`;

const BtnBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;

const BuyBtn = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #ddd;
  font-size: 20px;
`;

const CartBtn = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #ddd;
  font-size: 20px;
  border: none;
  background-color: #2e4995;
  color: #fff;
`;

const RadioBoxContainer = styled.div`
  display: ${({ radioBoxContainerDisplay }) => radioBoxContainerDisplay};
  width: ${({ radioBoxContainerWidth }) => radioBoxContainerWidth};
  justify-content: left;
`;

const FloatingMenu = ({
  floatingOptionValue,
  floatingOptionOnChange,
  optionData,
  productName,
  price,
  onClickMinus,
  onClickPlus,
  onChangeCount,
  onChangeValue,
  onClickBuy,
  onClickCart,
  isPayTypeRadioBox = false,
  payTypeRadioOnChange = null,
  payTypeRadioValue = '',
  radioBoxContainerWidth,
  backTop = '0',
  stickyTop = '0',
  backRight = '0',
  menuWidth = '250px',
  numInputWidth = '80px',
  plusMinusBtnSize = '30px',
  numInputPadding = '0 10px',
  totalPriceFontSize = '34px',
  totalTitleFontSize = '24px',
  radioBoxContainerDisplay = 'flex',
  totalTextAlign = 'right',
  isSoldOut,
  isLoggedIn,
}) => {
  return (
    <Container backTop={backTop} backRight={backRight}>
      <Layout stickyTop={stickyTop} menuWidth={menuWidth}>
        <InfoBox>
          <ProductName>{productName}</ProductName>
          {optionData?.length > 0 && isLoggedIn && (
            <div
              style={{
                width: '100%',
                marginBottom: '15px',
              }}
            >
              {/*<p style={{ fontSize: '14px', marginBottom: '5px' }}>*/}
              {/*  <span style={{ color: 'red' }}>*</span>옵션*/}
              {/*</p>*/}
              <SelectCustom
                fontSize="14px"
                maxWidth="100%"
                height="30px"
                padding="0 0 0 5px"
                data={[
                  { label: '상품을 선택해 주세요.', value: 0 },
                  ...(optionData?.map((el) => ({
                    label: ellipsisTextString({
                      text: el.name + ' (+' + el.price + '원)',
                      maxLength: 12,
                    }),
                    value: el.product_option_idx,
                  })) || []),
                ]}
                value={floatingOptionValue}
                onChangeEvent={floatingOptionOnChange}
              />
            </div>
          )}
          {!isSoldOut && isLoggedIn && (
            <>
              <NumBox>
                <MinusBtn
                  plusMinusBtnSize={plusMinusBtnSize}
                  type="button"
                  onClick={onClickMinus}
                />
                <CountInput
                  numInputPadding={numInputPadding}
                  numInputWidth={numInputWidth}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={onChangeValue}
                  onChange={onChangeCount}
                />
                <PlusBtn
                  plusMinusBtnSize={plusMinusBtnSize}
                  type="button"
                  onClick={onClickPlus}
                ></PlusBtn>
              </NumBox>
              <Price>
                <PriceFont>
                  {floatingOptionValue &&
                  floatingOptionValue !== 0 &&
                  optionData?.find((v) => v.product_option_idx === floatingOptionValue)?.price !==
                    undefined
                    ? changeEnteredNumComma(
                        price +
                          optionData?.find((v) => v.product_option_idx === floatingOptionValue)
                            ?.price,
                      )
                    : changeEnteredNumComma(price)}
                </PriceFont>
                원
              </Price>
            </>
          )}
        </InfoBox>

        <TotalContainer isSoldeOut={isSoldOut}>
          <TotalTitle totalTextAlign={totalTextAlign} totalTitleFontSize={totalTitleFontSize}>
            총 합계{' '}
            <span style={{ fontSize: `${Number(totalTitleFontSize.replace('px', '')) - 4}px` }}>
              (VAT 별도)
            </span>
          </TotalTitle>
          {!isSoldOut && isLoggedIn && (
            <TotalPrice totalTextAlign={totalTextAlign} totalPriceFontSize={totalPriceFontSize}>
              <TotalPriceFont totalPriceFontSize={totalPriceFontSize}>
                {floatingOptionValue &&
                onChangeValue &&
                floatingOptionValue !== 0 &&
                optionData?.find((v) => v.product_option_idx === floatingOptionValue)?.price !==
                  undefined
                  ? changeEnteredNumComma(
                      (price +
                        +Math.round(
                          (optionData?.find((v) => v.product_option_idx === floatingOptionValue)
                            ?.price +
                            optionData?.find((v) => v.product_option_idx === floatingOptionValue)
                              ?.price *
                              0.048) /
                            10,
                        ) *
                          10) *
                        onChangeValue,
                    )
                  : changeEnteredNumComma(price * onChangeValue)}
              </TotalPriceFont>{' '}
              원
            </TotalPrice>
          )}
          {isSoldOut && isLoggedIn && (
            <TotalPrice totalTextAlign={totalTextAlign} totalPriceFontSize={totalPriceFontSize}>
              <TotalPriceFont totalPriceFontSize={totalPriceFontSize} isSoldeOut={true}>
                {changeEnteredNumComma(price * onChangeValue)}
              </TotalPriceFont>
            </TotalPrice>
          )}
        </TotalContainer>

        {isPayTypeRadioBox && !isSoldOut && isLoggedIn && (
          <RadioBoxContainer
            radioBoxContainerDisplay={radioBoxContainerDisplay}
            radioBoxContainerWidth={radioBoxContainerWidth}
          >
            <InputBox
              inputType="radio"
              htmlFor="payTypeTransferFloat"
              inputWidth="20px"
              inputValue="transfer"
              justifyContent="center"
              inputChecked={payTypeRadioValue === 'transfer'}
              onChangeEvent={(e) =>
                payTypeRadioOnChange((cur) => (e.target.checked ? 'transfer' : ''))
              }
            >
              계좌이체
            </InputBox>
            <InputBox
              inputType="radio"
              htmlFor="payTypeCardFloat"
              inputWidth="20px"
              inputValue="card"
              justifyContent="center"
              inputChecked={payTypeRadioValue === 'card'}
              onChangeEvent={(e) => payTypeRadioOnChange((cur) => (e.target.checked ? 'card' : ''))}
            >
              카드결제
            </InputBox>
          </RadioBoxContainer>
        )}

        <BtnBox>
          {isSoldOut && (
            <BuyBtn disabled={true} type="button">
              품절
            </BuyBtn>
          )}
          {!isSoldOut && (
            <BuyBtn type="button" onClick={onClickBuy}>
              바로구매
            </BuyBtn>
          )}
          <CartBtn type="button" onClick={onClickCart}>
            장바구니
          </CartBtn>
        </BtnBox>
      </Layout>
    </Container>
  );
};

export default React.memo(FloatingMenu);
