import React, { useEffect, useRef, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { hover } from '../../assets/style/pointer';
import { colorList } from '../../assets/style/theme';
import { dateFormatHelper } from '../../utils/dateHelper';
import { exportOptionData } from '../../utils/exportOptionData';
import { commaInput, resultCommaRemove } from '../../utils/numberComma';
import BtnBack from '../BtnBack';
import BtnNormal from '../BtnNormal';
import HeaderTitle from '../HeaderTitle';
import InputBox from '../InputBox';
import { usePopup } from '../popup/usePopup';
import SelectCustom from '../SelectCustom';
import ellipsisTextString from '../textLimit/ellipsisTextString';
import ImageLayout from './ImageLayout';

const WriteEstimateCard = ({
  padding = '20px',
  wrapStyle,
  contentWidth,
  contentHeight,
  onClickCancelBtn,
  sendEstimate = false,
  initialSendEstimate = false,
  onClickSend,
  onClickBuy,
  time,
  estimateData,
  breakPoint,
  isMine,
  chatMessageIdx,
  extraProductList,
  messageType,
  onClickRemove,
}) => {
  const { openPopup } = usePopup();

  const quantityValueRef = useRef('');
  const priceValueRef = useRef('');
  // const textAreaValueRef = useRef('');

  const navigate = useNavigate();

  const [selectProduct, setSelectProduct] = useState(null);
  const [slideValue, setSlideValue] = useState(0);

  // 옵션 선택 value
  const [option, setOption] = useState('0');

  // 결제시 카드결제인지
  const [payType, setPayType] = useState('transfer');

  return (
    <WriteBoxWrap sendEstimate={sendEstimate} style={{ ...wrapStyle }}>
      <WriteEstimateContent
        contentWidth={contentWidth}
        contentHeight={contentHeight}
        padding={padding}
        sendEstimate={sendEstimate}
      >
        <HeaderTitle
          conPadding="0 0 10px 0"
          conMargin="0"
          fontSize="18px"
          fontWeight="600"
          borderBottom={'1px solid' + colorList.lightGray}
          btnTitle={!sendEstimate ? <RxCross2 size={20} /> : ''}
          btnPadding="5px 5px 0 5px"
          btnBgColor={colorList.white}
          btnColor={colorList.main_dark}
          btnBorder={'1px solid' + colorList.main_dark}
          btnOnClickEvent={onClickCancelBtn}
          conJustfyContent={
            sendEstimate && !initialSendEstimate && messageType === 40 ? 'center' : undefined
          }
        >
          {!sendEstimate && !initialSendEstimate && messageType !== 40 ? (
            '견적입력'
          ) : sendEstimate && !initialSendEstimate && messageType !== 40 ? (
            '견적서'
          ) : sendEstimate && !initialSendEstimate && messageType === 40 ? (
            <span style={{ color: colorList.main_dark }}>결제완료</span>
          ) : sendEstimate && initialSendEstimate && messageType !== 40 ? (
            '상품문의'
          ) : (
            ''
          )}
          {sendEstimate && initialSendEstimate && (
            <span
              style={{
                display: breakPoint ? 'block' : 'inline',
                fontSize: '12px',
                fontWeight: '400',
                marginLeft: breakPoint ? '0' : '5px',
                marginTop: breakPoint ? '5px' : '0',
                color: colorList.darkGray,
              }}
            >
              {dateFormatHelper(time)}
            </span>
          )}
        </HeaderTitle>
        {sendEstimate && initialSendEstimate && estimateData ? (
          <div>
            <Hover
              color="inherit"
              opacity="0.5"
              onClick={() => navigate(`/pages/product/detail/${estimateData.product_idx}`)}
            >
              <div style={{ width: '120px', margin: '10px auto' }}>
                <ImageLayout imgSrc={estimateData.img_url} alt="상품이미지" />
              </div>
              <p
                style={{
                  fontSize: breakPoint ? '12px' : '14px',
                  fontWeight: '400',
                  lineHeight: '1.2',
                  marginBottom: '5px',
                  textAlign: 'justify',
                }}
              >
                {estimateData.name}
              </p>
            </Hover>
            <p
              style={{
                fontSize: breakPoint ? '12px' : '14px',
                fontWeight: '600',
                textAlign: 'right',
                marginBottom: breakPoint ? '5px' : '10px',
              }}
            >
              {estimateData.price?.toLocaleString('ko-KR')} ₩
            </p>
            <p
              style={{
                fontSize: breakPoint ? '10px' : '12px',
                fontWeight: '400',
                textAlign: 'right',
                color: colorList.mild_dark,
              }}
            >
              {/*최소주문수량 {estimateData.min_order_quantity} 개*/}
            </p>
          </div>
        ) : !sendEstimate && !initialSendEstimate && extraProductList ? (
          <AnimatePresence mode="popLayout">
            {!selectProduct ? (
              <motion.div
                key="product"
                initial={{ x: slideValue, opacity: 1 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: `${Number(contentHeight?.replace('px', '')) - 180}px`,
                    boxSizing: 'border-box',
                    gap: '10px',
                    padding: '10px',
                    overflowY: 'scroll',
                  }}
                >
                  {extraProductList?.map((el) => (
                    <Hover
                      key={el.idx}
                      color="inherit"
                      opacity="0.5"
                      onClick={() => {
                        setSelectProduct({
                          idx: el.idx,
                          name: el.name,
                          price: el.price,
                          img_url: el.img_url,
                          product_option_list: el.product_option_list
                            ? el.product_option_list
                            : null,
                        });
                        setSlideValue(-300);
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          boxShadow: '1px 2px 2px 1px rgba(0, 0, 0, 0.3)',
                          padding: '10px',
                          boxSizing: 'border-box',
                          borderRadius: '10px',
                          backgroundColor: '#fafafa',
                        }}
                      >
                        <div style={{ width: '100px', margin: '10px auto' }}>
                          <ImageLayout imgSrc={el.img_url} alt="상품이미지" />
                        </div>
                        <p
                          style={{
                            fontSize: breakPoint ? '12px' : '14px',
                            lineHeight: '1.2',
                            marginBottom: '10px',
                          }}
                        >
                          {el.name}
                        </p>
                        <p style={{ fontSize: breakPoint ? '12px' : '14px', textAlign: 'right' }}>
                          {el.price?.toLocaleString('ko-KR')} ₩
                        </p>
                      </div>
                    </Hover>
                  ))}
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="noProduct"
                initial={{ x: 300, opacity: 1 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 300, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div style={{ padding: '10px 0' }}>
                  <BtnBack title="" padding="2px 5px" onClickEvent={() => setSelectProduct(null)} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <div style={{ flex: '2', marginTop: '10px' }}>
                    <ImageLayout imgSrc={selectProduct.img_url} alt="상품이미지" />
                  </div>
                  <p
                    style={{ flex: '8', lineHeight: '1.2', fontSize: breakPoint ? '14px' : '16px' }}
                  >
                    {ellipsisTextString({
                      text: selectProduct.name,
                      maxLength: 40,
                    })}
                  </p>
                </div>
                <p style={{ textAlign: 'right', fontSize: breakPoint ? '14px' : '16px' }}>
                  {selectProduct.price.toLocaleString('ko-KR')} ₩
                </p>

                {selectProduct.product_option_list && (
                  <div
                    style={{
                      width: '100%',
                      marginBottom: '15px',
                    }}
                  >
                    <p style={{ fontSize: '14px', padding: '10px 0' }}>
                      옵션<span style={{ color: 'red' }}>*</span>
                    </p>
                    <SelectCustom
                      fontSize="14px"
                      maxWidth="100%"
                      height="31px"
                      padding="5px 0 5px 5px"
                      data={[
                        { label: '-옵션선택-', value: 0 },
                        ...(selectProduct.product_option_list?.map((el) => ({
                          label: ellipsisTextString({ text: el.name, maxLength: 12 }),
                          value: el.product_option_idx,
                        })) || []),
                      ]}
                      value={option}
                      onChangeEvent={(e) => setOption(e.target.value)}
                    />
                  </div>
                )}

                <InputBox
                  inputDisabled={
                    selectProduct?.product_option_list &&
                    exportOptionData(selectProduct?.product_option_list, Number(option))
                      ?.stock_quantity <
                      exportOptionData(selectProduct?.product_option_list, Number(option))
                        ?.min_order_quantity
                  }
                  inputRef={quantityValueRef}
                  containerMargin="0 0 5px 0"
                  inputPadding="5px 10px"
                  inputFontSize="16px"
                  isRequiredMark
                  placeholder={
                    selectProduct?.product_option_list &&
                    exportOptionData(selectProduct?.product_option_list, Number(option))
                      ?.stock_quantity <
                      exportOptionData(selectProduct?.product_option_list, Number(option))
                        ?.min_order_quantity
                      ? 'Sold-Out'
                      : ''
                  }
                  onChangeEvent={(e) =>
                    commaInput(
                      e,
                      (newValue) => {
                        quantityValueRef.current.value = newValue;
                      },
                      quantityValueRef?.current,
                    )
                  }
                >
                  수량
                </InputBox>
                <InputBox
                  inputDisabled={
                    selectProduct?.product_option_list &&
                    exportOptionData(selectProduct?.product_option_list, Number(option))
                      ?.stock_quantity <
                      exportOptionData(selectProduct?.product_option_list, Number(option))
                        ?.min_order_quantity
                  }
                  inputRef={priceValueRef}
                  containerMargin="0 0 5px 0"
                  inputPadding="5px 10px"
                  inputFontSize="16px"
                  placeholder={
                    selectProduct?.product_option_list &&
                    exportOptionData(selectProduct?.product_option_list, Number(option))
                      ?.stock_quantity <
                      exportOptionData(selectProduct?.product_option_list, Number(option))
                        ?.min_order_quantity
                      ? 'Sold-Out'
                      : ''
                  }
                  isRequiredMark
                  onChangeEvent={(e) =>
                    commaInput(
                      e,
                      (newValue) => {
                        priceValueRef.current.value = newValue;
                      },
                      priceValueRef?.current,
                    )
                  }
                >
                  총 금액
                </InputBox>

                <p style={{ fontSize: '14px', color: 'red' }}>*부가세별도</p>
                {/* <TextAreaCustom
                  ref={textAreaValueRef}
                  minRows={1}
                  maxRows={2}
                  style={{ fontSize: '16px', padding: '5px 10px' }}
                >
                  기타
                </TextAreaCustom> */}

                <div style={{ textAlign: 'right', margin: '5px 0px' }}>
                  <BtnNormal
                    fontSize="14px"
                    fontWeight="600"
                    padding="5px 10px"
                    onClickEvent={() => {
                      if (selectProduct?.product_option_list && Number(option) === 0)
                        return openPopup({ title: '안내', content: '옵션을 체크해주세요.' });

                      if (
                        selectProduct?.product_option_list &&
                        exportOptionData(selectProduct?.product_option_list, Number(option))
                          ?.stock_quantity <
                          exportOptionData(selectProduct?.product_option_list, Number(option))
                            ?.min_order_quantity
                      )
                        return openPopup({ title: '안내', content: '재고가 없습니다.' });

                      onClickSend({
                        count: resultCommaRemove(quantityValueRef.current?.value),
                        price: resultCommaRemove(priceValueRef.current?.value),
                        // text: textAreaValueRef.current?.value,
                        product_idx: selectProduct.idx,
                        name: selectProduct.name,
                        official_price: selectProduct.price,
                        img_url: selectProduct.img_url,
                        product_option_idx:
                          Number(option) !== 0 && selectProduct?.product_option_list
                            ? Number(option)
                            : null,
                        product_option_name:
                          Number(option) !== 0 && selectProduct?.product_option_list
                            ? exportOptionData(selectProduct?.product_option_list, Number(option))
                                ?.name
                            : null,
                      });
                    }}
                  >
                    보내기
                  </BtnNormal>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        ) : sendEstimate && !initialSendEstimate && estimateData && messageType !== 40 ? (
          <div style={{ paddingTop: '10px' }}>
            <div style={{ width: '120px', margin: 'auto' }}>
              <ImageLayout imgSrc={estimateData.img_url} alt="상품이미지" />
            </div>
            <p
              style={{
                lineHeight: '1.2',
                fontSize: breakPoint ? '14px' : '16px',
                marginBottom: '10px',
              }}
            >
              {estimateData.name}
            </p>
            {!estimateData?.is_deleted ? (
              <>
                <p
                  style={{
                    textAlign: 'right',
                    fontSize: '10px',
                    marginBottom: '5px',
                    color: colorList.mild_dark,
                  }}
                >
                  1개당 기존 가격:
                </p>
                <p style={{ textAlign: 'right', fontSize: '14px', color: colorList.mild_dark }}>
                  {estimateData.official_price?.toLocaleString('ko-KR')} ₩
                </p>
                {estimateData.product_option_name && estimateData.product_option_idx && (
                  <InputBox
                    containerMargin="0 0 5px 0"
                    inputPadding="5px 10px"
                    inputFontSize={breakPoint ? '14px' : '16px'}
                    isRequiredMark
                    inputDisabled={sendEstimate}
                    inputDefaultValue={estimateData.product_option_name}
                  >
                    옵션
                  </InputBox>
                )}
                <InputBox
                  inputRef={quantityValueRef}
                  containerMargin="0 0 5px 0"
                  inputPadding="5px 10px"
                  inputFontSize={breakPoint ? '14px' : '16px'}
                  isRequiredMark
                  inputDisabled={sendEstimate}
                  inputDefaultValue={estimateData.count?.toLocaleString('ko-KR')}
                >
                  수량
                </InputBox>
                <InputBox
                  inputRef={priceValueRef}
                  containerMargin="0 0 5px 0"
                  inputPadding="5px 10px"
                  inputFontSize={breakPoint ? '14px' : '16px'}
                  isRequiredMark
                  inputDisabled={sendEstimate}
                  inputDefaultValue={estimateData.price?.toLocaleString('ko-KR')}
                >
                  총 합계
                </InputBox>
                <p style={{ fontSize: '14px', color: 'red' }}>*부가세별도</p>
              </>
            ) : (
              <p style={{ color: colorList.main_dark, fontWeight: '600' }}>삭제된 견적서입니다.</p>
            )}

            {/* <TextAreaCustom
              ref={textAreaValueRef}
              disabled={sendEstimate}
              minRows={1}
              maxRows={2}
              style={{ fontSize: breakPoint ? '14px' : '16px', padding: '5px 10px' }}
              defaultValue={estimateData?.text}
            >
              기타
            </TextAreaCustom> */}
            {sendEstimate && !isMine && !estimateData?.is_deleted ? (
              <div style={{ textAlign: 'right', margin: '5px 0px' }}>
                <div style={{ display: breakPoint ? 'block' : 'flex', gap: '20px' }}>
                  <InputBox
                    inputType="radio"
                    htmlFor={`payTypeTransfer_${chatMessageIdx ? chatMessageIdx : null}`}
                    inputWidth="20px"
                    containerWidth="fit-content"
                    containerMargin="20px 0"
                    inputValue="transfer"
                    inputChecked={payType === 'transfer'}
                    onChangeEvent={(e) => setPayType(() => (e.target.checked ? 'transfer' : ''))}
                  >
                    계좌이체
                  </InputBox>
                  <InputBox
                    inputType="radio"
                    htmlFor={`payTypeCard_${chatMessageIdx ? chatMessageIdx : null}`}
                    inputWidth="20px"
                    containerWidth="fit-content"
                    containerMargin="20px 0"
                    inputValue="card"
                    inputChecked={payType === 'card'}
                    onChangeEvent={(e) => setPayType(() => (e.target.checked ? 'card' : ''))}
                  >
                    카드결제
                  </InputBox>
                </div>
                <BtnNormal
                  fontSize="16px"
                  fontWeight="600"
                  padding="5px 10px"
                  onClickEvent={() => onClickBuy(payType)}
                >
                  구매
                </BtnNormal>
              </div>
            ) : sendEstimate && isMine && !estimateData?.is_deleted ? (
              <div style={{ textAlign: 'right' }}>
                <BtnNormal
                  fontSize="14px"
                  fontWeight="600"
                  padding="5px 10px"
                  bgColor={colorList.main}
                  onClickEvent={onClickRemove}
                >
                  삭제
                </BtnNormal>
              </div>
            ) : null}
          </div>
        ) : sendEstimate && !initialSendEstimate && estimateData && messageType === 40 ? (
          <div style={{ paddingTop: '10px' }}>
            <div style={{ width: '120px', margin: 'auto' }}>
              <ImageLayout imgSrc={estimateData.img_url} alt="상품이미지" />
            </div>
            <p
              style={{
                lineHeight: '1.2',
                fontSize: breakPoint ? '14px' : '16px',
                marginBottom: '10px',
                fontWeight: '600',
              }}
            >
              {estimateData.name}
            </p>
            <div
              style={{ display: 'flex', textAlign: 'center', marginBottom: '15px', gap: '10px' }}
            >
              <p style={{ fontSize: '14px', alignSelf: 'center' }}>수량:</p>
              <p style={{ wordBreak: 'break-all', fontSize: breakPoint ? '14px' : '16px' }}>
                {estimateData.count?.toLocaleString('ko-KR')} 개
              </p>
            </div>

            <div
              style={{ display: 'flex', textAlign: 'center', gap: '10px', marginBottom: '10px' }}
            >
              <p style={{ fontSize: '14px', alignSelf: 'center' }}>총 합계:</p>
              <p style={{ wordBreak: 'break-all', fontSize: breakPoint ? '14px' : '16px' }}>
                {estimateData.price?.toLocaleString('ko-KR')} ₩
              </p>
            </div>

            <p style={{ fontSize: breakPoint ? '12px' : '14px', color: 'red' }}>
              *부가세포함 결제완료
            </p>
            {/* <TextAreaCustom
            ref={textAreaValueRef}
            disabled={sendEstimate}
            minRows={1}
            maxRows={2}
            style={{ fontSize: breakPoint ? '14px' : '16px', padding: '5px 10px' }}
            defaultValue={estimateData?.text}
          >
            기타
          </TextAreaCustom> */}
            {sendEstimate && isMine ? (
              <div style={{ textAlign: 'right', margin: '5px 0px' }}>
                <BtnNormal
                  fontSize="14px"
                  fontWeight="600"
                  padding="5px 10px"
                  onClickEvent={() => navigate('/pages/member/mypage/order_list')}
                >
                  결제상세
                </BtnNormal>
              </div>
            ) : null}
          </div>
        ) : null}
      </WriteEstimateContent>
    </WriteBoxWrap>
  );
};

const WriteBoxWrap = styled.div`
  position: absolute;
  filter: ${({ sendEstimate }) =>
    !sendEstimate ? 'drop-shadow(0px 3px 2px rgba(50, 50, 0, 0.3))' : 'none'};
`;

const WriteEstimateContent = styled.div`
  position: relative;
  box-sizing: border-box;
  width: ${({ contentWidth }) => contentWidth};
  height: ${({ contentHeight }) => contentHeight};
  clip-path: ${({ sendEstimate }) =>
    !sendEstimate
      ? `polygon(
    1.186% 4%,
    1.186% 4%,
    1.238% 3.351%,
    1.387% 2.736%,
    1.627% 2.162%,
    1.948% 1.638%,
    2.343% 1.172%,
    2.804% 0.772%,
    3.322% 0.446%,
    3.889% 0.204%,
    4.497% 0.052%,
    5.138% 0%,
    96.047% 0%,
    96.047% 0%,
    96.689% 0.052%,
    97.297% 0.204%,
    97.864% 0.446%,
    98.382% 0.772%,
    98.842% 1.172%,
    99.237% 1.638%,
    99.559% 2.162%,
    99.799% 2.736%,
    99.948% 3.351%,
    100% 4%,
    100% 100%,
    75.296% 82.158%,
    50.593% 82.158%,
    5.138% 82.158%,
    5.138% 82.158%,
    4.497% 82.105%,
    3.889% 81.954%,
    3.322% 81.711%,
    2.804% 81.386%,
    2.343% 80.986%,
    1.948% 80.52%,
    1.627% 79.996%,
    1.387% 79.422%,
    1.238% 78.806%,
    1.186% 78.158%,
    1.186% 4%
  )`
      : 'none'};
  background-color: white;
  border-radius: 10px 10px 0px 10px;
  z-index: ${({ sendEstimate }) => (!sendEstimate ? '999' : '0')};
  padding: ${({ padding }) => padding};
`;

const Hover = styled.div`
  cursor: pointer;
  ${({ color, opacity, textUnderline, textUnderlineOffset }) =>
    hover({ color, opacity, textUnderline, textUnderlineOffset })}
`;

export default React.memo(WriteEstimateCard);
