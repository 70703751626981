import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { ImCancelCircle } from 'react-icons/im';
import { useLocation, useNavigate } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import { colorList, shadow } from '../../../assets/style/theme';
import BtnNormal from '../../../components/BtnNormal';
import ErrorFetching from '../../../components/error/ErrorFetching';
import Grid_OrderList from '../../../components/Grid_OrderList';
import HeaderTitle from '../../../components/HeaderTitle';
import SectionLayout from '../../../components/layout/SectionLayout';
import Pagination from '../../../components/Pagination';
import { usePopup } from '../../../components/popup/usePopup';
import SelectCustom from '../../../components/SelectCustom';
import { useLoginCheck } from '../../../components/useLoginCheck/useLoginCheck';
import { PRODUCT_TYPE } from '../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { exportCategoryPathAndName } from '../../../utils/exportCategoryName';
import ValidateInput from '../../../utils/ValidateInput';

const AdminProductList = () => {
  // 로그인첵 훅
  useLoginCheck();

  const navigate = useNavigate();

  const searchRef = useRef();

  // 파람스 데이터 꺼내기
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchProductName = params.get('search_product_name');
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');

  const {
    data: { data_list: productListData, total_count },
    isError,
  } = useQueryInstance({
    queryKey: [queryKeys.productList, searchProductName, null, page, count],
    apiMethod: 'get',
    apiEndPoint: endPoint.product.getProductList,
    apiQueryParams: {
      search_product_name: searchProductName,
      page,
      count,
    },
  });

  // 검색창 핸들
  const searchHandler = (e) => {
    e.preventDefault();

    const value = searchRef.current?.value;

    if (!value) return navigate('/admin/product_list');

    navigate(`/admin/product_list/?page=1&count=20&search_product_name=${value}`);

    searchRef.current.value = '';
    return;
  };

  // 검색 후 처음으로 돌아가기
  const searchReset = () => {
    navigate('/admin/product_list');
  };

  // 카테고리 불러오기
  const {
    data: { data_list: categories },
    isError: isErrorCategory,
  } = useQueryInstance({
    queryKey: [queryKeys.getProductCategoryList],
    apiMethod: 'get',
    apiEndPoint: endPoint.category.getProductCategoryList,
  });

  // 팝업 및 데이터 프롭스용
  const [openCategoryEdit, setOpenCategoryEdit] = useState(null);

  const categoryEditHandler = (e, idx) => {
    e.stopPropagation();
    setOpenCategoryEdit((cur) => (cur === null ? { idx } : { ...cur, idx }));
  };

  if (isError || isErrorCategory) return <ErrorFetching isError={isError || isErrorCategory} />;

  return (
    <>
      {openCategoryEdit && (
        <CategoryEditPopup
          productIdx={openCategoryEdit.idx}
          onClickClosePopup={() => setOpenCategoryEdit(null)}
        />
      )}
      <SectionLayout maxWidth="100%" padding="50px">
        <HeaderTitle
          searchAndFilterMode={true}
          searchParamsValueArr={[searchProductName]}
          searchAndFilterLabelArr={{}}
          onClickEventSearchReset={searchReset}
          searchAndFilterInputRef={searchRef}
          onClickEventSearchAndFilterInput={searchHandler}
        >
          상품 리스트
        </HeaderTitle>
        <Grid_OrderList
          conMinHeight="500px"
          idx="idx"
          data={productListData}
          onClickEventToDetail={(idx) => {
            navigate(`/pages/product/detail/${idx}`);
          }}
          labelData={[
            {
              label: ['상품정보'],
              labelKey: ['img_url', 'company_name', 'name'],
              flex: 4,
              img: true,
            },
            {
              label: ['등록일자'],
              labelKey: ['reg_by_time'],
              flex: 1.7,
              date: [true, false],
            },
            {
              label: ['등록금액', '수량 / 최수주문수량'],
              labelKey: ['price', 'stock_quantity', 'min_order_quantity'],
              flex: 1.2,
              quantityCheck: [
                [true, '원'],
                [true, ''],
                [true, '(개)'],
              ],
            },
            {
              label: ['상품상태'],
              labelKey: ['is_available'],
              flex: 1,
              isAvailable: true,
            },
            {
              label: ['카테고리'],
              labelKey: ['category'],
              flex: 3,
              children: (idx) => {
                const propertiesData = productListData?.filter((el) => el.idx === idx)[0]
                  .properties;
                const propertiesParse = propertiesData ? JSON.parse(propertiesData) : null;
                const categoryData = propertiesParse ? propertiesParse.category3 : 0;

                const categoryName = exportCategoryPathAndName(categories, categoryData);
                return (
                  <BtnNormal
                    color={colorList.dark}
                    bgColor={colorList.white}
                    border={`1px solid ${colorList.main_dark}`}
                    fontWeight="500"
                    fontSize="15px"
                    key={`edit_${idx}`}
                    padding="10px"
                    onClickEvent={(e) => categoryEditHandler(e, idx)}
                  >
                    {categoryName}
                  </BtnNormal>
                );
              },
            },
          ]}
        />
        {productListData?.length > 0 && (
          <Pagination
            conMargin="50px 0 0 0"
            page={page}
            totalCount={total_count}
            count={count}
            naviPath={`/admin/product_list/?${
              searchProductName ? `search_product_name=${searchProductName}&` : ''
            }`}
          />
        )}
      </SectionLayout>
    </>
  );
};

export default AdminProductList;

const CategoryEditPopup = ({ onClickClosePopup, productIdx }) => {
  const queryClient = useQueryClient();
  const { openPopup } = usePopup();
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  // 수정용 개별데이터 ----
  const {
    data: { data: productData },
    isError: itemIsError,
  } = useQueryInstance({
    queryKey: [queryKeys.product, productIdx],
    apiMethod: 'get',
    apiEndPoint: endPoint.product.getProduct,
    apiPathParams: productIdx,
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: 0,
  });

  // 카테고리 벨류 저장
  // 1depth
  const [selectedCategory1, setSelectedCategory1] = useState(null);
  // 2depth
  const [selectedCategory2, setSelectedCategory2] = useState(null);
  // 3depth
  const [selectedCategory3, setSelectedCategory3] = useState(null);

  // 셀렉터용 배열 1뎁스
  const [categoryArrFirst, setCategoryArrFirst] = useState(null);

  // 셀렉터용 배열 2뎁스
  const [categoryArrTwo, setCategoryArrTwo] = useState(null);

  // 셀렉터용 배열 3뎁스
  const [categoryArrThird, setCategoryArrThird] = useState([{ label: '- 분류 선택 -', value: '' }]);

  // 수정하기전 카테고리 데이터 세팅
  useEffect(() => {
    if (!productData) return;

    // json파싱할 데이터 파싱 후 아래에서 사용
    const propertiesParse = JSON.parse(productData.properties);

    // 카테고리 set
    if (propertiesParse) {
      setSelectedCategory1(propertiesParse.category1);
      setSelectedCategory2(propertiesParse.category2 || null);
      setSelectedCategory3(propertiesParse.category3 || null);
    }
  }, [productData]);

  // react-query에서 캐싱 된 모든 카테고리 불러오기 , 캐싱 없을 시 패칭 이루어짐
  const { data, isError } = useQueryInstance({
    queryKey: [queryKeys.getProductCategoryList],
    apiMethod: 'get',
    apiEndPoint: endPoint.category.getProductCategoryList,
    onSuccess: ({ data_list }) => {
      // 1뎁스 처리
      if (data_list?.length > 0) {
        setCategoryArrFirst(() => [
          { label: '- 분류 선택 -', value: '' },
          ...data_list.map((el) => ({ label: el.name, value: el.category_idx })),
        ]);
      }
    },
  });

  // 2뎁스처리
  useEffect(() => {
    if (data?.data_list?.length > 0) {
      const firstFiter = selectedCategory1
        ? data.data_list
            .map((el) =>
              selectedCategory1 === el.category_idx && el.child_category_list
                ? el.child_category_list
                : null,
            )
            .filter((el) => el !== null)[0]
        : null;

      const twoNewArr = firstFiter
        ? firstFiter.map((el) => ({ label: el.name, value: el.category_idx }))
        : null;

      setCategoryArrTwo(() => {
        return [{ label: '- 분류 선택 -', value: '' }, ...(twoNewArr ? twoNewArr : [])];
      });
    }
  }, [selectedCategory1]);

  // 3뎁스처리
  useEffect(() => {
    if (data?.data_list?.length > 0) {
      const firstFiter = selectedCategory1
        ? data.data_list
            .map((el) =>
              selectedCategory1 === el.category_idx && el.child_category_list
                ? el.child_category_list
                : null,
            )
            .filter((el) => el !== null)[0]
        : null;

      const twoFilter = selectedCategory2
        ? firstFiter
            .map((el) =>
              selectedCategory2 === el.category_idx && el.child_category_list
                ? el.child_category_list
                : null,
            )
            .filter((el) => el !== null)[0]
        : null;
      const thirdNewArr = twoFilter
        ? twoFilter.map((el) => ({ label: el.name, value: el.category_idx }))
        : null;

      setCategoryArrThird(() => {
        return [{ label: '- 분류 선택 -', value: '' }, ...(thirdNewArr ? thirdNewArr : [])];
      });
    }
  }, [selectedCategory2]);

  // 상품 수정 요청
  // mutation 요청--
  const { mutate: onSendEdtiProdcutAPI } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.product.editProduct,
    apiMultipartPost: true,
    onErrorFn: (error) => {
      const errorData = error.response.data;
      openPopup({
        title: '안내',
        content: errorData.message,
      });
    },
    onSuccessFn: () => {
      queryClient.refetchQueries([queryKeys.productList], { force: true });
      queryClient.refetchQueries([queryKeys.myProductList], { force: true });
      onClickClosePopup();
    },
  });

  // 요청 핸들 // 더블클릭 방지
  const onSendProduct = () => {
    if (!ValidateInput(selectedCategory1) || selectedCategory1 === 0) {
      openPopup({ title: '안내', content: '분류1을 선택해주세요.' });
      return;
    }
    if (!ValidateInput(selectedCategory2) || selectedCategory2 === 0) {
      openPopup({ title: '안내', content: '분류2을 선택해주세요.' });
      return;
    }
    if (!ValidateInput(selectedCategory3) || selectedCategory3 === 0) {
      openPopup({ title: '안내', content: '분류3을 선택해주세요.' });
      return;
    }

    // json파싱할 데이터 파싱 후 아래에서 사용
    const propertiesParse = JSON.parse(productData.properties);

    const properties = {
      category1: selectedCategory1,
      category2: selectedCategory2,
      category3: selectedCategory3,
      made_by_country: propertiesParse?.made_by_country,
      made_by_company: propertiesParse?.made_by_company,
      b2b_price: propertiesParse?.b2b_price,
      b2c_price: propertiesParse?.b2c_price,
      contentImagesUrl: propertiesParse?.contentImagesUrl ? propertiesParse.contentImagesUrl : null,
    };

    const data = {
      name: productData.name,
      standard: productData.standard,
      product_unit: productData.product_unit,
      product_usage: productData.product_usage,
      content: productData.content,
      price: productData.price,
      stock_quantity: productData.stock_quantity,
      min_order_quantity: productData.min_order_quantity,
      product_category_idx: selectedCategory3,
      product_type: PRODUCT_TYPE.GENERAL,
      properties: JSON.stringify(properties),
      is_available: 1,
    };

    const formData = new FormData();
    formData.append('product', JSON.stringify(data));
    formData.append('product_idx', productIdx);

    onSendEdtiProdcutAPI({ apiBody: formData });
  };

  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: '0',
        left: '0',
        zIndex: '900',
      }}
    >
      <div
        style={{
          position: 'absolute',
          borderRadius: '10px',
          width: '350px',
          minHeight: '500px',
          backgroundColor: colorList.white,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -60%)',
          boxShadow: shadow.boxShadow,
        }}
      >
        <div
          style={{
            padding: '5px 10px',
            textAlign: 'right',
            backgroundColor: colorList.lightGray,
            borderBottom: '1px solid' + colorList.gray,
            borderRadius: '10px 10px 0 0',
          }}
        >
          <ImCancelCircle size={20} onClick={onClickClosePopup} style={{ cursor: 'pointer' }} />
        </div>
        <div style={{ padding: '20px' }}>
          <div style={{ marginBottom: '10px' }}>
            <p style={{ fontSize: '12px', marginBottom: '5px' }}>
              <span style={{ color: colorList.darkGray }}>상품명</span>{' '}
              <span style={{ color: colorList.gray }}>|</span>
            </p>
            <p style={{ fontSize: '15px', fontWeight: '600', lineHeight: '1.5' }}>
              {productData?.name}
            </p>
          </div>
          <div style={{ padding: '10px 20px', backgroundColor: '#efefef', borderRadius: '10px' }}>
            <p style={{ textAlign: 'center', marginBottom: '10px' }}>카테고리 수정</p>

            <div>
              {isError || itemIsError ? (
                <ErrorFetching isError={isError || itemIsError} />
              ) : (
                <>
                  <div style={{ position: 'relative', width: '100%', marginBottom: '15px' }}>
                    <p style={{ fontSize: '14px', padding: '10px 0' }}>
                      분류1 <span style={{ color: 'rgb(238, 106, 123)' }}>*</span>
                    </p>
                    <SelectCustom
                      data={categoryArrFirst || [{ label: '- 분류 선택 -', value: '' }]}
                      value={selectedCategory1 || ''}
                      onChangeEvent={(e) => {
                        const newValue = Number(e.target.value);
                        setSelectedCategory1(newValue);
                        setSelectedCategory2(null); // 분류1이 바뀌면 분류2 초기화
                        setSelectedCategory3(null); // 분류1이 바뀌면 분류3도 초기화
                      }}
                      maxWidth="100%"
                    />
                  </div>

                  <div style={{ position: 'relative', width: '100%', marginBottom: '15px' }}>
                    <p style={{ fontSize: '14px', padding: '10px 0' }}>
                      분류2 <span style={{ color: 'rgb(238, 106, 123)' }}>*</span>
                    </p>
                    <SelectCustom
                      data={categoryArrTwo || [{ label: '- 분류 선택 -', value: '' }]}
                      value={selectedCategory2 || ''}
                      onChangeEvent={(e) => {
                        const newValue = Number(e.target.value);
                        setSelectedCategory2(newValue);
                        setSelectedCategory3(null); // 분류2가 바뀌면 분류3 초기화
                      }}
                      maxWidth="100%"
                    />
                  </div>

                  <div style={{ position: 'relative', width: '100%', marginBottom: '15px' }}>
                    <p style={{ fontSize: '14px', padding: '10px 0' }}>
                      분류3 <span style={{ color: 'rgb(238, 106, 123)' }}>*</span>
                    </p>
                    <SelectCustom
                      data={categoryArrThird || [{ label: '- 분류 선택 -', value: '' }]}
                      value={selectedCategory3 || ''}
                      onChangeEvent={(e) => setSelectedCategory3(Number(e.target.value))}
                      maxWidth="100%"
                    />
                  </div>
                </>
              )}
            </div>
            <div style={{ textAlign: 'right' }}>
              <BtnNormal fontSize="15px" padding="5px 10px" onClickEvent={onSendProduct}>
                수정
              </BtnNormal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
