import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { endPoint } from '../../../../api/endPoint';
import style from '../../../../assets/css/PayFinal.module.scss';
import ErrorFetching from '../../../../components/error/ErrorFetching';
import HeaderTitle from '../../../../components/HeaderTitle';
import SectionLayout from '../../../../components/layout/SectionLayout';
import { usePopup } from '../../../../components/popup/usePopup';
import { CACHE_TIME } from '../../../../constant/NUMBER_CONSTANT';
import { ORDER_ITEM_STATUS } from '../../../../constant/TYPE_CONSTANT';
import { queryKeys } from '../../../../react-query/queryKeyConstant';
import { useQueryInstance } from '../../../../react-query/useQueryInstance';
import { dateFormatHelper } from '../../../../utils/dateHelper';
import { IsLoggedIn } from '../../../../utils/IsLoggedIn';

// type = true 이면 Order 아니면 Sales
const OrderSalesDetail = ({ type }) => {
  const navigate = useNavigate();

  const { openPopup } = usePopup();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const { idx: order_item_idx } = useParams();

  const { data: item, isError } = useQueryInstance({
    queryKey: [type ? queryKeys.getOrderItem : queryKeys.getSalesItem, order_item_idx],
    apiMethod: 'get',
    apiEndPoint: type ? endPoint.order.getOrderItem : endPoint.sales.getSalesItem,
    apiQueryParams: { order_item_idx },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: CACHE_TIME.DEFAULT,
    selectFn: (data) => {
      const item = data.data;
      const newData = {
        ...item,
        order_properties: JSON.parse(item.order_properties),
        product_option_name: item.order_item_properties
          ? JSON.parse(item.order_item_properties).product_option_name
          : null,
      };
      return newData;
    },
  });

  if (isError) return <ErrorFetching isError={isError} />;

  // orderList
  if (type)
    return (
      <SectionLayout minHeight="400px" maxWidth="900px" padding="30px 30px 70px 30px">
        <HeaderTitle
          conMargin="30px 0"
          textAlign="center"
          fontSize="24px"
          fontWeight="500"
          backBtn={true}
        >
          주문 상세 내역
        </HeaderTitle>
        <div className={style.mainContainer}>
          <div className={style.textBoxWrap} style={{ maxWidth: '600px' }}>
            <p className={style.payGoodsName_detail}>
              상품명: <span>{item?.product_name} {item?.product_option_name ? '(' + item?.product_option_name + ')' : ''}</span>
            </p>
            <p className={style.memberName}>
              판매처: <span>{item?.company_name}</span>
            </p>
            <p className={style.payOid}>
              주문번호: <span>{item?.order_number}</span>
            </p>
            <p className={style.payTime}>
              주문일시: <span>{dateFormatHelper(item?.order_time)}</span>
            </p>
            <p className={style.payTime}>
              택배사: <span>{item?.order_properties?.shipping_company_name}</span>
            </p>
            <p className={style.payTime}>
              배송지 (우편):
              <span>{`${item?.address?.address1 ? item?.address?.address1 : ''} ${
                item?.address?.address2 ? item?.address?.address2 : ''
              } (${item?.address?.post_code ? item?.address?.post_code : ''})`}</span>
            </p>
            <p className={style.payType}>
              결제형태:
              <span>
                {item?.payment_type === 10
                  ? `카드결제 (${item?.order_properties?.payment_result?.PCD_PAY_CARDNAME}: ${item?.order_properties?.payment_result?.PCD_PAY_CARDNUM})`
                  : `계좌이체 (${item?.order_properties?.payment_result?.PCD_PAY_BANKNAME}: ${item?.order_properties?.payment_result?.PCD_PAY_BANKNUM})`}
              </span>
            </p>

            <p className={style.payProccess}>
              결제상태:
              <span>
                {item?.order_item_status === ORDER_ITEM_STATUS.ORDER
                  ? '입금완료'
                  : item?.order_item_status === ORDER_ITEM_STATUS.ORDER_COMPLETE
                  ? '구매확정'
                  : item?.order_item_status === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE
                  ? '구매확정'
                  : item?.order_item_status === ORDER_ITEM_STATUS.CANCEL
                  ? '주문취소'
                  : item?.order_item_status === ORDER_ITEM_STATUS.REFUND_REQUEST
                  ? '환불요청'
                  : item?.order_item_status === ORDER_ITEM_STATUS.REFUND
                  ? '환불완료' :
                    '관리자 문의'}
              </span>
            </p>

            <p className={style.payType}>
              주문수량:
              <span>{item?.order_count} 개 </span>
            </p>

            <p className={style.payTotalPrice}>
              총 결제금액:
              <span>{item?.order_price?.toLocaleString('ko-KR')} ₩</span>
            </p>

            <p className={style.payType} style={{ fontSize: '12px' }}>
              * 모두 vat포함 금액
            </p>
          </div>
        </div>
        <div className={style.btnWrap}>
          {/* {item?.order_item_status === ORDER_ITEM_STATUS.ORDER && (
              <>
                <BtnNormal flex="1" bgColor={colorList.main} onClickEvent={() => {}}>
                  주문취소
                </BtnNormal>
              </>
            )} */}
          {/* {(item?.order_item_status === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE ||
            item?.order_item_status === ORDER_ITEM_STATUS.REFUND_REQUEST) && (
            <BtnNormal
              flex="1"
              bgColor={colorList.main}
              onClickEvent={() => {
                openPopup({ title: '안내', content: '서비스 준비 중' });
                // fianlCalculateHandler();
              }}
            >
              취소
            </BtnNormal>
          )} */}
          {item?.order_item_status === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE && (
            <p className={style.complete_text}>구매확정되었습니다.</p>
          )}
          {item?.order_item_status === ORDER_ITEM_STATUS.CANCEL && (
            <p className={style.complete_text}>환불완료 된 상품입니다.</p>
          )}
        </div>
      </SectionLayout>
    );

  if (!type)
    return (
      <SectionLayout minHeight="400px" maxWidth="900px" padding="30px 30px 70px 30px">
        <HeaderTitle
          conMargin="30px 0"
          textAlign="center"
          fontSize="24px"
          fontWeight="500"
          backBtn={true}
        >
          판매 상세 내역
        </HeaderTitle>
        <div className={style.mainContainer}>
          <div className={style.textBoxWrap} style={{ maxWidth: '600px' }}>
            <p className={style.payGoodsName_detail}>
              상품명: <span>{item?.product_name} {item?.product_option_name ? '(' + item?.product_option_name + ')' : ''}</span>
            </p>
            <p className={style.memberName}>
              구매자: <span>{item?.name} ({item?.company_name})</span>
            </p>
            <p className={style.payOid}>
              주문번호: <span>{item?.order_number}</span>
            </p>
            <p className={style.payTime}>
              주문일시: <span>{dateFormatHelper(item?.order_time)}</span>
            </p>
            <p className={style.payTime}>
              택배사: <span>{item?.order_properties?.shipping_company_name}</span>
            </p>
            <p className={style.payTime}>
              배송지 (우편):
              <span>{`${item?.address?.address1 ? item?.address?.address1 : ''} ${
                item?.address?.address2 ? item?.address?.address2 : ''
              } (${item?.address?.post_code ? item?.address?.post_code : ''})`}</span>
            </p>
            <p className={style.payType}>
              결제형태:
              <span>
                {item?.payment_type === 10
                  ? `카드결제 (${item?.order_properties?.payment_result?.PCD_PAY_CARDNAME})`
                  : `계좌이체 (${item?.order_properties?.payment_result?.PCD_PAY_BANKNAME})`}
              </span>
            </p>

            <p className={style.payProccess}>
              결제상태:
              <span>
                {item?.order_item_status === ORDER_ITEM_STATUS.ORDER
                  ? '입금완료'
                  : item?.order_item_status === ORDER_ITEM_STATUS.ORDER_COMPLETE
                  ? '구매확정'
                  : item?.order_item_status === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE
                  ? '정산완료'
                  : item?.order_item_status === ORDER_ITEM_STATUS.CANCEL
                  ? '주문취소'
                  : item?.order_item_status === ORDER_ITEM_STATUS.REFUND_REQUEST
                  ? '환불요청'
                  : item?.order_item_status === ORDER_ITEM_STATUS.REFUND
                  ? '환불완료'
                  :  '관리자 문의'}
              </span>
            </p>

            {/* <p className={style.payType}>
                업체등록계좌:
                <span>
                  ({findBank(item?.bank_standard_code)}) {item?.account_number}
                </span>
              </p> */}

            <p className={style.payType}>
              주문수량:
              <span>{item?.order_count} 개 </span>
            </p>

            <p className={style.payTotalPrice}>
              총 결제금액:
              <span>{item?.order_price?.toLocaleString('ko-KR')} ₩</span>
            </p>

            <p className={style.payType} style={{ fontSize: '12px' }}>
              * 모두 vat포함 금액
            </p>
          </div>
        </div>
        <div className={style.btnWrap}>
          {/* {item?.order_item_status === ORDER_ITEM_STATUS.ORDER && (
              <>
                <BtnNormal onClickEvent={() => {}} flex="1">
                  정산
                </BtnNormal>
                <BtnNormal flex="1" bgColor={colorList.main} onClickEvent={() => {}}>
                  환불
                </BtnNormal>
              </>
            )} */}
          {/* {(item?.order_item_status === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE ||
            item?.order_item_status === ORDER_ITEM_STATUS.REFUND_REQUEST) && (
            <BtnNormal
              flex="1"
              bgColor={colorList.main}
              onClickEvent={() => {
                openPopup({ title: '안내', content: '서비스 준비 중' });
                // fianlCalculateHandler();
              }}
            >
              취소
            </BtnNormal>
          )} */}
          {item?.order_item_status === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE && (
            <p className={style.complete_text}>정산완료되었습니다.</p>
          )}
          {item?.order_item_status === ORDER_ITEM_STATUS.CANCEL && (
            <p className={style.complete_text}>환불완료 된 상품입니다.</p>
          )}
        </div>
      </SectionLayout>
    );
};

export default OrderSalesDetail;
