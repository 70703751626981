import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { BOARD_TYPE } from '../../constant/TYPE_CONSTANT';
import AdminHeader from '../admin/AdminHeader';
import AdminProductList from '../admin/adminProductList/AdminProductList';
import CategoryList from '../admin/category/CategoryList';
import JoinAdmin from '../admin/joinAdmin/JoinAdmin';
import MemberDetail from '../admin/member/MemberDetail';
import MemberList from '../admin/member/MemberList';
import PaymentDetail from '../admin/payment/PaymentDetail';
import PaymentList from '../admin/payment/PaymentList';
import PopupWrite from '../admin/popup/PopupWrite';
import NotFound from '../NotFound';
import { BoardWrite } from '../pages/board/BoardWrite';
import ProtectedRoute from './ProtectedRoute';

const AdminRoutes = () => {
  return (
    <>
      {/* <ProtectedRoute isAdmin={true}>
      </ProtectedRoute> */}
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute isAdmin={true}>
              <AdminHeader />
              {/* <AdminMain /> */}
            </ProtectedRoute>
          }
        >
          <Route
            path="/payment/payment_list"
            element={
              <ProtectedRoute isAdmin={true}>
                <PaymentList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment/payment_list/detail/:idx"
            element={
              <ProtectedRoute isAdmin={true}>
                <PaymentDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/member/list"
            element={
              <ProtectedRoute isAdmin={true}>
                <MemberList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/member/list/detail/:idx?"
            element={
              <ProtectedRoute isAdmin={true}>
                <MemberDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/member/list/join_admin"
            element={
              <ProtectedRoute isAdmin={true}>
                <JoinAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/category/category_list"
            element={
              <ProtectedRoute isAdmin={true}>
                <CategoryList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/board/notice/write/:idx?"
            element={
              <ProtectedRoute isAdmin={true}>
                <BoardWrite boardType={BOARD_TYPE.NOTICE} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/popup"
            element={
              <ProtectedRoute isAdmin={true}>
                <PopupWrite />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product_list"
            element={
              <ProtectedRoute isAdmin={true}>
                <AdminProductList />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default AdminRoutes;
