import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TbReload } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';

import { endPoint, endPointMutation } from '../../../api/endPoint';
import style from '../../../assets/css/AdminPage_PaymentList.module.scss';
import { colorList } from '../../../assets/style/theme.jsx';
import EmptyDataPage from '../../../components/EmptyDataPage.jsx';
import ErrorFetching from '../../../components/error/ErrorFetching';
import InputSearch from '../../../components/InputSearch.jsx';
import Pagination from '../../../components/Pagination.jsx';
import { usePopup } from '../../../components/popup/usePopup.js';
import SelectCustom from '../../../components/SelectCustom.jsx';
import { CACHE_TIME } from '../../../constant/NUMBER_CONSTANT.js';
import { ORDER_ITEM_STATUS } from '../../../constant/TYPE_CONSTANT.js';
import { queryKeys } from '../../../react-query/queryKeyConstant';
import { useMutationInstance } from '../../../react-query/useMutationInstance';
import { useQueryInstance } from '../../../react-query/useQueryInstance';
import { IsLoggedIn } from '../../../utils/IsLoggedIn.js';
import ValidateInput from '../../../utils/ValidateInput';

function renderStatusContent(statusCode) {
  switch (statusCode) {
    case ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE:
      return <span>정산완료</span>;

    case ORDER_ITEM_STATUS.ORDER:
      return <span>입금완료</span>;

    case ORDER_ITEM_STATUS.ORDER_COMPLETE:
      return <span>구매확정</span>;

    case ORDER_ITEM_STATUS.CANCEL:
      return <span>주문취소</span>;

    case ORDER_ITEM_STATUS.REFUND_REQUEST:
      return <span>환불요청</span>;

    case ORDER_ITEM_STATUS.REFUND:
      return <span>환불완료</span>;

    default:
      return <span>관리자문의</span>;
  }
}

function PaymentList() {
  const { openPopup, closePopup } = usePopup();

  const navigate = useNavigate();

  const isLoggedIn = IsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/pages/member/login');
      openPopup({ title: '안내', content: '로그인을 해주세요.' });
    }
  }, [isLoggedIn]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = !params.get('page') ? '1' : params.get('page');
  const count = !params.get('count') ? '20' : params.get('count');
  const status_code = params.get('status_code');
  const search_order_number = params.get('search_order_number');
  const search_product_name = params.get('search_product_name');

  const {
    data: { data_list, total_count },
    isError,
    refetch,
  } = useQueryInstance({
    queryKey: [
      queryKeys.paymentList,
      status_code,
      search_order_number,
      search_product_name,
      page,
      count,
    ],
    apiMethod: 'get',
    apiEndPoint: endPoint.order.getOrderList,
    apiQueryParams: { status_code, search_order_number, search_product_name, page, count },
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
    cacheTime: CACHE_TIME.DEFAULT,
  });

  // 관리자 - 주문 취소 ---
  const { mutate: onCancelMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.orderCancel,
    onErrorFn: () => {
      openPopup({ title: '오류', content: '다시 시도해주세요.' });
    },
    onSuccessFn: () => {
      closePopup();
      refetch();
    },
  });

  const onCancel = (item) => {
    openPopup({
      title: '안내',
      content: '정말 취소하시겠습니까?',
      input: true,
      inputMaxLength: 12,
      setInputValue: (cancel_reason) => {
        if (!cancel_reason)
          return openPopup({ title: '오류', content: '취소사유를 입력해주세요.' });

        const cancelData = {
          order_idx: item.order_idx,
          order_item_idx: item.order_item_idx,
          is_admin: 1,
          cancel_reason,
        };

        onCancelMutation({
          apiBody: cancelData,
        });
      },

      twoButton: true,
    });
  };
  // --------------

  // 관리자 입금완료 onClick -------------
  const { mutate: onCompleteMutation } = useMutationInstance({
    apiMethod: 'post',
    apiEndPoint: endPointMutation.order.orderComplete,
    onErrorFn: (error) => {
      openPopup({ title: '오류', content: error.response.data.message });
    },
    onSuccessFn: () => {
      closePopup();
      refetch();
    },
  });

  const onComplete = (item) => {
    const completeData = {
      // member_idx: memberIdx,
      order_idx: item.order_idx,
      order_item_idx: item.order_item_idx,
      is_admin: 1,
    };

    openPopup({
      title: '안내',
      content: '정말 입금완료 하시겠습니까?',
      onComplete: () =>
        onCompleteMutation({
          apiBody: completeData,
        }),
      twoButton: true,
    });
  };

  function DateFormatHelper(date) {
    if (!ValidateInput(date)) {
      return '';
    }

    const dateParts = date.split('T');
    const timeParts = dateParts[1].split(':');
    return `${dateParts[0]} ${timeParts[0]}:${timeParts[1]}`;
  }

  // 셀렉터데이터
  const [selectValue, setSelectValue] = useState(0);

  // 셀렉터 핸들
  const selectorHandler = useCallback((e) => {
    const selectValue = Number(e.target.value);

    switch (selectValue) {
      // 모두보기
      case 0:
        navigate('/admin/payment/payment_list');
        setSelectValue(0);
        break;

      // 정산완료
      case ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE:
        navigate(
          `/admin/payment/payment_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE);
        break;

      // 입금완료
      case ORDER_ITEM_STATUS.ORDER:
        navigate(
          `/admin/payment/payment_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.ORDER,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.ORDER);
        break;

      // 주문확정
      case ORDER_ITEM_STATUS.ORDER_COMPLETE:
        navigate(
          `/admin/payment/payment_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.ORDER_COMPLETE,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.ORDER_COMPLETE);
        break;

      // 주문취소
      case ORDER_ITEM_STATUS.CANCEL:
        navigate(
          `/admin/payment/payment_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.CANCEL,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.CANCEL);
        break;

      // 환불요청
      case ORDER_ITEM_STATUS.REFUND_REQUEST:
        navigate(
          `/admin/payment/payment_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.REFUND_REQUEST,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.REFUND_REQUEST);
        break;

      // 환불완료
      case ORDER_ITEM_STATUS.REFUND:
        navigate(
          `/admin/payment/payment_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.REFUND,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.REFUND);
        break;

      // 관리자문의
      case ORDER_ITEM_STATUS.ERROR:
        navigate(
          `/admin/payment/payment_list/?page=1&count=20&status_code=${String(
            ORDER_ITEM_STATUS.ERROR,
          )}`,
        );
        setSelectValue(ORDER_ITEM_STATUS.ERROR);
        break;
      default:
        break;
    }
  }, []);

  // 검색창 타입 셀렉터
  const [searchType, setSearchType] = useState('1');

  // 검색어 담기
  const searchValue = useRef('');

  // 검색창 핸들
  const searchHandler = (e) => {
    e.preventDefault();

    const value = searchValue.current.value;

    if (!value) return navigate('/admin/payment/payment_list');

    navigate(
      `/admin/payment/payment_list/?page=1&count=20${
        searchType === '0' ? `&search_product_name=${value}` : `&search_order_number=${value}`
      }`,
    );
    searchValue.current.value = '';
    setSelectValue(0);
  };

  // 검색 후 처음으로 돌아가기
  const searchReset = useCallback(() => {
    navigate('/admin/payment/payment_list');
  }, []);

  if (isError) return <ErrorFetching isError={isError} />;

  return (
    <div className={style.content}>
      <div className={style.section}>
        <div className={style.title_box}>
          <div>
            <p className={style.title}>결제관리</p>
            {search_product_name && (
              <p style={{ marginTop: '10px' }}>[{search_product_name}] 에 대한 검색 결과</p>
            )}
            {search_order_number && (
              <p style={{ marginTop: '10px' }}>[{search_order_number}] 에 대한 검색 결과</p>
            )}
            {status_code && status_code !== '0' && (
              <p style={{ marginTop: '10px' }}>
                [
                {Number(status_code) === ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE
                  ? '정산완료'
                  : Number(status_code) === ORDER_ITEM_STATUS.ORDER
                  ? '입금완료'
                  : Number(status_code) === ORDER_ITEM_STATUS.ORDER_COMPLETE
                  ? '구매확정'
                  : Number(status_code) === ORDER_ITEM_STATUS.CANCEL
                  ? '주문취소'
                  : Number(status_code) === ORDER_ITEM_STATUS.REFUND_REQUEST
                  ? '환불요청'
                  : Number(status_code) === ORDER_ITEM_STATUS.REFUND
                  ? '환불완료'
                  : '관리자문의'}
                ] 에 대한 필터 결과
              </p>
            )}
          </div>

          {/* 주문내역 조회 오른쪽 박스 */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '600px',
              gap: '10px',
            }}
          >
            {(search_product_name || search_order_number) && (
              <TbReload
                size="60"
                color={colorList.main}
                style={{ cursor: 'pointer' }}
                onClick={searchReset}
              />
            )}
            <SelectCustom
              maxWidth="150px"
              data={[
                { label: '주문번호검색', value: '1' },
                { label: '상품이름검색', value: '0' },
              ]}
              onChangeEvent={(e) => setSearchType(e.target.value)}
              value={searchType}
            />
            <div
              style={{
                width: '100%',
                borderColor: colorList.lightGray,
                borderStyle: 'solid',
                borderWidth: '0 2px 0 0',
                paddingRight: '20px',
                marginRight: '10px',
              }}
            >
              <InputSearch
                boxWidth="100%"
                boxHeight="38px"
                borderRadius="5px"
                inputPadding="10px"
                inputRef={searchValue}
                iconOnClickEvent={searchHandler}
              />
            </div>
            <SelectCustom
              maxWidth="130px"
              value={selectValue}
              data={[
                { label: '모두보기', value: 0 },
                { label: '정산완료', value: ORDER_ITEM_STATUS.SETTLEMENT_COMPLETE },
                { label: '입금완료', value: ORDER_ITEM_STATUS.ORDER },
                { label: '구매확정', value: ORDER_ITEM_STATUS.ORDER_COMPLETE },
                { label: '주문취소', value: ORDER_ITEM_STATUS.CANCEL },
                // { label: '환불요청', value: ORDER_ITEM_STATUS.REFUND_REQUEST },
                { label: '환불완료', value: ORDER_ITEM_STATUS.REFUND },
                { label: '관리자문의', value: ORDER_ITEM_STATUS.ERROR },
              ]}
              onChangeEvent={selectorHandler}
            />
          </div>
        </div>
        <div className={style.item_list_body}>
          <div className={style.item_list_head}>
            <p className={style.title_info}>상품정보 (옵션)</p>
            <p className={style.title_date}>주문번호 (주문일자)</p>
            <p className={style.title_price}>주문금액 (수량)</p>
            <p className={style.title_status}>주문상태</p>
          </div>

          {data_list?.length === 0 ? (
            <EmptyDataPage title="결제내역이 없습니다." />
          ) : (
            <div className={style.breakdown_body}>
              {data_list?.map((item, index) => {
                const imgUrl = item.img_url;

                var imageUrl = '';

                if (ValidateInput(imgUrl)) {
                  imageUrl = imgUrl;
                }

                const statusCode = item.order_item_status;

                const productOptionName = item.order_item_properties ? JSON.parse(item.order_item_properties).product_option_name : null;

                return (
                  <div
                    className={style.breakdown_item}
                    key={index}
                    onClick={() => navigate(`detail/${item?.order_item_idx}`)}
                  >
                    <div className={style.con_info}>
                      <div
                        className={style.thumbnail}
                        style={{ backgroundImage: `url('${imageUrl}')` }}
                      ></div>
                      <div className={style.info_box}>
                        <p className={style.p_company}>{item.company_name}</p>
                        <p className={style.p_name}>{item.product_name} {productOptionName ? '(' + productOptionName + ')' : ''}</p>
                      </div>
                    </div>
                    <div className={style.con_date}>
                      <p className={style.p_order_number}>{item.order_number}</p>
                      <p className={style.p_order_time}>{DateFormatHelper(item.order_time)}</p>
                    </div>
                    <div className={style.con_price}>
                      <p className={style.p_price}>{item.order_price.toLocaleString('ko-KR')}원</p>
                      <p className={style.p_num}>{item.order_count}(개)</p>
                    </div>
                    <div className={style.con_status}>
                      {renderStatusContent(statusCode, item, onComplete, onCancel)}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {data_list?.length !== 0 && (
        <Pagination
          conMargin="50px 0"
          totalCount={total_count}
          page={page}
          count={count}
          naviPath={`/admin/payment/payment_list/?${
            status_code ? `status_code=${status_code}&` : ''
          }${search_order_number ? `search_order_number=${search_order_number}&` : ''}${
            search_product_name ? `search_product_name=${search_product_name}&` : ''
          }`}
        />
      )}
    </div>
  );
}

export default PaymentList;
