/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const data = {
  '002': 'KDB산업은행',
  '003': 'IBK기업은행',
  '004': 'KB국민은행',
  '007': '수협은행',
  '011': 'NH농협은행',
  '012': '농협중앙회(단위농축협)',
  '020': '우리은행',
  '023': 'SC제일은행',
  '027': '한국씨티은행',
  '031': '대구은행',
  '032': '부산은행',
  '034': '광주은행',
  '035': '제주은행',
  '037': '전북은행',
  '039': '경남은행',
  '045': '새마을금고중앙회',
  '048': '신협중앙회',
  '050': '저축은행중앙회',
  '064': '산림조합중앙회',
  '071': '우체국',
  '081': '하나은행',
  '088': '신한은행',
  '089': '케이뱅크',
  '090': '카카오뱅크',
  '092': '토스뱅크',
  218: 'KB증권',
  238: '미래에셋대우',
  240: '삼성증권',
  243: '한국투자증권',
  247: 'NH투자증권',
  261: '교보증권',
  262: '하이투자증권',
  263: '현대차증권',
  264: '키움증권',
  265: '이베스트투자증권',
  266: 'SK증권',
  267: '대신증권',
  269: '한화투자증권',
  271: '토스증권',
  278: '신한금융투자',
  279: 'DB금융투자',
  280: '유진투자증권',
  287: '메리츠증권',
};
export const BankStandardCodeList = () => {
  return Object.entries(data).map(([code, bankName]) => ({
    bankName,
    code,
  }));
};
