/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  line-height: 1.5;
`;

const POLICY_TERMS = () => {
  return (
    <P>
      제 1 조 이용약관(이하 '약관')은 스판 (이하 '회사')과 이용 고객(이하 '회원')간에 회사가
      제공하는 인터넷 서비스의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
      <br />
      <br />
      제 2 조 (약관의 효력 및 변경)
      <br />
      (1) 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이
      발생합니다.
      <br />
      (2) 이 약관에 동의하는 것은 정기적으로 웹을 방문하여 약관의 변경사항을 확인하는 것에 동의함을
      의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지
      않습니다.
      <br />
      (3) 회사는 합리적인 사유가 발생될 경우에는 이 약관을 변경할 수 있으며, 약관을 변경할 경우에는
      지체 없이 이를 사전에 공시합니다.
      <br />
      <br />
      제 3 조 (약관 외 준칙)
      <br />
      (1) 이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 서비스별 안내의 취지에 따라 적용할
      수 있습니다.
      <br />
      <br />
      제 4 조 (정의)이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
      <br />
      (1) 회 원: 회사와 서비스 이용계약을 체결한 개인이나 법인 또는 법인에 준하는 단체
      <br />
      (2) 아이디(id): 회원식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와
      숫자의 조합
      <br />
      (3) 비밀번호 : 회원의 비밀 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합
      <br />
      (4) 해지 : 회사 또는 회원이 서비스 개통 후 이용계약을 해약하는 것
      <br />
      <br />
      제 5 조 (이용계약의 성립)본 이용약관에 대한 동의는 이용신청 당시 해당 '스판'이 운영하는
      서비스의 '동의' 버튼을 누름으로써 의사표시를 합니다.
      <br />
      제 6 조 (이용 신청 및 승낙)
      <br />
      (1) 이용신청자는 서비스의 이용자 등록에서 등록 양식을 작성하여 제출함으로써 이용신청을 할 수
      있습니다.
      <br />
      (2) 이용계약은 회원의 이용자등록에 대하여 회사의 이용승낙으로 성립합니다.
      <br />
      (3) 모든 회원은 반드시 회원 본인의 이름 및 전화번호를 제공하여야만 서비스를 이용할 수 있으며,
      실명으로 등록하지 않은 사용자는 일체의 권리를 주장할 수 없습니다.
      <br />
      <br />
      제 7 조 (회원 id의 변경) 다음의 경우 회사는 직권으로 회원 id를 변경하도록 요구하거나, 회원의
      신청에 의해 회원 id를 변경할 수 있습니다.
      <br />
      (1) 회원 id가 회원의 전화번호, e-mail 등으로 등록되어 회원 사생활 침해의 우려가 있는 경우
      <br />
      (2) 타인에게 혐오감을 주거나 미풍양속에 저해되는 경우
      <br />
      (3) 기타 회사 소정의 합리적인 이유가 있는 경우
      <br />
      <br />
      제 8 조 (서비스의 내용) 회사가 제공하는 서비스는 다음과 같습니다. - 일빵(MRO협동조합 내 B2B
      거래 플랫폼)
      <br />
      <br />
      제 9 조 (게시물 또는 내용물의 삭제) 이용자의 공개게시물의 내용이 다음 각 호에 해당하는 경우
      '스판'은 이용자에게 사전 통지 없이 해당 공개게시물을 삭제할 수 있고, 해당 이용자의 회원 자격을
      제한, 정지 또는 상실시킬 수 있습니다.
      <br />
      (1) 다른 이용자 또는 제3자를 비방하거나 명예를 손상시키는 내용
      <br />
      (2) 법적으로 위반되는 내용의 정보, 문장, 도형 등을 유포하는 내용
      <br />
      (3) 범죄행위와 관련이 있다고 판단되는 내용
      <br />
      (4) 다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용
      <br />
      (5) 기타 관계 법령에 위배된다고 판단되는 내용
      <br />
      <br />
      제 10 조 (저작권의 귀속 및 이용제한)
      <br />
      (1) '스판'이 제작한 저작물에 저작권 기타 지적저작권은 '스판'에 귀속됩니다.
      <br />
      (2) '스판'에 가입한 "회원"이 타 사이트의 저작물을 게시한 경우 '스판'에는 책임이 없습니다.
      <br />
      <br />
      제 11 조 (서비스 중단)
      <br />
      (1) '스판'은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한
      경우에는 서비스의 제공을 일시적으로 중단할 수 있고, 새로운 서비스로의 교체 기타 '스판'이
      적절하다고 판단하는 사유에 기하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.
      <br />
      (2) 제1항에 의한 서비스 중단의 경우에는 '스판'이 정한 방법으로 이용자에게 통지합니다. 다만,
      '스판'이 통제할 수 없는 사유로 인한 서비스의 중단(시스템 관리자의 고의,과실이 없는 디스크
      장애, 시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
      <br />
      <br />
      제 12 조 (서비스 사용)
      <br />
      (1) '스판'에서 제공하는 서비스 중 '커뮤니티 및 자유게시판'서비스는 '회원'간의 정보교류의
      공간으로서 제공하며 '회원'간의 분쟁에 대하여는 '스판'은 책임지지 않습니다.
      <br />
      <br />
      제 13 조 (회사의 의무)
      <br />
      (1) '스판'은 법령과 본 약관이 금지하거나 법적으로 위반하는 행위를 하지 않으며 본 약관이 정하는
      바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위해서 노력합니다.
      <br />
      (2) '스판'은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보
      포함)보호를 위한 보안 시스템을 구축합니다.
      <br />
      (3) '스판'은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
      <br />
      (4) '스판'은 이용자가 서비스를 이용함에 있어 '스판'의 고의 또는 중대한 과실로 인하여 입은
      손해를 배상할 책임을 부담합니다
      <br />
      <br />
      제 14 조 (이용자의 의무)이용자는 다음 각 호의 행위를 하여서는 안됩니다.
      <br />
      (1) 회원가입신청 또는 변경 시 허위내용을 등록하는 행위
      <br />
      (2) 회원가입 후 허위제품을 등록 판매하는 행위
      <br />
      (3) '스판'에서 서비스하는 사이트에 게시된 정보를 변경하는 행위
      <br />
      (4) '스판' 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위
      <br />
      (5) 다른 회원의 id를 도용하는 행위
      <br />
      (6) 정크메일(junk mail), 스팸메일(spam mail), 행운의 편지(chain letters), 피라미드 조직에
      가입할 것을 권유하는 메일, 외설 또는 폭력적인 메시지, 화상, 음성 등이 담긴 메일을 보내거나
      기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위.
      <br />
      (7) 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)의 전송 또는
      게시하는 행위
      <br />
      (8) 회사의 직원이나 '일빵' 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여
      글을 게시하거나 메일을 발송하는 행위
      <br />
      (9) 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된
      소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나
      전자우편으로 발송하는 행위
      <br />
      (10) 스토킹(stalking) 등 다른 이용자를 괴롭히는 행위
      <br />
      (11) 다른 이용자에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
      <br />
      (12) '스판'이 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한 규정을 위반하는 행위
      <br />
      제1항에 해당하는 행위를 한 이용자가 있을 경우 '스판'은 본 약관에 따라 이용자의 회원자격을
      적절한 방법으로 제한, 정지 및 상실시킬 수 있습니다. 이용자는 그 귀책사유로 인하여 '스판'이나
      다른 이용자가 입은 손해를 배상할 책임이 있습니다.
      <br />
      <br />
      제 15 조 (공개게시물 삭제) 이용자의 공개게시물의 내용이 다음 각 호에 해당하는 경우 '스판'은
      이용자에게 사전 통지 없이 해당 공개게시물을 삭제할 수 있고, 해당 이용자의 회원 자격을 제한,
      정지 또는 삭제 시킬 수 있습니다.
      <br />
      (1) 허위제품 등록 및 도용이 판단되는 내용
      <br />
      (2) 다른 이용자 또는 제3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용
      <br />
      (3) 법적으로 위반되는 내용의 정보, 문장, 도형 등을 유포하는 내용
      <br />
      (4) 범죄행위와 관련이 있다고 판단되는 내용
      <br />
      (5) 다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용
      <br />
      (6) 기타 관계 법령에 위배된다고 판단되는 내용
      <br />
      <br />
      제 16 조 (면책 조항)
      <br />
      (1) 회사는 회원이 서비스를 통해 게재 또는 전송한 정보, 자료, 사실의 정확성, 신뢰성 등 내용에
      관하여 어떠한 보증도 하지 아니하며 회원의 서비스 자료에 대한 취사선택 또는 이용으로 발생하는
      손해 등에 대해 책임을 지지 아니합니다.
      <br />
      (2) 회사는 회원이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인 한 손해에
      관하여 책임을 지지 아니합니다
      <br />
      (3) 회사는 회원 상호간 또는 회원과 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는
      개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
      <br />
      <br />
      제 17 조 (법률의 적용)
      <br />
      (1) 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는
      법원을 관할 법원으로 합니다.
      <br />
      부칙 이 약관은 2023년 08월 30일부터 시행합니다. 이 약관의 적용일 전에 시행되는 직전의 약관은
      이 약관으로 대체됩니다.
      <br />
      기존약관 확인하기
    </P>
  );
};

export default POLICY_TERMS;
