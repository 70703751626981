import React from 'react';

import '../assets/css/Sub.css';

import { Link, useNavigate } from 'react-router-dom';

// import styled from 'styled-components';

import { colorList } from '../assets/style/theme';
import BtnNormal from './BtnNormal';

function MypageButtonComponent({ dataList = [{ title: '제목', current: false, url: '/' }] }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="con-head">
        <p className="con-title">마이페이지</p>
      </div>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="page-tab">
          {dataList?.map((el) => (
            <Link to={el.url} key={el.title}>
              <button type="button" className={`page-tab-item ${el.current ? 'current' : ''}`}>
                {el.title}
              </button>
            </Link>
          ))}
        </div>
        <BtnNormal
          fontSize="16px"
          fontWeight="400"
          bgColor={colorList.main}
          padding="15px 30px"
          onClickEvent={() => navigate('/pages/product/write')}
        >
          상품등록
        </BtnNormal>
      </div>
    </>
  );
}

export default React.memo(MypageButtonComponent);

// 자동 컴포넌트화
// const ConHead = styled.div`
//   display: flex;
//   width: 100vw;
//   margin-bottom: 52px;
// `;

// const Title = styled.p`
//   font-size: 34px;
//   font-family: 'Pretendard-bold';
//   margin: 0 auto;
// `;

// export const SectionButton = ({ sectionArr, sectionValue, onClickEvent, index }) => {
//   return (
//     <ConHead>
//       {/*<p className="con-title">{ contentTitle }</p>*/}
//       <Title>{sectionValue}</Title>
//     </ConHead>
//   );
// };
