import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import App from './App';
import SEOHelmet from './components/seo/SEOHelmet';
import { queryClient } from './react-query/queryClient';
import reportWebVitals from './reportWebVitals';

const element = (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <CookiesProvider>
          <HelmetProvider>
            <BrowserRouter>
              <SEOHelmet />
              <App />
              <ReactQueryDevtools />
            </BrowserRouter>
          </HelmetProvider>
        </CookiesProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(element);

reportWebVitals();
