import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import style from '../../assets/css/AdminPageHeader.module.scss';
import { MEMBER_ROLE } from '../../constant/TYPE_CONSTANT';
import { decodingCrypto } from '../../utils/crypto';

function AdminHeader() {
  const memberRole = Number(decodingCrypto(localStorage.getItem('member_role')));

  return (
    <>
      <div className={style.admin_container}>
        <header className={style.admin_header}>
          <h1>관리자 대시보드</h1>
        </header>
        <div className={style.nav_main_wrap}>
          <nav className={style.admin_nav}>
            <ul>
              <li style={{ marginBottom: '20px' }}>
                <Link to="/">
                  <p>나가기</p>
                </Link>
              </li>
              {memberRole === MEMBER_ROLE.ADMIN ? (
                <li style={{ marginBottom: '20px' }}>
                  <Link to="/admin/payment/payment_list">
                    <p>결제 관리</p>
                  </Link>
                </li>
              ) : null}
              <li style={{ marginBottom: '20px' }}>
                <Link to="/admin/category/category_list">
                  <p>카테고리 관리</p>
                </Link>
              </li>
              <li style={{ marginBottom: '20px' }}>
                <Link to="/admin/product_list">
                  <p>상품 관리</p>
                </Link>
              </li>
              <li style={{ marginBottom: '20px' }}>
                <Link to="/admin/member/list">
                  <p>회원 관리</p>
                </Link>
              </li>
              {/* <li>
                <a href="#orders">주문 관리</a>
              </li> */}
              {/* TODO 배포때문에 잠시 주석 */}
              {/* <li>
                <Link to="/admin/board/notice/write">
                  <div>공지사항 글쓰기</div>
                </Link>
              </li> */}
              {/* TODO 배포때문에 잠시 주석 */}
              {/* <li>
                <Link to="/admin/popup">
                  <div>팝업 광고 등록</div>
                </Link>
              </li> */}
            </ul>
          </nav>
          <main className={style.admin_main}>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}

export default AdminHeader;
